import React from 'react'

export default function NavBarDashboard({ open }) {
  const target = window !== window.top ? '_blank' : '_self'
  return (
    <div className="dashboard">
      <a href="https://titletab.com/" target={target} className="logo-details">
        <div className="logo_name">
          <i className="bx bx-bookmarks"></i>titletab
        </div>
      </a>
      <div className="dashboard-open">
        {open ? (
          <i className="bx bx-menu-alt-right" id="btn"></i>
        ) : (
          <i className="bx bx-menu-alt-left" id="btn"></i>
        )}
      </div>
    </div>
  )
}
