import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import { usePage } from '../context/PageContext'
import { Container } from '../style/ModalSmall.styled'
import { toast } from 'react-toastify'
import initialLanguage from '../language/initialLanguage'
import Button from '../button/Button'
import Input from '../input/Input'
import Title from '../modal/Title'
import LineHr from '../minor/LineHr'
import Content from '../modal/Content'
import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getButton,
  checkEmail,
  checkPassword,
  checkPasswordMatch,
  clearError,
  getFocus,
} from '../functionSet/ModalFunction'

export default function SignUp({ button, fullWidth, mobileClose }) {
  const { signup, signupGoogle, signupFacebook, signupTwitter } = useAuth()
  const { global, setDrag, setGlobal, setRefresh, lang } = useData()
  const { settings, styleData } = global

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  const navigate = useNavigate()
  const [modal, setModal] = useState(true)

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({
      device,
      styleData,
      button,
    }),
    width: getWidth({ styleData, fullWidth }),
  }

  const { signUp, setSignUp } = usePage()
  const [activeDelay, setActiveDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(signUp)
      setDrag(false)
    }, 1)
  }, [signUp])

  let modalActiv = activeDelay

  async function handleClose(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/')
      setSignUp(false)
    }, 400)
  }

  async function handleBack(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate(-1)
      setSignUp(false)
    }, 400)
  }

  async function handleSignIn(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/sign-in')
      setSignUp(false)
    }, 400)
  }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  async function handleGoogle(e) {
    e.preventDefault()

    try {
      await signupGoogle()
      navigate('/')
      setGlobal()
      setRefresh('handleGoogle')
      toast.success('Sign in successfully completed!')
    } catch {
      toast.error('Failed to sign in!')
    }
  }

  async function handleFacebook(e) {
    e.preventDefault()

    try {
      await signupFacebook()
      navigate('/')
      setGlobal()
      setRefresh('handleFacebook')
      toast.success('Sign in successfully completed!')
    } catch {
      toast.error('Failed to sign in!')
    }
  }

  async function handleTwitter(e) {
    e.preventDefault()

    try {
      await signupTwitter()
      navigate('/')
      setGlobal()
      setRefresh('handleTwitter')
      toast.success('Sign in successfully completed!')
    } catch {
      toast.error('Failed to sign in!')
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()

    const emailValid = checkEmail({
      email: email,
      id: 'email_su',
      device: device,
      afterFocus: false,
    })

    const passwordValid = checkPassword({
      password: password,
      id: 'password_su',
      device: device,
      afterFocus: false,
    })

    const confirmPasswordValid = checkPassword({
      password: passwordConfirm,
      id: 'password2_su',
      device: device,
      afterFocus: false,
    })

    const passwordMatch = checkPasswordMatch({
      password: password,
      passwordValid: passwordValid,
      id: 'password_su',
      ////////
      confirmPassword: passwordConfirm,
      confirmPasswordValid: confirmPasswordValid,
      id2: 'password2_su',
    })

    if (
      !emailValid ||
      !passwordValid ||
      !confirmPasswordValid ||
      !passwordMatch
    ) {
      return
    }

    if (password !== passwordConfirm) {
      return toast.error('Passwords do not match!')
    }

    try {
      await signup(email, password)
      navigate('/')
      setGlobal()
      setRefresh('handleSubmit')
      toast.success('Sign in successfully completed!')
    } catch {
      toast.error('Failed to sign in!')
    }
  }

  function clearInput() {
    setEmail('')
    setPassword('')
    setPasswordConfirm('')
    clearError('email_su', initialLanguage[lang].auth_email_address)
    clearError('password_su', initialLanguage[lang].auth_password)
    clearError('password2_su', initialLanguage[lang].auth_password_confirmation)
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  useEffect(() => {
    //Для поля Email
    getFocus('email_su', setModal)
    //Для поля Пароль
    getFocus('password_su', setModal)
    //Для поля Подтверждение пароля
    getFocus('password2_su', setModal)
  }, [])

  return (
    <div
      className={getBlackout({ device, modalActiv })}
      onClick={modal ? handleClose : null}
    >
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={getClassName({
              modalActiv,
              device,
              dark: settings.styleTheme,
            })}
          >
            <Title
              children={initialLanguage[lang].auth_sign_up_titletab}
              dark={settings.styleTheme}
              handleBack={handleBack}
              handleClose={handleClose}
            />

            {device === 'desktop' ? <div className="indent20"></div> : null}

            <Button onClick={handleGoogle} color={'google'} isDesktop={true}>
              {initialLanguage[lang].auth_sign_google}
            </Button>

            <Button
              onClick={handleFacebook}
              color={'facebook'}
              isDesktop={true}
            >
              {initialLanguage[lang].auth_sign_facebook}
            </Button>

            <Button onClick={handleTwitter} color={'twitter'} isDesktop={true}>
              {initialLanguage[lang].auth_sign_twitter}
            </Button>

            <Content>
              <LineHr
                text={initialLanguage[lang].auth_or}
                dark={settings.styleTheme}
              />

              <Input
                id="email_su"
                labelId="email_su_label"
                type="email"
                state={email}
                setState={setEmail}
                placeholder={''}
                label={initialLanguage[lang].auth_email_address}
                autoComplete="new-password"
                required={true}
                dark={settings.styleTheme}
              />

              <Input
                id="password_su"
                labelId="password_su_label"
                type="password"
                state={password}
                setState={setPassword}
                placeholder={''}
                label={initialLanguage[lang].auth_password}
                autoComplete="new-password"
                required={true}
                dark={settings.styleTheme}
              />

              <Input
                id="password2_su"
                labelId="password2_su_label"
                type="password"
                state={passwordConfirm}
                setState={setPasswordConfirm}
                placeholder={''}
                label={initialLanguage[lang].auth_password_confirmation}
                autoComplete="new-password"
                required={true}
                dark={settings.styleTheme}
              />
            </Content>

            <Button
              onClick={handleSubmit}
              button={true}
              color={'blue'}
              isDesktop={true}
              type="submit"
            >
              {initialLanguage[lang].auth_sign_up}
            </Button>

            <Button
              onClick={handleSignIn}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].auth_have_account}
            </Button>

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
    </div>
  )
}
