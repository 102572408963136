import React from 'react'

export default function AboutEn() {
  return (
    <>
      <p>
        Welcome to TitleTab - Speed Dial Dashboard, the ultimate tab management
        solution designed to make your browsing experience faster and more
        convenient than ever before. Our solution offers a range of features
        that can help you personalize your browser tabs, prevent accidental
        browser closures, and share your favorite links with other users.
      </p>
      <p>Here are some of the amazing features that TitleTab offers:</p>
      <p>
        <strong>Personalized Start Page</strong>
      </p>
      <p>
        Our StartPage feature allows you to easily drag and drop your tabs to
        arrange them in a way that suits your needs. You can also customize each
        tab with various display options, including a screenshot of the webpage,
        text, logos for popular sites, or your own images. We offer both light
        and dark theme, so you can choose the one that suits your preference.
      </p>
      <p>
        <strong>Tab Organization: Folders and Limitless Spaces</strong>
      </p>
      <p>
        TitleTab offers powerful tab organization features that allow you to
        structure your tabs in a way that works for you. You can group tabs into
        folders by simply dragging and dropping one tab onto another.
        Additionally, you can create new spaces to help you separate different
        types of tabs, such as work-related tabs and hobby-related tabs. With
        TitleTab's tab organization features, you can easily manage your tabs
        and keep your browsing experience organized and efficient.
      </p>
      <p>
        <strong>Public Space with a Unique Link</strong>
      </p>
      <p>
        TitleTab users can also create a public space that can be accessed by
        other users who have the link. When you activate the "Public Space"
        button for the first time, we will ask you to fill in some fields that
        are necessary to start your public space. The most important field that
        needs to be filled in is the unique link address ("Path for public space
        access") through which your space will be accessible with the tabs you
        want to share with other users. Once you activate the public space, you
        can start adding tabs to it. This feature is especially useful if you
        want to share tabs with friends or colleagues for collaborative work.
        With TitleTab's public space feature, you can easily share your tabs and
        collaborate with others in a convenient and secure way.
      </p>
      <p>
        <strong>OneTab: Save all open tabs into one</strong>
      </p>
      <p>
        For popular browsers, an extension is available that allows users to
        manage their open tabs in their browser. By simply clicking on the
        extension button, you can save all (or selected) tabs into one tab that
        will store a list of sites and be ready to reopened for you at any time.
        This functionality significantly saves the resources of your device, as
        open tabs continue to work and use the memory of your device.
      </p>
      <p>
        It is also worth noting that we do not track what exactly you save in
        this way, which guarantees your privacy, but at the same time, we cannot
        save this information for you. Therefore, this information is only
        available locally in this browser on this device you are working on.
        This is the cost of your privacy.
      </p>
      <p>
        <strong>Infinity New Tab</strong>
      </p>
      <p>
        Infinity New Tab is another feature of our extension that provides an
        endless browsing experience. With infinity new tab, users can continue
        browsing without the fear of accidentally closing their last open tab.
        This feature automatically adds a new tab to the left of the last open
        tab, ensuring that users always have a tab to fall back on. Once
        additional tabs are opened, the Infinity New Tab disappears, allowing
        users to browse freely without any interruption. This feature provides
        users with peace of mind and prevents the frustration that comes with
        accidentally closing the last tab.
      </p>
      <p>
        <strong>TitleTab Extensions</strong>
      </p>
      <p>
        TitleTab is compatible with popular web browsers such as Google Chrome,
        Mozilla Firefox, and Microsoft Edge. Our extension can be easily
        downloaded and installed from the official extension stores of each
        browser.
      </p>
      <p>Here is a list of where you can download TitleTab:</p>
      <ul>
        <li>
          <a
            href="https://chrome.google.com/webstore/detail/titletab-web-dashboard/ldlndadamgafkigafpajckkjbblnepcb"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome Web Store
          </a>
        </li>
        <li>
          <a
            href="https://addons.mozilla.org/firefox/addon/titletab-web-dashboard/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox Add-ons
          </a>
        </li>
        <li>
          <a
            href="https://microsoftedge.microsoft.com/addons/detail/titletab-web-dashboard/mnckgifglkiecanfbdpnajiplpabkphh"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge Add-ons
          </a>
        </li>
      </ul>
      <p>
        Once installed, you can start using TitleTab to customize your new tab
        page, save and organize your tabs, prevent accidental tab closure, and
        share links with others. Don't miss out on the opportunity to streamline
        your browsing experience with TitleTab. Download our extension today!
      </p>
      <p>
        <strong>Privacy and Security</strong>
      </p>
      <p>
        TitleTab takes your privacy and security seriously. All your data is
        stored securely on your device and in the cloud, and we never share your
        information with third parties.
      </p>
      <hr />
      <p>
        TitleTab is the ultimate tab management solution for anyone looking to
        take control of their browsing experience. Try it today and see the
        difference for yourself!
      </p>
      <p>
        If you have any questions about TitleTab or need assistance with any of
        our features, please don't hesitate to reach out to us at&nbsp;
        <a
          href="mailto:info@titletab.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@titletab.com
        </a>
        . Our team is always happy to help and answer any questions you may
        have. We value your feedback and are committed to providing the best
        possible user experience, so please don't hesitate to contact us if you
        have any suggestions or feedback.
      </p>
    </>
  )
}
