import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useData } from '../../context/DataContext'

import initialLanguage from '../../language/initialLanguage'
import { Container } from '../../style/ModalSmall.styled'

import Button from '../../button/Button'
import Input from '../../input/Input'
import Title from '../../modal/Title'
import Content from '../../modal/Content'

import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getButton,
  checkRequired,
  clearError,
  getFocus,
} from '../../functionSet/ModalFunction'
import LineHr from '../../minor/LineHr'
import ListScroll from '../../listscroll/ListScroll'
import initialList from '../../listscroll/initialList'
import withLocalForage from '../../context/withLocalForage'

const AddSpaceModal = ({
  active,
  setActive,
  button,
  fullWidth,
  mobileClose,
  addSpaceModal,
}) => {
  const { currentUser } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`

  const { global, lang } = useData()
  const { settings, styleData } = global

  let { device } = useData()
  const inputActive = device === 'desktop' ? true : false
  if (window.innerWidth <= 1200) device = 'mobile'

  const iconList = initialList

  const [activeDelay, setActiveDelay] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(active)
    }, 1)
  }, [active])

  let modalActiv = activeDelay

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({ device, styleData, button }),
    width: getWidth({ styleData, fullWidth }),
  }

  const [modal, setModal] = useState(true)
  const [name, setName] = useState('')
  const [icon, setIcon] = useState()

  async function handleSubmit(e) {
    e.preventDefault()

    const urlRequired = checkRequired({
      value: name,
      device: device,
      id: 'name_add',
      afterFocus: true,
    })

    if (!urlRequired) {
      return
    }

    let newIcon = icon ? icon : iconList[Object.keys(global.spaces).length]

    let newSpace = {
      space: name,
      search: {},
      icon: newIcon,
      columnOrder: ['column-1'],
      columns: {
        'column-1': {
          id: 'column-1',
          taskIds: [],
        },
      },
      tasks: {},
    }

    addSpaceModal(path, newSpace)

    setName('')
    setActiveDelay(false)
    setTimeout(() => {
      setActive(false)
    }, 400)
  }

  async function handleClose(e) {
    e.preventDefault()
    setActiveDelay(false)
    setTimeout(() => {
      setActive(false)
    }, 400)
    clearInput()
  }

  async function handleBack(e) {
    e.preventDefault()
    handleClose(e)
    clearInput()
  }

  function clearInput() {
    setName('')
    setIcon()
    clearError('name_add', initialLanguage[lang].asm_space_name)
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
      // if (e.key === "Enter") {
      //   handleSubmit(e);
      // }
    })
  }, [])

  useEffect(() => {
    getFocus('name_add', setModal)
  }, [])

  //если устройство ПК, то фокус на поле ввода через 400 мс
  useEffect(() => {
    if (inputActive) {
      setTimeout(() => {
        if (document.getElementById('name_add') != null) {
          document.getElementById('name_add').focus()
        }
      }, 300)
    }
  }, [active])

  return (
    <div
      className={getBlackout({ device, modalActiv })}
      onClick={modal ? handleClose : null}
    >
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={getClassName({
              modalActiv,
              device,
              dark: settings.styleTheme,
            })}
          >
            <Title
              children={initialLanguage[lang].asm_add_new_space}
              dark={settings.styleTheme}
              handleBack={handleBack}
              handleClose={handleClose}
            />

            <Content>
              <Input
                id="name_add"
                labelId="name_add_label"
                type="text"
                state={name}
                setState={setName}
                placeholder={''}
                label={initialLanguage[lang].asm_space_name}
                autoComplete="off"
                dark={settings.styleTheme}
              />

              <LineHr
                text={initialLanguage[lang].asm_select_icon}
                dark={settings.styleTheme}
              />
              <ListScroll
                icon={icon}
                setIcon={setIcon}
                dark={settings.styleTheme}
              />
            </Content>

            <Button
              onClick={handleSubmit}
              button={true}
              color={'blue'}
              isDesktop={true}
              dark={settings.styleTheme}
              type="submit"
            >
              {initialLanguage[lang].atm_add_title}
            </Button>

            <Button
              onClick={handleClose}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].atm_add_cancel}
            </Button>

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
    </div>
  )
}

export default withLocalForage(AddSpaceModal)
