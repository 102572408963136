import styled from 'styled-components'

export const Container = styled.div`
  width: ${(props) => props.styleData.width}px;
  position: ${(props) => props.styleDataposition};
  top: ${(props) => props.styleDataTop};
  left: 0px;
  right: 0px;
  margin: auto;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  opacity: 0;
  transition: opacity 0.2s;

  @media (max-width: 1200px) {
    top: 85px;
    left: auto;
    right: 0px;
  }

  .dragMiddle {
    transform: translate(
      ${(props) => props.styleData.tabFullWidth}px,
      0px
    ) !important;
    transition: transform 0.4s !important;
    transition-delay: 0.07s !important;
  }
  .dragLast {
    z-index: 1 !important;
    transform: translate(
      -${(props) => props.styleData.transformX}px,
      ${(props) => props.styleData.transformY}px
    ) !important;
    transition: transform 0.5s !important;
    transition-delay: 0.07s !important;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1) !important;
  }

  .dragRightTop {
    z-index: 1 !important;
    transform: translate(
      ${(props) => props.styleData.transformX}px,
      -${(props) => props.styleData.transformY}px
    ) !important;
    transition: transform 0.5s !important;
    transition-delay: 0.07s !important;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1) !important;
  }
  .dragLeft {
    transform: translate(
      -${(props) => props.styleData.tabFullWidth}px,
      0px
    ) !important;
    transition: transform 0.4s !important;
    transition-delay: 0.07s !important;
  }

  .noDrag {
    z-index: 1 !important;
    transform: translate(0px, 0px) !important;
    transition-delay: 0.07s !important;
  }

  .shadowFolderLight {
    -webkit-box-shadow: ${(props) =>
      props.styleData.boxShadowFolderActiveLight};
    -moz-box-shadow: ${(props) => props.styleData.boxShadowFolderActiveLight};
    box-shadow: ${(props) => props.styleData.boxShadowFolderActiveLight};
    border-radius: ${(props) => props.styleData.tabWidth / 20}px;
    transition: all 0.4s !important;
  }
  .shadowFolderDark {
    -webkit-box-shadow: ${(props) => props.styleData.boxShadowFolderActiveDark};
    -moz-box-shadow: ${(props) => props.styleData.boxShadowFolderActiveDark};
    box-shadow: ${(props) => props.styleData.boxShadowFolderActiveDark};
    border-radius: ${(props) => props.styleData.tabWidth / 20}px;
    transition: all 0.4s !important;
  }
`
