import styled from 'styled-components'

export const Container = styled.div`
  .filesUploader {
    position: relative;
    width: calc(100% - ${({ styled }) => styled.borderWidth / 10}vw);
    width: calc(
      100% -
        max(
          ${({ styled }) => styled.borderWidth / 10}vw,
          ${({ styled }) => styled.borderWidth * 2}px
        )
    );
    height: ${({ styled }) => styled.heightContainer / 20}vw;
    min-height: ${({ styled }) => styled.heightContainer}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-width: ${({ styled }) => styled.borderWidth / 20}vw;
    border-width: max(
      ${({ styled }) => styled.borderWidth / 20}vw,
      ${({ styled }) => styled.borderWidth}px
    );
    border-style: ${({ styled }) => styled.borderStyle};
    border-color: ${({ styled }) => styled.borderColor};
    border-radius: ${({ styled }) => styled.borderWidth / 10}vw;
    border-radius: max(
      ${({ styled }) => styled.borderWidth / 10}vw,
      ${({ styled }) => styled.borderWidth * 2}px
    );
    background-color: ${({ styled }) => styled.backgroundColor};
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: ${({ styled }) => styled.fontSize / 20}vw;
    font-size: max(
      ${({ styled }) => styled.fontSize / 20}vw,
      ${({ styled }) => styled.fontSize}px
    );
  }

  .filesUploader.dark {
    border-color: ${({ styled }) => styled.borderColorDark};
    background-color: ${({ styled }) => styled.backgroundColorDark};
  }

  .filesUploader.onDrag {
    border-color: ${({ styled }) => styled.borderColorHover};
    background-color: ${({ styled }) => styled.backgroundColorHover};
  }

  .filesUploader.dark.onDrag {
    border-color: ${({ styled }) => styled.borderColorHoverDark};
    background-color: ${({ styled }) => styled.backgroundColorHoverDark};
  }

  .filesUploader:hover {
    border-color: ${({ styled }) => styled.borderColorHover};
    background-color: ${({ styled }) => styled.backgroundColorHover};
  }

  .filesUploader.dark:hover {
    border-color: ${({ styled }) => styled.borderColorHoverDark};
    background-color: ${({ styled }) => styled.backgroundColorHoverDark};
  }

  .filesUploader .drag {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .filesUploader .drag .icon {
    min-width: max(
      ${({ styled }) => styled.heightContainer / 20}vw,
      ${({ styled }) => styled.heightContainer}px
    );

    height: ${({ styled }) => styled.heightContainer / 20}vw;
    min-height: ${({ styled }) => styled.heightContainer}px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    pointer-events: none;
  }

  .filesUploader .drag .icon i {
    font-size: ${({ styled }) => styled.heightContainer / 26}vw;
    font-size: max(
      ${({ styled }) => styled.heightContainer / 26}vw,
      ${({ styled }) => styled.heightContainer / 1.3}px
    );
    color: ${({ styled }) => styled.iconColor};
    pointer-events: none;
    transition: all 0.3s ease-in-out;
  }

  .filesUploader.dark .drag .icon i {
    color: ${({ styled }) => styled.iconColorDark};
  }

  .filesUploader .drag:hover .icon i {
    color: ${({ styled }) => styled.iconColorHover};
  }

  .filesUploader.dark .drag:hover .icon i {
    color: ${({ styled }) => styled.iconColorHoverDark};
  }

  .filesUploader.onDrag .drag .icon i {
    color: ${({ styled }) => styled.iconColorHover};
  }

  .filesUploader.dark.onDrag .drag .icon i {
    color: ${({ styled }) => styled.iconColorHoverDark};
  }

  .filesUploader .drag .label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    float: left;
    color: ${({ styled }) => styled.textColor};
    pointer-events: none;
    overflow: hidden;
  }

  .filesUploader.dark .drag .label {
    color: ${({ styled }) => styled.textColorDark};
  }

  .filesUploader .drag:hover .label {
    color: ${({ styled }) => styled.textColorHover};
  }

  .filesUploader.dark .drag:hover .label {
    color: ${({ styled }) => styled.textColorHoverDark};
  }

  .filesUploader .drag .label p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filesUploader .drag .types {
    min-width: max(
      ${({ styled }) => styled.widthTypesSizeLabel / 20}vw,
      ${({ styled }) => styled.widthTypesSizeLabel}px
    );
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ styled }) => styled.textColor};
    font-size: ${({ styled }) => styled.fontSize / 20}vw;
    font-size: max(
      ${({ styled }) => styled.fontSize / 20}vw,
      ${({ styled }) => styled.fontSize}px
    );
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filesUploader.dark .drag .types {
    color: ${({ styled }) => styled.textColorDark};
  }

  .filesUploader .drag:hover .types {
    color: ${({ styled }) => styled.textColorHover};
  }

  .filesUploader.dark .drag:hover .types {
    color: ${({ styled }) => styled.textColorHoverDark};
  }

  .filesUploader input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }
`
