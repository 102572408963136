import styled from 'styled-components'

export const Container = styled.div`
  width: ${(props) => props.styleData.width}px;
  margin: 0 auto;
  position: relative;
`

export const TaskList = styled.div`
  min-height: ${(props) => props.styleData.tabFullHeight}px;
`
