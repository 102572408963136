export default function UrlValid({ url }) {
  //if url is not contain protocol
  if (!url.includes('://')) {
    url = 'http://' + url
  }

  //get url path (replase from url urlProtocol and urlDomain)
  let urlPath = url.replace(
    url.split('://')[0] + '://' + url.split('://')[1].split('/')[0],
    ''
  )
  if (urlPath === '') {
    urlPath = '/'
  }

  //Перевести в нижний регистр
  url = url.toLowerCase()

  //Выполняем валидацию ссылки
  let urlValid = getUrlValid(url)
  if (!urlValid) urlValid = getIpValid(url)
  if (!urlValid) return null

  //get url protocol
  let urlProtocol = url.split('://')[0] + '://'
  //get url domain
  let urlDomain = url.split('://')[1].split('/')[0]

  //url contains www
  let urlWWW = url.includes('www.')

  //if urlWWW is true, remove www
  if (urlWWW) {
    urlDomain = urlDomain.replace('www.', '')
  }

  let urlState = {
    url: url,
    urlDomain: urlDomain,
    urlPath: urlPath,
    urlProtocol: urlProtocol,
    urlProtocol1: urlProtocol.includes('http') ? 'http://' : urlProtocol,
    urlProtocol2: urlProtocol.includes('http') ? 'https://' : urlProtocol,
    urlWWW: urlWWW,
  }

  return urlState

  // let urlСhecked
  // try {
  //   urlСhecked = new URL(url)
  // } catch {
  //   //В случае если ссылка введена без протокола, назначить протокол по умолчанию
  //   url = 'http://' + url
  //   urlСhecked = new URL(url)
  // }
  // let urlCheck = /^[a-z0-9\-\.]+\.[a-z]{2,9}/
  // if (!urlCheck.test(urlСhecked.hostname)) return null

  // //Если протокол ссылки удовлетворяет, то вернуть ссылку, иначе ноль
  // if (
  //   urlСhecked.protocol === 'https:' ||
  //   urlСhecked.protocol === 'http:' ||
  //   urlСhecked.protocol === 'ftp:' ||
  //   urlСhecked.protocol === 'file:'
  //   // urlСhecked.protocol === 'rtmp:' ||
  //   // urlСhecked.protocol === 'rtsp:'
  // ) {
  //   return urlСhecked.href
  // } else {
  //   return null
  // }
  function getUrlValid(url) {
    //Выполняем валидацию ссылки
    //Данный код влияет на серверный скрипт валидации ссылки. Необходима синхронизация
    let urlValid =
      //check http/https url
      /^(https?:\/\/)?([\da-zа-я\.-]+)\.([a-zа-я\.]{2,6})/.test(url) ||
      //check validate ftp url
      /^(ftp:\/\/)?([\da-zа-я\.-]+)\.([a-zа-я\.]{2,6})/.test(url) ||
      //check validate file url
      /^(file:\/\/)?([\da-zа-я\.-]+)\.([a-zа-я\.]{2,6})/.test(url) ||
      //check validate rtmp url
      /^(rtmp:\/\/)?([\da-zа-я\.-]+)\.([a-zа-я\.]{2,6})/.test(url) ||
      //check validate rtsp url
      /^(rtsp:\/\/)?([\da-zа-я\.-]+)\.([a-zа-я\.]{2,6})/.test(url)

    //check validate mail url
    // /^(mailto:\/\/)?([\da-zа-я\.-]+)\.([a-zа-я\.]{2,6})/.test(url)
    //check validate tel url
    // /^(tel:\/\/)?([\da-zа-я\.-]+)\.([a-zа-я\.]{2,6})/.test(url)
    //check validate sms url
    // /^(sms:\/\/)?([\da-zа-я\.-]+)\.([a-zа-я\.]{2,6})/.test(url)
    //check validate skype url
    // /^(skype:\/\/)?([\da-zа-я\.-]+)\.([a-zа-я\.]{2,6})/.test(url)
    //check validate whatsapp url
    // /^(whatsapp:\/\/)?([\da-zа-я\.-]+)\.([a-zа-я\.]{2,6})/.test(url)
    //check validate telegram url
    // /^(telegram:\/\/)?([\da-zа-я\.-]+)\.([a-zа-я\.]{2,6})([\/\w \.-]*)*\/?$/.test(url)
    if (!urlValid) return null
    return urlValid
  }

  function getIpValid(url) {
    //Выполняем валидацию ссылки
    //Данный код влияет на серверный скрипт валидации ссылки. Необходима синхронизация
    //ссылка может иметь протокол, а может и не иметь
    let urlValid =
      //check validate ip url
      /^(https?:\/\/)?((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/.test(
        url
      ) ||
      //check validate ftp url
      /^(ftp:\/\/)?((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/.test(
        url
      ) ||
      //check validate file url
      /^(file:\/\/)?((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/.test(
        url
      ) ||
      //check validate rtmp url
      /^(rtmp:\/\/)?((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/.test(
        url
      ) ||
      //check validate rtsp url
      /^(rtsp:\/\/)?((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/.test(
        url
      )

    if (!urlValid) return null
    return urlValid
  }
}
