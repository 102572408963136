import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { useData } from '../../context/DataContext'

import { Container } from '../../style/ModalSmall.styled'

import Button from '../../button/Button'
import Input from '../../input/Input'
import Title from '../../modal/Title'
import Content from '../../modal/Content'
import initialLanguage from '../../language/initialLanguage'
import LineHr from '../../minor/LineHr'

import {
  getContextHeight,
  getWidth,
  getClassName,
  checkRequired,
  setError,
  getBlackout,
  getButton,
  getFocus,
} from '../../functionSet/ModalFunction'
import withLocalForage from '../../context/withLocalForage'

const UpdateSpacePublicModal = ({
  active,
  setActive,
  spaceUpdate,
  button,
  fullWidth,
  mobileClose,
  updateSpaceModal,
  changeSpaceBar,
  updatePublicSpaceModal,
}) => {
  const { currentUser } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`
  let uid = currentUser ? currentUser.uid : null

  const { global, space, setOutPublic, setDrag, lang } = useData()

  const { settings, styleData } = global

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  let updateSpace = global.spaces[spaceUpdate]

  if (!updateSpace) {
    updateSpace = {
      name: '',
      nickname: '',
      description: '',
    }
  }

  const currentName = updateSpace.name

  const currentNickname = updateSpace.nickname.replace('/', '')

  const currentDescription = updateSpace.description

  const [name, setName] = useState(currentName)
  const [nickname, setNickname] = useState(currentNickname)
  const [description, setDescription] = useState(currentDescription)
  let slash = name ? ' /' : '/'

  let titleInput = nickname
    ? name + slash + nickname + ' » TitleTab - Speed Dial Dashboard'
    : ''

  const [activeDelay, setActiveDelay] = useState(false)
  const [modal, setModal] = useState(true)

  async function handleSubmit(e) {
    e.preventDefault()

    if (!currentUser) return

    const nicknameRequired = checkRequired({
      value: nickname,
      device: device,
      id: 'nickname_add',
      afterFocus: true,
    })

    if (!nicknameRequired) {
      return
    }

    if (nickname.length < 4 || nickname.length > 21) {
      document.getElementById('nickname_add_label').className = 'labelUp'
      document.getElementById('nickname_add_error').className = 'error active'
      document.getElementById('nickname_add_error').innerHTML =
        'min 4 / max 21 chars'
      if (device === 'desktop') {
        if (document.getElementById('nickname_add') != null) {
          document.getElementById('nickname_add').focus()
        }
      }
      return
    }

    if (name.length > 36) {
      document.getElementById('name_add_label').className = 'labelUp'
      document.getElementById('name_add_error').className = 'error active'
      document.getElementById('name_add_error').innerHTML = 'max 36 chars'
      return
    }

    if (description.length > 225) {
      document.getElementById('description_add_label').className = 'labelUp'
      document.getElementById('description_add_error').className =
        'error active'
      document.getElementById('description_add_error').innerHTML =
        'max 225 chars'
      return
    }

    if (document.getElementById('nickname_add_label'))
      document.getElementById('nickname_add_label').style.color = ''

    setName(name || currentName)
    setNickname(nickname || currentNickname)
    setDescription(description || currentDescription)

    const updateSpace = {
      ...global.spaces[spaceUpdate],
      name: name,
      nickname: '/' + nickname,
      description: description,
    }

    const result = await updatePublicSpaceModal(
      path,
      uid,
      updateSpace,
      spaceUpdate
    )

    if (!result) {
      document.getElementById('nickname_add_label').className = 'labelUp'
      document.getElementById('nickname_add_error').className = 'error active'
      document.getElementById('nickname_add_error').innerHTML = 'nickname taken'
      if (device === 'desktop') {
        if (document.getElementById('nickname_add') != null) {
          document.getElementById('nickname_add').focus()
        }
      }
      return
    }

    setName('')
    setNickname('')
    setDescription('')
    setActiveDelay(false)
    setTimeout(() => {
      setActive(false)
    }, 400)
  }

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(active)
      setDrag(false)
    }, 1)
  }, [active])

  let modalActiv = activeDelay
  const navigate = useNavigate()

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({ device, styleData, button }),
    width: getWidth({ styleData, fullWidth }),
  }

  async function handleClose(e) {
    e.preventDefault()
    // navigate("/");
    if (global.spaces[0] && !global.spaces[0].nickname) {
      changeSpaceBar(path, 1, space)
    }

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      setActive(false)
    }, 400)
  }

  async function handleSignIn(e) {
    handleClose(e)
    navigate('/sign-in')
    setOutPublic(false)
    handleClose(e)
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  useEffect(() => {
    //Для поля Email
    getFocus('nickname_add', setModal)
    getFocus('url_add', setModal)
    getFocus('name_add', setModal)
    getFocus('title_add', setModal)
    getFocus('description_add', setModal)
  }, [])

  useEffect(() => {
    if (device === 'desktop' && !nickname) {
      setTimeout(() => {
        if (document.getElementById('nickname_add') != null) {
          document.getElementById('nickname_add').focus()
        }
      }, 300)
    }
  }, [active])

  //valdidation nickname start
  useEffect(() => {
    if (nickname) {
      setNickname(nickname.toLowerCase())
    }
  }, [nickname])
  const inputField = document.getElementById('nickname_add')
  const pattern = /^[a-zA-Z0-9_]{0,20}$/
  if (inputField) {
    inputField.addEventListener('keydown', (event) => {
      if (
        event.code === 'Delete' ||
        event.code === 'Backspace' ||
        event.code === 'Enter' ||
        event.code === 'ArrowLeft' ||
        event.code === 'ArrowRight' ||
        event.code === 'Tab' ||
        (pattern.test(event.key) && pattern.test(inputField.value))
      ) {
        document.getElementById('nickname_add_label').className = ''
        document.getElementById('nickname_add_error').className = 'error'
        document.getElementById('nickname_add_error').innerHTML = ''
        setNickname(inputField.value)
      } else {
        event.preventDefault()
        let errorType = 'Some error'
        if (/[^a-zA-Z]/g.test(event.key)) {
          // обработка ошибки символов отличных от латиницы
          errorType = 'latin letters only'
        }
        if (/\s/g.test(event.key)) {
          // обработка ошибки пробелов
          errorType = 'spaces not allowed'
        }
        if (/\d/g.test(event.key)) {
          // обработка ошибки цифр
          errorType = 'numbers not allowed'
        }
        if (inputField.value.length > 20) {
          errorType = 'max 21 chars'
        }
        document.getElementById('nickname_add_label').className = 'labelUp'
        document.getElementById('nickname_add_error').className = 'error active'
        document.getElementById('nickname_add_error').innerHTML = errorType
      }
    })
  }
  //valdidation nickname end

  //valdidation name start
  const inputFieldName = document.getElementById('name_add')
  const patternName = /^[\p{L}\u4E00-\u9FFF\d\s._-]{0,35}$/u
  if (inputFieldName) {
    inputFieldName.addEventListener('keydown', (event) => {
      if (
        event.code === 'Delete' ||
        event.code === 'Backspace' ||
        event.code === 'Enter' ||
        event.code === 'ArrowLeft' ||
        event.code === 'ArrowRight' ||
        event.code === 'Tab' ||
        (patternName.test(event.key) &&
          patternName.test(inputFieldName.value) &&
          !/[.,\\]/.test(event.key))
      ) {
        document.getElementById('name_add_label').className = ''
        document.getElementById('name_add_error').className = 'error'
        document.getElementById('name_add_error').innerHTML = ''
        setName(inputFieldName.value)
      } else {
        event.preventDefault()
        let errorType = 'char not available'
        if (inputFieldName.value.length > 35) {
          errorType = 'max 36 chars'
        }
        document.getElementById('name_add_label').className = 'labelUp'
        document.getElementById('name_add_error').className = 'error active'
        document.getElementById('name_add_error').innerHTML = errorType
      }
    })
  }
  //valdidation name end

  //valdidation description start
  const inputFieldDescription = document.getElementById('description_add')
  const patternDescription = /^[\p{L}\u4E00-\u9FFF\d\s.,!?_-]{0,224}$/u
  if (inputFieldDescription) {
    inputFieldDescription.addEventListener('keydown', (event) => {
      if (
        event.code === 'Delete' ||
        event.code === 'Backspace' ||
        event.code === 'Enter' ||
        event.code === 'ArrowLeft' ||
        event.code === 'ArrowRight' ||
        event.code === 'Tab' ||
        (patternDescription.test(event.key) &&
          patternDescription.test(inputFieldDescription.value) &&
          !/[\\]/.test(event.key))
      ) {
        document.getElementById('description_add_label').className = ''
        document.getElementById('description_add_error').className = 'error'
        document.getElementById('description_add_error').innerHTML = ''
        setDescription(inputFieldDescription.value)
      } else {
        event.preventDefault()
        let errorType = 'char not available'
        if (inputFieldDescription.value.length > 100) {
          errorType = 'max 225 chars'
        }
        document.getElementById('description_add_label').className = 'labelUp'
        document.getElementById('description_add_error').className =
          'error active'
        document.getElementById('description_add_error').innerHTML = errorType
      }
    })
  }
  //valdidation description end

  return (
    <div
      className={getBlackout({ device, modalActiv })}
      onClick={modal ? handleClose : null}
    >
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={getClassName({
              modalActiv,
              device,
              dark: settings.styleTheme,
            })}
          >
            <Title
              children={
                global.spaces[0]?.nickname
                  ? initialLanguage[lang].uspm_public_space_settings
                  : initialLanguage[lang].uspm_activate_public_space
              }
              dark={settings.styleTheme}
              handleBack={handleClose}
              handleClose={handleClose}
            />
            <Content>
              {!global.spaces[0]?.nickname ? (
                <div style={{ textAlign: 'center', fontWeight: '500' }}>
                  {initialLanguage[lang].uspm_message}
                </div>
              ) : null}

              <Input
                id="nickname_add"
                labelId="nickname_add_label"
                type="text"
                state={nickname}
                setState={setNickname}
                placeholder={''}
                label={initialLanguage[lang].uspm_path}
                labelUp={true}
                autoComplete="off"
                required={true}
                dark={settings.styleTheme}
              />
              <Input
                id="url_add"
                labelId="url_add_label"
                type="text"
                state={'https://titletab.com/' + nickname}
                readOnly={true}
                copyIcon={true}
                placeholder={''}
                label={initialLanguage[lang].uspm_link}
                labelUp={true}
                autoComplete="off"
                required={true}
                dark={settings.styleTheme}
              />
              <Input
                id="name_add"
                labelId="name_add_label"
                type="text"
                state={name}
                setState={setName}
                placeholder={''}
                label={initialLanguage[lang].uspm_public_space_name}
                labelUp={true}
                autoComplete="off"
                required={true}
                dark={settings.styleTheme}
              />

              <Input
                id="title_add"
                labelId="title_add_label"
                type="text"
                state={titleInput}
                placeholder={''}
                readOnly={true}
                label={initialLanguage[lang].uspm_public_space_title}
                labelUp={true}
                autoComplete="off"
                required={true}
                dark={settings.styleTheme}
              />

              <Input
                id="description_add"
                labelId="description_add_label"
                type="text"
                state={description}
                setState={setDescription}
                placeholder={''}
                label={initialLanguage[lang].uspm_description}
                labelUp={true}
                autoComplete="off"
                required={true}
                dark={settings.styleTheme}
              />
            </Content>

            {currentUser ? (
              <Button
                onClick={handleSubmit}
                button={true}
                color={'blue'}
                isDesktop={true}
                dark={settings.styleTheme}
                type="submit"
              >
                {global.spaces[0].nickname
                  ? initialLanguage[lang].uspm_save_space_settings
                  : initialLanguage[lang].uspm_activate_public_space}
              </Button>
            ) : (
              <Button
                onClick={handleSignIn}
                button={true}
                color={'google'}
                isDesktop={true}
                dark={settings.styleTheme}
              >
                {initialLanguage[lang].uspm_sign_in_to_get_started}
              </Button>
            )}

            <Button
              onClick={handleClose}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].uspm_cancel}
            </Button>

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
    </div>
  )
}

export default withLocalForage(UpdateSpacePublicModal)
