import React, { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import initialLanguage from '../language/initialLanguage'

export default memo(function NavBarCorner() {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const { lang, setOutPublic } = useData()

  const handleSignIn = useCallback(() => {
    navigate('/sign-in')
    setOutPublic(false)
  }, [navigate])

  return (
    <>
      {!currentUser ? (
        <div className="navbarcorner">
          <div className="button-singin" onClick={handleSignIn}>
            {initialLanguage[lang].nb_sign_in}
          </div>
        </div>
      ) : null}
    </>
  )
})
