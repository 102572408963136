import React from 'react'

export default function AboutHr() {
  return (
    <>
      <p>
        Dobro došli u TitleTab - nadzornu ploču za brzo biranje, ultimativno
        rješenje za upravljanje karticama osmišljeno da vaše iskustvo
        pregledavanja učini bržim i praktičnijim nego ikad prije. Naše rješenje
        nudi niz značajki koje vam mogu pomoći da personalizirate kartice
        preglednika, spriječite slučajno zatvaranje preglednika i podijelite
        svoje omiljene veze s drugim korisnicima.
      </p>
      <p>Evo nekih nevjerojatnih značajki koje TitleTab nudi:</p>
      <p>
        <strong>Personalizirana početna stranica</strong>
      </p>
      <p>
        Naša značajka StartPage omogućuje vam jednostavno povlačenje i
        ispuštanje kartica kako biste ih rasporedili na način koji odgovara
        vašim potrebama. Također možete prilagoditi svaku karticu različitim
        opcijama prikaza, uključujući snimku zaslona web-stranice, tekst,
        logotipe popularnih web-mjesta ili vlastite slike. Nudimo i svijetlu i
        tamnu temu, tako da možete odabrati onu koja odgovara vašim željama.
      </p>
      <p>
        <strong>Organizacija kartica: mape i neograničeni prostori</strong>
      </p>
      <p>
        TitleTab nudi snažne značajke organizacije kartica koje vam omogućuju
        strukturiranje kartica na način koji vam odgovara. Kartice možete
        grupirati u mape jednostavnim povlačenjem i ispuštanjem jedne kartice na
        drugu. Osim toga, možete stvoriti nove prostore koji će vam pomoći da
        odvojite različite vrste kartica, kao što su kartice vezane uz posao i
        kartice povezane s hobijem. Uz TitleTab značajke organizacije kartica,
        možete jednostavno upravljati svojim karticama i održavati svoje
        iskustvo pregledavanja organiziranim i učinkovitim.
      </p>
      <p>
        <strong>Javni prostor s jedinstvenom vezom</strong>
      </p>
      <p>
        Korisnici TitleTab-a također mogu stvoriti javni prostor kojem mogu
        pristupiti drugi korisnici koji imaju vezu. Kada prvi put aktivirate
        gumb "Javni prostor", tražit ćemo od vas da ispunite neka polja koja su
        potrebna za pokretanje vašeg javnog prostora. Najvažnije polje koje je
        potrebno ispuniti je jedinstvena adresa linka ("Putanja za pristup
        javnom prostoru") preko kojeg će biti dostupan vaš prostor sa tabovima
        koje želite podijeliti s drugim korisnicima. Nakon što aktivirate javni
        prostor, možete mu početi dodavati kartice. Ova je značajka posebno
        korisna ako želite dijeliti kartice s prijateljima ili kolegama radi
        zajedničkog rada. Sa značajkom javnog prostora TitleTaba možete
        jednostavno dijeliti svoje kartice i surađivati s drugima na prikladan i
        siguran način.
      </p>
      <p>
        <strong>OneTab: Spremite sve otvorene kartice u jednu</strong>
      </p>
      <p>
        Za popularne preglednike dostupno je proširenje koje korisnicima
        omogućuje upravljanje otvorenim karticama u pregledniku. Jednostavnim
        klikom na gumb proširenja možete spremiti sve (ili odabrane) kartice u
        jednu karticu koja će pohraniti popis stranica i biti spremna za ponovno
        otvaranje u bilo kojem trenutku. Ova funkcionalnost značajno štedi
        resurse vašeg uređaja, budući da otvorene kartice nastavljaju raditi i
        koriste memoriju vašeg uređaja.
      </p>
      <p>
        Također je vrijedno napomenuti da ne pratimo što točno spremate na ovaj
        način, što jamči vašu privatnost, ali u isto vrijeme ne možemo spremiti
        te podatke za vas. Stoga su ove informacije dostupne samo lokalno u ovom
        pregledniku na ovom uređaju na kojem radite. Ovo je cijena vaše
        privatnosti.
      </p>
      <p>
        <strong>Infinity Nova kartica</strong>
      </p>
      <p>
        Infinity New Tab još je jedna značajka našeg proširenja koja pruža
        beskrajno iskustvo pregledavanja. Uz beskonačnu novu karticu, korisnici
        mogu nastaviti pregledavati bez straha od slučajnog zatvaranja zadnje
        otvorene kartice. Ova značajka automatski dodaje novu karticu lijevo od
        posljednje otvorene kartice, osiguravajući da korisnici uvijek imaju
        karticu na koju se mogu vratiti. Nakon što se otvore dodatne kartice,
        Infinity New Tab nestaje, omogućujući korisnicima slobodno pregledavanje
        bez ikakvih prekida. Ova značajka pruža korisnicima bezbrižnost i
        sprječava frustracije koje nastaju slučajnim zatvaranjem zadnje kartice.
      </p>
      <p>
        <strong>TitleTab proširenja</strong>
      </p>
      <p>
        TitleTab je kompatibilan s popularnim web preglednicima kao što su
        Google Chrome, Mozilla Firefox i Microsoft Edge. Naše proširenje može se
        jednostavno preuzeti i instalirati iz službenih trgovina proširenja
        svakog preglednika.
      </p>
      <p>Ovdje je popis mjesta na kojima možete preuzeti TitleTab:</p>
      <ul>
        <li>
          <a
            href="https://chrome.google.com/webstore/detail/titletab-web-dashboard/ldlndadamgafkigafpajckkjbblnepcb"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome Web Store
          </a>
        </li>
        <li>
          <a
            href="https://addons.mozilla.org/firefox/addon/titletab-web-dashboard/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox Add-ons
          </a>
        </li>
        <li>
          <a
            href="https://microsoftedge.microsoft.com/addons/detail/titletab-web-dashboard/mnckgifglkiecanfbdpnajiplpabkphh"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge Add-ons
          </a>
        </li>
      </ul>
      <p>
        Nakon instaliranja, možete početi koristiti TitleTab za prilagodbu
        stranice nove kartice, spremanje i organiziranje kartica, sprječavanje
        slučajnog zatvaranja kartice i dijeljenje veza s drugima. Ne propustite
        priliku da poboljšate svoje iskustvo pregledavanja uz TitleTab.
        Preuzmite naše proširenje danas!
      </p>
      <p>
        <strong>Privatnost i sigurnost</strong>
      </p>
      <p>
        TitleTab ozbiljno shvaća vašu privatnost i sigurnost. Svi vaši podaci
        sigurno se pohranjuju na vašem uređaju i u oblaku i nikada ne dijelimo
        vaše podatke s trećim stranama.
      </p>
      <hr />
      <p>
        TitleTab je ultimativno rješenje za upravljanje karticama za svakoga tko
        želi preuzeti kontrolu nad svojim iskustvom pregledavanja. Probajte
        danas i uvjerite se u razliku!
      </p>
      <p>
        Ako imate bilo kakvih pitanja o TitleTab-u ili trebate pomoć s bilo
        kojom od naših značajki, slobodno nam se obratite na&nbsp;
        <a
          href="mailto:info@titletab.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@titletab.com
        </a>
        . Naš tim uvijek će rado pomoći i odgovoriti na sva vaša pitanja.
        Cijenimo vaše povratne informacije i predani smo pružanju najboljeg
        mogućeg korisničkog iskustva, pa nas slobodno kontaktirajte ako imate
        prijedloge ili povratne informacije.
      </p>
    </>
  )
}
