import React from 'react'
import './switch.css'
import { Container } from '../style/Switch.styled'
import { useData } from '../context/DataContext'

export default function Switch({
  name,
  title,
  smallTitle,
  checked,
  onChange,
  disabled = false,
}) {
  const { global } = useData()
  const { settings } = global

  const handler = !disabled ? onChange : undefined

  return (
    <Container>
      <section className={settings.styleTheme ? 'switch dark' : 'switch'}>
        <section className="switch_text small">
          {title}
          {smallTitle ? <span> {smallTitle}</span> : ''}
        </section>
        <section className="switch_box">
          <input
            className="switch_1"
            type="checkbox"
            name={name}
            id={`${name}-id`}
            checked={checked}
            onChange={handler}
          />
        </section>
      </section>
    </Container>
  )
}
