import { database } from '../firebase'

export default function SettingsSet({ uid, settings }) {
  return new Promise((resolve, reject) => {
    database.settings
      .doc(uid)
      .set({
        defaultSearch: settings.defaultSearch,
        showSearch: true,

        styleGlobalBackground: settings.styleGlobalBackground,
        styleGlobalImage: settings.styleGlobalImage,
        styleTabsTitleColor: settings.styleTabsTitleColor,
        styleTheme: settings.styleTheme,

        tabsAsImage: settings.tabsAsImage,
        tabsSizeDesktop: settings.tabsSizeDesktop,
        tabsSizeMobile: settings.tabsSizeMobile,

        targetTabs: settings.targetTabs,
        targetSearch: settings.targetSearch,

        time: settings.time,
      })
      .then(resolve('Done'))
  })
}
