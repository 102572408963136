import React from 'react'
import { useAuth } from './context/AuthContext'
import { usePage } from './context/PageContext'

import UpdateProfile from './pages/UpdateProfile'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import ForgotPassword from './pages/ForgotPassword'
import ChangeEmail from './pages/ChangeEmail'
import ChangePassword from './pages/ChangePassword'
import DeleteAccount from './pages/DeleteAccount'

export default function Auth() {
  const { currentUser } = useAuth()
  const {
    signIn,
    signUp,
    forgotPassword,
    updateProfile,
    changeEmail,
    changePassword,
    deleteAccount,
  } = usePage()
  return (
    <>
      {currentUser ? (
        <>
          {updateProfile ? (
            <UpdateProfile button={2} fullWidth={false} />
          ) : null}
          {changeEmail ? <ChangeEmail button={2} fullWidth={false} /> : null}
          {changePassword ? (
            <ChangePassword button={2} fullWidth={false} />
          ) : null}
          {deleteAccount ? (
            <DeleteAccount button={2} fullWidth={false} />
          ) : null}
        </>
      ) : (
        <>
          {signIn ? (
            <SignIn button={5} fullWidth={false} mobileClose={false} />
          ) : null}
          {signUp ? (
            <SignUp button={5} fullWidth={false} mobileClose={false} />
          ) : null}
          {forgotPassword ? (
            <ForgotPassword button={3} fullWidth={false} mobileClose={false} />
          ) : null}
        </>
      )}
    </>
  )
}
