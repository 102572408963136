import React from 'react'

export default function AboutCs() {
  return (
    <>
      <p>
        Vítejte v TitleTab – Speed Dial Dashboard, dokonalém řešení pro správu
        karet navrženém tak, aby vaše prohlížení bylo rychlejší a pohodlnější
        než kdy předtím. Naše řešení nabízí řadu funkcí, které vám mohou pomoci
        přizpůsobit si karty prohlížeče, zabránit náhodnému zavření prohlížeče a
        sdílet oblíbené odkazy s ostatními uživateli.
      </p>
      <p>Zde jsou některé z úžasných funkcí, které TitleTab nabízí:</p>
      <p>
        <strong>Personalizovaná úvodní stránka</strong>
      </p>
      <p>
        Naše funkce StartPage vám umožňuje snadno přetahovat karty a uspořádat
        je tak, aby vyhovovaly vašim potřebám. Každou kartu si také můžete
        přizpůsobit různými možnostmi zobrazení, včetně snímku obrazovky webové
        stránky, textu, log oblíbených stránek nebo vlastních obrázků. Nabízíme
        světlé i tmavé motivy, takže si můžete vybrat ten, který vám vyhovuje.
      </p>
      <p>
        <strong>Organizace záložek: Složky a neomezené prostory</strong>
      </p>
      <p>
        TitleTab nabízí výkonné funkce pro organizaci karet, které vám umožní
        strukturovat karty způsobem, který vám vyhovuje. Karty můžete seskupit
        do složek pouhým přetažením jedné karty na druhou. Navíc můžete vytvořit
        nové prostory, které vám pomohou oddělit různé typy karet, jako jsou
        karty související s prací a karty související s koníčky. S funkcemi
        organizace záložek TitleTab můžete snadno spravovat své karty a udržovat
        své prohlížení organizované a efektivní.
      </p>
      <p>
        <strong>Veřejný prostor s jedinečným odkazem</strong>
      </p>
      <p>
        Uživatelé TitleTab mohou také vytvořit veřejný prostor, ke kterému mají
        přístup ostatní uživatelé, kteří mají odkaz. Když poprvé aktivujete
        tlačítko „Veřejný prostor“, požádáme vás o vyplnění některých polí,
        která jsou nezbytná pro spuštění vašeho veřejného prostoru.
        Nejdůležitější pole, které je potřeba vyplnit, je unikátní adresa odkazu
        („Cesta pro přístup k veřejnému prostoru“), přes kterou bude váš prostor
        přístupný s kartami, které chcete sdílet s ostatními uživateli. Jakmile
        aktivujete veřejný prostor, můžete do něj začít přidávat karty. Tato
        funkce je zvláště užitečná, pokud chcete sdílet karty s přáteli nebo
        kolegy pro společnou práci. S funkcí veřejného prostoru TitleTab můžete
        snadno sdílet své karty a spolupracovat s ostatními pohodlným a
        bezpečným způsobem.
      </p>
      <p>
        <strong>OneTab: Uložte všechny otevřené karty do jedné</strong>
      </p>
      <p>
        Pro oblíbené prohlížeče je k dispozici rozšíření, které uživatelům
        umožňuje spravovat otevřené karty v prohlížeči. Pouhým kliknutím na
        tlačítko rozšíření můžete uložit všechny (nebo vybrané) karty na jednu
        kartu, která bude obsahovat seznam webů a bude připravena pro vás
        kdykoli znovu otevřít. Tato funkce výrazně šetří zdroje vašeho zařízení,
        protože otevřené karty nadále fungují a využívají paměť vašeho zařízení.
      </p>
      <p>
        Za zmínku také stojí, že nesledujeme, co přesně tímto způsobem ukládáte,
        což zaručuje vaše soukromí, ale zároveň za vás tyto informace uložit
        nemůžeme. Proto jsou tyto informace dostupné pouze lokálně v tomto
        prohlížeči na zařízení, na kterém pracujete. To jsou náklady na vaše
        soukromí.
      </p>
      <p>
        <strong>Nová karta Infinity</strong>
      </p>
      <p>
        Infinity New Tab je další funkcí našeho rozšíření, která poskytuje
        nekonečné možnosti procházení. S nekonečným počtem nových karet mohou
        uživatelé pokračovat v procházení, aniž by se museli obávat náhodného
        zavření poslední otevřené karty. Tato funkce automaticky přidá novou
        kartu nalevo od poslední otevřené karty, takže uživatelé budou mít vždy
        kartu, na kterou se mohou vrátit. Jakmile jsou otevřeny další karty,
        Infinity New Tab zmizí, což uživatelům umožňuje volně procházet bez
        jakéhokoli přerušení. Tato funkce poskytuje uživatelům klid a zabraňuje
        frustraci, která přichází s nechtěným zavřením poslední záložky.
      </p>
      <p>
        <strong>TitleTab Extensions</strong>
      </p>
      <p>
        TitleTab je kompatibilní s oblíbenými webovými prohlížeči, jako jsou
        Google Chrome, Mozilla Firefox a Microsoft Edge. Naše rozšíření lze
        snadno stáhnout a nainstalovat z oficiálních obchodů s rozšířeními
        každého prohlížeče.
      </p>
      <p>Zde je seznam míst, kde si můžete stáhnout TitleTab:</p>
      <ul>
        <li>
          <a
            href="https://chrome.google.com/webstore/detail/titletab-web-dashboard/ldlndadamgafkigafpajckkjbblnepcb"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome Web Store
          </a>
        </li>
        <li>
          <a
            href="https://addons.mozilla.org/firefox/addon/titletab-web-dashboard/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox Add-ons
          </a>
        </li>
        <li>
          <a
            href="https://microsoftedge.microsoft.com/addons/detail/titletab-web-dashboard/mnckgifglkiecanfbdpnajiplpabkphh"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge Add-ons
          </a>
        </li>
      </ul>
      <p>
        Po instalaci můžete začít používat TitleTab k přizpůsobení stránky na
        nové kartě, ukládání a uspořádání karet, zabránění náhodnému zavření
        karet a sdílení odkazů s ostatními. Nenechte si ujít příležitost
        zefektivnit své prohlížení pomocí TitleTab. Stáhněte si naše rozšíření
        ještě dnes!
      </p>
      <p>
        <strong>Ochrana osobních údajů a zabezpečení</strong>
      </p>
      <p>
        TitleTab bere vaše soukromí a bezpečnost vážně. Všechna vaše data jsou
        bezpečně uložena ve vašem zařízení a v cloudu a vaše informace nikdy
        nesdílíme s třetími stranami.
      </p>
      <hr />
      <p>
        TitleTab je dokonalé řešení pro správu karet pro každého, kdo chce
        převzít kontrolu nad svým procházením. Vyzkoušejte to ještě dnes a sami
        uvidíte rozdíl!
      </p>
      <p>
        Pokud máte nějaké dotazy ohledně TitleTab nebo potřebujete pomoc s
        některou z našich funkcí, neváhejte nás kontaktovat na adrese&nbsp;
        <a
          href="mailto:info@titletab.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@titletab.com
        </a>
        . Náš tým vám vždy rád pomůže a zodpoví všechny vaše dotazy. Vážíme si
        vaší zpětné vazby a jsme odhodláni poskytovat nejlepší možnou
        uživatelskou zkušenost, takže pokud máte nějaké návrhy nebo zpětnou
        vazbu, neváhejte nás kontaktovat.
      </p>
    </>
  )
}
