import React from 'react'

export default function AboutUk() {
  return (
    <>
      <p>
        Ласкаво просимо до TitleTab - Speed Dial Dashboard, найкращого рішення
        для керування вкладками, розробленого для того, щоб зробити ваш перегляд
        швидшим і зручнішим, ніж будь-коли раніше. Наше рішення пропонує ряд
        функцій, які можуть допомогти вам персоналізувати вкладки
        веб-переглядача, запобігти випадковому закриттю веб-переглядача та
        ділитися улюбленими посиланнями з іншими користувачами.
      </p>
      <p>Ось деякі з дивовижних функцій, які пропонує TitleTab:</p>
      <p>
        <strong>Персоналізована стартова сторінка</strong>
      </p>
      <p>
        Наша функція StartPage дозволяє легко перетягувати вкладки, щоб
        упорядкувати їх відповідно до ваших потреб. Ви також можете налаштувати
        кожну вкладку за допомогою різних параметрів відображення, включаючи
        знімок екрана веб-сторінки, текст, логотипи популярних сайтів або власні
        зображення. Ми пропонуємо як світлу, так і темну тему, тому ви можете
        вибрати ту, яка відповідає вашим уподобанням.
      </p>
      <p>
        <strong>Організація вкладок: папки та необмежені простори</strong>
      </p>
      <p>
        TitleTab пропонує потужні функції організації вкладок, які дозволяють
        вам структурувати свої вкладки так, як вам зручно. Ви можете групувати
        вкладки в папки, просто перетягуючи одну вкладку на іншу. Крім того, ви
        можете створити нові простори, щоб розділити різні типи вкладок, як-от
        вкладки, пов’язані з роботою, і вкладки, пов’язані з хобі. Завдяки
        функціям організації вкладок TitleTab ви можете легко керувати своїми
        вкладками та підтримувати організованість та ефективність перегляду.
      </p>
      <p>
        <strong>Публічний простір за унікальним посиланням</strong>
      </p>
      <p>
        Користувачі TitleTab також можуть створювати публічний простір, до якого
        можуть отримати доступ інші користувачі, які мають посилання. Коли ви
        вперше активуєте кнопку «Публічний простір», ми попросимо вас заповнити
        деякі поля, необхідні для запуску вашого публічного простору.
        Найважливішим полем, яке потрібно заповнити, є унікальна адреса
        посилання («Шлях доступу до публічного простору»), через який ваш
        простір буде доступним із вкладками, якими ви хочете поділитися з іншими
        користувачами. Після того, як ви активуєте публічний простір, ви можете
        почати додавати в нього вкладки. Ця функція особливо корисна, якщо ви
        хочете поділитися вкладками з друзями чи колегами для спільної роботи.
        Завдяки функції публічного простору TitleTab ви можете легко ділитися
        своїми вкладками та співпрацювати з іншими у зручний і безпечний спосіб.
      </p>
      <p>
        <strong>OneTab: збереження всіх відкритих вкладок в одну</strong>
      </p>
      <p>
        Для популярних браузерів доступне розширення, яке дозволяє користувачам
        керувати відкритими вкладками в браузері. Просто натиснувши кнопку
        розширення, ви можете зберегти всі (або вибрані) вкладки в одній
        вкладці, яка зберігатиме список сайтів і буде готова до повторного
        відкриття для вас у будь-який час. Ця функція значно економить ресурси
        вашого пристрою, оскільки відкриті вкладки продовжують працювати та
        використовувати пам'ять вашого пристрою.
      </p>
      <p>
        Також варто зазначити, що ми не відстежуємо, що саме ви зберігаєте таким
        чином, що гарантує вашу конфіденційність, але в той же час ми не можемо
        зберегти цю інформацію для вас. Тому ця інформація доступна лише
        локально в цьому браузері на пристрої, на якому ви працюєте. Це ціна
        вашої конфіденційності.
      </p>
      <p>
        <strong>Нескінчена нова вкладка</strong>
      </p>
      <p>
        Infinity New Tab — ще одна функція нашого розширення, яка забезпечує
        нескінченний досвід перегляду. З нескінченною новою вкладкою користувачі
        можуть продовжувати перегляд, не боячись випадково закрити останню
        відкриту вкладку. Ця функція автоматично додає нову вкладку ліворуч від
        останньої відкритої вкладки, гарантуючи, що користувачі завжди мають
        вкладку, до якої можна повернутися. Після відкриття додаткових вкладок
        Infinity New Tab зникає, дозволяючи користувачам вільно переглядати
        веб-сторінки без будь-яких перерв. Ця функція забезпечує користувачам
        душевний спокій і запобігає розчаруванням, пов’язаним із випадковим
        закриттям останньої вкладки.
      </p>
      <p>
        <strong>TitleTab розширення</strong>
      </p>
      <p>
        TitleTab сумісний із такими популярними веб-браузерами, як Google
        Chrome, Mozilla Firefox і Microsoft Edge. Наше розширення можна легко
        завантажити та встановити з офіційних магазинів розширень кожного
        браузера.
      </p>
      <p>Ось список місць, де можна завантажити TitleTab:</p>
      <ul>
        <li>
          <a
            href="https://chrome.google.com/webstore/detail/titletab-web-dashboard/ldlndadamgafkigafpajckkjbblnepcb"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome Web Store
          </a>
        </li>
        <li>
          <a
            href="https://addons.mozilla.org/firefox/addon/titletab-web-dashboard/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox Add-ons
          </a>
        </li>
        <li>
          <a
            href="https://microsoftedge.microsoft.com/addons/detail/titletab-web-dashboard/mnckgifglkiecanfbdpnajiplpabkphh"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge Add-ons
          </a>
        </li>
      </ul>
      <p>
        Після встановлення ви можете почати використовувати TitleTab, щоб
        налаштувати сторінку нової вкладки, зберігати й упорядковувати свої
        вкладки, запобігати випадковому закриттю вкладок і ділитися посиланнями
        з іншими. Не пропустіть можливість оптимізувати ваш досвід перегляду за
        допомогою TitleTab. Завантажте наше розширення сьогодні!
      </p>
      <p>
        <strong>Конфіденційність і безпека</strong>
      </p>
      <p>
        TitleTab серйозно ставиться до вашої конфіденційності та безпеки. Усі
        ваші дані надійно зберігаються на вашому пристрої та в хмарі, і ми
        ніколи не передаємо вашу інформацію третім особам.
      </p>
      <hr />
      <p>
        TitleTab — це найкраще рішення для керування вкладками для всіх, хто
        хоче контролювати свій досвід перегляду. Спробуйте сьогодні й самі
        побачите різницю!
      </p>
      <p>
        Якщо у вас виникли запитання щодо TitleTab або вам потрібна допомога
        щодо будь-якої з наших функцій, будь ласка, не соромтеся зв’язатися з
        нами за адресою&nbsp;
        <a
          href="mailto:info@titletab.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@titletab.com
        </a>
        . Наша команда завжди рада допомогти та відповісти на будь-які ваші
        запитання. Ми цінуємо ваш відгук і прагнемо забезпечити найкращу
        взаємодію з користувачами, тому, будь ласка, не соромтеся звертатися до
        нас, якщо у вас є пропозиції чи відгуки.
      </p>
    </>
  )
}
