import React, { memo, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useData } from '../context/DataContext'
import { usePage } from '../context/PageContext'
import initialLanguage from '../language/initialLanguage'

export default memo(function NavBarButtons({ setModalActive }) {
  const { lang, setOutPublic, setShareState } = useData()
  const { setShareModal } = usePage()
  const navigate = useNavigate()

  const browser = useMemo(() => {
    let browser = 'unknown'
    if (
      (navigator.userAgent.indexOf('Opera') != -1 ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      browser = 'Opera'
    } else if (navigator.userAgent.indexOf('Edg') != -1) {
      browser = 'Edge'
    } else if (
      navigator.userAgent.indexOf('Chrome') != -1 ||
      navigator.userAgent.indexOf('Chromium') != -1
    ) {
      browser = 'Chrome'
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      browser = 'Safari'
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      browser = 'Firefox'
    } else if (
      navigator.userAgent.indexOf('MSIE') != -1 ||
      !!document.documentMode == true
    ) {
      browser = 'IE'
    }
    return browser
  }, [])

  const extensionIcon = useMemo(() => {
    if (browser === 'Chrome') {
      return 'bx bxl-chrome'
    } else if (browser === 'Firefox') {
      return 'bx bxl-firefox'
    } else if (browser === 'Edge') {
      return 'bx bxl-edge'
    } else {
      return null
    }
  }, [browser])

  const extensionText = useMemo(() => {
    if (browser === 'Chrome') {
      return `${initialLanguage[lang].nb_extension} Chrome`
    } else if (browser === 'Firefox') {
      return `${initialLanguage[lang].nb_extension} Firefox`
    } else if (browser === 'Edge') {
      return `${initialLanguage[lang].nb_extension} Edge`
    } else {
      return null
    }
  }, [browser])

  const extensionLink = useMemo(() => {
    if (browser === 'Chrome') {
      return 'https://chrome.google.com/webstore/detail/titletab-web-dashboard/ldlndadamgafkigafpajckkjbblnepcb'
    } else if (browser === 'Firefox') {
      return 'https://addons.mozilla.org/firefox/addon/titletab-web-dashboard/'
    } else if (browser === 'Edge') {
      return 'https://microsoftedge.microsoft.com/addons/detail/titletab-web-dashboard/mnckgifglkiecanfbdpnajiplpabkphh'
    } else {
      return null
    }
  }, [browser])

  let addView = 'dynamic'
  const spacesContainer = document.getElementById('spacesContainer')
  const spacesContent = document.getElementById('spacesContent')

  if (spacesContainer && spacesContent) {
    if (spacesContainer.offsetHeight < spacesContent.offsetHeight) {
      addView = 'static'
    }
  }

  function addSpace() {
    setModalActive(true)
    navigate('/')
    setOutPublic(false)
  }

  async function handleExtension() {
    window.open(extensionLink)
  }

  async function handleBackground() {
    navigate('/background')
    setOutPublic(false)
  }

  async function handleAbout() {
    navigate('/about')
    setOutPublic(false)
  }

  async function handleShare() {
    setShareModal(true)
    setShareState()
    return
  }

  return (
    <div className="buttons">
      {addView === 'static' ? (
        <div className="container">
          <div className="button" onClick={addSpace}>
            <i className="bx bx-plus" id="space"></i>
          </div>
          <div className="tooltip">{initialLanguage[lang].nb_add_space}</div>
        </div>
      ) : null}
      {extensionIcon && extensionText && extensionLink ? (
        <div className="container">
          <div className="button" onClick={handleExtension}>
            <i className={extensionIcon} id="extension"></i>
          </div>
          <div className="tooltip">{extensionText}</div>
        </div>
      ) : null}
      <div className="container">
        <div
          className="button"
          onClick={() => {
            window.open('https://www.buymeacoffee.com/titletab')
          }}
        >
          <i className="bx bx-coffee-togo" id="about"></i>
        </div>
        <div className="tooltip">Buy Me a Coffee</div>
      </div>
      <div className="container">
        <div className="button" onClick={handleBackground}>
          <i className="bx bx-image" id="backgroung"></i>
        </div>
        <div className="tooltip">
          {initialLanguage[lang].nb_backgroung_image}
        </div>
      </div>
      <div className="container">
        <div className="button" onClick={handleShare}>
          <i className="bx bx-share-alt" id="share"></i>
        </div>
        <div className="tooltip">{initialLanguage[lang].nb_share_link}</div>
      </div>
    </div>
  )
})
