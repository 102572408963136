import { database } from '../firebase'

export default function DomainGet({ idDomain }) {
  let errorDomain = {
    domainBackground: '#0364c1',
    domainColor: '#ffffff',
    domainHostname: 'titletab.com',
    domainPicture: 'https://bucket.titletab.com/404FB.webp',
    domainProtocol: 'https://',
    domainTitle: 'Something went wrong...',
    domainWWW: false,
    verifiedD: true,
  }
  return new Promise((resolve, reject) => {
    database.domain
      .doc(idDomain)
      .get()
      .then((doc) => {
        if (doc.exists) {
          resolve(doc.data())
        } else {
          resolve(errorDomain)
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error)
        resolve(errorDomain)
      })
  })
}
