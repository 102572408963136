import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useData } from '../context/DataContext'
import { usePage } from '../context/PageContext'
import { Container } from '../style/ModalSmall.styled'
import Button from '../button/Button'
import Title from '../modal/Title'
import Content from '../modal/Content'

import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getColor,
  getButton,
} from '../functionSet/ModalFunction'
import LineHr from '../minor/LineHr'
import Gallery from '../minor/Gallery'
import BackgroundUploader from '../minor/BackgroundUploader'
import initialLanguage from '../language/initialLanguage'

export default function BackgroundModal({ button, fullWidth, mobileClose }) {
  const { global, lang, setDrag } = useData()
  const { settings, styleData } = global

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  const navigate = useNavigate()

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({
      device,
      styleData,
      button,
    }),
    width: getWidth({ styleData, fullWidth }),
  }

  const { backgroundModal, setBackgroundModal } = usePage()
  const [activeDelay, setActiveDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(backgroundModal)
      setDrag(false)
    }, 1)
  }, [backgroundModal])

  let modalActiv = activeDelay

  async function handleClose(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/')
      setBackgroundModal(false)
    }, 400)
  }

  async function handleBack(e) {
    e.preventDefault()
    // navigate(-1)
    handleClose(e)
  }

  async function handleSubmit(e) {
    e.preventDefault()
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  return (
    <div className={getBlackout({ device, modalActiv })} onClick={handleClose}>
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={getClassName({
              modalActiv,
              device,
              dark: settings.styleTheme,
            })}
          >
            <Title
              children={initialLanguage[lang].bm_choose_image}
              dark={settings.styleTheme}
              handleBack={handleBack}
              handleClose={handleClose}
            />

            {device === 'desktop' ? <div className="indent20"></div> : null}

            <Content>
              <BackgroundUploader />
              <br />
              <LineHr
                text={initialLanguage[lang].bm_or}
                dark={settings.styleTheme}
              />
              <br />
              <Gallery />
            </Content>

            {device === 'mobile' ? (
              mobileClose ? (
                <Button
                  onClick={handleClose}
                  color={getColor({ dark: settings.styleTheme })}
                  isDesktop={false}
                  plug={true}
                >
                  Close
                </Button>
              ) : null
            ) : null}

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
    </div>
  )
}
