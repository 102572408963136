import React from 'react'
import { UsersListContainer } from '../styles/GetAnalytics.styled'

export function List({ visitor, index, user }) {
  let date = ''
  if (visitor.length === 13) {
    date = `- ${new Date(Number(visitor)).toISOString().slice(0, 10)}`
  }

  return (
    <tr>
      <td>{`${index + 1}.`}</td>
      <td>{`${visitor} ${date}`}</td>
      <td>{user.country}</td>
      <td>{user.city}</td>
      <td>{user.language}</td>
      <td>{user.browser}</td>
      <td>{user.system}</td>
      <td>{user.extantion ? 'yes' : 'no'}</td>
      <td>{user.views}</td>
      <td>{user.clicks}</td>
    </tr>
  )
}

export default function ActiveUsers({ activeUsers }) {
  activeUsers = Object.keys(activeUsers)
    .sort()
    .reverse()
    .reduce((obj, key) => {
      obj[key] = activeUsers[key]
      return obj
    }, {})

  return (
    <UsersListContainer>
      <div className="title">
        Active Users ( {Object.keys(activeUsers).length} )
      </div>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>UID</th>
            <th>Country</th>
            <th>City</th>
            <th>Language</th>
            <th>Browser</th>
            <th>System</th>
            <th>Extantion</th>
            <th>Views</th>
            <th>Clicks</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(activeUsers).map((visitor, index) => (
            <List
              key={index}
              visitor={visitor}
              index={index}
              user={activeUsers[visitor]}
            />
          ))}
        </tbody>
      </table>
    </UsersListContainer>
  )
}
