export default function OnDragUpdate({ update, state, settings }) {
  const { destination, source, draggableId, combine } = update
  const { tasks, columns, columnOrder } = state
  const main = state.id ? false : true

  if (!tasks[update.draggableId].tasks) {
    let shadowClass = settings.styleTheme
      ? 'shadowFolderDark'
      : 'shadowFolderLight'
    let element = document.querySelector(`.${shadowClass}`)
    if (element) {
      element.classList.remove(shadowClass)
    }
  }

  if (combine) {
    let sourceIndex = columnOrder.indexOf(source.droppableId)
    let destinationIndex = columnOrder.indexOf(combine.droppableId)
    let taskId = update.combine.draggableId

    // check if the taskId is within the bounds of the tasks array
    if (destinationIndex > sourceIndex && taskId < Object.keys(tasks).length) {
      taskId = parseInt(update.combine.draggableId) + 1
    }

    // check if the task is not a folder
    if (!tasks[update.draggableId].tasks) {
      let shadowClass = settings.styleTheme
        ? 'shadowFolderDark'
        : 'shadowFolderLight'
      let element = document.querySelector(`.task-${taskId}`)
      if (element) {
        element.classList.add(shadowClass)
      }
    }
  }

  if (!destination) {
    if (update.combine) {
      return
    }
    removeAllDragClasses()
    return
  }
  if (
    destination !== null &&
    destination.index + 1 > columns[destination.droppableId].taskIds.length
  ) {
    removeAllDragClasses()
    update.destination.droppableId = update.source.droppableId
    update.destination.draggableId = update.source.draggableId
    return
  }
  if (source.droppableId === destination.droppableId) {
    removeAllDragClasses()
  }

  let dragUpDownCss = []
  let dragUpDownJs = []

  let dragLeftRightCss = []
  let dragLeftRightJs = []

  let dragOff = []
  let dragOn = []

  let iDestination = columnOrder.indexOf(destination.droppableId)
  let iSource = columnOrder.indexOf(source.droppableId)

  const isMovingRightDown = iDestination > iSource

  if (isMovingRightDown) {
    //движение вправо/вниз

    //================================================================================================
    dragUpDownJs = columnOrder.map((value) => columns[value].taskIds[0])
    dragLeftRightJs = columnOrder
      .flatMap((value) => columns[value].taskIds)
      .filter((value) => !dragUpDownJs.includes(value) && value !== draggableId)
    //================================================================================================

    for (let index = iDestination; index > iSource; index--) {
      const currentTaskIds = columns[columnOrder[index]].taskIds

      dragUpDownJs = dragUpDownJs.filter(
        (taskId) => taskId !== currentTaskIds[0]
      )
      dragUpDownCss.push(currentTaskIds[0])

      currentTaskIds.forEach((value) => {
        if (!dragUpDownCss.includes(value) && !dragUpDownJs.includes(value)) {
          dragLeftRightCss.push(value)
          dragOn.push(value)
        }
      })

      dragLeftRightJs = dragLeftRightJs.filter(
        (taskId) => !currentTaskIds.includes(taskId)
      )
    }

    //================================================================================================
    const currentTaskIds =
      state.columns[state.columnOrder[iDestination]].taskIds
    dragOff = currentTaskIds.filter(
      (value, index) => index - 1 >= update.destination.index
    )

    dragLeftRightCss = dragLeftRightCss.filter(
      (value) => !dragOff.includes(value)
    )
    dragOn = dragOn.filter((value) => !dragOff.includes(value))
    //================================================================================================

    classListAdd(dragOff, 'noDrag', main)
    classListRemove(dragOn, 'noDrag', main)

    classListAdd(dragUpDownCss, 'dragRightTop', main)
    classListRemove(dragUpDownJs, 'dragRightTop', main)

    classListAdd(dragLeftRightCss, 'dragLeft', main)
    classListRemove(dragLeftRightJs, 'dragLeft', main)
  }

  const isMovingLeftUp = iDestination < iSource

  if (isMovingLeftUp) {
    // движение влево/вверх
    let lastTab = settings.tabsInRow - 1

    //заполенение табсами массива последних табсов
    const lastTabTaskIds = columnOrder
      .map((value) => columns[value].taskIds[lastTab])
      .filter((taskId) => taskId !== draggableId)

    dragUpDownJs.push(...lastTabTaskIds)

    //заполенение табсами массива первых табсов
    const taskIds = columnOrder.map((value) => columns[value].taskIds).flat()

    const newTaskIds = taskIds.filter(
      (taskId) => !dragUpDownJs.includes(taskId)
    )
    dragLeftRightJs.push(...newTaskIds)

    for (let loop = iSource - iDestination; loop > 0; loop--) {
      const index = iDestination + loop - 1
      const value = columns[columnOrder[index]].taskIds[lastTab]

      dragUpDownJs = dragUpDownJs.filter((taskId) => taskId !== value)
      dragUpDownCss.push(value)

      const taskIds = columns[columnOrder[iSource - loop + 1]].taskIds
      const newTaskIds = taskIds.filter(
        (taskId) =>
          taskId !== draggableId &&
          !dragUpDownCss.includes(taskId) &&
          !dragUpDownJs.includes(taskId)
      )

      dragLeftRightCss.push(...newTaskIds)
      dragLeftRightJs = dragLeftRightJs.filter(
        (taskId) => !newTaskIds.includes(taskId)
      )
    }

    const lastInDroppable = columns[columnOrder[iSource]].taskIds[lastTab]
    if (lastInDroppable != null) {
      dragLeftRightCss.push(lastInDroppable)
      dragUpDownJs = dragUpDownJs.filter((taskId) => taskId !== lastInDroppable)
    }

    classListAdd(dragUpDownCss, 'dragLast', main)
    classListRemove(dragUpDownJs, 'dragLast', main)

    classListAdd(dragLeftRightCss, 'dragMiddle', main)
    classListRemove(dragLeftRightJs, 'dragMiddle', main)
  }

  // //крайние в ряду вкладки которые двигаются
  // console.log('крайние в ряду вкладки которые двигаются')
  // console.log(dragUpDownCss)
  // //крайние в ряду вкладки которые не двигаются
  // console.log('крайние в ряду вкладки которые не двигаются')
  // console.log(dragUpDownJs)

  // // средние вкладки которые двигаются
  // console.log('средние вкладки которые двигаются')
  // console.log(dragLeftRightCss)
  // // средние вкладки которые не двигаются
  // console.log('средние вкладки которые не двигаются')
  // console.log(dragLeftRightJs)

  // console.log('dragOff')
  // console.log(dragOff)
  // console.log('dragOn')
  // console.log(dragOn)
}

function classListAdd(array, css, main) {
  array.forEach((value) => {
    let elem = document.getElementById(
      main ? `task-${value}` : `taskF-${value}`
    )
    if (elem) elem.classList.add(css)
  })
}

function classListRemove(array, css, main) {
  array.map((value) => {
    if (value != null) {
      let elem = document.getElementById(
        main ? `task-${value}` : `taskF-${value}`
      )
      if (elem) elem.classList.remove(css)
    }
  })
}

function classListForRemove(array, css) {
  for (var i = 0; i < array.length; i++) {
    array[i].classList.remove(css)
  }
}

function removeAllDragClasses() {
  let dragLast = document.querySelectorAll('.dragLast')
  let dragMiddle = document.querySelectorAll('.dragMiddle')
  let dragRightTop = document.querySelectorAll('.dragRightTop')
  let dragLeft = document.querySelectorAll('.dragLeft')
  let noDrag = document.querySelectorAll('.noDrag')

  classListForRemove(dragLast, 'dragLast')
  classListForRemove(dragMiddle, 'dragMiddle')
  classListForRemove(dragRightTop, 'dragRightTop')
  classListForRemove(dragLeft, 'dragLeft')
  classListForRemove(noDrag, 'noDrag')
}
