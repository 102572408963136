import React from 'react'

export default function AboutFr() {
  return (
    <>
      <p>
        Bienvenue dans TitleTab - Speed Dial Dashboard, la solution ultime de
        gestion des onglets conçue pour rendre votre expérience de navigation
        plus rapide et plus pratique que jamais. Notre solution offre une gamme
        de fonctionnalités qui peuvent vous aider à personnaliser les onglets de
        votre navigateur, à empêcher les fermetures accidentelles du navigateur
        et à partager vos liens favoris avec d'autres utilisateurs.
      </p>
      <p>
        Voici quelques-unes des fonctionnalités étonnantes offertes par TitleTab
        :
      </p>
      <p>
        <strong>Page de démarrage personnalisée</strong>
      </p>
      <p>
        Notre fonctionnalité StartPage vous permet de glisser et déposer
        facilement vos onglets pour les organiser d'une manière qui répond à vos
        besoins. Vous pouvez également personnaliser chaque onglet avec diverses
        options d'affichage, y compris une capture d'écran de la page Web, du
        texte, des logos pour les sites populaires ou vos propres images. Nous
        proposons à la fois un thème clair et un thème sombre, vous pouvez donc
        choisir celui qui correspond à vos préférences.
      </p>
      <p>
        <strong>
          Organisation des onglets : dossiers et espaces illimités
        </strong>
      </p>
      <p>
        TitleTab offre de puissantes fonctionnalités d'organisation des onglets
        qui vous permettent de structurer vos onglets d'une manière qui vous
        convient. Vous pouvez regrouper les onglets dans des dossiers en faisant
        simplement glisser et déposer un onglet sur un autre. De plus, vous
        pouvez créer de nouveaux espaces pour vous aider à séparer différents
        types d'onglets, tels que les onglets liés au travail et les onglets
        liés aux loisirs. Avec les fonctionnalités d'organisation des onglets de
        TitleTab, vous pouvez facilement gérer vos onglets et garder votre
        expérience de navigation organisée et efficace.
      </p>
      <p>
        <strong>Espace public avec un lien unique</strong>
      </p>
      <p>
        Les utilisateurs de TitleTab peuvent également créer un espace public
        accessible aux autres utilisateurs disposant du lien. Lorsque vous
        activez le bouton "Espace public" pour la première fois, nous vous
        demanderons de remplir certains champs nécessaires au démarrage de votre
        espace public. Le champ le plus important à renseigner est l'adresse du
        lien unique ("Chemin d'accès à l'espace public") par lequel votre espace
        sera accessible avec les onglets que vous souhaitez partager avec
        d'autres utilisateurs. Une fois que vous avez activé l'espace public,
        vous pouvez commencer à y ajouter des onglets. Cette fonctionnalité est
        particulièrement utile si vous souhaitez partager des onglets avec des
        amis ou des collègues pour un travail collaboratif. Avec la
        fonctionnalité d'espace public de TitleTab, vous pouvez facilement
        partager vos onglets et collaborer avec d'autres de manière pratique et
        sécurisée.
      </p>
      <p>
        <strong>
          OneTab : enregistrez tous les onglets ouverts en un seul
        </strong>
      </p>
      <p>
        Pour les navigateurs populaires, une extension est disponible qui permet
        aux utilisateurs de gérer leurs onglets ouverts dans leur navigateur. En
        cliquant simplement sur le bouton d'extension, vous pouvez enregistrer
        tous les onglets (ou sélectionnés) dans un seul onglet qui stockera une
        liste de sites et sera prêt à être rouvert pour vous à tout moment.
        Cette fonctionnalité économise considérablement les ressources de votre
        appareil, car les onglets ouverts continuent de fonctionner et
        d'utiliser la mémoire de votre appareil.
      </p>
      <p>
        Il convient également de noter que nous ne suivons pas exactement ce que
        vous enregistrez de cette manière, ce qui garantit votre
        confidentialité, mais en même temps, nous ne pouvons pas enregistrer ces
        informations pour vous. Par conséquent, ces informations ne sont
        disponibles que localement dans ce navigateur sur cet appareil sur
        lequel vous travaillez. C'est le prix de votre vie privée.
      </p>
      <p>
        <strong>Infini Nouvel onglet</strong>
      </p>
      <p>
        Infinity New Tab est une autre fonctionnalité de notre extension qui
        offre une expérience de navigation sans fin. Avec le nouvel onglet
        Infinity, les utilisateurs peuvent continuer à naviguer sans craindre de
        fermer accidentellement leur dernier onglet ouvert. Cette fonctionnalité
        ajoute automatiquement un nouvel onglet à gauche du dernier onglet
        ouvert, garantissant que les utilisateurs ont toujours un onglet sur
        lequel se rabattre. Une fois que des onglets supplémentaires sont
        ouverts, le nouvel onglet Infinity disparaît, permettant aux
        utilisateurs de naviguer librement sans aucune interruption. Cette
        fonctionnalité offre aux utilisateurs la tranquillité d'esprit et évite
        la frustration liée à la fermeture accidentelle du dernier onglet.
      </p>
      <p>
        <strong>Extensions TitleTab</strong>
      </p>
      <p>
        TitleTab est compatible avec les navigateurs Web populaires tels que
        Google Chrome, Mozilla Firefox et Microsoft Edge. Notre extension peut
        être facilement téléchargée et installée à partir des magasins
        d'extension officiels de chaque navigateur.
      </p>
      <p>Voici une liste des endroits où vous pouvez télécharger TitleTab :</p>
      <ul>
        <li>
          <a
            href="https://chrome.google.com/webstore/detail/titletab-web-dashboard/ldlndadamgafkigafpajckkjbblnepcb"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome Web Store
          </a>
        </li>
        <li>
          <a
            href="https://addons.mozilla.org/firefox/addon/titletab-web-dashboard/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox Add-ons
          </a>
        </li>
        <li>
          <a
            href="https://microsoftedge.microsoft.com/addons/detail/titletab-web-dashboard/mnckgifglkiecanfbdpnajiplpabkphh"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge Add-ons
          </a>
        </li>
      </ul>
      <p>
        Une fois installé, vous pouvez commencer à utiliser TitleTab pour
        personnaliser votre nouvelle page d'onglet, enregistrer et organiser vos
        onglets, empêcher la fermeture accidentelle des onglets et partager des
        liens avec d'autres. Ne manquez pas l'opportunité de rationaliser votre
        expérience de navigation avec TitleTab. Téléchargez notre extension dès
        aujourd'hui !
      </p>
      <p>
        <strong>Confidentialité et sécurité</strong>
      </p>
      <p>
        TitleTab prend votre vie privée et votre sécurité au sérieux. Toutes vos
        données sont stockées en toute sécurité sur votre appareil et dans le
        cloud, et nous ne partageons jamais vos informations avec des tiers.
      </p>
      <hr />
      <p>
        TitleTab est la solution ultime de gestion des onglets pour tous ceux
        qui cherchent à prendre le contrôle de leur expérience de navigation.
        Essayez-le aujourd'hui et voyez la différence par vous-même !
      </p>
      <p>
        Si vous avez des questions sur TitleTab ou si vous avez besoin d'aide
        avec l'une de nos fonctionnalités, n'hésitez pas à nous contacter
        à&nbsp;
        <a
          href="mailto:info@titletab.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@titletab.com
        </a>
        . Notre équipe est toujours heureuse de vous aider et de répondre à
        toutes vos questions. Nous apprécions vos commentaires et nous nous
        engageons à fournir la meilleure expérience utilisateur possible, alors
        n'hésitez pas à nous contacter si vous avez des suggestions ou des
        commentaires.
      </p>
    </>
  )
}
