import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { useData } from '../../context/DataContext'

import initialLanguage from '../../language/initialLanguage'
import { Container } from '../../style/ModalSmall.styled'

import Button from '../../button/Button'
import Input from '../../input/Input'
import Title from '../../modal/Title'
import Content from '../../modal/Content'

import UrlValid from '../../url/UrlValid'
import Domain from '../../url/Domain'
import Page from '../../url/Page'

import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getButton,
  checkRequired,
  clearError,
  getFocus,
} from '../../functionSet/ModalFunction'
import withLocalForage from '../../context/withLocalForage'

const AddTabsModal = ({ button, fullWidth, mobileClose, addTabsModal }) => {
  const { currentUser } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`
  const { global, lang, setOutPublic, addModal, setAddModal } = useData()
  const { settings, styleData, domains, pages } = global

  const navigate = useNavigate()

  let { device } = useData()
  const inputActive = device === 'desktop' ? true : false
  if (window.innerWidth <= 1200) device = 'mobile'

  const [activeDelay, setActiveDelay] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(addModal)
    }, 1)
  }, [addModal])

  let modalActiv = activeDelay

  button = getButton({ button, device, mobileClose })
  if (!currentUser) button += 1

  let styledIndent = {
    contextHeight: getContextHeight({ device, styleData, button }),
    width: getWidth({ styleData, fullWidth }),
  }

  const [modal, setModal] = useState(true)
  const [url, setUrl] = useState('')
  const [name, setName] = useState('')

  async function handleSubmit(e) {
    e.preventDefault()

    const urlRequired = checkRequired({
      value: url,
      device: device,
      id: 'url_add',
      afterFocus: true,
    })

    if (!urlRequired) {
      return
    }

    //проверка на валидность url
    const urlValid = UrlValid({ url })
    //если url валидный, то удаляем красное выделение ошибки
    if (urlValid) {
      if (document.getElementById('url_add_label'))
        document.getElementById('url_add_label').style.color = ''
    }
    //если url не валидный, то добавляем красное выделение ошибки + фокус на поле
    if (!urlValid) {
      if (document.getElementById('url_add_label'))
        document.getElementById('url_add_label').style.color = 'red'
      if (device === 'desktop') {
        if (document.getElementById('url_add') != null) {
          document.getElementById('url_add').focus()
        }
      }
      return
    }

    //проверка на наличие домена в списке доменов
    const domain = await Domain({ urlValid })
    if (!domain) return
    domains[domain.idDomain] = {
      ...domain,
    }

    //проверка на наличие страницы в списке страниц
    const page = await Page({ urlValid })
    if (!page) return
    pages[page.idPages] = {
      ...page,
    }

    setActiveDelay(false)

    //формируем новый объект для добавления
    const task = {
      idDomain: domain.idDomain,
      idPages: page.idPages,
      idParent: null,
      ownBackground: '',
      ownColor: '',
      ownPicture: '',
      ownTitle: name ? name : '',
      pictureImage: null,
      tabFill: '',
    }

    addTabsModal(path, task, domains, pages, settings)

    setUrl('')
    setName('')
    setTimeout(() => {
      // if (search) search.style.zIndex = '100'
      setAddModal(false)
    }, 400)
  }

  async function handleClose(e) {
    e.preventDefault()
    setActiveDelay(false)
    setTimeout(() => {
      // if (search) search.style.zIndex = '100'
      setAddModal(false)
    }, 400)
    clearInput()
  }

  async function handleBack(e) {
    e.preventDefault()
    handleClose(e)
    clearInput()
  }

  async function handleSignIn(e) {
    handleClose(e)
    navigate('/sign-in')
    setOutPublic(false)
    clearInput()
  }

  function clearInput() {
    setUrl('')
    setName('')
    clearError('url_add', initialLanguage[lang].atm_type_url)
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
      // if (e.key === "Enter") {
      //   handleSubmit(e);
      // }
    })
  }, [])

  useEffect(() => {
    //Для поля URL
    getFocus('url_add', setModal)

    //Для поля названия
    getFocus('name_add', setModal)
  }, [])

  //если устройство ПК, то фокус на поле ввода через 400 мс
  useEffect(() => {
    if (inputActive) {
      setTimeout(() => {
        if (document.getElementById('url_add') != null) {
          document.getElementById('url_add').focus()
        }
      }, 300)
    }
  }, [addModal])

  return (
    <div
      className={getBlackout({ device, modalActiv })}
      onClick={modal ? handleClose : null}
    >
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={getClassName({
              modalActiv,
              device,
              dark: settings.styleTheme,
            })}
          >
            <Title
              children={initialLanguage[lang].atm_add_title}
              dark={settings.styleTheme}
              handleBack={handleBack}
              handleClose={handleClose}
            />

            <Content>
              <Input
                id="url_add"
                labelId="url_add_label"
                type="text"
                state={url}
                setState={setUrl}
                placeholder={''}
                label={initialLanguage[lang].atm_type_url}
                autoComplete="off"
                dark={settings.styleTheme}
              />
              <Input
                id="name_add"
                labelId="name_add_label"
                type="text"
                state={name}
                setState={setName}
                placeholder={''}
                label={initialLanguage[lang].atm_enter_title}
                autoComplete="off"
                dark={settings.styleTheme}
              />
            </Content>

            <Button
              onClick={handleSubmit}
              button={true}
              color={'blue'}
              isDesktop={true}
              dark={settings.styleTheme}
              type="submit"
            >
              {initialLanguage[lang].atm_add_title}
            </Button>

            {!currentUser ? (
              <Button
                onClick={handleSignIn}
                button={true}
                color={'google'}
                isDesktop={true}
                dark={settings.styleTheme}
              >
                {initialLanguage[lang].atm_log_in}
              </Button>
            ) : null}

            <Button
              onClick={handleClose}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].atm_add_cancel}
            </Button>

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
    </div>
  )
}

export default withLocalForage(AddTabsModal)
