import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { useData } from '../../context/DataContext'

import { Container } from '../../style/ModalSmall.styled'

import Button from '../../button/Button'
import Input from '../../input/Input'
import Title from '../../modal/Title'
import Content from '../../modal/Content'
import initialLanguage from '../../language/initialLanguage'
import LineHr from '../../minor/LineHr'

import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getButton,
  getFocus,
} from '../../functionSet/ModalFunction'
import ListScroll from '../../listscroll/ListScroll'
import withLocalForage from '../../context/withLocalForage'

const UpdateSpaceModal = ({
  active,
  setActive,
  spaceUpdate,
  button,
  fullWidth,
  mobileClose,
  updateSpaceModal,
  deleteSpaceModal,
}) => {
  const { currentUser } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`

  const { global, setSpace, setActiveState, setDrag, lang } = useData()

  const { settings, styleData } = global

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  const iconList = [
    'bx bx-bookmarks',
    'bx bxs-bookmarks',
    'bx bx-bookmark-heart',
    'bx bxs-bookmark-heart',
    'bx bx-bookmark',
    'bx bxs-bookmark',
    'bx bx-bookmark-plus',
    'bx bxs-bookmark-plus',
    'bx bx-folder',
    'bx bxs-folder',
    'bx bx-folder-open',
    'bx bxs-folder-open',
    'bx bx-box',
    'bx bxs-box',
    'bx bx-archive',
    'bx bxs-archive',
    'bx bx-archive-in',
    'bx bxs-archive-in',
  ]

  const updateSpace = global.spaces[spaceUpdate]

  const currentName = updateSpace.space

  const currentIcon = updateSpace.icon

  const [name, setName] = useState(currentName)
  const [icon, setIcon] = useState(currentIcon)

  const [activeDelay, setActiveDelay] = useState(false)
  const [modal, setModal] = useState(true)

  async function handleSubmit(e) {
    e.preventDefault()

    setName(name || currentName)
    setIcon(icon || currentIcon)

    const updateSpace = {
      ...global.spaces[spaceUpdate],
      space: name,
      icon: icon,
    }

    updateSpaceModal(path, updateSpace, spaceUpdate)

    setName('')
    setIcon('')
    setActiveDelay(false)
    setTimeout(() => {
      setActive(false)
    }, 400)
  }

  async function handleDelete(e) {
    e.preventDefault()

    let index = currentUser ? 2 : 1

    if (Object.keys(global.spaces).length > index) {
      deleteSpaceModal(path, spaceUpdate)

      const dragContainer = document.getElementById('dragContainer')
      if (dragContainer) dragContainer.style.opacity = 0

      setTimeout(() => {
        setSpace(1)
        if (dragContainer) dragContainer.style.opacity = 1
      }, 400)
      setActiveState(global.spaces['1'])

      setActiveDelay(false)
      setName('')
      setIcon('')
      setTimeout(() => {
        setActive(false)
      }, 400)

      return
    }

    setActiveDelay(false)
    setName('')
    setIcon('')
    setTimeout(() => {
      setActive(false)
    }, 400)
  }

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(active)
      setDrag(false)
    }, 1)
  }, [active])

  let modalActiv = activeDelay
  const navigate = useNavigate()

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({ device, styleData, button }),
    width: getWidth({ styleData, fullWidth }),
  }

  async function handleClose(e) {
    e.preventDefault()
    // navigate("/");

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      setActive(false)
    }, 400)
  }

  async function handleBack(e) {
    e.preventDefault()
    // navigate(-1);
    handleClose(e)
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  useEffect(() => {
    //Для поля Email
    getFocus('name_add', setModal)
  }, [])

  const chooseIcon = (e) => {
    setIcon(iconList[e])
  }
  const iconNav = iconList.map((item, index) => {
    return (
      <div
        className={icon == item ? 'chooseIcon active' : 'chooseIcon'}
        key={index}
        onClick={() => chooseIcon(index)}
      >
        <i className={item}></i>
      </div>
    )
  })

  return (
    <div
      className={getBlackout({ device, modalActiv })}
      onClick={modal ? handleClose : null}
    >
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={getClassName({
              modalActiv,
              device,
              dark: settings.styleTheme,
            })}
          >
            <Title
              children={initialLanguage[lang].usm_update_space}
              dark={settings.styleTheme}
              handleBack={handleBack}
              handleClose={handleClose}
            />

            <Content>
              <Input
                id="name_add"
                labelId="name_add_label"
                type="text"
                state={name}
                setState={setName}
                placeholder={''}
                label={initialLanguage[lang].usm_space_name}
                labelUp={true}
                autoComplete="off"
                required={true}
                dark={settings.styleTheme}
              />

              <LineHr
                text={initialLanguage[lang].asm_select_icon}
                dark={settings.styleTheme}
              />
              <ListScroll
                icon={icon}
                setIcon={setIcon}
                dark={settings.styleTheme}
              />
            </Content>

            <Button
              onClick={handleSubmit}
              button={true}
              color={'blue'}
              isDesktop={true}
              dark={settings.styleTheme}
              type="submit"
            >
              {initialLanguage[lang].atm_add_title}
            </Button>

            <Button
              onClick={handleDelete}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].utm_delete}
            </Button>
            <Button
              onClick={handleClose}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].atm_add_cancel}
            </Button>

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
    </div>
  )
}

export default withLocalForage(UpdateSpaceModal)
