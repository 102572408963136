import * as localForage from 'localforage'

export default async function TrackingSettings({
  base,
  path,
  launch,
  setLaunch,
}) {
  //Получение данных из localForage
  let data = await localForage.getItem(path)

  if (!data) return null

  //Изменяем время на время из настроек
  data.time = data.settings.time

  if (data.time !== base.time) {
    let unique = Math.floor(Math.random() * (7000 - 6000 + 1)) + 6000
    while (unique === launch) {
      unique += 1
    }
    setLaunch(unique)
  }
}
