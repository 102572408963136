import React from 'react'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import initialLanguage from '../language/initialLanguage'
import { Container } from './UserName.styled'

export default function UserName() {
  const { currentUser } = useAuth()
  const { global, lang } = useData()
  const { settings } = global

  const getClassName = (value) => {
    if (settings.styleTheme) return value + ' dark'
    return value
  }

  const getClassNameSmall = () => {
    if (currentUser.displayName) {
      if (settings.styleTheme) return 'header dark small'
      return 'header small'
    } else {
      if (settings.styleTheme) return 'header dark'
      return 'header'
    }
  }

  const displayName = () => {
    if (currentUser.displayName) {
      return (
        <div className={getClassName('header')}>{currentUser.displayName}</div>
      )
    } else return null
  }

  const userEmail = () => {
    if (currentUser.email) {
      return <div className={getClassNameSmall()}>{currentUser.email}</div>
    } else return null
  }

  const greatestUser = () => {
    return (
      <div className={getClassName('account')}>
        {initialLanguage[lang].up_greatest_user}
      </div>
    )
  }

  return (
    <Container>
      {displayName()}
      {userEmail()}
      {greatestUser()}
    </Container>
  )
}
