import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import { usePage } from '../context/PageContext'

import { Container } from '../style/ModalSmall.styled'

import Button from '../button/Button'
import Input from '../input/Input'
import Title from '../modal/Title'
import LineHr from '../minor/LineHr'
import { toast } from 'react-toastify'
import Content from '../modal/Content'
import initialLanguage from '../language/initialLanguage'
import UserName from '../minor/UserName'

import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getColor,
  getButton,
  getClassLink,
  checkRequired,
  checkEmail,
  checkPassword,
  checkPasswordMatch,
  setError,
  clearError,
  getFocus,
} from '../functionSet/ModalFunction'

export default function ChangePassword({ button, fullWidth, mobileClose }) {
  const { currentUser, login, passwordUpdate } = useAuth()
  const { global, lang, setDrag } = useData()
  const { settings, styleData } = global

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  const navigate = useNavigate()
  const [modal, setModal] = useState(true)

  button = getButton({ button, device, mobileClose })

  if (currentUser.providerData[0].providerId === 'password') {
    button = button + 1
  }

  let styledIndent = {
    contextHeight: getContextHeight({
      device,
      styleData,
      button,
    }),
    width: getWidth({ styleData, fullWidth }),
  }

  const { changePassword, setChangePassword } = usePage()
  const [activeDelay, setActiveDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(changePassword)
      setDrag(false)
    }, 1)
  }, [changePassword])

  let modalActiv = activeDelay

  async function handleClose(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/')
      setChangePassword(false)
    }, 400)
  }

  async function handleBack(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate(-1)
      setChangePassword(false)
    }, 400)
  }

  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  async function handleSubmit(e) {
    e.preventDefault()

    const passwordValid = checkPassword({
      password: password,
      id: 'password_cp',
      device: device,
      afterFocus: false,
    })

    const newPasswordValid = checkPassword({
      password: newPassword,
      id: 'password2_cp',
      device: device,
      afterFocus: false,
    })

    const confirmNewPasswordValid = checkPassword({
      password: confirmNewPassword,
      id: 'password3_cp',
      device: device,
      afterFocus: false,
    })

    const passwordMatch = checkPasswordMatch({
      password: newPassword,
      passwordValid: newPasswordValid,
      id: 'password2_cp',
      ////////
      confirmPassword: confirmNewPassword,
      confirmPasswordValid: confirmNewPasswordValid,
      id2: 'password3_cp',
    })

    if (
      !passwordValid ||
      !newPasswordValid ||
      !confirmNewPasswordValid ||
      !passwordMatch
    ) {
      return
    }

    try {
      await login(currentUser.email, password)
      try {
        await passwordUpdate(newPassword)

        setPassword('')
        setNewPassword('')
        setConfirmNewPassword('')
        handleBack(e)
        toast.success(initialLanguage[lang].cp_new_password_saved)
      } catch {
        setPassword('')
        setNewPassword('')
        setConfirmNewPassword('')
        toast.error(initialLanguage[lang].cp_failed_update_password)
      }
    } catch {
      setPassword('')
      setNewPassword('')
      setConfirmNewPassword('')
      toast.error(initialLanguage[lang].cp_wrong_password)
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  useEffect(() => {
    //Для старого поля Пароль
    getFocus('password_cp', setModal)
    //Для нового поля Пароль
    getFocus('password2_cp', setModal)
    //Для поля Подтверждение пароля
    getFocus('password3_cp', setModal)
  }, [])

  const changePasswordTag = () => {
    if (currentUser.providerData[0].providerId === 'password') {
      return (
        <>
          <br />
          <LineHr
            text={initialLanguage[lang].cp_change_password}
            dark={settings.styleTheme}
          />

          <Input
            id="password_cp"
            labelId="password_cp_label"
            type="password"
            state={password}
            setState={setPassword}
            placeholder={''}
            label={initialLanguage[lang].cp_old_password}
            autoComplete="new-password"
            dark={settings.styleTheme}
          />

          <Input
            id="password2_cp"
            labelId="password2_cp_label"
            type="password"
            state={newPassword}
            setState={setNewPassword}
            placeholder={''}
            label={initialLanguage[lang].cp_new_password}
            autoComplete="new-password"
            dark={settings.styleTheme}
          />

          <Input
            id="password3_cp"
            labelId="password3_cp_label"
            type="password"
            state={confirmNewPassword}
            setState={setConfirmNewPassword}
            placeholder={''}
            label={initialLanguage[lang].cp_confirm_new_password}
            autoComplete="new-password"
            required={true}
            dark={settings.styleTheme}
          />
        </>
      )
    } else {
      return <p>{initialLanguage[lang].cp_forbidden}</p>
    }
  }

  return (
    <div
      className={getBlackout({ device, modalActiv })}
      onClick={modal ? handleClose : null}
    >
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={getClassName({
              modalActiv,
              device,
              dark: settings.styleTheme,
            })}
          >
            <Title
              children={initialLanguage[lang].cp_change_password}
              dark={settings.styleTheme}
              handleBack={handleBack}
              handleClose={handleClose}
            />

            {device === 'desktop' ? <div className="indent20"></div> : null}

            <Content>
              <UserName />
              {changePasswordTag()}
            </Content>

            {currentUser.providerData[0].providerId === 'password' ? (
              <Button
                onClick={handleSubmit}
                button={true}
                color={'blue'}
                isDesktop={true}
                dark={settings.styleTheme}
                type="submit"
              >
                {initialLanguage[lang].cp_save_new_password}
              </Button>
            ) : null}

            <Button
              onClick={handleClose}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].cp_cancel}
            </Button>

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
    </div>
  )
}
