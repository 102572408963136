import React, { useState, useEffect } from 'react'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import { database, currentTime, currentTimeMilliseconds } from '../firebase'

import Today from './components/Today'
import Extantions from './components/Extantions'
import MonthGraph from './components/MonthGraph'
import ExtantionsGraph from './components/ExtantionsGraph'
import MinuteGraph from './components/MinuteGraph'
import NewUsersList from './components/NewUsersList'
import ActiveUsers from './components/ActiveUsers'
import { Container } from './styles/GetAnalytics.styled'

export default function GetAnalytics() {
  let id = 30
  const startDay = new Date(currentTimeMilliseconds).setHours(0, 0, 0, 0)
  const { currentUser } = useAuth()
  const { global } = useData()
  const { styleData } = global

  const [analytics, setAnalytics] = useState()

  useEffect(() => {
    getAnalytics()
  }, [])

  async function getAnalytics() {
    try {
      const querySnapshot = await database.analytics
        .orderBy('timestamp', 'desc')
        .limit(id + 1)
        .get()

      const analytics = querySnapshot.docs.map((doc) => doc.data())

      console.log(analytics)

      let timestamp = []
      let clicksTimeLine = []
      let viewsTimeLine = []
      let visitorsTimeLine = []
      let clicks = []
      let views = []
      let visitors = []
      let clicksDifference = 0
      let viewsDifference = 0
      let visitorsDifference = 0

      let newVisitors = []

      let useExtantion = []
      let useRegistration = []

      let country = []
      let language = []
      let system = []
      let browser = []

      let listUsers = []
      let activeUsers = {}

      // let dayUsers = []

      //цикл на уровне дней
      analytics.forEach((item, index) => {
        //формируем текущую дату (начало и конец дня)
        const currentDate = {
          start: startDay - 86400000 * index,
          end: startDay - 86400000 * (index - 1),
        }

        //формируем массив из дат
        timestamp.push(item.timestamp)

        //удаляем timestamp из объекта
        delete item.timestamp

        // dayUsers.push(item)

        //создаем объекты с ключами в виде времени 00:00 - 23:59 и значениями 0
        let clicksLineCount = {}
        let viewstampCount = {}
        let visitorsLineCount = {}
        for (let i = 0; i < 24; i++) {
          let hour = i < 10 ? `0${i}` : `${i}`
          for (let j = 0; j < 60; j++) {
            let minute = j < 10 ? `0${j}` : `${j}`
            clicksLineCount[`${hour}:${minute}`] = 0
            viewstampCount[`${hour}:${minute}`] = 0
            visitorsLineCount[`${hour}:${minute}`] = 0
          }
        }

        //переменные для подсчета общего количества кликов, просмотров и новых посетителей
        let clicksCount = 0
        let viewsCount = 0
        let newVisitorsCount = {}
        //переменная подсчета количества пользователей с установленным расширением и без
        let extantionCount = {
          use: 0,
          not: 0,
        }
        //переменная подсчета количества зарегистрированных пользователей и не зарегистрированных
        let registrationCount = {
          use: 0,
          not: 0,
        }

        let listUsersDay = {}
        let countryCount = {}
        let languageCount = {}
        let systemCount = {}
        let browserCount = {}

        //цикл на уровне посетителей
        Object.keys(item).forEach((key) => {
          //сортировка кликов и просмотров по времени для каждого посетителя
          item[key].timeLine = Object.fromEntries(
            Object.entries(item[key].timeLine).sort()
          )

          //считаем количество пользователей с установленным расширением
          if (item[key].extantion) {
            extantionCount.use++
          } else {
            extantionCount.not++
          }
          //считаем количество зарегистрированных пользователей
          if (key.length === 28) {
            registrationCount.use++
          } else {
            registrationCount.not++
          }

          //переменные для подсчета количества кликов и просмотров для каждого посетителя
          let clicksCountUser = 0
          let viewsCountUser = 0

          //цикл на уровне шкалы времени для каждого посетителя
          Object.keys(item[key].timeLine).forEach((key2, index2) => {
            //добавляем в объекты с ключами в виде времени 00:00 - 23:59 значения из объекта timeLine
            clicksLineCount[key2] += item[key].timeLine[key2].clicks
            viewstampCount[key2] += item[key].timeLine[key2].views

            if (index2 === 0) {
              visitorsLineCount[key2] += 1
            }

            //считаем общее количество кликов и просмотров для каждого посетителя
            clicksCountUser += item[key].timeLine[key2].clicks
            viewsCountUser += item[key].timeLine[key2].views

            //считаем активных пользователей за последние 30 минут
            if (index === 0) {
              //формируем время в формате 2021-05-25T12:00:00.000Z
              let time = timestamp[0] + 'T' + key2 + ':00.000Z'
              //переводим время в миллисекунды
              let timeMilliseconds = new Date(time).getTime()

              //если время меньше 30 минут назад, то добавляем в объект activeUsers
              if (currentTimeMilliseconds - timeMilliseconds <= 1800000) {
                let clicks = activeUsers[key]?.clicks || 0
                let views = activeUsers[key]?.views || 0
                activeUsers[key] = {
                  clicks: item[key].timeLine[key2].clicks + clicks,
                  views: item[key].timeLine[key2].views + views,
                  country: item[key].country,
                  city: item[key].city,
                  latitude: item[key].latitude,
                  longitude: item[key].longitude,
                  extantion: item[key].extantion,
                  language: item[key].language,
                  browser: item[key].browser,
                  system: item[key].system,
                }
              }
            }

            //считаем количество посетителей вчера на текущее время
            if (index === 1) {
              //разбиваем время на часы и минуты и переводим в число
              let timeHours = Number(key2.split(':')[0])
              let timeMinutes = Number(key2.split(':')[1])

              //текущее время в часах и минутах и переводим в число
              let currentTime = new Date().toISOString().slice(11, 16)
              let currentTimeHours = Number(currentTime.split(':')[0])
              let currentTimeMinutes = Number(currentTime.split(':')[1])

              //если время меньше текущего, то добавляем в объект yesterdayUsers
              if (
                timeHours < currentTimeHours ||
                (timeHours === currentTimeHours &&
                  timeMinutes <= currentTimeMinutes)
              ) {
                clicksDifference += item[key].timeLine[key2].clicks
                viewsDifference += item[key].timeLine[key2].views
                if (index2 === 0) {
                  visitorsDifference += 1
                }
              }
            }
          })

          //формируем список всех пользователей
          listUsersDay[key] = {
            clicks: clicksCountUser,
            views: viewsCountUser,
            country: item[key].country,
            city: item[key].city,
            latitude: item[key].latitude,
            longitude: item[key].longitude,
          }

          //суммируем в общее количество кликов и просмотров
          clicksCount += clicksCountUser
          viewsCount += viewsCountUser

          //считаем количество новых посетителей за день
          if (
            key.length === 13 &&
            Number(key) >= currentDate.start &&
            Number(key) < currentDate.end
          ) {
            newVisitorsCount[key] = {
              clicks: clicksCountUser,
              views: viewsCountUser,
              country: item[key].country,
              city: item[key].city,
              latitude: item[key].latitude,
              longitude: item[key].longitude,
              extantion: item[key].extantion,
              language: item[key].language,
              browser: item[key].browser,
              system: item[key].system,
            }
          }

          //считаем количество посетителей из каждой страны
          if (countryCount[item[key].country]) {
            countryCount[item[key].country].clicks += clicksCountUser
            countryCount[item[key].country].views += viewsCountUser
            countryCount[item[key].country].visitors += 1

            if (countryCount[item[key].country].city[item[key].city]) {
              countryCount[item[key].country].city[item[key].city].clicks +=
                clicksCountUser
              countryCount[item[key].country].city[item[key].city].views +=
                viewsCountUser
              countryCount[item[key].country].city[item[key].city].visitors += 1
            } else {
              countryCount[item[key].country].city[item[key].city] = {
                clicks: clicksCountUser,
                views: viewsCountUser,
                visitors: 1,
                latitude: item[key].latitude,
                longitude: item[key].longitude,
              }
            }
          } else {
            countryCount[item[key].country] = {
              clicks: clicksCountUser,
              views: viewsCountUser,
              visitors: 1,
              city: {
                [item[key].city]: {
                  clicks: clicksCountUser,
                  views: viewsCountUser,
                  visitors: 1,
                  latitude: item[key].latitude,
                  longitude: item[key].longitude,
                },
              },
            }
          }

          //считаем количество языковых версий
          if (languageCount[item[key].language]) {
            languageCount[item[key].language].clicks += clicksCountUser
            languageCount[item[key].language].views += viewsCountUser
            languageCount[item[key].language].visitors += 1
          } else {
            languageCount[item[key].language] = {
              clicks: clicksCountUser,
              views: viewsCountUser,
              visitors: 1,
            }
          }

          //считаем количество операционных систем
          if (systemCount[item[key].system]) {
            systemCount[item[key].system].clicks += clicksCountUser
            systemCount[item[key].system].views += viewsCountUser
            systemCount[item[key].system].visitors += 1
          } else {
            systemCount[item[key].system] = {
              clicks: clicksCountUser,
              views: viewsCountUser,
              visitors: 1,
            }
          }

          //считаем количество браузеров
          if (browserCount[item[key].browser]) {
            browserCount[item[key].browser].clicks += clicksCountUser
            browserCount[item[key].browser].views += viewsCountUser
            browserCount[item[key].browser].visitors += 1
          } else {
            browserCount[item[key].browser] = {
              clicks: clicksCountUser,
              views: viewsCountUser,
              visitors: 1,
            }
          }

          // console.log(item[key].country)
          // console.log(item[key].city)
        })

        //добавляем в массивы созданные объекты
        clicksTimeLine.push(clicksLineCount) //шкала кликов
        viewsTimeLine.push(viewstampCount) //шкала просмотров
        visitorsTimeLine.push(visitorsLineCount) //шкала посетителей
        clicks.push(clicksCount) //количество кликов
        views.push(viewsCount) //количество просмотров
        visitors.push(Object.keys(item).length) //количество посетителей
        newVisitors.push(newVisitorsCount) //количество новых посетителей
        listUsers.push(listUsersDay) //список всех пользователей

        useExtantion.push(extantionCount) //пользователи с расширением и без
        useRegistration.push(registrationCount) //зарегистрированные пользователи и нет

        country.push(countryCount)
        language.push(languageCount)
        system.push(systemCount)
        browser.push(browserCount)
      })

      let newAnalytics = {
        timestamp: timestamp.reverse(),
        clicksTimeLine: clicksTimeLine.reverse(),
        viewsTimeLine: viewsTimeLine.reverse(),
        visitorsTimeLine: visitorsTimeLine.reverse(),
        clicks: clicks.reverse(),
        views: views.reverse(),
        visitors: visitors.reverse(),
        newVisitors: newVisitors.reverse(),
        listUsers: listUsers.reverse(),
        clicksDifference,
        viewsDifference,
        visitorsDifference,
        useExtantion: useExtantion.reverse(),
        useRegistration: useRegistration.reverse(),
        country: country.reverse(),
        language: language.reverse(),
        system: system.reverse(),
        browser: browser.reverse(),
        activeUsers,
      }

      console.log(newAnalytics)

      setAnalytics(newAnalytics)
    } catch (error) {
      console.error('Error getting analytics:', error)
    }
  }

  // var preferredLanguages = navigator.languages || [
  //   navigator.language || navigator.userLanguage,
  // ]
  // console.log(preferredLanguages)

  // var userTimezoneOffset = new Date().getTimezoneOffset()
  // console.log(userTimezoneOffset)

  // var colorDepth = window.screen.colorDepth
  // console.log(colorDepth)

  var screenWidth = window.screen.width
  var screenHeight = window.screen.height

  console.log(screenWidth + 'x' + screenHeight)

  return (
    <Container styleData={styleData}>
      {analytics ? (
        <>
          <Today
            clicks={analytics.clicks[id]}
            views={analytics.views[id]}
            visitors={analytics.visitors[id]}
            newVisitors={analytics.newVisitors[id]}
            clicksDifference={analytics.clicksDifference}
            viewsDifference={analytics.viewsDifference}
            visitorsDifference={analytics.visitorsDifference}
          />
          <Extantions
            use={analytics.useRegistration[id].use}
            not={analytics.useRegistration[id].not}
          />
          <MinuteGraph
            clicksTimeLine={analytics.clicksTimeLine[id]}
            viewsTimeLine={analytics.viewsTimeLine[id]}
            visitorsTimeLine={analytics.visitorsTimeLine[id]}
          />
          <MonthGraph
            timestamp={analytics.timestamp}
            clicks={analytics.clicks}
            views={analytics.views}
            visitors={analytics.visitors}
          />
          <ExtantionsGraph
            timestamp={analytics.timestamp}
            extantion={analytics.useExtantion}
          />
          <ActiveUsers activeUsers={analytics.activeUsers} />
          <NewUsersList newVisitors={analytics.newVisitors[id]} />
          {/* <NewUsersList newVisitors={analytics.listUsers[id]} /> */}
        </>
      ) : null}
    </Container>
  )
}
