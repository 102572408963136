import React, { useState, useEffect } from 'react'
import * as localForage from 'localforage'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import { usePage } from '../context/PageContext'

import { Container } from '../style/ModalSmall.styled'

import Button from '../button/Button'
import Link from '../button/Link'
import Title from '../modal/Title'
import LineHr from '../minor/LineHr'
import { toast } from 'react-toastify'
import Content from '../modal/Content'
import initialLanguage from '../language/initialLanguage'
import UserName from '../minor/UserName'

import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getButton,
} from '../functionSet/ModalFunction'

export default function UpdateProfile({ button, fullWidth, mobileClose }) {
  const { currentUser, logout } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`
  const { global, setGlobal, setDrag, setRefresh, lang } = useData()
  const { settings, styleData } = global

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  const navigate = useNavigate()

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({
      device,
      styleData,
      button,
    }),
    width: getWidth({ styleData, fullWidth }),
  }

  const { updateProfile, setUpdateProfile } = usePage()
  const [activeDelay, setActiveDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(updateProfile)
      setDrag(false)
    }, 1)
  }, [updateProfile])

  let modalActiv = activeDelay

  async function handleClose(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/')
      setUpdateProfile(false)
    }, 400)
  }

  async function handleBack(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate(-1)
      setUpdateProfile(false)
    }, 400)
  }

  async function handleChangeEmail(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/change-email')
      setUpdateProfile(false)
    }, 400)
  }

  async function handleChangePassword(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/change-password')
      setUpdateProfile(false)
    }, 400)
  }

  async function handleDeleteAccount(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/delete-account')
      setUpdateProfile(false)
    }, 400)
  }

  async function handleLogout() {
    try {
      await logout()
      navigate('/')
      setGlobal()
      setRefresh('handleLogout')
      toast.success('Sign out successfully completed!')
      await localForage.removeItem(path)
    } catch {
      toast.error('Failed to sign out!')
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  return (
    <div className={getBlackout({ device, modalActiv })} onClick={handleClose}>
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={getClassName({
            modalActiv,
            device,
            dark: settings.styleTheme,
          })}
        >
          <Title
            children={initialLanguage[lang].up_account_settings}
            dark={settings.styleTheme}
            handleBack={handleBack}
            handleClose={handleClose}
          />

          {device === 'desktop' ? <div className="indent20"></div> : null}

          <Content>
            <UserName />
            <br />
            <LineHr
              text={initialLanguage[lang].up_make_selection}
              dark={settings.styleTheme}
            />

            <br />
            <Link onClick={handleChangeEmail} color={'blue'}>
              {initialLanguage[lang].up_change_email}
            </Link>

            <Link onClick={handleChangePassword} color={'blue'}>
              {initialLanguage[lang].up_change_password}
            </Link>

            <Link onClick={handleDeleteAccount} color={'blue'}>
              {initialLanguage[lang].up_delete_account}
            </Link>
          </Content>

          <Button
            onClick={handleLogout}
            color={'grey'}
            isDesktop={true}
            dark={settings.styleTheme}
          >
            {initialLanguage[lang].up_log_out}
          </Button>

          <Button
            onClick={handleClose}
            color={'grey'}
            isDesktop={true}
            dark={settings.styleTheme}
          >
            {initialLanguage[lang].up_account_settingsCancel}
          </Button>

          <div className="indent40"></div>
        </div>
      </Container>
    </div>
  )
}
