import styled from 'styled-components'

export const Container = styled.div`
  .switch {
    position: relative;
    height: 1.5vw;
    min-height: 30px;
    border-bottom: solid #ccc;
    border-bottom-width: 0.05vw;
    border-bottom-width: max(0.05vw, 1px);
    margin-bottom: 30px;
    margin-bottom: max(1.5vw, 30px);
    padding-top: 0.4vw;
    padding-top: max(0.4vw, 8px);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .switch .switch_text {
    position: absolute;
    left: 0.5vw;
    left: max(0.5vw, 10px);
    color: #999;
    font-size: 0.9vw;
    font-size: max(0.9vw, 18px);
  }
  .switch .switch_text.small {
    font-size: 0.75vw;
    font-size: max(0.75vw, 15px);
    padding-top: 0.15vw;
    padding-top: max(0.15vw, 3px);
  }

  .switch .switch_text span {
    font-size: 0.6vw;
    font-size: max(0.6vw, 12px);
  }

  .switch .switch_box {
    position: absolute;
    right: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 2.5vw;
    min-width: 50px;
    height: 1.25vw;
    min-height: 25px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  /* Switch 1 Specific Styles Start */

  .switch input[type='checkbox'].switch_1 {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 2.2vw;
    min-width: 43px;
    height: 1.05vw;
    min-height: 21px;
    background: #ddd;
    border-radius: 3em;
    padding: 0;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .switch.dark input[type='checkbox'].switch_1 {
    background: #42474b;
  }
  .switch input[type='checkbox'].switch_1:checked {
    background: #0364c1 !important;
  }

  .switch input[type='checkbox'].switch_1:after {
    position: absolute;
    content: '';
    width: 1.05vw;
    min-width: 21px;
    height: 1.05vw;
    min-height: 21px;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    left: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .switch input[type='checkbox'].switch_1:checked:after {
    left: calc(100% - 21px);
    left: calc(100% - max(1.05vw, 21px));
  }

  /* Switch 1 Specific Style End */
`
