import styled from 'styled-components'

export const Container = styled.div`
  .line {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 0.05vw;
    min-height: 1px;
    margin: 0 auto;
    margin-top: 1vw;
    margin-top: max(1vw, 20px);
    position: relative;
    background-color: #ccc;
  }

  .line span {
    position: absolute;
    top: -0.5vw;
    top: min(-0.5vw, -10px);
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.5vw;
    padding-left: max(0.5vw, 10px);
    padding-right: 0.5vw;
    padding-right: max(0.5vw, 10px);
    font-size: 0.85vw;
    font-size: max(0.85vw, 17px);
    font-weight: normal;
    line-height: 0.8vw;
    line-height: max(0.8vw, 16px);
    color: #999;
  }

  .line.dark span {
    background-color: #1e1b30;
  }
`
