import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useData } from '../context/DataContext'
import { usePage } from '../context/PageContext'
import { Container } from '../style/ModalSmall.styled'
import PrivacyPolicyText from '../modal/PrivacyPolicy'
import Button from '../button/Button'
import Title from '../modal/Title'
import Content from '../modal/Content'
import initialLanguage from '../language/initialLanguage'

import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getColor,
  getButton,
} from '../functionSet/ModalFunction'

export default function About({ button, fullWidth, mobileClose }) {
  const { global, lang } = useData()
  const { settings, styleData } = global

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  const navigate = useNavigate()

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({
      device,
      styleData,
      button,
    }),
    width: getWidth({ styleData, fullWidth }),
  }

  const { about, setAbout } = usePage()
  const [activeDelay, setActiveDelay] = useState(false)

  let AboutLang

  if (lang === 'de') {
    AboutLang = require('./about/AboutDe').default
  } else if (lang === 'fr') {
    AboutLang = require('./about/AboutFr').default
  } else if (lang === 'es') {
    AboutLang = require('./about/AboutEs').default
  } else if (lang === 'pt') {
    AboutLang = require('./about/AboutPt').default
  } else if (lang === 'it') {
    AboutLang = require('./about/AboutIt').default
  } else if (lang === 'pl') {
    AboutLang = require('./about/AboutPl').default
  } else if (lang === 'cs') {
    AboutLang = require('./about/AboutCs').default
  } else if (lang === 'hr') {
    AboutLang = require('./about/AboutHr').default
  } else if (lang === 'ru') {
    AboutLang = require('./about/AboutRu').default
  } else if (lang === 'uk') {
    AboutLang = require('./about/AboutUk').default
  } else {
    AboutLang = require('./about/AboutEn').default
  }

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(about)
    }, 1)
  }, [about])

  let modalActiv = activeDelay

  async function handleClose(e) {
    e.preventDefault()

    setActiveDelay(false)
    setTimeout(() => {
      navigate('/')
      setAbout(false)
    }, 400)
  }

  async function handleBack(e) {
    e.preventDefault()
    // navigate(-1)
    handleClose(e)
  }

  async function handleSubmit(e) {
    e.preventDefault()
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  return (
    <div className={getBlackout({ device, modalActiv })} onClick={handleClose}>
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={getClassName({
              modalActiv,
              device,
              dark: settings.styleTheme,
            })}
          >
            <Title
              children={initialLanguage[lang].a_title}
              dark={settings.styleTheme}
              handleBack={handleBack}
              handleClose={handleClose}
            />

            {device === 'desktop' ? <div className="indent20"></div> : null}

            <Content>
              <AboutLang />
            </Content>

            {device === 'mobile' ? (
              mobileClose ? (
                <Button
                  onClick={handleClose}
                  color={getColor({ dark: settings.styleTheme })}
                  isDesktop={false}
                  plug={true}
                >
                  {initialLanguage[lang].a_cancel}
                </Button>
              ) : null
            ) : null}

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
    </div>
  )
}
