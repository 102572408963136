import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext'
import { DataProvider } from './context/DataContext'
import { PageProvider } from './context/PageContext'
import State from './components/State'
import './style/main.css'
import Device from './hooks/useDevice'
import Language from './language/Language'

//*
const allowedPaths = [
  '/',
  '/sign-in',
  '/sign-up',
  '/forgot-password',
  '/privacy-policy',
  '/update-profile',
  '/contact-us',
  '/change-email',
  '/change-password',
  '/delete-account',
  '/mobile-menu',
  '/modal-large',
  '/modal-small',
  '/about',
  '/background',
  '/analytics',
  '/:userId',
]

const App = () => {
  const device = Device()
  const lang = Language()

  // let time = 1677055746443
  // console.log(new Date(time).toISOString().slice(0, 10))
  // console.log(new Date(time).toISOString().slice(11, 16))

  // const path = window.location.pathname
  // if (
  //   !allowedPaths.includes(path) &&
  //   path !== '/domain-list' &&
  //   path !== '/page-list'
  // ) {
  //   window.location.href = '/'
  // }

  return (
    // <div className={`global ${device === 'mobile' ? ' mobile' : ''}`}>
    <div className={'global'}>
      <div className="responsive" />
      <Router>
        <AuthProvider>
          <DataProvider>
            <PageProvider>
              <Routes>
                {allowedPaths.map((path) => (
                  <Route
                    exact
                    key={path}
                    path={path}
                    element={<State device={device} lang={lang} />}
                  />
                ))}

                {/* {!allowedPaths.includes(path) && (
                  <>
                    <Route
                     exact
                     path="/:userId"
                     element={<Space device={device} lang={lang} />}
                   />
                    <Route exact path="/domain-list" element={<DomainList />} />
                    <Route exact path="/page-list" element={<PageList />} />
                  </>
                )}
                <PrivateRoute path="/update-profile" element={UpdateProfile} /> */}
              </Routes>
            </PageProvider>
          </DataProvider>
        </AuthProvider>
      </Router>
    </div>
  )
}

export default App
