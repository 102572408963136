import React, { useState, useEffect } from 'react'
import * as localForage from 'localforage'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import { usePage } from '../context/PageContext'

import { Container } from '../style/ModalSmall.styled'

import Button from '../button/Button'
import Link from '../button/Link'
import Title from '../modal/Title'
import LineHr from '../minor/LineHr'
import { toast } from 'react-toastify'
import Content from '../modal/Content'
import initialLanguage from '../language/initialLanguage'
import UserName from '../minor/UserName'

import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getButton,
} from '../functionSet/ModalFunction'

export default function ContactUs({ button, fullWidth, mobileClose }) {
  const { currentUser, logout } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`
  const { global, setGlobal, setDrag, setRefresh, lang } = useData()
  const { settings, styleData } = global

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  const navigate = useNavigate()

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({
      device,
      styleData,
      button,
    }),
    width: getWidth({ styleData, fullWidth }),
  }

  const { contactUs, setContactUs } = usePage()
  const [activeDelay, setActiveDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(contactUs)
      setDrag(false)
    }, 1)
  }, [contactUs])

  let modalActiv = activeDelay

  async function handleClose(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/')
      setContactUs(false)
    }, 400)
  }

  async function handleBack(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate(-1)
      setContactUs(false)
    }, 400)
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  return (
    <div className={getBlackout({ device, modalActiv })} onClick={handleClose}>
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={getClassName({
            modalActiv,
            device,
            dark: settings.styleTheme,
          })}
        >
          <Title
            children={initialLanguage[lang].nb_contact_us}
            dark={settings.styleTheme}
            handleBack={handleBack}
            handleClose={handleClose}
          />

          {device === 'desktop' ? <div className="indent20"></div> : null}

          <Content>
            <a
              className={`contactText ${settings.styleTheme && 'dark'}`}
              href="mailto:info@titletab.com"
            >
              <i className="bx bx-envelope"></i> info@titletab.com
            </a>
            <LineHr
              text={initialLanguage[lang].auth_or}
              dark={settings.styleTheme}
            />
            <a
              className={`contactText mt-2 ${settings.styleTheme && 'dark'}`}
              href="https://t.me/titletab"
              target="_blank"
            >
              <i className="bx bxl-telegram"></i> @titletab
            </a>
            <br />
          </Content>

          <Button
            onClick={handleClose}
            color={'grey'}
            isDesktop={true}
            dark={settings.styleTheme}
          >
            {initialLanguage[lang].up_account_settingsCancel}
          </Button>

          <div className="indent40"></div>
        </div>
      </Container>
    </div>
  )
}
