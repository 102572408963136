import React from 'react'
import { MinuteGraphContainer } from '../styles/GetAnalytics.styled'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default function MinuteGraph({
  clicksTimeLine,
  viewsTimeLine,
  visitorsTimeLine,
}) {
  const labels = []
  for (let i = 0; i < 24; i++) {
    let hour = i < 10 ? `0${i}` : `${i}`
    for (let j = 0; j < 60; j++) {
      let minute = j < 10 ? `0${j}` : `${j}`
      labels.push(`${hour}:${minute}`)
    }
  }
  let clicks = []
  Object.keys(clicksTimeLine).forEach((key) => {
    clicks.push(clicksTimeLine[key])
  })

  let views = []
  Object.keys(viewsTimeLine).forEach((key) => {
    views.push(viewsTimeLine[key])
  })

  let visitors = []
  Object.keys(visitorsTimeLine).forEach((key) => {
    visitors.push(visitorsTimeLine[key])
  })

  const options = {
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: false,
      },
    },
  }

  //get first 24 elements from array labels
  // labels.splice(144, labels.length - 144)
  // clicks.splice(144, clicks.length - 144)
  // views.splice(144, views.length - 144)
  // visitors.splice(144, visitors.length - 144)

  const data = {
    labels,
    datasets: [
      {
        label: 'Visitors',
        data: visitors,
        backgroundColor: 'rgba(26, 115, 232)',
      },
      {
        label: 'Views',
        data: views,
        backgroundColor: 'rgba(249, 171, 0)',
      },
      {
        label: 'Clicks',
        data: clicks,
        backgroundColor: 'rgba(30, 142, 62)',
      },
    ],
  }
  return (
    <MinuteGraphContainer>
      <div className="content">
        <Bar options={options} data={data} />
      </div>
    </MinuteGraphContainer>
  )
}
