import React from 'react'
import { Container } from './ListScroll.styled'

export default function ListScrollOne({ setState, dark }) {
  const iconList = [
    'bx bxl-facebook-circle',
    'bx bxl-twitter',
    'bx bxl-linkedin-square',
    'bx bxl-pinterest',
    'bx bxl-reddit',
    'bx bxl-tumblr',
    'bx bxl-skype',
    'bx bxl-whatsapp',
    'bx bxl-telegram',
    'bx bxl-blogger',
    'bx bx-envelope',
    'bx bxl-vk',
    'bx bxl-ok-ru',
  ]

  const iconTitle = [
    'Share on Facebook',
    'Share on Twitter',
    'Share on Linkedin',
    'Share on Pinterest',
    'Share on Reddit',
    'Share on Tumblr',
    'Share on Skype',
    'Share on Whatsapp',
    'Share on Telegram',
    'Share on Blogger',
    'Share on Email',
    'Share on Vk',
    'Share on Ok',
  ]

  const chooseIcon = (e) => {
    setState(e)
  }

  let iconScroll = document.getElementById('iconScroll')
  if (iconScroll) {
    iconScroll.addEventListener('wheel', function (event) {
      event.preventDefault()
      let delta = event.deltaY || event.detail || event.wheelDelta
      if (delta > 0) {
        iconScroll.scrollLeft += 20
      } else {
        iconScroll.scrollLeft -= 20
      }
    })
  }

  const iconFirst = iconList.map((item, index) => {
    return (
      <div
        className={'chooseIcon'}
        key={index}
        onClick={() => chooseIcon(index)}
        title={iconTitle[index]}
      >
        <i className={item}></i>
      </div>
    )
  })

  function getClassName() {
    let className = 'iconContainer'
    if (dark) className += ' dark'
    return className
  }

  return (
    <Container>
      <div className={getClassName()} id="iconScroll">
        <div
          className="iconLine"
          style={{ marginTop: '1vw', marginBottom: '1vw' }}
        >
          {iconFirst}
        </div>
      </div>
    </Container>
  )
}
