import React from 'react'

export default function AboutDe() {
  return (
    <>
      <p>
        Willkommen bei TitleTab - Speed Dial Dashboard, der ultimativen
        Tab-Verwaltungslösung, die entwickelt wurde, um Ihr Surferlebnis
        schneller und bequemer als je zuvor zu machen. Unsere Lösung bietet eine
        Reihe von Funktionen, mit denen Sie Ihre Browser-Tabs personalisieren,
        versehentliches Schließen des Browsers verhindern und Ihre bevorzugten
        Links mit anderen Benutzern teilen können.
      </p>
      <p>Hier sind einige der erstaunlichen Funktionen, die TitleTab bietet:</p>
      <p>
        <strong>Personalisierte Startseite</strong>
      </p>
      <p>
        Mit unserer StartPage-Funktion können Sie Ihre Registerkarten einfach
        per Drag & Drop verschieben, um sie so anzuordnen, wie es Ihren
        Anforderungen entspricht. Sie können auch jede Registerkarte mit
        verschiedenen Anzeigeoptionen anpassen, darunter ein Screenshot der
        Webseite, Text, Logos für beliebte Websites oder Ihre eigenen Bilder.
        Wir bieten sowohl ein helles als auch ein dunkles Thema an, sodass Sie
        dasjenige auswählen können, das Ihren Vorlieben entspricht.
      </p>
      <p>
        <strong>Tab-Organisation: Ordner und unbegrenzte Plätze</strong>
      </p>
      <p>
        TitleTab bietet leistungsstarke Tab-Organisationsfunktionen, mit denen
        Sie Ihre Tabs so strukturieren können, wie es für Sie am besten
        funktioniert. Sie können Registerkarten in Ordnern gruppieren, indem Sie
        einfach eine Registerkarte auf eine andere ziehen und dort ablegen.
        Darüber hinaus können Sie neue Bereiche erstellen, die Ihnen helfen,
        verschiedene Arten von Registerkarten zu trennen, z. B. arbeitsbezogene
        Registerkarten und hobbybezogene Registerkarten. Mit den
        Tab-Organisationsfunktionen von TitleTab können Sie Ihre Tabs einfach
        verwalten und Ihr Surferlebnis organisiert und effizient halten.
      </p>
      <p>
        <strong>Öffentlicher Platz mit einem einzigartigen Link</strong>
      </p>
      <p>
        TitleTab-Benutzer können auch einen öffentlichen Bereich erstellen, auf
        den andere Benutzer zugreifen können, die über den Link verfügen. Wenn
        Sie die Schaltfläche "Öffentlicher Bereich" zum ersten Mal aktivieren,
        werden wir Sie bitten, einige Felder auszufüllen, die zum Starten Ihres
        öffentlichen Bereichs erforderlich sind. Das wichtigste Feld, das
        ausgefüllt werden muss, ist die eindeutige Linkadresse ("Pfad für den
        öffentlichen Platzzugriff"), über die Ihr Bereich mit den Registerkarten
        zugänglich ist, die Sie mit anderen Benutzern teilen möchten. Sobald Sie
        den öffentlichen Bereich aktiviert haben, können Sie mit dem Hinzufügen
        von Registerkarten beginnen. Diese Funktion ist besonders nützlich, wenn
        Sie Registerkarten für gemeinsames Arbeiten mit Freunden oder Kollegen
        teilen möchten. Mit der öffentlichen Bereichsfunktion von TitleTab
        können Sie Ihre Registerkarten ganz einfach teilen und auf bequeme und
        sichere Weise mit anderen zusammenarbeiten.
      </p>
      <p>
        <strong>OneTab: Speichern Sie alle geöffneten Tabs in einem</strong>
      </p>
      <p>
        Für gängige Browser ist eine Erweiterung verfügbar, mit der Benutzer
        ihre geöffneten Tabs in ihrem Browser verwalten können. Durch einfaches
        Klicken auf die Erweiterungsschaltfläche können Sie alle (oder
        ausgewählte) Registerkarten in einer Registerkarte speichern, die eine
        Liste von Websites speichert und jederzeit für Sie wieder geöffnet
        werden kann. Diese Funktion schont die Ressourcen Ihres Geräts
        erheblich, da geöffnete Tabs weiterhin funktionieren und den Speicher
        Ihres Geräts verwenden.
      </p>
      <p>
        Es ist auch erwähnenswert, dass wir nicht nachverfolgen, was genau Sie
        auf diese Weise speichern, was Ihre Privatsphäre garantiert, aber
        gleichzeitig können wir diese Informationen nicht für Sie speichern.
        Daher sind diese Informationen nur lokal in diesem Browser auf diesem
        Gerät verfügbar, an dem Sie arbeiten. Dies ist der Preis für Ihre
        Privatsphäre.
      </p>
      <p>
        <strong>Unendlich neue Registerkarte</strong>
      </p>
      <p>
        Infinity New Tab ist eine weitere Funktion unserer Erweiterung, die ein
        endloses Surferlebnis bietet. Mit unendlich neuen Registerkarten können
        Benutzer weiter surfen, ohne befürchten zu müssen, dass sie
        versehentlich ihre letzte geöffnete Registerkarte schließen. Diese
        Funktion fügt automatisch eine neue Registerkarte links neben der
        zuletzt geöffneten Registerkarte hinzu, um sicherzustellen, dass
        Benutzer immer eine Registerkarte haben, auf die sie zurückgreifen
        können. Sobald zusätzliche Tabs geöffnet werden, verschwindet der
        Infinity New Tab, sodass Benutzer ohne Unterbrechung frei surfen können.
        Diese Funktion bietet Benutzern Sicherheit und verhindert die
        Frustration, die durch das versehentliche Schließen des letzten Tabs
        entsteht.
      </p>
      <p>
        <strong>TitleTab-Erweiterungen</strong>
      </p>
      <p>
        TitleTab ist mit gängigen Webbrowsern wie Google Chrome, Mozilla Firefox
        und Microsoft Edge kompatibel. Unsere Erweiterung kann einfach von den
        offiziellen Erweiterungsspeichern jedes Browsers heruntergeladen und
        installiert werden.
      </p>
      <p>Hier ist eine Liste, wo Sie TitleTab herunterladen können:</p>
      <ul>
        <li>
          <a
            href="https://chrome.google.com/webstore/detail/titletab-web-dashboard/ldlndadamgafkigafpajckkjbblnepcb"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome Web Store
          </a>
        </li>
        <li>
          <a
            href="https://addons.mozilla.org/firefox/addon/titletab-web-dashboard/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox Add-ons
          </a>
        </li>
        <li>
          <a
            href="https://microsoftedge.microsoft.com/addons/detail/titletab-web-dashboard/mnckgifglkiecanfbdpnajiplpabkphh"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge Add-ons
          </a>
        </li>
      </ul>
      <p>
        Nach der Installation können Sie TitleTab verwenden, um Ihre neue
        Tab-Seite anzupassen, Ihre Tabs zu speichern und zu organisieren, ein
        versehentliches Schließen von Tabs zu verhindern und Links mit anderen
        zu teilen. Verpassen Sie nicht die Gelegenheit, Ihr Surferlebnis mit
        TitleTab zu optimieren. Laden Sie unsere Erweiterung noch heute
        herunter!
      </p>
      <p>
        <strong>Privatsphäre und Sicherheit</strong>
      </p>
      <p>
        TitleTab nimmt Ihre Privatsphäre und Sicherheit ernst. Alle Ihre Daten
        werden sicher auf Ihrem Gerät und in der Cloud gespeichert, und wir
        geben Ihre Informationen niemals an Dritte weiter.
      </p>
      <hr />
      <p>
        TitleTab ist die ultimative Tab-Management-Lösung für alle, die die
        Kontrolle über ihr Surferlebnis übernehmen möchten. Probieren Sie es
        noch heute aus und überzeugen Sie sich selbst vom Unterschied!
      </p>
      <p>
        Wenn Sie Fragen zu TitleTab haben oder Hilfe zu einer unserer Funktionen
        benötigen, zögern Sie bitte nicht, uns unter&nbsp;
        <a
          href="mailto:info@titletab.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@titletab.com
        </a>
        . Unser Team hilft Ihnen gerne weiter und beantwortet Ihre Fragen. Wir
        schätzen Ihr Feedback und sind bestrebt, die bestmögliche
        Benutzererfahrung zu bieten. Zögern Sie also nicht, uns zu kontaktieren,
        wenn Sie Vorschläge oder Feedback haben.
      </p>
    </>
  )
}
