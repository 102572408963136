import React, { useState, useEffect } from 'react'
import './loader.css'

export default function Loader({ global, loadSpace }) {
  const [showLoader, setShowLoader] = useState(false)
  useEffect(() => {
    if ((!global && !loadSpace) || (global && loadSpace)) {
      const timeoutId = setTimeout(() => {
        setShowLoader(true)
      }, 400)
      return () => clearTimeout(timeoutId)
    } else {
      setShowLoader(false)
    }
  }, [global, loadSpace])

  if (!showLoader) return null

  return (
    <div className="lds-position">
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
