import styled from 'styled-components'

export const Container = styled.div`
.version {
  position: fixed;
  bottom: 5px;
  right: 5px;
  font-size: 12px;
  color: #777;

 a {
    color: #777;
    text-decoration: underline;
  }
  
`
