import { database } from '../firebase'

export default async function Domain({ urlValid }) {
  //Запрашиваем у Firebase наличие домена в domain
  let domain = await getDomain(urlValid)

  //Если домен есть в Firebase, обновляем amount домена в Firebase
  if (domain) {
    await updateDomain(domain)
    return domain
  }

  //Если домена нет в Firebase, добавляем его в Firebase
  //Создаем в Firebase домен
  let set = await setDomain(urlValid)

  //Если результат работы null выдаем ошибку
  if (!set) return null

  //Повторно запрашиваем домен у Firebase
  domain = await getDomain(urlValid)

  return domain
}

function getDomain(urlValid) {
  return new Promise((resolve) => {
    database.domain
      .where('domainHostname', '==', urlValid.urlDomain)
      .where('domainProtocol', 'in', [
        urlValid.urlProtocol1,
        urlValid.urlProtocol2,
      ])
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            let domain = doc.data()
            domain.idDomain = doc.id
            resolve(domain)
          })
        } else {
          resolve(null)
        }
      })
  })
}

function setDomain(urlValid) {
  return new Promise((resolve) => {
    database.domain
      .add({
        amount: 0,
        domainBackground: '',
        domainColor: '',
        domainHostname: urlValid.urlDomain,
        domainPicture: '',
        domainProtocol: urlValid.urlProtocol,
        domainTitle: '',
        domainWWW: urlValid.urlWWW,
        verifiedD: false,
      })
      .then(resolve(true))
  })
}

function updateDomain(domain) {
  return new Promise((resolve) => {
    database.domain
      .doc(domain.idDomain)
      .update({
        amount: domain.amount + 1,
      })
      .then(resolve(true))
  })
}
