import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import { usePage } from '../context/PageContext'

import { Container } from '../style/ModalSmall.styled'

import Button from '../button/Button'
import Title from '../modal/Title'

import Switch from '../switch/Switch'
import Scale from '../scale/Scale'
import initialLanguage from '../language/initialLanguage'

import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getColor,
  getButton,
  getClassLink,
  checkRequired,
  checkEmail,
  checkPassword,
  checkPasswordMatch,
  setError,
  clearError,
  getFocus,
} from '../functionSet/ModalFunction'
import Select from '../select/Select'
import AddSpaceModal from '../components/dnd/AddSpaceModal'
import UpdateSpaceModal from '../components/dnd/UpdateSpaceModal'
import withLocalForage from '../context/withLocalForage'

const MobileMenu = ({
  button,
  fullWidth,
  mobileClose,
  updateSettings,
  changeSpaceBar,
  spaceUpBar,
  spaceDownBar,
}) => {
  const { currentUser } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`
  const { global, space, launch, setLaunch, lang } = useData()
  const { settings, styleData } = global

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  const [spaces, setSpaces] = useState(global.spaces)
  useEffect(() => {
    setSpaces(global.spaces)
  }, [launch])
  const { mobileMenu } = usePage()

  const [defaultSearch, setDefaultSearch] = useState(settings.defaultSearch)
  let modalActiv = mobileMenu
  const navigate = useNavigate()

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({
      device,
      styleData,
      button,
    }),
    width: getWidth({ styleData, fullWidth }),
  }

  const searchList = [
    'Google',
    'Bing',
    'Yahoo',
    'Baidu',
    'DuckDuckGo',
    'Yandex',
  ]

  const [swUseDark, setSwUseDark] = useState(settings.styleTheme)
  const [swUseImg, setSwUseImg] = useState(settings.tabsAsImage)
  const [swOpenTabTarget, setSwOpenTabTarget] = useState(settings.targetTabs)
  const [swShowSearch, setSwShowSearch] = useState(settings.showSearch)
  const [swOpenSearchTarget, setSwOpenSearchTarget] = useState(
    settings.targetSearch
  )

  const styleThemeChange = () => {
    setSwUseDark(!swUseDark)
    global.settings.styleTheme = !swUseDark
    updateSettings(path, global.settings)
  }

  const styleUseImg = () => {
    setSwUseImg(!swUseImg)
    global.settings.tabsAsImage = !swUseImg
    updateSettings(path, global.settings)
  }

  const openTabTarget = () => {
    setSwOpenTabTarget(!swOpenTabTarget)
    global.settings.targetTabs = !swOpenTabTarget
    updateSettings(path, global.settings)
  }

  const showSearch = () => {
    setSwShowSearch(!swShowSearch)
    global.settings.showSearch = !swShowSearch
    updateSettings(path, global.settings)
  }

  const openSearchTarget = () => {
    setSwOpenSearchTarget(!swOpenSearchTarget)
    global.settings.targetSearch = !swOpenSearchTarget
    updateSettings(path, global.settings)
  }

  const selectSearch = (value) => {
    global.settings.defaultSearch = value
    updateSettings(path, global.settings)
  }

  function startLaunch() {
    //get unique number from 6000 to 7000
    let unique = Math.floor(Math.random() * (7000 - 6000 + 1)) + 6000
    while (unique == launch) {
      unique = Math.floor(Math.random() * (7000 - 6000 + 1)) + 6000
    }
    setLaunch(unique)
  }

  async function handleSubmit(e) {
    e.preventDefault()
  }

  async function handleClose(e) {
    e.preventDefault()
    navigate('/')
  }

  async function handleBack(e) {
    e.preventDefault()
    navigate(-1)
  }

  async function handlePolicy(e) {
    navigate('/privacy-policy')
  }

  async function handleUpdateProfile() {
    navigate('/update-profile')
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  const spaceFullNav = Object.entries(spaces).map((item, index) => {
    return (
      <li key={item} id={'li' + item[0]}>
        <a className={space == item[0] ? 'active' : null} title={item[1].space}>
          <i
            className={item[1].icon ? item[1].icon : 'bx bx-bookmarks'}
            onClick={() => changeSpace(item[0])}
          ></i>
          <span
            className="links_name short"
            onClick={() => changeSpace(item[0])}
          >
            {item[1].space}
          </span>
          <i
            className="up bx bx-chevron-up"
            title="Space Up"
            onClick={() => spaceUp(item[0])}
          ></i>
          <i
            className="down bx bx-chevron-down"
            title="Space Down"
            onClick={() => spaceDown(item[0])}
          ></i>
          <i
            className="settings bx bx-cog"
            title="Space Settings"
            onClick={() => updateSpace(item[0])}
          ></i>
        </a>
      </li>
    )
  })

  let isSpaceRunning = false
  function changeSpace(value) {
    if (isSpaceRunning) return
    isSpaceRunning = true

    changeSpaceBar(path, value, space)

    isSpaceRunning = false
    return
  }

  function spaceUp(key) {
    if (isSpaceRunning) return
    isSpaceRunning = true

    spaceUpBar(path, key, space)

    isSpaceRunning = false
    return
  }

  function spaceDown(key) {
    if (isSpaceRunning) return
    isSpaceRunning = true

    spaceDownBar(path, key, space)

    isSpaceRunning = false
    return
  }

  const [modalActive, setModalActive] = useState(false)
  const [modalUpdateActive, setModalUpdateActive] = useState(false)
  const [spaceUpdate, setSpaceUpdate] = useState()

  function addSpace() {
    setModalActive(true)
  }

  const modalAdd = modalActive ? (
    <AddSpaceModal
      active={modalActive}
      setActive={setModalActive}
      button={2}
      fullWidth={false}
      mobileClose={false}
    />
  ) : null

  function updateSpace(value) {
    setSpaceUpdate(value)
    setModalUpdateActive(true)
  }

  const modalUpdate = modalUpdateActive ? (
    <UpdateSpaceModal
      active={modalUpdateActive}
      setActive={setModalUpdateActive}
      spaceUpdate={spaceUpdate}
      button={3}
      fullWidth={false}
      mobileClose={false}
    />
  ) : null

  return (
    <div className={getBlackout({ device, modalActiv })} onClick={handleClose}>
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={
              getClassName({
                modalActiv,
                device,
                dark: settings.styleTheme,
              }) +
              ' ' +
              'mobileMenu'
            }
          >
            <Title
              children={'titletab'}
              dark={settings.styleTheme}
              icon={'bx-bookmarks'}
              handleBack={handleBack}
              handleClose={handleClose}
            />

            {device === 'desktop' ? <div className="indent20"></div> : null}

            <div className="content mobileMenu">
              <div className="text">
                <div
                  className={
                    settings.styleTheme ? 'spaceList dark' : 'spaceList'
                  }
                >
                  <ul>
                    {spaceFullNav}
                    <li>
                      <a onClick={addSpace}>
                        <i className="bx bx-plus"></i>
                        <span className="links_name">Add space</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <br />
                <Switch
                  name="swUseDark"
                  title="Use dark theme"
                  checked={swUseDark}
                  onChange={styleThemeChange}
                  disabled={false}
                />
                <Switch
                  name="swUseImg"
                  title="Use the images"
                  smallTitle="as a fill tabs"
                  checked={swUseImg}
                  onChange={styleUseImg}
                  disabled={false}
                />
                <Switch
                  name="swOpenTabTarget"
                  title="Open Tabs"
                  smallTitle="in a new window"
                  checked={swOpenTabTarget}
                  onChange={openTabTarget}
                  disabled={false}
                />
                <Switch
                  name="swShowSearch"
                  title="Show Search"
                  checked={swShowSearch}
                  onChange={showSearch}
                  disabled={false}
                />
                <Switch
                  name="swOpenSearchTarget"
                  title="Open search results"
                  smallTitle="in a new window"
                  checked={swOpenSearchTarget}
                  onChange={openSearchTarget}
                  disabled={false}
                />
                <Select
                  id="defaultSearch"
                  title={initialLanguage[lang].nb_default_search}
                  value={defaultSearch}
                  options={searchList}
                  onChange={selectSearch}
                />
                <Scale />
              </div>
            </div>

            <Button onClick={handlePolicy} color={'blue'}>
              {initialLanguage[lang].nb_privacy_policy}
            </Button>

            {currentUser ? (
              <Button onClick={handleUpdateProfile} color={'blue'}>
                {initialLanguage[lang].nb_account_settings}
              </Button>
            ) : (
              ''
            )}

            {device === 'mobile' ? (
              mobileClose ? (
                <Button
                  onClick={handleClose}
                  color={getColor({ dark: settings.styleTheme })}
                  isDesktop={false}
                  plug={true}
                >
                  Close
                </Button>
              ) : null
            ) : null}

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
      {modalAdd}
      {modalUpdate}
    </div>
  )
}

export default withLocalForage(MobileMenu)
