import React from 'react'
import initialLanguage from '../language/initialLanguage'
import { useData } from '../context/DataContext'
import { Container } from '../style/UpdateNavigation.styled'

export default function UpdateNavigation({
  screen,
  logo,
  homeNav,
  colorNav,
  screenNav,
  logoNav,
  imageNav,
  setHomeNav,
  setColorNav,
  setScreenNav,
  setLogoNav,
  setImageNav,
  dark,
}) {
  const { lang } = useData()

  function handleHome() {
    setHomeNav(true)
    setColorNav(false)
    setScreenNav(false)
    setLogoNav(false)
    setImageNav(false)
  }

  function handleColor() {
    setHomeNav(false)
    setColorNav(true)
    setScreenNav(false)
    setLogoNav(false)
    setImageNav(false)
  }

  function handleScreen() {
    if (screen) {
      setHomeNav(false)
      setColorNav(false)
      setScreenNav(true)
      setLogoNav(false)
      setImageNav(false)
    }
  }

  function handleLogo() {
    if (logo) {
      setHomeNav(false)
      setColorNav(false)
      setScreenNav(false)
      setLogoNav(true)
      setImageNav(false)
    }
  }

  function handleImage() {
    setHomeNav(false)
    setColorNav(false)
    setScreenNav(false)
    setLogoNav(false)
    setImageNav(true)
  }

  return (
    <Container>
      <div className={dark ? 'navUpdate dark' : 'navUpdate'}>
        <div
          className="navButton"
          onClick={handleHome}
          title={initialLanguage[lang].un_primary_settings}
        >
          <i className="bx bx-bookmark-plus"></i>
          <div className={homeNav ? 'navText border' : 'navText'}>home</div>
        </div>

        <div
          className="navButton"
          onClick={handleColor}
          title={initialLanguage[lang].un_customize_color_apply}
        >
          <i className="bx bx-hash"></i>
          <div className={colorNav ? 'navText border' : 'navText'}>color</div>
        </div>

        <div
          className={screen ? 'navButton' : 'navButton inactive'}
          onClick={handleScreen}
          title={
            screen
              ? initialLanguage[lang].un_apply_screenshot
              : initialLanguage[lang].un_temporarily_unavailable
          }
        >
          <i className="bx bx-screenshot"></i>
          {screen ? null : (
            <div className="await">
              <i className="bx bxs-hourglass-top"></i>
            </div>
          )}
          <div className={screenNav ? 'navText border' : 'navText'}>screen</div>
        </div>

        <div
          className={logo ? 'navButton' : 'navButton inactive'}
          onClick={handleLogo}
          title={
            logo
              ? initialLanguage[lang].un_apply_logo
              : initialLanguage[lang].un_temporarily_unavailable
          }
        >
          <i className="bx bxs-shapes"></i>
          {logo ? null : (
            <div className="await">
              <i className="bx bxs-hourglass-top"></i>
            </div>
          )}
          <div className={logoNav ? 'navText border' : 'navText'}>logo</div>
        </div>

        <div
          className="navButton"
          onClick={handleImage}
          title={initialLanguage[lang].un_add_own_image}
        >
          <i className="bx bx-image"></i>
          <div className={imageNav ? 'navText border' : 'navText'}>image</div>
        </div>
      </div>
    </Container>
  )
}
