import { database } from '../firebase'

export default function PublicOutGet({ pathname }) {
  return new Promise((resolve, reject) => {
    let docExists = false
    // Проверяем, существует ли документ с указанным uid
    database.space
      .where('nickname', '==', pathname)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          let data = {
            ...querySnapshot.docs[0].data(),
            uid: querySnapshot.docs[0].id,
          }
          // Если документ с указанным pathname существует, возвращаем его данные
          resolve(data)
        } else {
          database.space
            .doc(pathname)
            .get()
            .then((doc) => {
              if (doc.exists) {
                docExists = true
                resolve(doc.data())
              } else {
                // Если документ не найден, возвращаем null
                resolve(null)
              }
            })
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
