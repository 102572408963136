import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import { usePage } from '../context/PageContext'
import { Container } from '../style/ModalSmall.styled'
import { toast } from 'react-toastify'
import initialLanguage from '../language/initialLanguage'
import Button from '../button/Button'
import Input from '../input/Input'
import Title from '../modal/Title'
import LineHr from '../minor/LineHr'
import Content from '../modal/Content'
import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getButton,
  getClassLink,
  checkEmail,
  checkPassword,
  clearError,
  getFocus,
} from '../functionSet/ModalFunction'

export default function SignIn({ button, fullWidth, mobileClose }) {
  const { login, signupGoogle, signupFacebook, signupTwitter } = useAuth()
  const { global, setGlobal, setDrag, setRefresh, lang } = useData()
  const { settings, styleData } = global

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  const navigate = useNavigate()
  const [modal, setModal] = useState(true)

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({
      device,
      styleData,
      button,
    }),
    width: getWidth({ styleData, fullWidth }),
  }

  const { signIn, setSignIn } = usePage()
  const [activeDelay, setActiveDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(signIn)
      setDrag(false)
    }, 1)
  }, [signIn])

  let modalActiv = activeDelay

  async function handleClose(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/')
      setSignIn(false)
    }, 400)
  }

  async function handleBack(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate(-1)
      setSignIn(false)
    }, 400)
  }

  async function handleSignUp(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/sign-up')
      setSignIn(false)
    }, 400)
  }

  async function handleForgot(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/forgot-password')
      setSignIn(false)
    }, 400)
  }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  async function handleGoogle(e) {
    e.preventDefault()

    try {
      await signupGoogle()
      navigate('/')
      setGlobal()
      setRefresh('handleGoogle')
      toast.success('Sign in successfully completed!')
    } catch {
      toast.error('Failed to sign in!')
    }
  }

  async function handleFacebook(e) {
    e.preventDefault()

    try {
      await signupFacebook()
      navigate('/')
      setGlobal()
      setRefresh('handleFacebook')
      toast.success('Sign in successfully completed!')
    } catch {
      toast.error('Failed to sign in!')
    }
  }

  async function handleTwitter(e) {
    e.preventDefault()

    try {
      await signupTwitter()
      navigate('/')
      setGlobal()
      setRefresh('handleTwitter')
      toast.success('Sign in successfully completed!')
    } catch {
      toast.error('Failed to sign in!')
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()

    const emailValid = checkEmail({
      email: email,
      id: 'email_si',
      device: device,
      afterFocus: false,
    })

    const passwordValid = checkPassword({
      password: password,
      id: 'password_si',
      device: device,
      afterFocus: false,
    })

    if (!emailValid || !passwordValid) {
      return
    }

    try {
      await login(email, password)
      navigate('/')
      setGlobal()
      setRefresh('handleSubmit')
      toast.success('Sign in completed successfully!')
    } catch {
      toast.error('Failed to sign in!')
    }
  }

  function clearInput() {
    setEmail('')
    setPassword('')
    clearError('email_si', initialLanguage[lang].auth_email_address)
    clearError('password_si', initialLanguage[lang].auth_password)
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  useEffect(() => {
    //Для поля Email
    getFocus('email_si', setModal)
    //Для поля Пароль
    getFocus('password_si', setModal)
  }, [])

  return (
    <div
      className={getBlackout({ device, modalActiv })}
      onClick={modal ? handleClose : null}
    >
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={getClassName({
              modalActiv,
              device,
              dark: settings.styleTheme,
            })}
          >
            <Title
              children={initialLanguage[lang].auth_sing_in_titletab}
              dark={settings.styleTheme}
              handleBack={handleBack}
              handleClose={handleClose}
            />

            {device === 'desktop' ? <div className="indent20"></div> : null}

            <Button onClick={handleGoogle} color={'google'} isDesktop={true}>
              {initialLanguage[lang].auth_sign_google}
            </Button>

            <Button
              onClick={handleFacebook}
              color={'facebook'}
              isDesktop={true}
            >
              {initialLanguage[lang].auth_sign_facebook}
            </Button>

            <Button onClick={handleTwitter} color={'twitter'} isDesktop={true}>
              {initialLanguage[lang].auth_sign_twitter}
            </Button>

            <Content>
              <LineHr
                text={initialLanguage[lang].auth_or}
                dark={settings.styleTheme}
              />

              <Input
                id="email_si"
                labelId="email_si_label"
                type="email"
                state={email}
                setState={setEmail}
                placeholder={''}
                label={initialLanguage[lang].auth_email_address}
                error={'Invalid email'}
                autoComplete="new-password"
                required={true}
                dark={settings.styleTheme}
              />

              <Input
                id="password_si"
                labelId="password_si_label"
                type="password"
                state={password}
                setState={setPassword}
                placeholder={''}
                label={initialLanguage[lang].auth_password}
                autoComplete="new-password"
                required={true}
                dark={settings.styleTheme}
              />
              <a className={getClassLink({ device })} onClick={handleForgot}>
                {initialLanguage[lang].auth_forgot_password}
              </a>
            </Content>

            <Button
              onClick={handleSubmit}
              button={true}
              color={'blue'}
              isDesktop={true}
              type="submit"
            >
              {initialLanguage[lang].auth_sign_in}
            </Button>

            <Button
              onClick={handleSignUp}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].auth_need_sign_up}
            </Button>

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
    </div>
  )
}
