import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  database,
  // runTransaction,
  FieldValue,
  currentDate,
  // currentTime,
} from '../firebase'
import localForage from 'localforage'

export default function SetAnalytics({ uid }) {
  const pathLocation = useLocation()
  const pathBase = btoa(pathLocation.pathname)

  let referrer = document.referrer
  if (!referrer) referrer = 'direct'
  const referrerBase = btoa(referrer)

  let analyticsRef = database.analytics.doc(currentDate)

  const analyticsHandler = async () => {
    // console.log('Delete this code after testing')
    // if (
    //   window.location.href.startsWith('http://localhost:3000/') ||
    //   window.location.href.startsWith('http://192.168.0.121:3000') ||
    //   window.location.href.startsWith('http://192.168.31.76:3000/')
    // )
    //   return null

    // // Читаем или устанавливаем куки с уникальным ID пользователя
    const user = await readOrSetUserIdCookie()
    if (!uid) uid = user.userId
    const location = await readOrSetUserLocationCookie()
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    try {
      await analyticsRef.update({
        // [`${uid}.pageViews`]: FieldValue.increment(1),
        [`${uid}.country`]: location.country,
        [`${uid}.city`]: location.city,
        [`${uid}.latitude`]: location.latitude,
        [`${uid}.longitude`]: location.longitude,
        [`${uid}.language`]: navigator.language,
        [`${uid}.system`]: getSystem(userAgent), ///////////////////////ПЕРЕДЕЛАТЬ
        [`${uid}.browser`]: getBrowser(userAgent),
        [`${uid}.screenX`]: window.innerWidth,
        [`${uid}.screenY`]: window.innerHeight,
        [`${uid}.extantion`]: window === window.top ? false : true,
        [`${uid}.path.${pathBase}.path`]: new FieldValue(pathLocation.pathname),
        [`${uid}.path.${pathBase}.views`]: FieldValue.increment(1),
        [`${uid}.referrer.${referrerBase}.referrer`]: new FieldValue(referrer),
        [`${uid}.referrer.${referrerBase}.views`]: FieldValue.increment(1),
        [`${uid}.timeLine.${new Date().toISOString().slice(11, 16)}.views`]:
          FieldValue.increment(1),
        [`${uid}.timeLine.${new Date().toISOString().slice(11, 16)}.clicks`]:
          FieldValue.increment(0),
      })
    } catch (error) {
      if (error.code === 'not-found') {
        await analyticsRef.set({
          [uid]: {
            country: location.country,
            city: location.city,
            latitude: location.latitude,
            longitude: location.longitude,
            language: navigator.language,
            system: getSystem(userAgent), ///////////////////////ПЕРЕДЕЛАТЬ
            browser: getBrowser(userAgent),
            screenX: window.innerWidth,
            screenY: window.innerHeight,
            extantion: window === window.top ? false : true,
            path: {
              [pathBase]: {
                path: new FieldValue(pathLocation.pathname),
                views: FieldValue.increment(1),
              },
            },
            referrer: {
              [referrerBase]: {
                referrer: new FieldValue(referrer),
                views: FieldValue.increment(1),
              },
            },
            timeLine: {
              [new Date().toISOString().slice(11, 16)]: {
                views: FieldValue.increment(1),
                clicks: FieldValue.increment(0),
              },
            },
          },
          timestamp: currentDate,
        })
      } else {
        console.error(error)
      }
    }
  }

  // const clickHandler = async () => {
  //   await analyticsRef.update({
  //     [`${uid}.timeLine.${new Date().toISOString().slice(11, 16)}.views`]:
  //       FieldValue.increment(0),
  //     [`${uid}.timeLine.${new Date().toISOString().slice(11, 16)}.clicks`]:
  //       FieldValue.increment(1),
  //   })
  // }

  useEffect(() => {
    analyticsHandler()
  }, [window.location.pathname])

  // useEffect(() => {
  //   document.addEventListener('click', clickHandler, true)
  // }, [])

  return null
}

// Функция для чтения или установки куки с уникальным ID пользователя
async function readOrSetUserIdCookie() {
  let userId = getCookie('userId')

  if (!userId) {
    let localForageUserId = await localForage.getItem('userId')
    if (localForageUserId === null) {
      userId = new Date().getTime().toString()
      setCookie('userId', userId, 3650)
      await localForage.setItem('userId', userId)

      return {
        userId,
      }
    } else {
      userId = localForageUserId
    }
  }

  return {
    userId,
  }
}

// Функция для чтения или установки куки с локацией пользователя
async function readOrSetUserLocationCookie() {
  let location = getCookie('locations')

  if (!location) {
    let localForageLocation = await localForage.getItem('locations')
    if (localForageLocation === null) {
      try {
        const data = await getLocation()
        location = JSON.stringify(data)
        setCookie('locations', location, 30)
        await localForage.setItem('locations', location)

        return data
      } catch (error) {
        throw error
      }
    } else {
      location = localForageLocation
      setCookie('locations', location, 30)
    }
  }

  return JSON.parse(location)
}

// Функция для получения локации пользователя
async function getLocation() {
  const timeout = new Promise((resolve, reject) => {
    setTimeout(() => reject('Timeout'), 2000)
  })

  try {
    const response = await Promise.race([
      timeout,
      fetch('https://ipapi.co/json/'),
    ])
    const data = await response.json()
    return {
      country: data.country_name,
      city: data.city,
      latitude: data.latitude,
      longitude: data.longitude,
    }
  } catch (error) {
    return {
      country: 'Unknown',
      city: 'Unknown',
      latitude: 'Unknown',
      longitude: 'Unknown',
    }
  }
}

// Функция для установки куки
function setCookie(name, value, days) {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

// Функция для чтения куки
function getCookie(name) {
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

function getSystem(userAgent) {
  if (/android/i.test(userAgent)) {
    return 'Android'
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }
  if (/Mac/.test(userAgent)) {
    return 'Mac OS'
  }
  if (/Windows/.test(userAgent)) {
    return 'Windows'
  }
  if (/Linux/.test(userAgent)) {
    return 'Linux'
  }
  return 'Unknown'
}

function getBrowser(userAgent) {
  if (/OPR\//.test(userAgent)) {
    return 'Opera'
  }
  if (/Chrome\//.test(userAgent) && !/Edg\//.test(userAgent)) {
    return 'Google Chrome'
  }
  if (/Edg\//.test(userAgent)) {
    return 'Microsoft Edge'
  }
  if (/Firefox\//.test(userAgent)) {
    return 'Mozilla Firefox'
  }
  if (/Safari\//.test(userAgent) && !/Chrome\//.test(userAgent)) {
    return 'Safari'
  }
  if (/MSIE|Trident/.test(userAgent)) {
    return 'Internet Explorer'
  }
  return 'Unknown'
}
