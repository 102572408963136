import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Draggable } from 'react-beautiful-dnd'
import { useData } from '../../context/DataContext'
import TabBody from './TabBody'
import { Container } from '../../style/Tab.styled'

//*
export default function Tab({ task, index, main, dragData }) {
  const navigate = useNavigate()
  const { global, space, drag, addButton, setLoadSpace, setUpdateModal } =
    useData()
  const { settings, styleData, domains, pages } = global

  let isDragDisabled = !drag
  if (space === null) isDragDisabled = true

  const title =
    task.ownTitle ||
    domains[task.idDomain].domainTitle ||
    pages[task.idPages].pagesTitle ||
    domains[task.idDomain].domainHostname

  const shotTitle =
    title.length > Math.floor(styleData.tabWidth / 8)
      ? title.slice(0, Math.floor(styleData.tabWidth / 8)) + '...'
      : title

  const url = `${domains[task.idDomain].domainProtocol}${
    domains[task.idDomain].domainWWW ? 'www.' : ''
  }${domains[task.idDomain].domainHostname}${pages[task.idPages].pagesPathname}`

  const target =
    window === window.top && !settings.targetTabs ? '_self' : '_blank'

  function activUpdateModal() {
    if (addButton && space !== null) {
      setUpdateModal({
        active: true,
        task,
        main,
        dragData,
      })
    }
  }

  const settingsButton =
    addButton && space !== null ? (
      <a onClick={activUpdateModal}>
        <div className="settings-inviz" title="Edit">
          <span className="settings-inviz-icon">&#9776;</span>
        </div>
      </a>
    ) : null

  const handleClick = (event) => {
    event.preventDefault()
    clickLink(url, event)
  }

  const isExternal = (url) => {
    if (url.startsWith('https://titletab.com')) return false
    return true
  }

  const clickLink = (url, event) => {
    if (event.ctrlKey || event.shiftKey || event.altKey) {
      window.open(url, '_blank')
    } else {
      const path = url.replace('https://titletab.com', '')
      setLoadSpace(true)
      navigate(path)
    }
  }

  const tabBg = () => {
    let bg = settings.styleTheme ? '#11101d75' : '#ffffff75'
    return bg
  }

  const tabColor = () => {
    return settings.styleTheme ? '#bbb' : '#505050'
  }

  return (
    <>
      <Draggable
        key={task.id}
        draggableId={task.id}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided) => (
          <Container
            styleData={styleData}
            dark={settings.styleTheme}
            headersBg={settings.headersBg}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            id={main ? `task-${task.id}` : `taskF-${task.id}`}
            className={main ? `task-${task.id}` : `taskF-${task.id}`}
          >
            {isExternal(url) ? (
              <a target={target} href={url} title={title}>
                <TabBody task={task} />
                <p>{shotTitle}</p>
              </a>
            ) : (
              <a target={target} href={url} title={title} onClick={handleClick}>
                <TabBody task={task} />
                <p>{shotTitle}</p>
              </a>
            )}
            {settingsButton}
          </Container>
        )}
      </Draggable>
    </>
  )
}
