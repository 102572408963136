import { database } from '../firebase'

export default function PublicOwnGet({ uid }) {
  return new Promise((resolve, reject) => {
    database.space
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          resolve(doc.data())
        } else {
          resolve(null)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
