import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { MonthGraphContainer } from '../styles/GetAnalytics.styled'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default function MonthGraph({ timestamp, clicks, views, visitors }) {
  const options = {
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,

    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
    },
  }

  const labels = timestamp

  // const clicks = state.map((item) => item.clicks).reverse()
  // const views = state.map((item) => item.views).reverse()
  // const visitors = state.map((item) => item.visitors).reverse()

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Clicks',
        data: clicks,
        borderColor: 'rgb(30, 142, 62)',
        backgroundColor: 'rgba(30, 142, 62, 1)',
      },
      {
        label: 'Views',
        data: views,
        borderColor: 'rgb(249, 171, 0)',
        backgroundColor: 'rgba(249, 171, 0, 1)',
      },
      {
        label: 'Visitors',
        data: visitors,
        borderColor: 'rgb(26, 115, 232)',
        backgroundColor: 'rgba(26, 115, 232, 1)',
      },
    ],
  }
  return (
    <MonthGraphContainer>
      <div className="content">
        <Line options={options} data={data} />
      </div>
    </MonthGraphContainer>
  )
}
