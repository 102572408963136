import styled from 'styled-components'

export const Container = styled.div`
  width: ${(props) => props.styleData.tabWidth}px;
  height: ${(props) => props.styleData.tabHeight}px;
  float: left;
  margin: 0px ${(props) => props.styleData.tabMargin}px;
  position: relative;
  font-weight: 500;
  cursor: pointer;

  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    border: none;
    outline: none;
  }

  .folder {
    width: ${(props) => props.styleData.tabWidth}px;
    max-width: ${(props) => props.styleData.tabWidth}px;
    height: ${(props) => props.styleData.tabHeight}px;
    border-radius: ${(props) => props.styleData.tabWidth / 20}px;
    margin-bottom: 0.25vw;
    margin-bottom: max(0.25vw, 5px);
    -webkit-box-shadow: ${(props) => props.styleData.boxShadowTabs};
    -moz-box-shadow: ${(props) => props.styleData.boxShadowTabs};
    box-shadow: ${(props) => props.styleData.boxShadowTabs};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    cursor: pointer;
  }

  .folderTransition {
    transition: background-color 0.5s ease;
  }

  img {
    width: ${(props) => props.styleData.tabWidth * 0.4}px;
    height: ${(props) => props.styleData.tabHeight * 0.4}px;
    border-radius: ${(props) => props.styleData.tabWidth * 0.02}px;
    -webkit-box-shadow: ${(props) => props.styleData.boxShadowFolder};
    -moz-box-shadow: ${(props) => props.styleData.boxShadowFolder};
    box-shadow: ${(props) => props.styleData.boxShadowFolder};
  }

  .background {
    width: ${(props) => props.styleData.tabWidth * 0.4}px;
    height: ${(props) => props.styleData.tabHeight * 0.4}px;
    border-radius: ${(props) => props.styleData.tabWidth * 0.02}px;
    -webkit-box-shadow: ${(props) => props.styleData.boxShadowFolder};
    -moz-box-shadow: ${(props) => props.styleData.boxShadowFolder};
    box-shadow: ${(props) => props.styleData.boxShadowFolder};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .folderSpace {
    width: ${(props) => props.styleData.tabWidth / 1.16}px;
    height: 90%;
  }

  .name {
    padding-left: 0.5vw;
    padding-left: max(0.5vw, 10px);
    padding-right: 0.5vw;
    padding-right: max(0.5vw, 10px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .folderTab {
    width: ${(props) => props.styleData.tabWidth / 2.33}px;
    height: ${(props) => props.styleData.tabHeight / 2.2}px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  p {
    width: ${(props) => props.styleData.tabWidth}px;
    height: 1.1vw;
    min-height: 22px;
    line-height: max(1.1vw, 22px);
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: ${(props) =>
      props.headersBg ? (props.dark ? '#11101d75' : '#ffffff75') : null};
    color: ${(props) => (props.dark ? '#bbbbbb' : '#505050')};
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
    text-align: center;
    border-radius: 5px;
    box-shadow: ${(props) =>
      props.headersBg ? '0 10px 20px -5px rgb(0 0 0 / 24%)' : null};
    margin: 0;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }

  a:hover p {
    background-color: ${(props) =>
      props.headersBg
        ? props.dark
          ? '#11101d'
          : '#ffffff'
        : props.dark
        ? '#11101d75'
        : '#ffffff75'};
  }

  .settings-inviz {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    width: max(1vw, 20px);
    height: ${(props) => props.styleData.tabHeight}px;

    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    border-top-right-radius: ${(props) => props.styleData.tabWidth / 20}px;

    border-bottom-right-radius: ${(props) => props.styleData.tabWidth / 20}px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .settings-inviz-icon {
    display: none;
  }
  :hover .settings-inviz {
    background-color: rgba(0, 0, 0, 0.2);
  }

  :hover .settings-inviz-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1vw;
    font-size: max(1vw, 16px);
  }
`
