import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useData } from '../context/DataContext'
import withLocalForage from '../context/withLocalForage'
import SearchDesktop from './SearchDesktop'
import NavBarVersion from './NavBarVersion'
import NavBarButtons from './NavBarButtons'
import NavBarSpaces from './NavBarSpaces'
import NavBarProfile from './NavBarProfile'
import NavBarDashboard from './NavBarDashboard'
import NavBarOpen from './NavBarOpen'
import NavBarCorner from './NavBarCorner'
import AddSpaceModal from '../components/dnd/AddSpaceModal'
import UpdateSpaceModal from '../components/dnd/UpdateSpaceModal'
import UpdateSpacePublicModal from '../components/dnd/UpdateSpacePublicModal'
import { Container } from '../style/NavBar.styled'

const NavBarDesktop = ({
  updateSettings,
  changeSpaceBar,
  spaceUpBar,
  spaceDownBar,
  searchRestore,
}) => {
  const { global, open, setOpen } = useData()
  const { settings } = global

  if (open === null) {
    localStorage.setItem('open', JSON.stringify(false))
    setOpen(false)
  }

  const getBrowser = () => {
    if (
      (navigator.userAgent.indexOf('Opera') != -1 ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera'
    } else if (navigator.userAgent.indexOf('Edg') != -1) {
      return 'Edge'
    } else if (
      navigator.userAgent.indexOf('Chrome') != -1 ||
      navigator.userAgent.indexOf('Chromium') != -1
    ) {
      return 'Chrome'
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari'
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox'
    } else if (
      navigator.userAgent.indexOf('MSIE') != -1 ||
      !!document.documentMode == true
    ) {
      return 'IE'
    }
    return 'unknown'
  }

  const getStyledHeight = (isExtension) => {
    const extraHeight = isExtension ? 7 : 6
    const height = window.innerHeight - extraHeight * 3 * global.styleData.vw
    return { height }
  }

  const browser = useMemo(() => getBrowser(), [])
  const isExtension = useMemo(
    () => ['Chrome', 'Firefox', 'Edge'].includes(browser),
    [browser]
  )
  const styled = useMemo(() => getStyledHeight(isExtension), [isExtension])

  //блок кода управлением открытия и закрытия боковой панели
  const sidebarRef = useRef(null)
  const closeBtnRef = useRef(null)
  useEffect(() => {
    sidebarRef.current = document.querySelector('.sidebar')
    closeBtnRef.current = document.querySelector('#btn')
    closeBtnRef.current.addEventListener('click', handleClick)
    return () => closeBtnRef.current.removeEventListener('click', handleClick)
  }, [])
  const handleClick = () => {
    sidebarRef.current.classList.toggle('open')
    setOpen(!open)
    localStorage.setItem('open', JSON.stringify(!open))
    closeBtnRef.current.classList.replace(
      sidebarRef.current.classList.contains('open')
        ? 'bx-menu-alt-left'
        : 'bx-menu-alt-right',
      sidebarRef.current.classList.contains('open')
        ? 'bx-menu-alt-right'
        : 'bx-menu-alt-left'
    )
  }
  //блок кода управлением открытия и закрытия боковой панели

  let sidebar = `sidebar${settings.styleTheme ? ' dark' : ''}${
    open ? ' open' : ''
  }`

  const [modalActive, setModalActive] = useState(false)
  const [modalUpdateActive, setModalUpdateActive] = useState(false)
  const [modalUpdatePublicActive, setModalUpdatePublicActive] = useState(false)
  const [spaceUpdate, setSpaceUpdate] = useState()

  const modalAdd = modalActive ? (
    <AddSpaceModal
      active={modalActive}
      setActive={setModalActive}
      button={2}
      fullWidth={false}
      mobileClose={false}
    />
  ) : null

  const modalUpdate = modalUpdateActive ? (
    <UpdateSpaceModal
      active={modalUpdateActive}
      setActive={setModalUpdateActive}
      spaceUpdate={spaceUpdate}
      button={3}
      fullWidth={false}
      mobileClose={false}
    />
  ) : null

  const modalUpdatePublic = modalUpdatePublicActive ? (
    <UpdateSpacePublicModal
      active={modalUpdatePublicActive}
      setActive={setModalUpdatePublicActive}
      spaceUpdate={spaceUpdate}
      button={2}
      fullWidth={false}
      mobileClose={false}
    />
  ) : null

  return (
    <Container styled={styled}>
      <div className={sidebar}>
        <NavBarDashboard open={open} />

        <NavBarSpaces
          changeSpaceBar={changeSpaceBar}
          searchRestore={searchRestore}
          setModalActive={setModalActive}
          setSpaceUpdate={setSpaceUpdate}
          setModalUpdateActive={setModalUpdateActive}
          setModalUpdatePublicActive={setModalUpdatePublicActive}
        />

        <NavBarButtons setModalActive={setModalActive} />

        <NavBarProfile />

        <NavBarOpen
          changeSpaceBar={changeSpaceBar}
          updateSettings={updateSettings}
          spaceUpBar={spaceUpBar}
          spaceDownBar={spaceDownBar}
          searchRestore={searchRestore}
          setModalActive={setModalActive}
          setSpaceUpdate={setSpaceUpdate}
          setModalUpdateActive={setModalUpdateActive}
          setModalUpdatePublicActive={setModalUpdatePublicActive}
        />
      </div>

      <NavBarCorner />

      {modalAdd}
      {modalUpdate}
      {modalUpdatePublic}

      <SearchDesktop />
      <NavBarVersion />
    </Container>
  )
}

export default withLocalForage(NavBarDesktop)
