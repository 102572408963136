import styled from 'styled-components'

export const Container = styled.div`
  .navUpdate {
    position: relative;
    width: 100%;
    height: 3.5vw;
    min-height: 70px;
    margin-top: 0.5vw;
    margin-top: max(0.5vw, 10px);
  }

  .navUpdate .navButton {
    position: relative;
    width: 20%;
    float: left;
    cursor: pointer;
    border-radius: 0.2vw;
    border-radius: max(0.2vw, 4px);
    color: #0364c1;
    transition: all 0.5s ease;
  }

  .navUpdate .navButton.inactive {
    color: #c7c7c7;
    cursor: not-allowed;
  }
  .navUpdate.dark .navButton.inactive {
    color: #524d75;
  }

  .navUpdate .navButton:hover {
    background-color: #e7e7e7;
  }

  .navUpdate .navButton:hover.inactive {
    background-color: transparent;
  }

  .navUpdate.dark .navButton:hover.inactive {
    background-color: transparent;
  }

  .navUpdate.dark .navButton {
    color: #fff;
  }

  .navUpdate.dark .navButton:hover {
    background-color: #2e275c;
  }

  .navUpdate i {
    font-size: 2vw;
    font-size: max(2vw, 40px);
    margin: 0 auto;
    position: relative;
    width: 100%;
    height: 2.5vw;
    min-height: 50px;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navUpdate .navButton .navText {
    position: relative;
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
    top: -0.5vw;
    top: min(-0.5vw, -10px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid;
    border-color: transparent;
    transition: all 0.5s ease;
  }

  .navUpdate .navButton .navText.border {
    border-color: #0364c1;
  }

  .navUpdate .navButton:hover .navText {
    border-color: #0364c1;
  }

  .navUpdate .navButton:hover.inactive .navText {
    border-color: #c7c7c7;
  }

  .navUpdate.dark .navButton:hover.inactive .navText {
    border-color: #524d75;
  }

  .navUpdate.dark .navButton .navText.border {
    border-color: #fff;
  }

  .navUpdate.dark .navButton:hover .navText {
    border-color: #fff;
  }

  .navUpdate .navButton .await i {
    position: absolute;
    top: 0.5vw;
    top: max(0.5vw, 10px);
    right: 0.7vw;
    right: max(0.7vw, 14px);
    width: 0.5vw;
    min-width: 10px;
    height: 0.5vw;
    min-height: 10px;
    transform: rotate(20deg);
    font-size: 0.75vw;
    font-size: max(0.75vw, 15px);
    transition: all 0.5s ease;
  }

  .navUpdate .navButton:hover .await i {
    animation: spinner 2s linear infinite;
    color: #0364c1;
  }

  .navUpdate.dark .navButton:hover .await i {
    animation: spinner 2s linear infinite;
    color: #fff;
  }
  @keyframes spinner {
    to {
      transform: rotate(380deg);
    }
  }
`
