import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import { database } from '../firebase'
import initialLanguage from '../language/initialLanguage'

import { Container } from '../style/SearchDesktop.styled'
import withLocalForage from '../context/withLocalForage'

const SearchDesktop = ({ searchCreate, searchRestore }) => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`

  const { global, activeState, lang, open, setOpen, device } = useData()

  const { settings, styleData } = global

  const [request, setRequest] = useState()

  // const browser = getUserBrowser()
  // function getUserBrowser() {
  //   let browser = 'unknown'
  //   if (
  //     (navigator.userAgent.indexOf('Opera') != -1 ||
  //       navigator.userAgent.indexOf('OPR')) != -1
  //   ) {
  //     browser = 'Opera'
  //   } else if (navigator.userAgent.indexOf('Edg') != -1) {
  //     browser = 'Edge'
  //   } else if (
  //     navigator.userAgent.indexOf('Chrome') != -1 ||
  //     navigator.userAgent.indexOf('Chromium') != -1
  //   ) {
  //     browser = 'Chrome'
  //   } else if (navigator.userAgent.indexOf('Safari') != -1) {
  //     browser = 'Safari'
  //   } else if (navigator.userAgent.indexOf('Firefox') != -1) {
  //     browser = 'Firefox'
  //   } else if (
  //     navigator.userAgent.indexOf('MSIE') != -1 ||
  //     !!document.documentMode == true
  //   ) {
  //     browser = 'IE'
  //   }
  //   return browser
  // }

  const searchSubmit = (value, start) => {
    if (value.length > 0) {
      document.getElementById('clearButton').style.display = 'flex'
    } else {
      searchRestore(path, true)
    }

    setRequest(value.toLowerCase())
    if (value.length > 2) {
      value = value.toLowerCase()
      const searchTasks = {}

      let i = 1
      for (let key in activeState.search) {
        if (
          activeState.search[key].title.toLowerCase().includes(value) ||
          activeState.search[key].url.toLowerCase().includes(value)
        ) {
          searchTasks[i] = activeState.tasks[key]

          searchTasks[i].id = String(i)
          i++
        }
      }

      if (Object.keys(searchTasks).length > 0) {
        // создать новый объект
        searchCreate(path, activeState, searchTasks, settings)
      } else {
        //восстановить значение по умолчанию
        searchRestore(path)
      }
    } else {
      //восстановить значение по умолчанию
      searchRestore(path)
    }
  }

  function setSearchFirebase(value) {
    return new Promise((resolve) => {
      database.search
        .add({
          amount: 1,
          search: value,
        })
        .then(resolve('Done'))
    })
  }

  function getSearchOverlap(value) {
    return new Promise((resolve) => {
      database.search
        .where('search', '==', value)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              let search = doc.data()
              search.id = doc.id
              resolve(search)
            })
          } else {
            resolve(null)
          }
        })
    })
  }

  async function handleSubmit(e) {
    e.preventDefault()

    searchRestore(path, true)

    const form = document.getElementById('searchForm')

    if (settings.defaultSearch === 'Google') {
      form.action = 'https://www.google.com/search'
    }
    if (settings.defaultSearch === 'Bing') {
      form.action = 'https://www.bing.com/search'
    }
    if (settings.defaultSearch === 'Yahoo') {
      form.action = 'https://search.yahoo.com/search'
    }
    if (settings.defaultSearch === 'Baidu') {
      form.action = 'https://www.baidu.com/baidu'
    }
    if (settings.defaultSearch === 'DuckDuckGo') {
      form.action = 'https://duckduckgo.com/'
    }
    if (settings.defaultSearch === 'Yandex') {
      form.action = 'https://yandex.ru/search/'
    }

    form.submit()

    if (request) {
      let searchOverlap = await getSearchOverlap(request)
      if (searchOverlap) {
        database.search.doc(searchOverlap.id).update({
          amount: searchOverlap.amount + 1,
        })
      } else {
        setSearchFirebase(request)
      }
    }

    setTimeout(() => {
      if (document.getElementById('searchInput')) {
        document.getElementById('searchInput').value = ''
        document.getElementById('searchInput').placeholder = getPlaceholder()
        document.getElementById('clearButton').style.display = 'none'
      }

      setRequest()
    }, 200)
  }

  function searchEngines() {
    let sidebar = document.querySelector('.sidebar')
    let closeBtn = document.querySelector('#btn')
    let options = document.querySelector('.options')

    let ms = 0
    if (!sidebar.classList.contains('open')) ms = 800

    sidebar.classList.add('open')
    closeBtn.classList.replace('bx-menu-alt-left', 'bx-menu-alt-right')
    if (!open) localStorage.setItem('open', JSON.stringify(!open))
    if (!open) setOpen(!open)

    setTimeout(() => {
      options.classList.add('blink')
    }, ms + 100)
    setTimeout(() => {
      options.classList.remove('blink')
    }, ms + 700)
    // if (device === 'mobile') {
    //   navigate('/mobile-menu')
    //   let options = document.querySelector('.options')
    //   //get element by id
    //   let defaultSearch = document.getElementById('defaultSearch')
    //   defaultSearch.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'end',
    //   })

    //   setTimeout(() => {
    //     options.classList.add('blink')
    //   }, 600)
    //   setTimeout(() => {
    //     options.classList.remove('blink')
    //   }, 1400)
    // } else {
    //   let sidebar = document.querySelector('.sidebar')
    //   let closeBtn = document.querySelector('#btn')
    //   let options = document.querySelector('.options')

    //   let ms = 0
    //   if (!sidebar.classList.contains('open')) ms = 800

    //   sidebar.classList.add('open')
    //   closeBtn.classList.replace('bx-menu-alt-left', 'bx-menu-alt-right')
    //   if (!open) localStorage.setItem('open', JSON.stringify(!open))
    //   if (!open) setOpen(!open)

    //   setTimeout(() => {
    //     options.classList.add('blink')
    //   }, ms + 100)
    //   setTimeout(() => {
    //     options.classList.remove('blink')
    //   }, ms + 700)
    // }
  }

  function getSearchLogo() {
    if (settings.defaultSearch === 'Google') {
      return <i className="bx bxl-google" style={{ marginLeft: '0.05vw' }}></i>
    }
    if (settings.defaultSearch === 'Bing') {
      return <i className="bx bxl-bing" style={{ marginLeft: '0.15vw' }}></i>
    }
    if (settings.defaultSearch === 'Yahoo') {
      return <i className="bx bxl-yahoo" style={{ marginLeft: '0.1vw' }}></i>
    }
    if (settings.defaultSearch === 'Baidu') {
      return <i className="bx bxl-baidu" style={{ marginLeft: '0.1vw' }}></i>
    }
    if (settings.defaultSearch === 'DuckDuckGo') {
      return <span className="duckduckgoIcon"></span>
    }
    if (settings.defaultSearch === 'Yandex') {
      return <span className="yandexIcon"></span>
    }
  }

  function getPlaceholder() {
    // console.log(activeState)
    let placeholder = initialLanguage[lang].search_web
    if (activeState?.nickname) {
      const title = `${activeState.name} ${activeState.nickname}`
      return title
    }
    if (activeState?.id) {
      const title =
        '(' +
        Object.keys(activeState.tasks).length +
        ')' +
        ' ' +
        (activeState.ownTitle || 'New Folder')
      return title
    }

    return placeholder
  }

  function getForm() {
    if (settings.defaultSearch === 'Baidu') {
      return (
        <>
          <input name="tn" type="hidden" value="baidu" />
          <input name="ie" type="hidden" value="utf-8" />
          <input
            onChange={(e) => searchSubmit(e.target.value)}
            placeholder={getPlaceholder()}
            name="word" // For Baidu
            id="searchInput" //работает без
            size="40" //работает без
            maxLength="2000" //работает без
            type="text" //работает без
            autoComplete="off"
          />
        </>
      )
    }
    return (
      <>
        <input name="tn" type="hidden" value="" />
        <input name="ie" type="hidden" value="" />
        <input
          onChange={(e) => searchSubmit(e.target.value)}
          placeholder={getPlaceholder()}
          name="q" // не работает без параметра
          id="searchInput" //работает без
          size="40" //работает без
          maxLength="2000" //работает без
          type="text" //работает без
          autoComplete="off"
        />
      </>
    )
  }

  function handleMicrophone() {
    if (window.SpeechRecognition || window.webkitSpeechRecognition) {
      let SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition
      let recognition = new SpeechRecognition()

      recognition.onstart = function () {
        document.getElementById('searchInput').placeholder =
          initialLanguage[lang].search_listening
      }

      recognition.onspeechend = function () {
        recognition.stop()
      }

      recognition.onresult = function (event) {
        let last = event.results.length - 1
        let text = event.results[last][0].transcript

        document.getElementById('searchInput').value = text
        document.getElementById('searchInput').focus()
        document.getElementById('searchInput').placeholder =
          initialLanguage[lang].search_web
        searchSubmit(text, true)
      }

      recognition.onerror = function (event) {
        if (event.error !== 'aborted') {
          document.getElementById('searchInput').placeholder =
            initialLanguage[lang].search_error

          setTimeout(() => {
            document.getElementById('searchInput').placeholder =
              getPlaceholder()
          }, 3000)
        }
      }

      recognition.start()
    } else {
      document.getElementById('searchInput').placeholder =
        initialLanguage[lang].search_unsupport

      setTimeout(() => {
        document.getElementById('searchInput').placeholder = getPlaceholder()
      }, 3000)
    }
  }

  function getTarget() {
    if (window !== window.top) return '_blank'
    if (settings.targetSearch) return '_blank'
    if (!settings.targetSearch) return '_self'
    return '_blank'
  }

  function handleClear() {
    searchRestore(path, true)
  }

  function getClassName() {
    let className = 'search'
    if (settings.styleTheme) className += ' dark'
    return className
  }

  function searchClassName() {
    let className = 'searchContainer'
    if (settings.styleTheme) className += ' dark'
    if (activeState?.id) className += ' openFolder'
    return className
  }

  return (
    <Container styleData={styleData}>
      <div
        className={'underSearch' + (settings.styleTheme ? ' dark' : '')}
      ></div>
      <div className={searchClassName()}>
        <div className={getClassName()}>
          <div className="logo" onClick={searchEngines} id="searchLogoButton">
            {getSearchLogo()}
          </div>
          <span className="form">
            <form target={getTarget()} id="searchForm" onSubmit={handleSubmit}>
              {getForm()}
            </form>
          </span>
          <span className="clearButton" onClick={handleClear} id="clearButton">
            <i className="bx bx-x"></i>
          </span>
          {/* {browser === 'Chrome' ||
        browser === 'Edge' ||
        browser === 'Firefox' ? (
          <span className="microphone" onClick={handleMicrophone}>
            <i className="bx bx-microphone"></i>
          </span>
        ) : null} */}
          <span className="button" id="search" onClick={handleSubmit}>
            <i className="bx bx-search-alt-2"></i>
          </span>
        </div>
      </div>
    </Container>
  )
}

export default withLocalForage(SearchDesktop)
