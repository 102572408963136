import styled from 'styled-components'

export const Container = styled.div`
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
  }

  .gallery {
    width: calc(25% - 20px);
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;
  }

  .gallery img {
    width: 100%;
    height: auto;
    min-width: 200px;
    object-fit: cover;
    border-radius: 10px;
    aspect-ratio: 16 / 9; /* Устанавливаем соотношение сторон 16:9 */
    background-color: #d6e7f5;
  }

  .gallery:hover {
    scale: 1.02;
  }

  @media screen and (max-width: 800px) {
    .gallery {
      width: calc(33.33% - 20px);
    }
  }

  @media screen and (max-width: 600px) {
    .gallery {
      width: calc(50% - 20px);
    }
  }

  @media screen and (max-width: 400px) {
    .gallery {
      width: 100%;
    }
  }
`
