import styled from 'styled-components'

export const Container = styled.div`
  .area {
    height: 2.25vw;
    min-height: 45px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .buttonLink {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.75vw;
    line-height: max(1.75vw, 35px);
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
    font-weight: normal;
    color: #bababa;
    transition: all 0.5s ease;
    cursor: pointer;
    border-radius: 0.2vw;
    border-radius: max(0.2vw, 4px);
  }

  //////////////////////////////////////////////////////////////////////////////

  .grey {
    background-color: #e7e7e7;
    color: #0364c1;
  }
  .grey:hover {
    background-color: #d5d5d5;s
    color: #0364c1;
  }

  .grey.dark {
    background-color: #2e275c;
    color: #fff;
  }
  .grey.dark:hover {
    background-color: #251f4a;
    color: #fff;
  }

  .blue {
    background-color: #0364c1;
    color: #fff;
  }

  .blue:hover {
    background-color: #2b7de9;
  }

  .red {
    background-color: #fc3220;
    color: #fff;
  }

  .red:hover {
    background-color: #d00000;
  }

  .google {
    background-color: #dc4e41;
    color: #fff;
  }
  .google:hover {
    background-color: #dd5e52;
    color: #fff;
  }

  .facebook {
    background-color: #3b5998;
    color: #fff;
  }
  .facebook:hover {
    background-color: #4c6599;
    color: #fff;
  }
  .twitter {
    background-color: #5baaf4;
    color: #fff;
  }
  .twitter:hover {
    background-color: #72b5f4;
    color: #fff;
  }
`
