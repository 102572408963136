import { database } from '../firebase'
import initialColors from '../components/initialState/initialColors'

export default async function Page({ urlValid }) {
  //Запрашиваем у Firebase наличие страницы в page
  let pages = await getPages(urlValid)

  //Если странца есть в Firebase, обновляем amount страницы в Firebase
  if (pages) {
    await updatePages(pages)
  }

  //Если страницы нет в Firebase, добавляем ее в Firebase
  if (!pages) {
    //Создаем рандомное число от 1 до 150 для рандомного выбора цветовой схемы вкладки
    let rand = Math.floor(1 + Math.random() * 150)

    //Создаем в Firebase страницу
    let set = await setPages(urlValid, rand)

    //Если результат работы null выдаем ошибку
    if (!set) return null

    //Повторно запрашиваем страницу у Firebase
    pages = await getPages(urlValid)
  }

  return pages
}

// let pages = {
//   amount: 362,
//   idPages: 'waqE80psvfuV32hfo60m',
//   pagesBackground: '',
//   pagesColor: '',
//   pagesHostname: 'google.com',
//   pagesPathname: '/',
//   pagesPicture: '',
//   pagesTitle: 'Google',
//   verifiedP: true,
// }

function getPages(urlValid) {
  return new Promise((resolve) => {
    database.pages
      .where('pagesHostname', '==', urlValid.urlDomain)
      .where('pagesPathname', '==', urlValid.urlPath)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            let pages = doc.data()
            pages.idPages = doc.id
            resolve(pages)
          })
        } else {
          resolve(null)
        }
      })
  })
}

function setPages(urlValid, rand) {
  return new Promise((resolve) => {
    database.pages
      .add({
        amount: 0,
        influent: false,
        pagesBackground: initialColors.colors[rand].bg,
        pagesColor: initialColors.colors[rand].clr,
        pagesHostname: urlValid.urlDomain,
        pagesPathname: urlValid.urlPath,
        pagesPicture: '',
        pagesTitle: '',
        verifiedP: false,
      })
      .then(resolve(true))
  })
}

function updatePages(pages) {
  return new Promise((resolve) => {
    database.pages
      .doc(pages.idPages)
      .update({
        amount: pages.amount + 1,
      })
      .then(resolve(true))
  })
}
