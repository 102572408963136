import React from 'react'

export default function AboutRu() {
  return (
    <>
      <p>
        Добро пожаловать в TitleTab - Speed Dial Dashboard, идеальное решение
        для управления вкладками, разработанное, чтобы сделать ваш просмотр
        быстрее и удобнее, чем когда-либо прежде. Наше решение предлагает ряд
        функций, которые помогут вам персонализировать вкладки вашего браузера,
        предотвратить случайное закрытие браузера и поделиться вашими любимыми
        ссылками с другими пользователями.
      </p>
      <p>Вот некоторые из удивительных функций, которые предлагает TitleTab:</p>
      <p>
        <strong>Персонализированная стартовая страница</strong>
      </p>
      <p>
        Наша функция StartPage позволяет легко перетаскивать вкладки, чтобы
        упорядочить их в соответствии с вашими потребностями. Вы также можете
        настроить каждую вкладку с различными параметрами отображения, включая
        скриншот экрана веб-страницы, текст, логотипы популярных сайтов или ваши
        собственные изображения. Мы предлагаем как светлую, так и темную тему,
        поэтому вы можете выбрать ту, которая соответствует вашим предпочтениям.
      </p>
      <p>
        <strong>
          Организация вкладок: папки и неограниченное пространства
        </strong>
      </p>
      <p>
        TitleTab предлагает мощные функции организации вкладок, которые
        позволяют структурировать вкладки удобным для вас способом. Вы можете
        группировать вкладки в папки, просто перетаскивая одну вкладку на
        другую. Кроме того, вы можете создавать новые пространства, чтобы
        разделить вкладки разных типов, например вкладки, связанные с работой, и
        вкладки, связанные с хобби. Благодаря функциям организации вкладок
        TitleTab вы можете легко управлять своими вкладками и поддерживать
        организованность и эффективность работы в Интернете.
      </p>
      <p>
        <strong>Публичное пространство с уникальной ссылкой</strong>
      </p>
      <p>
        Пользователи TitleTab также могут создать общедоступное пространство, к
        которому могут получить доступ другие пользователи, у которых есть
        ссылка. Когда вы впервые активируете кнопку «Публичное пространство», мы
        попросим вас заполнить некоторые поля, необходимые для запуска вашего
        публичного пространства. Самое важное поле, которое необходимо
        заполнить, — это уникальный адрес ссылки («Путь для доступа к публичному
        пространству»), по которой будет доступно ваше пространство с вкладками,
        которыми вы хотите поделиться с другими пользователями. Как только вы
        активируете общедоступное пространство, вы можете начать добавлять в
        него вкладки. Эта функция особенно полезна, если вы хотите поделиться
        вкладками с друзьями или коллегами для совместной работы. Благодаря
        функции общедоступного пространства TitleTab вы можете легко делиться
        своими вкладками и сотрудничать с другими пользователями удобным и
        безопасным способом.
      </p>
      <p>
        <strong>OneTab: сохраните все открытые вкладки в одну</strong>
      </p>
      <p>
        Для популярных браузеров доступно расширение, которое позволяет
        пользователям управлять своими открытыми вкладками в своем браузере.
        Просто нажав на кнопку расширения, вы можете сохранить все (или
        выбранные) вкладки в одну вкладку, которая будет хранить список сайтов и
        будет готова к повторному открытию для вас в любое время. Этот
        функционал значительно экономит ресурсы вашего устройства, так как
        открытые вкладки продолжают работать и использовать память вашего
        устройства.
      </p>
      <p>
        Также стоит отметить, что мы не отслеживаем, что именно вы сохраняете
        таким образом, что гарантирует вашу конфиденциальность, но в то же время
        мы не можем сохранить эту информацию для вас. Поэтому эта информация
        доступна только локально в этом браузере на том устройстве, на котором
        вы работаете. Это цена вашей конфиденциальности.
      </p>
      <p>
        <strong>Бесконечная новая вкладка</strong>
      </p>
      <p>
        Infinity New Tab — еще одна функция нашего расширения, обеспечивающая
        бесконечный просмотр. С бесконечной новой вкладкой пользователи могут
        продолжать просмотр, не опасаясь случайно закрыть последнюю открытую
        вкладку. Эта функция автоматически добавляет новую вкладку слева от
        последней открытой вкладки, гарантируя, что у пользователей всегда будет
        вкладка, на которую можно вернуться. Как только открываются
        дополнительные вкладки, Infinity New Tab исчезает, позволяя
        пользователям свободно просматривать страницы без перерыва. Эта функция
        обеспечивает пользователям душевное спокойствие и предотвращает
        разочарование, связанное со случайным закрытием последней вкладки.
      </p>
      <p>
        <strong>TitleTab расширения</strong>
      </p>
      <p>
        TitleTab совместим с популярными веб-браузерами, такими как Google
        Chrome, Mozilla Firefox и Microsoft Edge. Наше расширение можно легко
        загрузить и установить из официальных магазинов расширений каждого
        браузера.
      </p>
      <p>Вот список мест, где можно скачать TitleTab:</p>
      <ul>
        <li>
          <a
            href="https://chrome.google.com/webstore/detail/titletab-web-dashboard/ldlndadamgafkigafpajckkjbblnepcb"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome Web Store
          </a>
        </li>
        <li>
          <a
            href="https://addons.mozilla.org/firefox/addon/titletab-web-dashboard/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox Add-ons
          </a>
        </li>
        <li>
          <a
            href="https://microsoftedge.microsoft.com/addons/detail/titletab-web-dashboard/mnckgifglkiecanfbdpnajiplpabkphh"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge Add-ons
          </a>
        </li>
      </ul>
      <p>
        После установки вы можете начать использовать TitleTab для настройки
        страницы новой вкладки, сохранения и упорядочения вкладок,
        предотвращения случайного закрытия вкладок и обмена ссылками с другими.
        Не упустите возможность упростить работу в Интернете с помощью TitleTab.
        Загрузите наше расширение сегодня!
      </p>
      <p>
        <strong>Конфиденциальность и безопасность</strong>
      </p>
      <p>
        TitleTab серьезно относится к вашей конфиденциальности и безопасности.
        Все ваши данные надежно хранятся на вашем устройстве и в облаке, и мы
        никогда не передаем вашу информацию третьим лицам.
      </p>
      <hr />
      <p>
        TitleTab — это идеальное решение для управления вкладками для всех, кто
        хочет контролировать работу в Интернете. Попробуйте сегодня и убедитесь
        сами!
      </p>
      <p>
        Если у вас есть какие-либо вопросы о TitleTab или вам нужна помощь с
        любой из наших функций, не стесняйтесь обращаться к нам по адресу&nbsp;
        <a
          href="mailto:info@titletab.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@titletab.com
        </a>
        . Наша команда всегда рада помочь и ответить на любые ваши вопросы. Мы
        ценим ваши отзывы и стремимся обеспечить наилучшее взаимодействие с
        пользователем, поэтому, пожалуйста, не стесняйтесь обращаться к нам,
        если у вас есть какие-либо предложения или отзывы.
      </p>
    </>
  )
}
