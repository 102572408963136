import React, { useContext, useState, useEffect } from "react";
import {
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  // deleteUser,
} from "firebase/auth";
import { auth } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function signupGoogle() {
    return signInWithPopup(auth, new GoogleAuthProvider());
  }

  function signupFacebook() {
    return signInWithPopup(auth, new FacebookAuthProvider());
  }

  function signupTwitter() {
    return signInWithPopup(auth, new TwitterAuthProvider());
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function emailUpdate(email) {
    return updateEmail(currentUser, email);
  }

  function passwordUpdate(password) {
    return updatePassword(currentUser, password);
  }

  // function userDelete() {
  //   return deleteUser(currentUser);
  // }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    signupGoogle,
    signupFacebook,
    signupTwitter,
    login,
    logout,
    resetPassword,
    emailUpdate,
    passwordUpdate,
    // userDelete,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
