import styled from 'styled-components'
//*
export const Container = styled.div`
  width: ${(props) => props.styleData.tabWidth}px;
  height: ${(props) => props.styleData.tabHeight}px;
  float: left;
  margin: 0px ${(props) => props.styleData.tabMargin}px;
  position: relative;
  font-weight: 500;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${(props) => props.styleData.tabWidth}px;
    height: ${(props) => props.styleData.tabHeight}px;
    opacity: 0.35;
    margin-bottom: 0.2vw;
    margin-bottom: max(0.2vw, 4px);
    background-color: #000;
    border-radius: ${(props) => props.styleData.tabWidth / 20}px;
    box-shadow: ${(props) => props.styleData.boxShadowTabs};
    font-size: 2.7vw;
    color: #fff;
    transition: opacity 0.5s ease;
    &:hover {
      opacity: 0.3;
    }
  }

  .inactive {
    opacity: 0.1;
    cursor: default;
    &:hover {
      opacity: 0.1;
    }
  }

  .button.mobile {
    font-size: 7vw;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      border: none;
      outline: none;
    }
  }

  p {
    width: ${(props) => props.styleData.tabWidth}px;
    height: 1.1vw;
    min-height: 22px;
    line-height: max(1.1vw, 22px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: ${(props) =>
      props.headersBg ? (props.dark ? '#11101d75' : '#ffffff75') : null};
    color: ${(props) => (props.dark ? '#bbbbbb' : '#505050')};
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
    text-align: center;
    border-radius: 5px;
    box-shadow: ${(props) =>
      props.headersBg ? '0 10px 20px -5px rgb(0 0 0 / 24%)' : null};
    margin: 0;
    transition: background-color 0.3s ease;

    &.inactiveP {
      opacity: 0.3;
      cursor: default;
    }
  }

  a:hover p {
    background-color: ${(props) =>
      props.headersBg
        ? props.dark
          ? '#11101d'
          : '#ffffff'
        : props.dark
        ? '#11101d75'
        : '#ffffff75'};
  }
`
