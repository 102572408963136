import styled from 'styled-components'

export const Container = styled.div`
  .palette {
    width: 100%;
    position: relative;
    margin: 0 auto;
    margin-top: 1vw;
    margin-top: max(1vw, 30px);
    margin-bottom: 1vw;
    margin-bottom: max(1vw, 30px);
  }

  .palette input[type='color'] {
    position: relative;
    top: -1px;
    -webkit-appearance: none;
    width: 2vw;
    min-width: 40px;
    height: 2vw;
    min-height: 40px;
    float: left;
    border: 0.12vw solid #0364c1;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  .palette input[type='color']::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  .palette input[type='color']::-webkit-color-swatch {
    border: 0.1vw solid #ccc;
  }

  .palette input[type='text'] {
    position: relative;
    font-size: 0.8vw;
    font-size: max(0.8vw, 16px);
    padding: 0.5vw;
    padding: max(0.5vw, 10px);
    width: calc(100% - 2.25vw);
    max-width: calc(100% - 41px);
    box-sizing: border-box;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-bottom: solid rgb(204, 204, 204);
    border-bottom-width: 0.05vw;
    border-bottom-width: max(0.05vw, 1px);
    outline: none;
  }
  .palette input[type='text']:focus {
    outline: none;
  }
  .palette.dark input[type='text'] {
    background: #1e1b30;
    color: #fff;
  }

  .palette label {
    color: #999;
    font-size: 0.9vw;
    font-size: max(0.9vw, 18px);
    position: absolute;
    pointer-events: none;
    left: 0.5vw;
    left: max(0.5vw, 10px);
    top: 0.4vw;
    top: max(0.4vw, 8px);
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .palette label.activ {
    top: -0.88vw;
    top: min(-0.88vw, -17px);
    left: 2.5vw !important;
    left: max(2.5vw, 50px) !important;
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
    color: #0364c1;
  }

  .palette.dark label.activ {
    color: #999;
  }

  .palette input:focus ~ label,
  .palette input:required:valid ~ label {
    top: -0.88vw;
    top: min(-0.88vw, -17px);
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
    color: #0364c1;
  }

  .palette input::placeholder ~ label {
    top: -0.88vw;
    top: min(-0.88vw, -17px);
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
  }

  .palette .labelUp {
    top: -0.88vw;
    top: min(-0.88vw, -17px);
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
    color: #0364c1;
  }

  .palette.dark .labelUp {
    color: #999;
  }

  .palette .bar {
    position: relative;
    display: block;
    width: 100%;
  }
  .palette .bar:before,
  .palette .bar:after {
    content: '';
    height: 0.1vw;
    min-height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: #0364c1;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .palette .bar:before {
    left: 50%;
  }
  .palette .bar:after {
    right: 50%;
  }

  .palette input:focus ~ .bar:before,
  .palette input:focus ~ .bar:after {
    width: 50%;
  }
`
