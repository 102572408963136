import styled from 'styled-components'

export const Container = styled.div`
  .modalSmall {
    position: relative;
    width: ${(props) => props.styledIndent.width}px;
    min-width: 550px;
    left: 0px;
    right: 0px;
    margin: auto;
    background-color: #fff;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s ease;
    transform: scale(0.5);
    border-top-left-radius: 0.35vw;
    border-top-left-radius: max(0.35vw, 7px);
    border-top-right-radius: 0.35vw;
    border-top-right-radius: max(0.35vw, 7px);
    border-bottom-left-radius: 0.35vw;
    border-bottom-left-radius: max(0.35vw, 7px);
    border-bottom-right-radius: 0.35vw;
    border-bottom-right-radius: max(0.35vw, 7px);
    z-index: 100;
    color: #2b7de9;
  }
  .modalSmall.dark {
    background-color: #1e1b30;
    color: #fff;
  }
  .modalSmall.mobileMenu.dark {
    background-color: #11101d;
  }
  .modalSmall.active {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
  }

  .contactText {
    font-size: 1.2vw;
    font-size: max(1.2vw, 24px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contactText a {
    text-decoration: none;
  }

  .contactText.dark {
    color: #fff !important;
  }

  .contactText i {
    font-size: 1.2vw;
    font-size: max(1.2vw, 21px);
    margin-top: 0.25vw;
    margin-top: max(0.25vw, 5px);
    margin-right: 0.25vw;
    margin-right: max(0.25vw, 5px);
  }

  .mt-2 {
    margin-top: 0.75vw;
    margin-top: max(0.75vw, 15px);
  }

  @media (max-width: 1200px) {
    .modalSmall {
      display: block;
      top: 0px;
      position: fixed;
      width: 100%;
      min-width: 100%;
      height: 100%;
      border-radius: 0;
      z-index: 1000;
    }
  }

  // .modalSmall.mobile {
  //   display: block;
  //   top: 0px;
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   border-radius: 0;
  // }

  @media (max-width: 1200px), screen and (max-height: 580px) {
    // .modalSmall {
    //   display: block;
    //   top: 0px;
    //   position: fixed;
    //   overflow: auto;
    //   width: 100%;
    //   height: 100%;
    //   border-radius: 0;
    //   z-index: 1000;
    // }
  }

  .modalSmall .content {
    position: relative;
    left: 0px;
    width: 100%;
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
    background-color: #fff;
    transition: all 0.5s ease;
    margin-top: 1vw;
    margin-top: max(1vw, 20px);
    margin-bottom: 0.75vw;
    margin-bottom: max(0.75vw, 15px);
  }
  .modalSmall.dark .content {
    background-color: #1e1b30;
    color: #fff;
  }
  .modalSmall.dark .content.mobileMenu {
    background-color: #11101d;
  }

  .modalSmall .text {
    max-height: ${(props) => props.styledIndent.contextHeight}px;
    text-align: justify;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    margin-right: 2vw; //на мобильном сделать 0
    margin-right: max(2vw, 40px);
    scrollbar-color: #0364c1 #ffffff00;
    padding-left: 3vw;
    padding-left: max(3vw, 60px);
    padding-right: 0.6vw;
    padding-right: max(0.6vw, 12px);
    color: #333;
  }

  .modalSmall.dark .text {
    color: #fff;
  }

  .modalSmall .text a {
    color: #0364c1;
  }

  @media (max-width: 1200px), screen and (max-height: 580px) {
    .modalSmall .text {
      height: ${(props) => props.styledIndent.contextHeight}px;
      margin-right: 0;
      padding-left: 1vw;
      padding-left: max(1vw, 20px);
    }
  }

  .modalSmall .link {
    position: absolute;
    bottom: 0px;
    cursor: pointer;
    color: #0364c1;

    right: 3vw;
    right: max(3vw, 60px);
  }
  .modalSmall .link.mobile {
    position: absolute;
    bottom: 0px;
    cursor: pointer;
    color: #0364c1;

    right: 1vw;
    right: max(1vw, 20px);
  }
  .modalSmall.dark .link {
    color: #fff;
  }

  //настроки scrollbar
  .modalSmall .text::-webkit-scrollbar {
    width: 0.4vw;
    min-width: 8px;
    background-color: none;
  }
  .modalSmall .text::-webkit-scrollbar-thumb {
    width: 0.4vw;
    min-width: 8px;
    background-color: #0364c1;
  }
  .modalSmall.dark .text {
    scrollbar-color: #0364c1 #ffffff00;
  }
  .modalSmall.dark .text::-webkit-scrollbar {
    background-color: none;
  }
  .modalSmall.dark .text::-webkit-scrollbar-thumb {
    background-color: #0364c1;
  }

  .null {
    width: 100%;
    height: 96px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .desc {
    width: 100%;
    height: 69px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .ref {
    position: absolute;
    top: 40px;
    right: 0px;
  }

  .ref a:hover,
  .ref a:focus,
  .ref a:active {
    text-decoration: none;
    border: none;
    outline: none;
  }

  .spaceList ul {
    padding-left: 0;
  }
  .spaceList li {
    position: relative;
    margin-top: 0.4vw;
    margin-top: max(0.4vw, 8px);
    margin-bottom: 0.4vw;
    margin-bottom: max(0.4vw, 8px);
    list-style: none;
  }
  .spaceList li a {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 0.35vw;
    border-radius: max(0.35vw, 7px);
    align-items: center;
    text-decoration: none;
    transition: all 0.5s ease;
    background: #e7e7e7;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .spaceList.dark li a {
    background: #1e1b30;
  }
  .spaceList li a:focus {
    outline: 0 !important;
  }
  .spaceList li a .links_name {
    height: 2vw;
    min-height: 40px;
    line-height: 2vw;
    line-height: max(2vw, 40px);
    color: #0364c1;
    font-size: 0.75vw;
    font-size: max(0.75vw, 15px);
    white-space: nowrap;
    transition: all 0.5s ease;
  }
  .spaceList li a .links_name.short {
    width: calc(100% - 160px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .spaceList.dark li a .links_name {
    color: #fff;
  }
  .spaceList li a .links_name:hover {
    transition: all 0.5s ease;
    margin-left: 0.2vw;
    margin-left: max(0.2vw, 4px);
  }

  .spaceList ul li i {
    color: #0364c1;
    height: 2vw;
    min-height: 40px;
    width: 2vw;
    min-width: 40px;
    text-align: center;
    font-size: 0.9vw;
    font-size: max(0.9vw, 18px);
    line-height: 2vw;
    line-height: max(2vw, 40px);
    border-radius: 0;
    transition: all 0.5s ease;
  }
  .spaceList.dark ul li i {
    color: #fff;
  }

  .spaceList li i.settings {
    position: absolute;
    right: 0;
    border-top-right-radius: 0.35vw;
    border-top-right-radius: max(0.35vw, 7px);
    border-bottom-right-radius: 0.35vw;
    border-bottom-right-radius: max(0.35vw, 7px);
    color: #0364c1;
  }

  .spaceList.dark li i.settings {
    color: #fff;
  }

  .spaceList li i.down {
    position: absolute;
    right: 2vw;
    right: max(2vw, 40px);
    color: #0364c1;
  }

  .spaceList.dark li i.down {
    color: #fff;
  }

  .spaceList li i.up {
    position: absolute;
    right: 4vw;
    right: max(4vw, 80px);
    color: #0364c1;
  }

  .spaceList.dark li i.up {
    color: #fff;
  }

  .spaceList ul li i:hover.settings,
  .spaceList ul li i:hover.down,
  .spaceList ul li i:hover.up {
    color: #2b7de9;
  }

  .spaceList.dark ul li i:hover.settings,
  .spaceList.dark ul li i:hover.down,
  .spaceList.dark ul li i:hover.up {
    color: #fff;
  }
`
