export default function Language() {
  let language
  let browserLang = navigator.language || navigator.userLanguage
  // console.log(browserLang)
  if (
    browserLang === 'de-DE' || //Germany
    browserLang === 'de-AT' || //Austria
    browserLang === 'de-LI' || //Liechtenstein
    browserLang === 'de-CH' //Switzerland
  ) {
    browserLang = 'de'
  }
  if (
    browserLang === 'fr-FR' || //French (France)
    browserLang === 'fr-CA' || //French (Canada)
    browserLang === 'fr-CH' //French (Switzerland)
  ) {
    browserLang = 'fr'
  }
  if (
    browserLang === 'es-ES' || //Spanish (Spain)
    browserLang === 'es-US' || //Spanish (United States)
    browserLang === 'es-AR' || //Spanish (Argentina)
    browserLang === 'es-CL' || //Spanish (Chile)
    browserLang === 'es-CO' || //Spanish (Colombia)
    browserLang === 'es-CR' || //Spanish (Costa Rica)
    browserLang === 'es-HN' || //Spanish (Honduras)
    browserLang === 'es-419' || //Spanish (Latin America)
    browserLang === 'es-MX' || //Spanish (Mexico)
    browserLang === 'es-PE' || //Spanish (Peru)
    browserLang === 'es-UY' || //Spanish (Uruguay)
    browserLang === 'es-VE' //Spanish (Venezuela)
  ) {
    browserLang = 'es'
  }
  if (
    browserLang === 'pt-PT' || //Portuguese (Portugal)
    browserLang === 'pt-BR' //Portuguese (Brazil)
  ) {
    browserLang = 'pt'
  }
  if (
    browserLang === 'it-IT' || //Italian (Italy)
    browserLang === 'it-CH' //Italian (Switzerland)
  ) {
    browserLang = 'it'
  }
  if (browserLang === 'pl-PL') {
    browserLang = 'pl'
  }
  if (browserLang === 'cs-CZ') {
    browserLang = 'cs'
  }
  if (browserLang === 'hr-HR') {
    browserLang = 'hr'
  }
  if (browserLang === 'ru-RU') {
    browserLang = 'ru'
  }
  if (browserLang === 'uk-UA') {
    browserLang = 'uk'
  }
  if (browserLang === 'tr-TR' || browserLang === 'tr-CY') {
    browserLang = 'tr'
  }
  if (
    browserLang === 'de' ||
    browserLang === 'fr' ||
    browserLang === 'es' ||
    browserLang === 'pt' ||
    browserLang === 'it' ||
    browserLang === 'pl' ||
    browserLang === 'cs' ||
    browserLang === 'hr' ||
    browserLang === 'ru' ||
    browserLang === 'uk' ||
    browserLang === 'tr'
  ) {
    language = browserLang
  } else {
    language = 'en'
  }

  return language
}
