import React, { useEffect } from 'react'
import { useAuth } from './context/AuthContext'
import { useData } from './context/DataContext'
import Menu from './Menu'
import Auth from './Auth'
import Modal from './Modal'
import withLocalForage from './context/withLocalForage'

const Router = ({ updateStyled, syncDomains, syncPages }) => {
  const { currentUser } = useAuth()
  const { global } = useData()
  const { settings, styleData } = global
  const path = `state${currentUser ? currentUser.uid : ''}`

  useEffect(() => {
    syncDomain(global)
    syncPage(global)
  }, [])

  function syncDomain(global) {
    if (!global) return
    let domainsReCheck = []
    Object.keys(global.domains).forEach((domainId) => {
      if (global.domains[domainId].verifiedD === true) return
      domainsReCheck.push(domainId)
    })
    if (domainsReCheck.length === 0) return
    syncDomains(domainsReCheck, path)
  }

  function syncPage(global) {
    if (!global) return
    let pagesReCheck = []
    Object.keys(global.pages).forEach((pageId) => {
      if (global.pages[pageId].verifiedP === true) return
      pagesReCheck.push(pageId)
    })
    if (pagesReCheck.length === 0) return
    syncPages(pagesReCheck, path)
  }

  useEffect(() => {
    updateStyled(path, settings, styleData)
  }, [global])

  useEffect(() => {
    const handleResize = () => {
      updateStyled(path, settings, styleData)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <Menu />
      <Auth />
      <Modal />
    </>
  )
}

export default withLocalForage(Router)
