import styled from 'styled-components'

export const Container = styled.div`
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    border: none;
    outline: none;
  }

  .preview {
    width: 18vw;
    min-width: 325px;
    height: 9.75vw;
    min-height: 194px;
    border-radius: 0.5vw;
    border-radius: max(0.5vw, 10px);
    -webkit-box-shadow: ${(props) => props.styleData.boxShadowTabs};
    -moz-box-shadow: ${(props) => props.styleData.boxShadowTabs};
    box-shadow: ${(props) => props.styleData.boxShadowTabs};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    cursor: pointer;
    margin: 0 auto;
  }

  .preview img {
    width: 95%;
    height: 90%;
    border-radius: 0.25vw;
    border-radius: max(0.25vw, 5px);
    -webkit-box-shadow: ${(props) => props.styleData.boxShadowFolder};
    -moz-box-shadow: ${(props) => props.styleData.boxShadowFolder};
    box-shadow: ${(props) => props.styleData.boxShadowFolder};
  }

  .preview .background {
    width: 95%;
    height: 90%;
    border-radius: 0.25vw;
    border-radius: max(0.25vw, 5px);
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: ${(props) => props.styleData.boxShadowFolder};
    -moz-box-shadow: ${(props) => props.styleData.boxShadowFolder};
    box-shadow: ${(props) => props.styleData.boxShadowFolder};
  }

  .folderTab {
    width: 50%;
    height: 50%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .folderSpace {
    width: 85%;
    height: 85%;
  }
`
