import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import { usePage } from '../context/PageContext'

import { Container } from '../style/ModalSmall.styled'

import Button from '../button/Button'
import Input from '../input/Input'
import Title from '../modal/Title'
import LineHr from '../minor/LineHr'
import { toast } from 'react-toastify'
import Content from '../modal/Content'
import initialLanguage from '../language/initialLanguage'
import UserName from '../minor/UserName'

import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getColor,
  getButton,
  getClassLink,
  checkRequired,
  checkEmail,
  checkPassword,
  checkPasswordMatch,
  setError,
  clearError,
  getFocus,
} from '../functionSet/ModalFunction'

export default function ChangeEmail({ button, fullWidth, mobileClose }) {
  const { currentUser, login, emailUpdate } = useAuth()
  const { global, lang, setDrag } = useData()
  const { settings, styleData } = global

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  const navigate = useNavigate()
  const [modal, setModal] = useState(true)

  button = getButton({ button, device, mobileClose })

  if (currentUser.providerData[0].providerId === 'password') {
    button = button + 1
  }

  let styledIndent = {
    contextHeight: getContextHeight({
      device,
      styleData,
      button,
    }),
    width: getWidth({ styleData, fullWidth }),
  }

  const { changeEmail, setChangeEmail } = usePage()
  const [activeDelay, setActiveDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(changeEmail)
      setDrag(false)
    }, 1)
  }, [changeEmail])

  let modalActiv = activeDelay

  async function handleClose(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/')
      setChangeEmail(false)
    }, 400)
  }

  async function handleBack(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate(-1)
      setChangeEmail(false)
    }, 400)
  }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  async function handleSubmit(e) {
    e.preventDefault()

    // const emailRequired = checkRequired({
    //   value: email,
    //   device: device,
    //   id: 'email_ce',
    //   afterFocus: false,
    // })

    const emailValid = checkEmail({
      email: email,
      id: 'email_ce',
      device: device,
      afterFocus: false,
    })

    const passwordValid = checkPassword({
      password: password,
      id: 'password_ce',
      device: device,
      afterFocus: false,
    })

    const confirmPasswordValid = checkPassword({
      password: confirmPassword,
      id: 'password2_ce',
      device: device,
      afterFocus: false,
    })

    const passwordMatch = checkPasswordMatch({
      password: password,
      passwordValid: passwordValid,
      id: 'password_ce',
      ////////
      confirmPassword: confirmPassword,
      confirmPasswordValid: confirmPasswordValid,
      id2: 'password2_ce',
    })

    if (
      !emailValid ||
      !passwordValid ||
      !confirmPasswordValid ||
      !passwordMatch
    ) {
      return
    }

    try {
      await login(currentUser.email, password)
      try {
        await emailUpdate(email)
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        handleBack(e)
        toast.success(initialLanguage[lang].ce_new_email_saved)
      } catch {
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        toast.error(initialLanguage[lang].ce_failed_update_email)
      }
    } catch {
      setEmail('')
      setPassword('')
      setConfirmPassword('')
      toast.error(initialLanguage[lang].ce_wrong_password)
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  useEffect(() => {
    //Для старого поля Email
    getFocus('email_ce', setModal)
    //Для нового поля Пароль
    getFocus('password_ce', setModal)
    //Для поля Подтверждение пароля
    getFocus('password2_ce', setModal)
  }, [])

  const changePasswordTag = () => {
    if (currentUser.providerData[0].providerId === 'password') {
      return (
        <>
          <br />
          <LineHr
            text={initialLanguage[lang].ce_change_email}
            dark={settings.styleTheme}
          />

          <Input
            id="email_ce"
            labelId="email_ce_label"
            type="email"
            state={email}
            setState={setEmail}
            placeholder={''}
            label={initialLanguage[lang].ce_new_email}
            autoComplete="new-password"
            dark={settings.styleTheme}
          />

          <Input
            id="password_ce"
            labelId="password_ce_label"
            type="password"
            state={password}
            setState={setPassword}
            placeholder={''}
            label={initialLanguage[lang].ce_password}
            autoComplete="new-password"
            dark={settings.styleTheme}
          />

          <Input
            id="password2_ce"
            labelId="password2_ce_label"
            type="password"
            state={confirmPassword}
            setState={setConfirmPassword}
            placeholder={''}
            label={initialLanguage[lang].ce_confirm_password}
            autoComplete="new-password"
            required={true}
            dark={settings.styleTheme}
          />
        </>
      )
    } else {
      return <p>{initialLanguage[lang].ce_forbidden}</p>
    }
  }

  return (
    <div
      className={getBlackout({ device, modalActiv })}
      onClick={modal ? handleClose : null}
    >
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={getClassName({
              modalActiv,
              device,
              dark: settings.styleTheme,
            })}
          >
            <Title
              children={initialLanguage[lang].ce_change_email}
              dark={settings.styleTheme}
              handleBack={handleBack}
              handleClose={handleClose}
            />

            {device === 'desktop' ? <div className="indent20"></div> : null}

            <Content>
              <UserName />
              {changePasswordTag()}
            </Content>

            {currentUser.providerData[0].providerId === 'password' ? (
              <Button
                onClick={handleSubmit}
                button={true}
                color={'blue'}
                isDesktop={true}
                dark={settings.styleTheme}
                type="submit"
              >
                {initialLanguage[lang].ce_save_new_email}
              </Button>
            ) : null}

            <Button
              onClick={handleClose}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].ce_cancel}
            </Button>

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
    </div>
  )
}
