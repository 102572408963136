import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as localForage from 'localforage'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import initialLanguage from '../language/initialLanguage'

export default function NavBarSpaces({
  changeSpaceBar,
  searchRestore,
  setModalActive,
  setSpaceUpdate,
  setModalUpdateActive,
  setModalUpdatePublicActive,
}) {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`
  const { global, setOutPublic, space, setSpace, setActiveState, lang } =
    useData()

  let isPublic = space == 0 ? 'activeSpace' : 'space'

  let sliceIndex = currentUser ? 1 : 0

  const spaceShotNav = Object.entries(global.spaces)
    .slice(sliceIndex)
    .map((item, index) => {
      index = parseInt(index, 10) + 1
      let isActive = space == index ? 'activeSpace' : 'space'
      return (
        <div className="space" key={item} title={item[1].space}>
          <div className="button" onClick={() => changeSpace(index)}>
            <i
              className={item[1].icon ? item[1].icon : 'bx bx-bookmarks'}
              id={isActive}
            ></i>
          </div>
          <div className="tooltip">{item[1].space}</div>
        </div>
      )
    })

  let isSpaceRunning = false
  function changeSpace(value) {
    if (isSpaceRunning) return
    isSpaceRunning = true

    changeSpaceBar(path, value, space)

    isSpaceRunning = false
    return
  }

  let addView = 'dynamic'
  const spacesContainer = document.getElementById('spacesContainer')
  const spacesContent = document.getElementById('spacesContent')

  if (spacesContainer && spacesContent) {
    const maxY = spacesContent.offsetHeight - spacesContainer.offsetHeight
    let currentY = 0
    let touchStartY = 0
    let touchEndY = 0

    if (spacesContainer.offsetHeight < spacesContent.offsetHeight) {
      addView = 'static'
      spacesContainer.addEventListener('wheel', scrollSimulation)
      spacesContainer.addEventListener('touchstart', handleTouchStart)
      spacesContainer.addEventListener('touchmove', handleTouchMove)
      spacesContainer.addEventListener('touchend', handleTouchEnd)
    }

    function scrollSimulation(e) {
      e.preventDefault()
      const delta = e.deltaY || e.detail || e.wheelDelta
      currentY += delta > 0 ? global.styleData.vw : -global.styleData.vw
      if (currentY < 0) currentY = 0
      if (currentY > maxY) currentY = maxY
      requestAnimationFrame(() => {
        spacesContent.style.transform = `translateY(-${currentY}px)`
      })
    }

    function handleTouchStart(e) {
      touchStartY = e.touches[0].pageY
    }

    function handleTouchMove(e) {
      e.preventDefault()
      touchEndY = e.touches[0].pageY
      currentY +=
        touchEndY > touchStartY
          ? -global.styleData.vw / 4
          : global.styleData.vw / 4
      if (currentY < 0) currentY = 0
      if (currentY > maxY) currentY = maxY
      requestAnimationFrame(() => {
        spacesContent.style.transform = `translateY(-${currentY}px)`
      })
    }

    function handleTouchEnd(e) {
      touchEndY = e.changedTouches[0].pageY
      touchStartY = 0
    }
  }

  const cleanup =
    window.location.href === 'http://localhost:3000/' ? true : false
  function handleCleanUp() {
    localStorage.clear()
    localForage.clear()
    window.location.reload()
  }

  function addSpace() {
    setModalActive(true)
    navigate('/')
    setOutPublic(false)
  }

  function updateSpace(value) {
    searchRestore(path, true)
    if (currentUser) {
      if (Number(value) !== space || space === null) {
        const dragContainer = document.getElementById('dragContainer')
        if (dragContainer) dragContainer.style.opacity = 0
        navigate('/')
        setTimeout(() => {
          setOutPublic(false)
          setSpace(Number(value))
        }, 200)
      }
    }

    setActiveState(global.spaces[value])
    setSpaceUpdate(value)
    if (value === 0) {
      setModalUpdatePublicActive(true)
    } else {
      setModalUpdateActive(true)
    }
  }

  function openOwnPublic() {
    if (global.spaces[0] && global.spaces[0].nickname) {
      changeSpaceBar(path, 0, space)
    } else {
      updateSpace(0)
    }
    return
  }

  return (
    <div className="spaces" id="spacesContainer">
      <div className="spacesContent" id="spacesContent">
        <div className="space">
          <div className="button" onClick={openOwnPublic}>
            <i className="bx bx-world" id={isPublic}></i>
          </div>
          <div className="tooltip">{initialLanguage[lang].nb_public_space}</div>
        </div>
        {spaceShotNav}

        {cleanup ? (
          <div className="space">
            <div className="button" onClick={handleCleanUp}>
              <i className="bx bx-eraser" id="space"></i>
            </div>
            <div className="tooltip">CleanUp</div>
          </div>
        ) : null}
      </div>
      {addView === 'dynamic' ? (
        <div className="space add" id="spaceAdd">
          <div className="button" onClick={addSpace}>
            <i className="bx bx-plus" id="space"></i>
          </div>
          <div className="tooltip">{initialLanguage[lang].nb_add_space}</div>
        </div>
      ) : null}
      {addView === 'dynamic' ? (
        <div className="space add" id="spaceAdd">
          <div className="button" onClick={addSpace}>
            <i className="bx bx-plus" id="space"></i>
          </div>
          <div className="tooltip">{initialLanguage[lang].nb_add_space}</div>
        </div>
      ) : null}
    </div>
  )
}
