import React from 'react'

export default function AboutEs() {
  return (
    <>
      <p>
        Bienvenido a TitleTab - Speed Dial Dashboard, la última solución de
        administración de pestañas diseñada para hacer que su experiencia de
        navegación sea más rápida y conveniente que nunca. Nuestra solución
        ofrece una variedad de funciones que pueden ayudarlo a personalizar las
        pestañas de su navegador, evitar cierres accidentales del navegador y
        compartir sus enlaces favoritos con otros usuarios.
      </p>
      <p>
        Estas son algunas de las increíbles características que ofrece TitleTab:
      </p>
      <p>
        <strong>Página de inicio personalizada</strong>
      </p>
      <p>
        Nuestra función StartPage le permite arrastrar y soltar fácilmente sus
        pestañas para organizarlas de la manera que mejor se adapte a sus
        necesidades. También puede personalizar cada pestaña con varias opciones
        de visualización, incluida una captura de pantalla de la página web,
        texto, logotipos de sitios populares o sus propias imágenes. Ofrecemos
        temas claros y oscuros, para que pueda elegir el que se adapte a sus
        preferencias.
      </p>
      <p>
        <strong>
          Organización de pestañas: carpetas y espacios ilimitados
        </strong>
      </p>
      <p>
        TitleTab ofrece potentes funciones de organización de pestañas que le
        permiten estructurar sus pestañas de una manera que funcione para usted.
        Puede agrupar pestañas en carpetas simplemente arrastrando y soltando
        una pestaña sobre otra. Además, puede crear nuevos espacios para
        ayudarlo a separar diferentes tipos de pestañas, como pestañas
        relacionadas con el trabajo y pestañas relacionadas con pasatiempos. Con
        las funciones de organización de pestañas de TitleTab, puede administrar
        fácilmente sus pestañas y mantener su experiencia de navegación
        organizada y eficiente.
      </p>
      <p>
        <strong>Espacio público con un vínculo único</strong>
      </p>
      <p>
        Los usuarios de TitleTab también pueden crear un espacio público al que
        pueden acceder otros usuarios que tengan el enlace. Cuando active el
        botón "Espacio público" por primera vez, le pediremos que complete
        algunos campos que son necesarios para iniciar su espacio público. El
        campo más importante que debe completarse es la dirección de enlace
        única ("Ruta de acceso al espacio público") a través de la cual se podrá
        acceder a su espacio con las pestañas que desea compartir con otros
        usuarios. Una vez que active el espacio público, puede comenzar a
        agregarle pestañas. Esta función es especialmente útil si desea
        compartir pestañas con amigos o colegas para el trabajo colaborativo.
        Con la función de espacio público de TitleTab, puede compartir
        fácilmente sus pestañas y colaborar con otros de una manera conveniente
        y segura.
      </p>
      <p>
        <strong>OneTab: guarda todas las pestañas abiertas en una</strong>
      </p>
      <p>
        Para los navegadores populares, hay una extensión disponible que permite
        a los usuarios administrar sus pestañas abiertas en su navegador.
        Simplemente haciendo clic en el botón de extensión, puede guardar todas
        las pestañas (o las seleccionadas) en una pestaña que almacenará una
        lista de sitios y estará lista para reabrirla en cualquier momento. Esta
        funcionalidad ahorra significativamente los recursos de su dispositivo,
        ya que las pestañas abiertas continúan funcionando y utilizando la
        memoria de su dispositivo.
      </p>
      <p>
        También vale la pena señalar que no hacemos un seguimiento de lo que
        guarda exactamente de esta manera, lo que garantiza su privacidad, pero
        al mismo tiempo, no podemos guardar esta información por usted. Por lo
        tanto, esta información solo está disponible localmente en este
        navegador en este dispositivo en el que está trabajando. Este es el
        costo de su privacidad.
      </p>
      <p>
        <strong>Nueva pestaña infinita</strong>
      </p>
      <p>
        Infinity New Tab es otra característica de nuestra extensión que brinda
        una experiencia de navegación sin fin. Con infinity new tab, los
        usuarios pueden continuar navegando sin temor a cerrar accidentalmente
        la última pestaña abierta. Esta función agrega automáticamente una nueva
        pestaña a la izquierda de la última pestaña abierta, lo que garantiza
        que los usuarios siempre tengan una pestaña a la que recurrir. Una vez
        que se abren pestañas adicionales, Infinity New Tab desaparece, lo que
        permite a los usuarios navegar libremente sin ninguna interrupción. Esta
        función brinda tranquilidad a los usuarios y evita la frustración que
        surge al cerrar accidentalmente la última pestaña.
      </p>
      <p>
        <strong>Extensiones de TitleTab</strong>
      </p>
      <p>
        TitleTab es compatible con navegadores web populares como Google Chrome,
        Mozilla Firefox y Microsoft Edge. Nuestra extensión se puede descargar e
        instalar fácilmente desde las tiendas de extensiones oficiales de cada
        navegador.
      </p>
      <p>Aquí hay una lista de dónde puede descargar TitleTab:</p>
      <ul>
        <li>
          <a
            href="https://chrome.google.com/webstore/detail/titletab-web-dashboard/ldlndadamgafkigafpajckkjbblnepcb"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome Web Store
          </a>
        </li>
        <li>
          <a
            href="https://addons.mozilla.org/firefox/addon/titletab-web-dashboard/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox Add-ons
          </a>
        </li>
        <li>
          <a
            href="https://microsoftedge.microsoft.com/addons/detail/titletab-web-dashboard/mnckgifglkiecanfbdpnajiplpabkphh"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge Add-ons
          </a>
        </li>
      </ul>
      <p>
        Una vez instalado, puede comenzar a usar TitleTab para personalizar su
        nueva página de pestañas, guardar y organizar sus pestañas, evitar el
        cierre accidental de pestañas y compartir enlaces con otros. No pierda
        la oportunidad de optimizar su experiencia de navegación con TitleTab.
        ¡Descarga nuestra extensión hoy!
      </p>
      <p>
        <strong>Privacidad y seguridad</strong>
      </p>
      <p>
        TitleTab toma en serio su privacidad y seguridad. Todos sus datos se
        almacenan de forma segura en su dispositivo y en la nube, y nunca
        compartimos su información con terceros.
      </p>
      <hr />
      <p>
        TitleTab es la mejor solución de administración de pestañas para
        cualquiera que busque tomar el control de su experiencia de navegación.
        ¡Pruébelo hoy y vea la diferencia por sí mismo!
      </p>
      <p>
        Si tiene alguna pregunta sobre TitleTab o necesita ayuda con cualquiera
        de nuestras funciones, no dude en comunicarse con nosotros en&nbsp;
        <a
          href="mailto:info@titletab.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@titletab.com
        </a>
        . Nuestro equipo siempre está feliz de ayudar y responder cualquier
        pregunta que pueda tener. Valoramos sus comentarios y estamos
        comprometidos a brindar la mejor experiencia de usuario posible, así que
        no dude en comunicarse con nosotros si tiene alguna sugerencia o
        comentario.
      </p>
    </>
  )
}
