import { database } from '../firebase'

export default function PagesGet({ idPages }) {
  let errorPage = {
    pagesBackground: '#0364c1',
    pagesColor: '#ffffff',
    pagesHostname: 'titletab.com',
    pagesPathname: '/',
    pagesPicture: 'https://bucket.titletab.com/404FB.webp',
    pagesTitle: 'Something went wrong...',
    verifiedP: true,
  }
  return new Promise((resolve, reject) => {
    database.pages
      .doc(idPages)
      .get()
      .then((doc) => {
        if (doc.exists) {
          resolve(doc.data())
        } else {
          resolve(errorPage)
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error)
        resolve(errorPage)
      })
  })
}
