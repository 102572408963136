import React, { useRef } from 'react'
import { Container } from './Input.styled'

export default function Input({
  id,
  type,
  state,
  setState,
  placeholder,
  disabled,
  readOnly,
  copyIcon,
  label,
  labelUp,
  labelId,
  autoComplete,
  dark,
  refLink,
}) {
  const copyRef = useRef(null)

  function getClassNameDark(classElement) {
    if (dark) classElement += ' dark'
    return classElement
  }

  function copyData() {
    const copyText = copyRef.current
    copyText.select()
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
    //get element by id
    const copyIcon = document.getElementById(id + '-i')
    if (copyIcon) {
      copyIcon.style.color = '#2b7de9'
      copyIcon.style.backgroundColor = '#f5f5f5'
      setTimeout(() => {
        copyIcon.style.color = ''
        copyIcon.style.backgroundColor = ''
      }, 2000)
    }
  }

  const labelTag = () => {
    if (placeholder || state || labelUp) {
      if (readOnly) {
        return (
          <label id={labelId} className="labelUp" style={{ color: '#777' }}>
            {label}
          </label>
        )
      }

      return (
        <label id={labelId} className="labelUp">
          {label}
        </label>
      )
    } else {
      return <label id={labelId}>{label}</label>
    }
  }

  return (
    <Container>
      <div className={getClassNameDark('component')} id={id + 'Container'}>
        <input
          id={id}
          type={type}
          value={state ? state : ''}
          onChange={(e) => setState(e.target.value)}
          placeholder={placeholder ? placeholder : null}
          autoComplete="new-password"
          required
          disabled={disabled}
          readOnly={readOnly}
          ref={copyRef}
        />
        {!readOnly ? <span className="bar"></span> : null}

        {copyIcon ? (
          <i
            className="bx bx-copy"
            title="Copy"
            onClick={copyData}
            id={id + '-i'}
          ></i>
        ) : null}
        {labelTag()}
        <span className="error" id={id + '_error'}></span>
      </div>
    </Container>
  )
}
