import styled from 'styled-components'

export const Container = styled.div`
  a {
    width: 18vw;
    min-width: 325px;
    height: 9.75vw;
    min-height: 194px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-decoration: none;
  }

  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    border: none;
    outline: none;
  }

  .preview {
    width: 18vw;
    min-width: 325px;
    height: 9.75vw;
    min-height: 194px;
    border-radius: 0.5vw;
    border-radius: max(0.5vw, 10px);
    box-shadow: 0 0.5vw 1vw -0.2vw rgba(0, 0, 0, 0.24);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    opacity: 0;
    transition: opacity 1s;
  }

  .preview img {
    width: 18vw;
    min-width: 325px;
    height: 9.75vw;
    min-height: 194px;
    border-radius: 0.5vw;
    border-radius: max(0.5vw, 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .preview p {
    margin-top: 5vw;
    margin-top: max(5vw, 100px);
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
  }

  .preview_text {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1vw;
    padding-left: max(1vw, 20px);
    padding-right: 1vw;
    padding-right: max(1vw, 20px);
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
