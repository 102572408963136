import React, { useContext, useState } from 'react'

const DataContext = React.createContext()

export function useData() {
  return useContext(DataContext)
}

export function DataProvider({ children }) {
  const [global, setGlobal] = useState()
  const [outPublic, setOutPublic] = useState()
  const [load, setLoad] = useState()
  const [activeState, setActiveState] = useState()
  const [space, setSpace] = useState()
  const [spaceUpdate, setSpaceUpdate] = useState(true)
  const [refresh, setRefresh] = useState()
  const [lang, setLang] = useState()
  const [launch, setLaunch] = useState()
  const [background, setBackground] = useState()
  const [drag, setDrag] = useState(true)
  const [addButton, setAddButton] = useState(true)
  const [open, setOpen] = useState()
  const [loadSpace, setLoadSpace] = useState(false)
  const [device, setDevice] = useState()
  const [shareState, setShareState] = useState(false)
  const [folderActive, setFolderActive] = useState(false)
  const [folderState, setFolderState] = useState()
  const [taskUpdateInFolder, setTaskUpdateInFolder] = useState()
  const [stateUpdateInFolder, setStateUpdateInFolder] = useState()

  const [addModal, setAddModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)

  const value = {
    global,
    setGlobal,
    outPublic,
    setOutPublic,
    load,
    setLoad,
    activeState,
    setActiveState,
    space,
    setSpace,
    spaceUpdate,
    setSpaceUpdate,
    lang,
    setLang,
    refresh,
    setRefresh,
    drag,
    setDrag,
    addButton,
    setAddButton,
    launch,
    setLaunch,
    background,
    setBackground,
    open,
    setOpen,
    loadSpace,
    setLoadSpace,
    device,
    setDevice,
    shareState,
    setShareState,
    folderActive,
    setFolderActive,
    folderState,
    setFolderState,
    taskUpdateInFolder,
    setTaskUpdateInFolder,
    stateUpdateInFolder,
    setStateUpdateInFolder,
    addModal,
    setAddModal,
    updateModal,
    setUpdateModal,
  }

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}
