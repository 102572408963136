import React from 'react'
import { useData } from '../context/DataContext'
import { Container } from '../components/folders/PreviewFolder.styled'

export default function PreviewFolder({ task, background }) {
  const { global, device } = useData()
  const { settings, styleData, domains, pages, images } = global

  let ratio = 0.7
  if (device === 'mobile') ratio = 0.9

  if (!background) background = '#fff'

  const getContent = () => {
    const getFolderContent = (value) => {
      const linkHost = getLinkHost()
      const background = getBackground()
      const color = getColor()
      const screen = getScreen()
      const logo = getLogo()
      const image = getImage()
      const tabFill = getTabFill()

      function getBackground() {
        if (value.ownBackground) return value.ownBackground
        if (domains[value.idDomain].domainBackground)
          return domains[value.idDomain].domainBackground
        if (pages[value.idPages].pagesBackground)
          return pages[value.idPages].pagesBackground
        return '#ffffff'
      }

      function getColor() {
        if (value.ownColor) return value.ownColor
        if (domains[value.idDomain].domainColor)
          return domains[value.idDomain].domainColor
        if (pages[value.idPages].pagesColor)
          return pages[value.idPages].pagesColor
        return '#4285F4'
      }

      function getScreen() {
        if (pages[value.idPages].pagesPicture)
          return pages[value.idPages].pagesPicture
        return null
      }

      function getLogo() {
        if (domains[value.idDomain].domainPicture)
          return domains[value.idDomain].domainPicture
        return null
      }

      function getImage() {
        if (value.ownPicture && value.ownPicture.startsWith('http'))
          return value.ownPicture
        if (value.ownPicture && images[value.ownPicture])
          return images[value.ownPicture].image
        return ''
      }

      function getTabFill() {
        if (value.tabFill) return value.tabFill
        if (settings.tabsAsImage) {
          if (image) return 'image'
          if (logo) return 'logo'
          if (screen) return 'screen'
          return 'color'
        }
        return 'color'
      }

      function getLinkHost() {
        let linkHostLength = domains[value.idDomain].domainHostname.length
        let domainZones = ['com', 'net', 'org']
        let urlParts = domains[value.idDomain].domainHostname.split('.')
        let urlPartsLength = domains[value.idDomain].domainHostname.split('.')

        let urlShortParts = domains[value.idDomain].domainHostname.split('.')
        if (linkHostLength > 18) {
          do {
            let newUrlShortParts = urlShortParts.slice()
            newUrlShortParts.sort(function (a, b) {
              return b.length - a.length
            })
            if (newUrlShortParts[0].length < 5) {
              break
            }
            for (let i = 0; i < urlShortParts.length; i++) {
              if (urlShortParts[i] === newUrlShortParts[0]) {
                urlShortParts[i] =
                  urlShortParts[i].slice(0, urlShortParts[i].length - 3) + '..'
              }
            }
          } while (urlShortParts.join('.').length > 18)
        }

        urlPartsLength.sort(function (a, b) {
          return b.length - a.length
        })
        let strong = true
        for (let i = 0; i < urlParts.length; i++) {
          if (
            urlParts[i] === urlPartsLength[0] &&
            i != urlParts.length - 1 &&
            urlParts[i].length > 2 &&
            domainZones.includes(urlParts[i]) === false
          ) {
            urlParts[i] = (
              <b key={'linkHost' + value.id + i}>{urlShortParts[i]}.</b>
            )
            strong = false
          } else {
            if (i != urlParts.length - 1) {
              urlParts[i] = urlShortParts[i] + '.'
            }
          }
        }

        if (strong) {
          urlParts[0] = <b key={'linkHost' + value.id}>{urlParts[0]}</b>
        }

        return urlParts
      }

      if (tabFill === 'color') {
        return (
          <span
            className="background"
            style={{
              backgroundColor: background,
            }}
          >
            <span
              className="name"
              style={{
                color: color,
                fontSize: global.styleData.tabFontSize * ratio + 'px',
              }}
            >
              {linkHost}
            </span>
          </span>
        )
      }

      if (tabFill === 'screen') {
        if (screen === 'downloadFile') {
          return (
            <span
              className="background"
              style={{
                backgroundColor: '#ffffff',
              }}
            >
              <span
                className="name"
                style={{
                  color: '#0364c1',
                  fontSize: global.styleData.tabFontSize * ratio * 3 + 'px',
                }}
              >
                <i className="bx bxs-file-import"></i>
              </span>
            </span>
          )
        }
        return (
          <img
            src={screen}
            style={{
              backgroundColor: background,
            }}
          />
        )
      }

      if (tabFill === 'logo') {
        return (
          <img
            src={logo}
            style={{
              backgroundColor: background,
            }}
          />
        )
      }

      if (tabFill === 'image') {
        if (image) {
          return (
            <img
              src={image}
              style={{
                backgroundColor: background,
              }}
            />
          )
        }
        return (
          <span
            className="background"
            style={{
              backgroundColor: background,
            }}
          >
            <span
              className="name"
              style={{
                color: color,
                fontSize: global.styleData.tabFontSize * ratio + 'px',
              }}
            >
              {linkHost}
            </span>
          </span>
        )
      }

      return (
        <span
          className="background"
          style={{
            backgroundColor: background,
          }}
        >
          <span
            className="name"
            style={{
              color: color,
              fontSize: global.styleData.tabFontSize * ratio + 'px',
            }}
          >
            {linkHost}
          </span>
        </span>
      )
    }

    const folderTabs = Object.keys(task.tasks).map((key) => {
      if (key > 4) return null

      return (
        <span className="folderTab" key={'dfgdfg' + task.tasks[key].id}>
          {getFolderContent(task.tasks[key])}
        </span>
      )
    })

    return folderTabs
  }

  return (
    <Container styleData={styleData}>
      <section
        className="preview"
        style={{
          backgroundColor: background,
        }}
      >
        <span className="folderSpace" key={'folderTabSmall'}>
          {getContent()}
        </span>
      </section>
    </Container>
  )
}
