import React, { useState } from 'react'
import { useData } from '../../context/DataContext'

export default function FolderBody({ task }) {
  const [imageError, setImageError] = useState(false)
  const { global } = useData()
  const { settings, styleData, domains, pages, images } = global

  const getFolderContent = (value) => {
    const linkHost = getLinkHost()
    const background =
      value.ownBackground ||
      domains[value.idDomain].domainBackground ||
      pages[value.idPages].pagesBackground ||
      '#ffffff'

    const color =
      value.ownColor ||
      domains[value.idDomain].domainColor ||
      pages[value.idPages].pagesColor ||
      '#4285F4'

    const screen = pages[value.idPages].pagesPicture || null

    const logo = domains[value.idDomain].domainPicture || null

    const image =
      value.ownPicture && value.ownPicture.startsWith('http')
        ? value.ownPicture
        : value.ownPicture && images[value.ownPicture]
        ? images[value.ownPicture].image
        : null

    const tabFill =
      value.tabFill ||
      (settings.tabsAsImage &&
        (image ? 'image' : logo ? 'logo' : screen ? 'screen' : 'color')) ||
      'color'

    function getLinkHost() {
      const domainZones = ['com', 'net', 'org']
      const { domainHostname } = domains[value.idDomain]

      let linkHostLength = domainHostname.length
      let urlParts = domainHostname.split('.')
      let urlPartsLength = domainHostname.split('.')
      let urlShortParts = domainHostname.split('.')

      if (linkHostLength > 18) {
        do {
          let newUrlShortParts = urlShortParts.slice()
          newUrlShortParts.sort(function (a, b) {
            return b.length - a.length
          })
          if (newUrlShortParts[0].length < 5) {
            break
          }
          for (let i = 0; i < urlShortParts.length; i++) {
            if (urlShortParts[i] === newUrlShortParts[0]) {
              urlShortParts[i] =
                urlShortParts[i].slice(0, urlShortParts[i].length - 3) + '..'
            }
          }
        } while (urlShortParts.join('.').length > 18)
      }

      urlPartsLength.sort(function (a, b) {
        return b.length - a.length
      })
      let strong = true
      for (let i = 0; i < urlParts.length; i++) {
        if (
          urlParts[i] === urlPartsLength[0] &&
          i != urlParts.length - 1 &&
          urlParts[i].length > 2 &&
          domainZones.includes(urlParts[i]) === false
        ) {
          urlParts[i] = (
            <b key={'linkHost' + value.id + i}>{urlShortParts[i]}.</b>
          )
          strong = false
        } else {
          if (i != urlParts.length - 1) {
            urlParts[i] = urlShortParts[i] + '.'
          }
        }
      }

      if (strong) {
        urlParts[0] = <b key={'linkHost' + value.id}>{urlParts[0]}</b>
      }

      return urlParts
    }

    if (tabFill === 'color') {
      return (
        <span
          className="background"
          style={{
            backgroundColor: background,
          }}
        >
          <span
            className="name"
            style={{
              color: color,
              fontSize: styleData.tabFontSize * 0.41 + 'px',
            }}
          >
            {linkHost}
          </span>
        </span>
      )
    }

    if (tabFill === 'screen') {
      if (imageError)
        return (
          <span
            className="background"
            style={{
              backgroundColor: background,
            }}
          >
            <span
              className="name"
              style={{
                color: color,
                fontSize: styleData.tabFontSize * 0.41 + 'px',
              }}
            >
              {linkHost}
            </span>
          </span>
        )
      if (screen === 'downloadFile') {
        return (
          <span
            className="background"
            style={{
              backgroundColor: '#ffffff',
            }}
          >
            <span
              className="name"
              style={{
                color: '#0364c1',
                fontSize: styleData.tabFontSize * 1.6 + 'px',
              }}
            >
              <i className="bx bxs-file-import"></i>
            </span>
          </span>
        )
      }
      return (
        <img
          src={screen}
          style={{
            backgroundColor: background,
          }}
          onError={() => setImageError(true)}
        />
      )
    }

    if (tabFill === 'logo') {
      if (imageError)
        return (
          <span
            className="background"
            style={{
              backgroundColor: background,
            }}
          >
            <span
              className="name"
              style={{
                color: color,
                fontSize: styleData.tabFontSize * 0.41 + 'px',
              }}
            >
              {linkHost}
            </span>
          </span>
        )
      return (
        <img
          src={logo}
          style={{
            backgroundColor: background,
          }}
          onError={() => setImageError(true)}
        />
      )
    }

    if (tabFill === 'image') {
      if (image) {
        if (imageError)
          return (
            <span
              className="background"
              style={{
                backgroundColor: background,
              }}
            >
              <span
                className="name"
                style={{
                  color: color,
                  fontSize: styleData.tabFontSize * 0.41 + 'px',
                }}
              >
                {linkHost}
              </span>
            </span>
          )
        return (
          <img
            src={image}
            style={{
              backgroundColor: background,
            }}
            onError={() => setImageError(true)}
          />
        )
      }
      return (
        <span
          className="background"
          style={{
            backgroundColor: background,
          }}
        >
          <span
            className="name"
            style={{
              color: color,
              fontSize: styleData.tabFontSize * 0.41 + 'px',
            }}
          >
            {linkHost}
          </span>
        </span>
      )
    }

    return (
      <span
        className="background"
        style={{
          backgroundColor: background,
        }}
      >
        <span
          className="name"
          style={{
            color: color,
            fontSize: styleData.tabFontSize * 0.41 + 'px',
          }}
        >
          {linkHost}
        </span>
      </span>
    )
  }

  if (task.tasks === undefined) return null

  return Object.keys(task.tasks)
    .filter((key) => key <= 4)
    .map((key) => (
      <span className="folderTab" key={'folder' + key}>
        {getFolderContent(task.tasks[key])}
      </span>
    ))
}
