const initialList = [
  'bx bx-bookmarks',
  'bx bxs-bookmarks',
  'bx bx-bookmark-heart',
  'bx bxs-bookmark-heart',
  'bx bx-bookmark',
  'bx bxs-bookmark',
  'bx bx-bookmark-plus',
  'bx bxs-bookmark-plus',
  'bx bx-star',
  'bx bxs-star',
  'bx bx-meteor',
  'bx bxs-meteor',
  'bx bx-heart',
  'bx bxs-heart',
  'bx bx-heart-circle',
  'bx bxs-heart-circle',
  'bx bx-folder',
  'bx bxs-folder',
  'bx bx-folder-open',
  'bx bxs-folder-open',
  'bx bx-box',
  'bx bxs-box',
  'bx bx-archive',
  'bx bxs-archive',
  'bx bx-archive-in',
  'bx bxs-archive-in',
  'bx bx-memory-card',
  'bx bxs-memory-card',
  'bx bx-chat',
  'bx bxs-chat',
  'bx bx-wallet-alt',
  'bx bxs-wallet-alt',
  'bx bx-edit-alt',
  'bx bxs-edit-alt',
  'bx bx-notepad',
  'bx bxs-notepad',
  'bx bx-ghost',
  'bx bxs-ghost',
  'bx bx-lock-alt',
  'bx bxs-lock-alt',
  'bx bx-lock-open-alt',
  'bx bxs-lock-open-alt',
  'bx bx-smile',
  'bx bxs-smile',
  'bx bx-spa',
  'bx bxs-spa',
  'bx bx-home',
  'bx bxs-home',
  'bx bx-note',
  'bx bxs-note',
  'bx bx-cart',
  'bx bxs-cart',
  'bx bx-palette',
  'bx bxs-palette',
  'bx bx-movie-play',
  'bx bxs-movie-play',
  'bx bx-video-recording',
  'bx bxs-video-recording',
  'bx bx-joystick',
  'bx bxs-joystick',
  'bx bx-customize',
  'bx bxs-customize',
  'bx bx-extension',
  'bx bxs-extension',
  'bx bx-code',
  'bx bx-code-alt',
  'bx bx-git-branch',
  'bx bx-command',
]

export default initialList
