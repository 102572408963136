export function getContextHeight({ device, styleData, button }) {
  let titleHeight = 3 * styleData.vw
  let buttonHeight = 3.25 * styleData.vw
  let buttonHeightDesktop = 2.25 * styleData.vw
  let contextMargin = 2 * styleData.vw

  let contextHeight =
    window.innerHeight -
    titleHeight -
    2 * styleData.top -
    button * buttonHeightDesktop -
    2 * contextMargin

  if (device === 'mobile')
    contextHeight =
      window.innerHeight - titleHeight - button * buttonHeight - contextMargin

  return contextHeight
}

export function getWidth({ styleData, fullWidth }) {
  let width = styleData.modalWidthSmall
  if (fullWidth) width = styleData.modalWidthLarge
  return width
}

export function getClassName({ modalActiv, device, dark }) {
  let className = 'modalSmall'
  if (modalActiv) className += ' active'
  // if (device === 'mobile') className += ' mobile'
  if (dark) className += ' dark'
  return className
}

export function getBlackout({ device, modalActiv: modalSmall }) {
  let className = 'blackoutDesktop'
  // if (device === 'mobile') className = 'blackoutMobile zIndex'
  if (modalSmall) className += ' active'
  return className
}

export function getColor({ dark }) {
  let color = 'grey'
  if (dark) color += ' dark'
  return color
}

export function getButton({ button, device, mobileClose }) {
  if (mobileClose) {
    if (device === 'mobile') button += 1
  }
  return button
}

export function getClassLink({ device }) {
  let classLink = 'link'
  if (device === 'mobile') classLink = 'link mobile'
  return classLink
}

export function checkRequired({ value, device, id, afterFocus }) {
  if (value) {
    document.getElementById(id + '_label').className = ''
    document.getElementById(id + '_error').className = 'error'
    document.getElementById(id + '_error').innerHTML = ''

    return true
  } else {
    document.getElementById(id + '_label').className = 'labelUp'
    document.getElementById(id + '_error').className = 'error active'
    document.getElementById(id + '_error').innerHTML = 'required'
    if (afterFocus && device === 'desktop') {
      if (document.getElementById(id) != null) {
        document.getElementById(id).focus()
      }
    }

    return false
  }
}

export function checkEmail({ email, id, device, afterFocus }) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (re.test(String(email).toLowerCase())) {
    document.getElementById(id + '_label').className = ''
    document.getElementById(id + '_error').className = 'error'
    document.getElementById(id + '_error').innerHTML = ''

    return true
  } else {
    document.getElementById(id + '_label').className = 'labelUp'
    document.getElementById(id + '_error').className = 'error active'
    document.getElementById(id + '_error').innerHTML = 'email is not valid'

    if (afterFocus && device === 'desktop') {
      if (document.getElementById(id) != null) {
        document.getElementById(id).focus()
      }
    }
    return false
  }
}

export function checkPassword({ password, id, device, afterFocus }) {
  let valid
  const re = /\s/
  if (password.length < 6) {
    valid = false
  } else if (re.test(password)) {
    valid = false
  } else {
    valid = true
  }

  if (valid) {
    document.getElementById(id + '_label').className = ''
    document.getElementById(id + '_error').className = 'error'
    document.getElementById(id + '_error').innerHTML = ''

    return true
  } else {
    document.getElementById(id + '_label').className = 'labelUp'
    document.getElementById(id + '_error').className = 'error active'
    document.getElementById(id + '_error').innerHTML = 'min. 6 symbols'
    if (afterFocus && device === 'desktop') {
      if (document.getElementById(id) != null) {
        document.getElementById(id).focus()
      }
    }

    return false
  }
}

export function checkPasswordMatch({
  password,
  passwordValid,
  id,
  confirmPassword,
  confirmPasswordValid,
  id2,
}) {
  if (!passwordValid && !confirmPasswordValid) {
    return
  }
  if (password === confirmPassword) {
    document.getElementById(id + '_label').className = ''
    document.getElementById(id + '_error').className = 'error'
    document.getElementById(id + '_error').innerHTML = ''

    document.getElementById(id2 + '_label').className = ''
    document.getElementById(id2 + '_error').className = 'error'
    document.getElementById(id2 + '_error').innerHTML = ''

    return true
  } else {
    document.getElementById(id + '_label').className = 'labelUp'
    document.getElementById(id + '_error').className = 'error active'
    document.getElementById(id + '_error').innerHTML = 'Passwords must match'

    document.getElementById(id2 + '_label').className = 'labelUp'
    document.getElementById(id2 + '_error').className = 'error active'
    document.getElementById(id2 + '_error').innerHTML = 'Passwords must match'

    return false
  }
}

export function setError(value, value2, device, focus) {
  document.getElementById(value).style.color = 'red'
  document.getElementById(value).innerHTML =
    document.getElementById(value).innerHTML + ' *'

  if (focus && device === 'desktop') {
    if (document.getElementById(value2) != null) {
      document.getElementById(value2).focus()
    }
  }
}

export function clearError(value) {
  if (document.getElementById(value + '_label') != null) {
    document.getElementById(value + '_label').className = ''
  }
  if (document.getElementById(value + '_error') != null) {
    document.getElementById(value + '_error').className = 'error'
  }
}

export function getFocus(value, setModal) {
  if (document.getElementById(value) != null) {
    document.getElementById(value).addEventListener('focus', () => {
      setModal(false)
    })
    document.getElementById(value).addEventListener('blur', () => {
      setModal(true)
    })
  }
}
