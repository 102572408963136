import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useData } from '../../context/DataContext'
import { Container } from './DragFolder.styled'
import Drag from '../tabs/Drag'
import initialLanguage from '../../language/initialLanguage'
import withLocalForage from '../../context/withLocalForage'

const FolderModal = ({
  task,
  active,
  setActive,
  searchRestore,
  buildFolder,
}) => {
  const { currentUser } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`
  const { global, activeState, setActiveState, space, lang, device } = useData()
  const { settings, styleData } = global

  // useEffect(() => {
  //   setActiveState(task)
  // }, [task])

  useEffect(() => {
    if (!task) return

    const dragFolder = document.getElementById('dragFolder')
    if (dragFolder) dragFolder.style.opacity = 0

    setTimeout(() => {
      const images = document.querySelectorAll('.imgLoad')
      if (!images) return
      const handleLoad = () => {
        if (dragFolder) {
          dragFolder.style.opacity = 1
        }
      }
      const promises = Array.from(images).map((img) => {
        return new Promise((resolve) => {
          if (img.complete) {
            resolve()
          } else {
            img.addEventListener('load', resolve)
          }
        })
      })
      Promise.all(promises)
        .then(handleLoad)
        .catch((dragFolder.style.opacity = 1))
    }, 10)
  }, [task])

  useEffect(() => {
    if (!task.hasOwnProperty('columnOrder')) {
      buildFolder(path, task, settings)
    }
  }, [task])

  const globalFolder = task.hasOwnProperty('columnOrder') ? task : null

  const [activeDelay, setActiveDelay] = useState(false)

  const title =
    '(' +
    Object.keys(task.tasks).length +
    ')' +
    ' ' +
    (task.ownTitle || 'New Folder')

  const shotTitle =
    title.length > Math.floor(styleData.width / 16)
      ? title.slice(0, Math.floor(styleData.width / 16)) + '...'
      : title

  const styledFolder = getStyledFolder()

  useEffect(() => {
    setTimeout(() => {
      let folderOpen = document.querySelector('.folderOpen')
      if (folderOpen) folderOpen.style.scale = 'none'
      setActiveDelay(active)
    }, 1)
  }, [active])
  let modalActiv = activeDelay

  useEffect(() => {
    if (!activeState?.id) {
      let folderOpen = document.querySelector('.folderOpen')
      if (folderOpen) folderOpen.style.scale = '0.5'
      setActiveDelay(false)
      setTimeout(() => {
        setActive(false)
      }, 400)
    }
  }, [activeState])

  async function handleClose(e) {
    e.preventDefault()

    let folderOpen = document.querySelector('.folderOpen')
    if (folderOpen) folderOpen.style.scale = '0.5'

    setActiveDelay(false)
    setActiveState(global.spaces[space])

    searchRestore(path, true, true)

    setTimeout(() => {
      setActive(false)
    }, 400)

    // setDrag(true)
    // setAddButton(true)
  }

  function getBlackout() {
    let className = 'blackoutDesktopFolder'
    // if (device === 'mobile') className = 'blackoutMobile'
    if (modalActiv) className += ' active'
    return className
  }

  function getClassName() {
    let className = 'folderOpen'
    if (modalActiv) className += ' active'
    // if (device === 'mobile') className += ' mobile'
    if (settings.styleTheme) className += ' dark'
    return className
  }

  function getStyledFolder() {
    let vw = calculateVW()
    let contentHeight = calculateContentHeight()
    let folderHeight = contentHeight + 5 * vw
    let folderTop = calculateFolderTop()

    function calculateVW() {
      let vw = window.innerWidth / 100
      if (vw < 20) vw = 20
      return vw
    }

    function calculateContentHeight() {
      let row = Math.ceil(Object.keys(task.tasks).length / settings.tabsInRow)

      if (Object.keys(task.tasks).length % settings.tabsInRow === 0) {
        row = row + 1
      }

      let innerHeight = window.innerHeight
      let fullHeight = styleData.top * 2 + 5 * vw

      let contentHeight = innerHeight - fullHeight
      if (row > 1) {
        if (contentHeight < 1.5 * styleData.tabFullHeight) {
          contentHeight = 1.5 * styleData.tabFullHeight
        }
      }

      if (contentHeight > row * styleData.tabFullHeight) {
        contentHeight = row * styleData.tabFullHeight
      }

      return contentHeight
    }

    function calculateFolderTop() {
      let innerHeight = window.innerHeight

      let top = (innerHeight - folderHeight) / 2
      if (top < styleData.top) {
        top = styleData.top
      }
      return top
    }
    return { vw, contentHeight, folderHeight, folderTop }
  }

  // useEffect(() => {
  //   const handleLoadFolder = () => {
  //     const dragFolder = document.getElementById('dragFolder')
  //     if (dragFolder) {
  //       dragFolder.style.opacity = 1
  //     }
  //   }
  //   window.addEventListener('load', handleLoadFolder)
  //   return () => window.removeEventListener('load', handleLoadFolder)
  // }, [])

  return (
    <div className={getBlackout()} onClick={modalActiv ? handleClose : null}>
      <Container
        styleData={styleData}
        styledFolder={styledFolder}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={getClassName()}>
          <div className="title">
            <div className="back" onClick={handleClose} />
            <div className="header">
              {device == 'desktop' ? shotTitle : null}
            </div>
            <div className="close" onClick={handleClose}></div>
          </div>
          <div className="contentFolder">
            {globalFolder ? (
              <Drag dragData={globalFolder} id={'dragFolder'} />
            ) : null}
          </div>
          <div className="footer" />
        </div>
      </Container>
    </div>
  )
}

export default withLocalForage(FolderModal)
