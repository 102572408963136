import { database } from '../firebase'

export default function PublicOwnSet({ uid }) {
  return new Promise((resolve, reject) => {
    database.space
      .doc(uid)
      .set({
        name: '',
        nickname: '',
        description: '',
        tasks: {},
      })
      .then(resolve('Done'))
  })
}
