import React from 'react'
import { useData } from '../context/DataContext'
import './select.css'

export default function Select({ id, title, onChange, value, options }) {
  const { global } = useData()
  const { settings } = global

  let select = 'select'
  if (settings.styleTheme) select = select + ' dark'

  let searchOptions = []
  searchOptions.push(value)
  options.forEach((option) => {
    if (option !== value) {
      searchOptions.push(option)
    }
  })

  function handleSelect(e) {
    onChange(e.target.value)
  }

  return (
    <div className={select} id={id}>
      <section className="title">{title}</section>
      <select className="options" onChange={handleSelect}>
        {searchOptions.map((option) => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          )
        })}
      </select>
    </div>
  )
}
