import React, { useEffect } from 'react'
import initialState from './initialState'
import { Container } from './UploaderFile.styled'

export default function UploaderFile({
  id,
  handlerChange,
  styled,
  label,
  settings,
  children,
}) {
  if (!styled) styled = initialState.styled
  else {
    if (!styled.theme) styled.theme = initialState.styled.theme
    if (!styled.icon) styled.icon = initialState.styled.icon
    if (!styled.iconColor) styled.iconColor = initialState.styled.iconColor
    if (!styled.iconColorDark)
      styled.iconColorDark = initialState.styled.iconColorDark
    if (!styled.iconColorHover)
      styled.iconColorHover = initialState.styled.iconColorHover
    if (!styled.iconColorHoverDark)
      styled.iconColorHoverDark = initialState.styled.iconColorHoverDark
    if (!styled.textColor) styled.textColor = initialState.styled.textColor
    if (!styled.textColorDark)
      styled.textColorDark = initialState.styled.textColorDark
    if (!styled.textColorHover)
      styled.textColorHover = initialState.styled.textColorHover
    if (!styled.textColorHoverDark)
      styled.textColorHoverDark = initialState.styled.textColorHoverDark
    if (!styled.borderWidth)
      styled.borderWidth = initialState.styled.borderWidth
    if (!styled.borderStyle)
      styled.borderStyle = initialState.styled.borderStyle
    if (!styled.borderColor)
      styled.borderColor = initialState.styled.borderColor
    if (!styled.borderColorDark)
      styled.borderColorDark = initialState.styled.borderColorDark
    if (!styled.borderColorHover)
      styled.borderColorHover = initialState.styled.borderColorHover
    if (!styled.borderColorHoverDark)
      styled.borderColorHoverDark = initialState.styled.borderColorHoverDark
    if (!styled.backgroundColor)
      styled.backgroundColor = initialState.styled.backgroundColor
    if (!styled.backgroundColorDark)
      styled.backgroundColorDark = initialState.styled.backgroundColorDark
    if (!styled.backgroundColorHover)
      styled.backgroundColorHover = initialState.styled.backgroundColorHover
    if (!styled.backgroundColorHoverDark)
      styled.backgroundColorHoverDark =
        initialState.styled.backgroundColorHoverDark
    if (!styled.fontSize) styled.fontSize = initialState.styled.fontSize
    if (!styled.heightContainer)
      styled.heightContainer = initialState.styled.heightContainer
    if (!styled.widthTypesSizeLabel)
      styled.widthTypesSizeLabel = initialState.styled.widthTypesSizeLabel
  }

  if (!label) label = initialState.label
  else {
    if (!label.main) label.main = initialState.label.main
    if (!label.drag) label.drag = initialState.label.drag
    if (!label.success) label.success = initialState.label.success
    if (!label.errorMaxSize)
      label.errorMaxSize = initialState.label.errorMaxSize
    if (!label.errorMinSize)
      label.errorMinSize = initialState.label.errorMinSize
    if (!label.errorType) label.errorType = initialState.label.errorType
    if (!label.errorMultiple)
      label.errorMultiple = initialState.label.errorMultiple
  }

  if (!settings) settings = initialState.settings
  else {
    if (!settings.children) settings.children = initialState.settings.children
    if (!settings.multiple) settings.multiple = initialState.settings.multiple
    if (!settings.types) settings.types = initialState.settings.types
    if (!settings.typesLabel)
      settings.typesLabel = initialState.settings.typesLabel
    if (!settings.maxSize) settings.maxSize = initialState.settings.maxSize
    if (!settings.minSize) settings.minSize = initialState.settings.minSize
  }

  useEffect(() => {
    if (!settings.children) {
      let element = document.getElementById('labelFilesUploader' + id)
      if (element) element.innerHTML = label.main
    }
  }, [])

  function dragStartHandler(e) {
    e.preventDefault()
    let element = document.getElementById('labelFilesUploader' + id)
    if (!settings.multiple && e.dataTransfer.items.length > 1) {
      if (element) element.innerHTML = label.errorMultiple
      return
    }
    if (element) element.innerHTML = label.drag

    let el = document.getElementById('filesUploader-' + id)
    if (el) el.classList.add('onDrag')
  }

  function dragLeaveHandler(e) {
    e.preventDefault()
    let element = document.getElementById('labelFilesUploader' + id)
    if (element) element.innerHTML = label.main

    let el = document.getElementById('filesUploader-' + id)
    if (el) el.classList.remove('onDrag')
  }

  function onDropHandler(e) {
    e.preventDefault()
    let files = [...e.dataTransfer.files]
    if (!settings.multiple && files.length > 1) {
      setTimeout(() => {
        let element = document.getElementById('labelFilesUploader' + id)
        if (element) element.innerHTML = label.main
      }, 2000)
      return
    }

    filesHandler(files)

    let el = document.getElementById('filesUploader-' + id)
    if (el) el.classList.remove('onDrag')
  }

  function onInputHandler(e) {
    e.preventDefault()
    let files = [...e.target.files]
    filesHandler(files)
  }

  function filesHandler(files) {
    let fileTypes = settings.types

    if (files.length > 1) {
      files.map((file) => {
        if (!fileTypes.includes(file.type)) {
          let element = document.getElementById('labelFilesUploader' + id)
          if (element) element.innerHTML = label.errorType

          setTimeout(() => {
            if (element) element.innerHTML = label.main
          }, 2000)

          return
        }

        if (file.size > settings.maxSize * 1000000) {
          let element = document.getElementById('labelFilesUploader' + id)
          if (element) element.innerHTML = label.errorMaxSize

          setTimeout(() => {
            if (element) element.innerHTML = label.main
          }, 2000)

          return
        }

        if (file.size < settings.minSize * 1000000) {
          let element = document.getElementById('labelFilesUploader' + id)
          if (element) element.innerHTML = label.errorMinSize

          setTimeout(() => {
            if (element) element.innerHTML = label.main
          }, 2000)

          return
        }
      })
    } else {
      if (!fileTypes.includes(files[0].type)) {
        let element = document.getElementById('labelFilesUploader' + id)
        if (element) element.innerHTML = label.errorType

        setTimeout(() => {
          if (element) element.innerHTML = label.main
        }, 2000)

        return
      }
      if (files[0].size > settings.maxSize * 1000000) {
        let element = document.getElementById('labelFilesUploader' + id)
        if (element) element.innerHTML = label.errorMaxSize

        setTimeout(() => {
          if (element) element.innerHTML = label.main
        }, 2000)

        return
      }

      if (files[0].size < settings.minSize * 1000000) {
        let element = document.getElementById('labelFilesUploader' + id)
        if (element) element.innerHTML = label.errorMinSize

        setTimeout(() => {
          if (element) element.innerHTML = label.main
        }, 2000)

        return
      }
    }

    handlerChange(files)

    let element = document.getElementById('labelFilesUploader' + id)

    if (element) element.innerHTML = label.success
    setTimeout(() => {
      if (element) element.innerHTML = label.main
    }, 2000)
  }

  if (settings.children) {
    return (
      <Container
        id={'filesUploader'}
        onDragStart={(e) => dragStartHandler(e)}
        onDragLeave={(e) => dragLeaveHandler(e)}
        onDragOver={(e) => dragStartHandler(e)}
        onDrop={(e) => onDropHandler(e)}
        styled={styled}
      >
        {children}
      </Container>
    )
  }

  return (
    <Container id={id} styled={styled}>
      <div
        id={'filesUploader-' + id}
        className={styled.theme ? 'filesUploader dark' : 'filesUploader'}
      >
        <div
          className="drag"
          onDragStart={(e) => dragStartHandler(e)}
          onDragLeave={(e) => dragLeaveHandler(e)}
          onDragOver={(e) => dragStartHandler(e)}
          onDrop={(e) => onDropHandler(e)}
          onClick={(e) => document.getElementById('file').click()}
          title="Upload or drop an image right here"
        >
          <div className="icon">
            <i className="bx bx-image-add"></i>
          </div>
          <div className="label" title="Upload or drop an image right here">
            <p id={'labelFilesUploader' + id}></p>
          </div>
          <div className="types">
            {settings.typesLabel} &#8804; {settings.maxSize}Mb
          </div>
        </div>
        <input
          type="file"
          id="file"
          onChange={(e) => {
            onInputHandler(e)
          }}
          multiple={settings.multiple}
        />
      </div>
    </Container>
  )
}
