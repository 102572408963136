import React from 'react'
import { useAuth } from '../context/AuthContext'
import { Container } from './Gallery.styled'
import withLocalForage from '../context/withLocalForage'

const Gallery = ({ updateBackground }) => {
  const { currentUser } = useAuth()
  let path = `background${currentUser ? currentUser.uid : ''}`

  const galleryList = [
    '1682433271021_dflt.webp',
    '1682431364148_aeez.webp',
    '1682431436985_yshl.webp',
    '1682431537923_qfwg.webp',
    '1682431592787_jaye.webp',
    '1682431622973_snua.webp',
    '1682431637659_uxjw.webp',
    '1682431651560_xnbz.webp',
    '1682431697772_kkiy.webp',
    '1682431709723_fcvi.webp',
    '1682431741885_pffe.webp',
    '1682431776093_wehz.webp',
    '1682431788223_priu.webp',
    '1682431811652_bgtd.webp',
    '1682432084545_mmum.webp',
    '1682431825294_tvpk.webp',
    '1682431873714_vkpo.webp',
    '1682431906450_bglk.webp',
    '1682431934016_vhrs.webp',
    '1682431958188_wtqy.webp',
    '1682431971944_fxqx.webp',
    '1682432045187_rzow.webp',
    '1682432102558_mpms.webp',
    '1682432123699_nvdd.webp',
    '1682432140258_vjdx.webp',
    '1682432152008_xcvf.webp',
    '1682432166369_iaiw.webp',
    '1682432181260_csde.webp',
    '1682432194701_hnhk.webp',
    '1682432210714_xlab.webp',
    '1682432223079_cvjk.webp',
    '1682432246176_srvv.webp',
    '1682432708380_xnwv.webp',
    '1682432258223_urnd.webp',
    '1682432272060_zwym.webp',
    '1682432282656_tqzc.webp',
    '1682432294957_gzkb.webp',
    '1682432320215_rbun.webp',
    '1682432358230_gexo.webp',
    '1682432369120_gcvf.webp',
    '1682432418120_gejc.webp',
    '1682432429601_payu.webp',
    '1682432476677_gkik.webp',
    '1682432504491_mvoo.webp',
    '1682432517163_jeax.webp',
    '1682432540790_seau.webp',
    '1682432551844_tnda.webp',
    '1682432562670_aobn.webp',
    '1682432574700_ukny.webp',
    '1682432589654_jukv.webp',
    '1682432601138_wnqf.webp',
    '1682432612905_rqre.webp',
    '1682432887836_olqj.webp',
    '1682432632922_abng.webp',
    '1682432648531_suxj.webp',
    '1682432672306_qfve.webp',
    '1682432683538_zoal.webp',
    '1682432694703_xczk.webp',
    '1682432724188_estg.webp',
    '1682432738885_mbey.webp',
    '1682432750348_cwkb.webp',
    '1682432763596_efst.webp',
    '1682432774741_noqq.webp',
    '1682432788763_bwmj.webp',
    '1682432798794_ixzp.webp',
    '1682432809807_sovt.webp',
    '1682432913200_jhxn.webp',
    '1682432925606_uwej.webp',
    '1682432945617_fbtp.webp',
    '1682432958837_emys.webp',
    '1682432970706_dfrp.webp',
    '1682432982360_vozm.webp',
    '1682433001094_nnns.webp',
    '1682433015066_xtsb.webp',
  ]

  const handleClick = (e) => {
    e.preventDefault()
    if (e.target.src === undefined) return
    const image = e.target.src.split('/preview/').pop()
    updateBackground(path, image, 'image')
  }

  return (
    <Container>
      <div className="container">
        {galleryList.map((image, index) => (
          <div className="gallery" key={index} onClick={handleClick}>
            <img
              src={`https://bucket.titletab.com/background/preview/${image}`}
              alt="gallery image"
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </Container>
  )
}

export default withLocalForage(Gallery)
