const initialColors = {
  colors: {
    //green/white
    1: { id: '1', bg: '#336633', clr: '#ffffff' },
    2: { id: '2', bg: '#20805e', clr: '#ffffff' },
    3: { id: '3', bg: '#3a833d', clr: '#ffffff' },
    4: { id: '4', bg: '#2d9b27', clr: '#ffffff' },
    5: { id: '5', bg: '#3ac71e', clr: '#ffffff' },
    6: { id: '6', bg: '#38af83', clr: '#ffffff' },
    7: { id: '7', bg: '#00ab6f', clr: '#ffffff' },
    8: { id: '8', bg: '#66cc66', clr: '#ffffff' },
    9: { id: '9', bg: '#22afa3', clr: '#ffffff' },
    10: { id: '10', bg: '#35cd9a', clr: '#ffffff' },
    11: { id: '11', bg: '#3cb26f', clr: '#ffffff' },
    12: { id: '12', bg: '#0adc4e', clr: '#ffffff' },
    13: { id: '13', bg: '#01796e', clr: '#ffffff' },
    14: { id: '14', bg: '#053019', clr: '#ffffff' },
    15: { id: '15', bg: '#6a8e22', clr: '#ffffff' },
    16: { id: '16', bg: '#7f8000', clr: '#ffffff' },
    17: { id: '17', bg: '#78a15a', clr: '#ffffff' },
    18: { id: '18', bg: '#77846c', clr: '#ffffff' },
    19: { id: '19', bg: '#bdb76b', clr: '#ffffff' },
    20: { id: '20', bg: '#8cb403', clr: '#ffffff' },
    //white/green
    21: { id: '21', bg: '#ffffff', clr: '#20805e' },
    22: { id: '22', bg: '#ffffff', clr: '#2d9b27' },
    23: { id: '23', bg: '#ffffff', clr: '#3ac71e' },
    24: { id: '24', bg: '#ffffff', clr: '#38af83' },
    25: { id: '25', bg: '#ffffff', clr: '#22afa3' },
    26: { id: '26', bg: '#ffffff', clr: '#35cd9a' },
    27: { id: '27', bg: '#ffffff', clr: '#3cb26f' },
    28: { id: '28', bg: '#ffffff', clr: '#6a8e22' },
    29: { id: '29', bg: '#ffffff', clr: '#78a15a' },
    30: { id: '30', bg: '#ffffff', clr: '#8cb403' },
    //blue/white
    31: { id: '31', bg: '#04396c', clr: '#ffffff' },
    32: { id: '32', bg: '#0f5490', clr: '#ffffff' },
    33: { id: '33', bg: '#3366cc', clr: '#ffffff' },
    34: { id: '34', bg: '#0c5aa6', clr: '#ffffff' },
    35: { id: '35', bg: '#2000aa', clr: '#ffffff' },
    36: { id: '36', bg: '#2f47eb', clr: '#ffffff' },
    37: { id: '37', bg: '#2595d4', clr: '#ffffff' },
    38: { id: '38', bg: '#0bcae1', clr: '#ffffff' },
    39: { id: '39', bg: '#013298', clr: '#ffffff' },
    40: { id: '40', bg: '#4882b4', clr: '#ffffff' },
    41: { id: '41', bg: '#3a75c5', clr: '#ffffff' },
    42: { id: '42', bg: '#2175fc', clr: '#ffffff' },
    43: { id: '43', bg: '#266df0', clr: '#ffffff' },
    44: { id: '44', bg: '#5171f0', clr: '#ffffff' },
    45: { id: '45', bg: '#2d24f0', clr: '#ffffff' },
    46: { id: '46', bg: '#771af0', clr: '#ffffff' },
    47: { id: '47', bg: '#5d87f0', clr: '#ffffff' },
    48: { id: '48', bg: '#8dd1f0', clr: '#ffffff' },
    49: { id: '49', bg: '#5d9bf0', clr: '#ffffff' },
    50: { id: '50', bg: '#2278f0', clr: '#ffffff' },
    //white/blue
    51: { id: '51', bg: '#ffffff', clr: '#0f5490' },
    52: { id: '52', bg: '#ffffff', clr: '#3366cc' },
    53: { id: '53', bg: '#ffffff', clr: '#2f47eb' },
    54: { id: '54', bg: '#ffffff', clr: '#2595d4' },
    55: { id: '55', bg: '#ffffff', clr: '#0bcae1' },
    56: { id: '56', bg: '#ffffff', clr: '#4882b4' },
    57: { id: '57', bg: '#ffffff', clr: '#3a75c5' },
    58: { id: '58', bg: '#ffffff', clr: '#2175fc' },
    59: { id: '59', bg: '#ffffff', clr: '#2d24f0' },
    60: { id: '60', bg: '#ffffff', clr: '#5d9bf0' },
    //purple/white
    61: { id: '61', bg: '#522f8c', clr: '#ffffff' },
    62: { id: '62', bg: '#8729c3', clr: '#ffffff' },
    63: { id: '63', bg: '#7a0be1', clr: '#ffffff' },
    64: { id: '64', bg: '#bc02fa', clr: '#ffffff' },
    65: { id: '65', bg: '#b320cd', clr: '#ffffff' },
    66: { id: '66', bg: '#550062', clr: '#ffffff' },
    67: { id: '67', bg: '#740182', clr: '#ffffff' },
    68: { id: '68', bg: '#900298', clr: '#ffffff' },
    69: { id: '69', bg: '#994d98', clr: '#ffffff' },
    70: { id: '70', bg: '#772f98', clr: '#ffffff' },
    71: { id: '71', bg: '#590292', clr: '#ffffff' },
    72: { id: '72', bg: '#35025f', clr: '#ffffff' },
    73: { id: '73', bg: '#9d0e9c', clr: '#ffffff' },
    74: { id: '74', bg: '#bd32bd', clr: '#ffffff' },
    75: { id: '75', bg: '#8729c3', clr: '#ffffff' },
    76: { id: '76', bg: '#7a0be1', clr: '#ffffff' },
    77: { id: '77', bg: '#b257fd', clr: '#ffffff' },
    78: { id: '78', bg: '#470186', clr: '#ffffff' },
    79: { id: '79', bg: '#cb02c9', clr: '#ffffff' },
    80: { id: '80', bg: '#e460f1', clr: '#ffffff' },
    //white/purple
    81: { id: '81', bg: '#ffffff', clr: '#8729c3' },
    82: { id: '82', bg: '#ffffff', clr: '#7a0be1' },
    83: { id: '83', bg: '#ffffff', clr: '#bc02fa' },
    84: { id: '84', bg: '#ffffff', clr: '#b320cd' },
    85: { id: '85', bg: '#ffffff', clr: '#900298' },
    86: { id: '86', bg: '#ffffff', clr: '#994d98' },
    87: { id: '87', bg: '#ffffff', clr: '#9d0e9c' },
    88: { id: '88', bg: '#ffffff', clr: '#b257fd' },
    89: { id: '89', bg: '#ffffff', clr: '#cb02c9' },
    90: { id: '90', bg: '#ffffff', clr: '#e460f1' },
    //yellow/white
    91: { id: '91', bg: '#f17d03', clr: '#ffffff' },
    92: { id: '92', bg: '#bf6f30', clr: '#ffffff' },
    93: { id: '93', bg: '#ff7e40', clr: '#ffffff' },
    94: { id: '94', bg: '#ff9933', clr: '#ffffff' },
    95: { id: '95', bg: '#e1c50b', clr: '#ffffff' },
    96: { id: '96', bg: '#f0d102', clr: '#ffffff' },
    97: { id: '97', bg: '#e79c25', clr: '#ffffff' },
    98: { id: '98', bg: '#ffa042', clr: '#ffffff' },
    99: { id: '99', bg: '#ff9b65', clr: '#ffffff' },
    100: { id: '100', bg: '#fda503', clr: '#ffffff' },
    101: { id: '101', bg: '#e97e5a', clr: '#ffffff' },
    102: { id: '102', bg: '#fa7611', clr: '#ffffff' },
    103: { id: '103', bg: '#945d0b', clr: '#ffffff' },
    104: { id: '104', bg: '#b48865', clr: '#ffffff' },
    105: { id: '105', bg: '#5a3d2d', clr: '#ffffff' },
    106: { id: '106', bg: '#c58800', clr: '#ffffff' },
    107: { id: '107', bg: '#fbb736', clr: '#ffffff' },
    108: { id: '108', bg: '#ffc847', clr: '#ffffff' },
    109: { id: '109', bg: '#ffde00', clr: '#ffffff' },
    110: { id: '110', bg: '#bea500', clr: '#ffffff' },
    //white/yellow
    111: { id: '111', bg: '#ffffff', clr: '#f17d03' },
    112: { id: '112', bg: '#ffffff', clr: '#bf6f30' },
    113: { id: '113', bg: '#ffffff', clr: '#ff9933' },
    114: { id: '114', bg: '#ffffff', clr: '#e1c50b' },
    115: { id: '115', bg: '#ffffff', clr: '#e79c25' },
    116: { id: '116', bg: '#ffffff', clr: '#ffa042' },
    117: { id: '117', bg: '#ffffff', clr: '#e97e5a' },
    118: { id: '118', bg: '#ffffff', clr: '#fa7611' },
    119: { id: '119', bg: '#ffffff', clr: '#fbb736' },
    120: { id: '120', bg: '#ffffff', clr: '#ffc847' },
    //red/white
    121: { id: '121', bg: '#ff3366', clr: '#ffffff' },
    122: { id: '122', bg: '#fd0006', clr: '#ffffff' },
    123: { id: '123', bg: '#a63600', clr: '#ffffff' },
    124: { id: '124', bg: '#cc3366', clr: '#ffffff' },
    125: { id: '125', bg: '#e14927', clr: '#ffffff' },
    126: { id: '126', bg: '#d71e1e', clr: '#ffffff' },
    127: { id: '127', bg: '#cf2a42', clr: '#ffffff' },
    128: { id: '128', bg: '#e10b84', clr: '#ffffff' },
    129: { id: '129', bg: '#fa02c0', clr: '#ffffff' },
    130: { id: '130', bg: '#ff66cc', clr: '#ffffff' },
    131: { id: '131', bg: '#e22028', clr: '#ffffff' },
    132: { id: '132', bg: '#e86026', clr: '#ffffff' },
    133: { id: '133', bg: '#b03c6d', clr: '#ffffff' },
    134: { id: '134', bg: '#e35a88', clr: '#ffffff' },
    135: { id: '135', bg: '#e85c43', clr: '#ffffff' },
    136: { id: '136', bg: '#ef7e46', clr: '#ffffff' },
    137: { id: '137', bg: '#f49c6e', clr: '#ffffff' },
    138: { id: '138', bg: '#ee846a', clr: '#ffffff' },
    139: { id: '139', bg: '#e66f95', clr: '#ffffff' },
    140: { id: '140', bg: '#ec97ac', clr: '#ffffff' },
    //white/red
    141: { id: '141', bg: '#ffffff', clr: '#fd0006' },
    142: { id: '142', bg: '#ffffff', clr: '#cc3366' },
    143: { id: '143', bg: '#ffffff', clr: '#e10b84' },
    144: { id: '144', bg: '#ffffff', clr: '#fa02c0' },
    145: { id: '145', bg: '#ffffff', clr: '#e22028' },
    146: { id: '146', bg: '#ffffff', clr: '#e86026' },
    147: { id: '147', bg: '#ffffff', clr: '#b03c6d' },
    148: { id: '148', bg: '#ffffff', clr: '#e35a88' },
    149: { id: '149', bg: '#ffffff', clr: '#e85c43' },
    150: { id: '150', bg: '#ffffff', clr: '#ee846a' },
  },
}

export default initialColors
