import React from 'react'
import { Container } from './Palette.styled'

export default function Palette({ text, setText, label, id, dark }) {
  return (
    <Container>
      <section
        className={dark ? 'palette dark' : 'palette'}
        id={'color-' + text}
      >
        <input
          type="color"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <input
          id={id}
          type="text"
          value={text}
          autoComplete="off"
          onChange={(e) => setText(e.target.value)}
        />
        <span className="bar"></span>
        {text ? (
          <label className="activ">{label}</label>
        ) : (
          <label>{label}</label>
        )}
      </section>
    </Container>
  )
}
