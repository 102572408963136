import styled from 'styled-components'

export const Container = styled.div`
  .component {
    width: 100%;
    position: relative;
    margin: 0 auto;
    margin-top: 1vw;
    margin-top: max(1vw, 30px);
    margin-bottom: 1vw;
    margin-bottom: max(1vw, 30px);
  }

  .component i {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0.15vw;
    padding: 0.25vw;
    font-size: 1.2vw;
    font-size: max(1.2vw, 24px);
    color: #ccc;
    cursor: pointer;
    transition: 0.5s ease all;
    background: #fff;
    border-radius: 0.2vw;
  }

  .component i:hover {
    color: #999;
    background: #f5f5f5;
  }

  .component.dark i {
    color: #999;
    background: #1e1b30;
  }

  .component.dark i:hover {
    color: #f5f5f5;
    background: #2e275c;
  }

  .component input {
    position: relative;
    font-size: 0.8vw;
    font-size: max(0.8vw, 16px);
    padding: 0.5vw;
    padding: max(0.5vw, 10px);
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-bottom: solid #ccc;
    border-bottom-width: 0.05vw;
    border-bottom-width: max(0.05vw, 1px);
    outline: none;
  }

  //style for readonly input
  .component input[readonly] {
    color: #777;
  }
  .component.dark input[readonly] {
    color: #777;
  }

  .component input:focus::placeholder {
    color: transparent;
  }

  .component.dark input {
    background: #1e1b30;
    color: #fff;
  }

  .component .error {
    position: absolute;
    top: -0.88vw;
    top: min(-0.88vw, -17px);
    right: 0;
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
    color: #f00;
    opacity: 0;
    transition: 0.5s ease all;
  }

  .component .error.active {
    opacity: 1;
    color: #f00;
  }

  .component label {
    color: #999;
    font-size: 0.9vw;
    font-size: max(0.9vw, 18px);
    position: absolute;
    pointer-events: none;
    left: 0.5vw;
    left: max(0.5vw, 10px);
    top: 0.4vw;
    top: max(0.4vw, 8px);
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  .component label.activ {
    top: -0.88vw;
    top: min(-0.88vw, -17px);
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
    color: #0364c1;
  }

  .component input:focus ~ label,
  .component input:required:valid ~ label {
    top: -0.88vw;
    top: min(-0.88vw, -17px);
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
    color: #0364c1;
  }

  .component.dark input:focus ~ label,
  .component.dark input:required:valid ~ label {
    color: #fff;
  }

  .component input::placeholder ~ label {
    top: -0.88vw;
    top: min(-0.88vw, -17px);
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
  }

  .component .labelUp {
    top: -0.88vw;
    top: min(-0.88vw, -17px);
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
    color: #0364c1;
  }

  .component.dark .labelUp {
    color: #fff;
  }

  //Стиль полосы Input
  /////////////////////////////////////////////////////////////////////////
  .component .bar {
    position: relative;
    display: block;
    width: 100%;
  }
  .component .bar:before,
  .component .bar:after {
    content: '';
    height: 0.1vw;
    min-height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: #0364c1;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .component .bar:before {
    left: 50%;
  }
  .component .bar:after {
    right: 50%;
  }

  .component input:focus ~ .bar:before,
  .component input:focus ~ .bar:after {
    width: 50%;
  }
  /////////////////////////////////////////////////////////////////////////

  //Цвет заполнения фона поля в случае если есть автозаполнение
  /////////////////////////////////////////////////////////////////////////
  .component input:-webkit-autofill,
  .component input:-webkit-autofill:hover,
  .component input:-webkit-autofill:focus,
  .component input:-webkit-autofill:active {
    -webkit-text-fill-color: #000 !important;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }

  .component.dark input:-webkit-autofill,
  .component.dark input:-webkit-autofill:hover,
  .component.dark input:-webkit-autofill:focus,
  .component.dark input:-webkit-autofill:active {
    -webkit-text-fill-color: #fff !important;
    -webkit-box-shadow: 0 0 0px 1000px #1e1b30 inset;
  }
  /////////////////////////////////////////////////////////////////////////
`
