import React from 'react'

export default function AboutPt() {
  return (
    <>
      <p>
        Bem-vindo ao TitleTab - Speed Dial Dashboard, a melhor solução de
        gerenciamento de guias projetada para tornar sua experiência de
        navegação mais rápida e conveniente do que nunca. Nossa solução oferece
        uma variedade de recursos que podem ajudá-lo a personalizar as guias do
        navegador, evitar o fechamento acidental do navegador e compartilhar
        seus links favoritos com outros usuários.
      </p>
      <p>Aqui estão alguns dos recursos incríveis que o TitleTab oferece:</p>
      <p>
        <strong>Página inicial personalizada</strong>
      </p>
      <p>
        Nosso recurso StartPage permite que você arraste e solte facilmente suas
        guias para organizá-las de acordo com suas necessidades. Você também
        pode personalizar cada guia com várias opções de exibição, incluindo uma
        captura de tela da página da Web, texto, logotipos de sites populares ou
        suas próprias imagens. Oferecemos temas claros e escuros, para que você
        possa escolher aquele que mais se adapta às suas preferências.
      </p>
      <p>
        <strong>Organização de guias: pastas e espaços ilimitados</strong>
      </p>
      <p>
        O TitleTab oferece recursos poderosos de organização de guias que
        permitem estruturar suas guias de uma maneira que funcione para você.
        Você pode agrupar guias em pastas simplesmente arrastando e soltando uma
        guia sobre a outra. Além disso, você pode criar novos espaços para
        ajudá-lo a separar diferentes tipos de guias, como guias relacionadas ao
        trabalho e guias relacionadas ao hobby. Com os recursos de organização
        de guias do TitleTab, você pode gerenciar facilmente suas guias e manter
        sua experiência de navegação organizada e eficiente.
      </p>
      <p>
        <strong>Espaço público com um link único</strong>
      </p>
      <p>
        Os usuários do TitleTab também podem criar um espaço público que pode
        ser acessado por outros usuários que tenham o link. Ao ativar o botão
        "Espaço público" pela primeira vez, solicitaremos que você preencha
        alguns campos necessários para iniciar seu espaço público. O campo mais
        importante que precisa ser preenchido é o endereço do link exclusivo
        ("Caminho de acesso ao espaço público") por meio do qual seu espaço
        estará acessível com as guias que você deseja compartilhar com outros
        usuários. Depois de ativar o espaço público, você pode começar a
        adicionar guias a ele. Esse recurso é especialmente útil se você deseja
        compartilhar guias com amigos ou colegas para trabalho colaborativo. Com
        o recurso de espaço público do TitleTab, você pode compartilhar
        facilmente suas guias e colaborar com outras pessoas de maneira
        conveniente e segura.
      </p>
      <p>
        <strong>OneTab: salve todas as guias abertas em uma</strong>
      </p>
      <p>
        Para navegadores populares, está disponível uma extensão que permite aos
        usuários gerenciar suas guias abertas no navegador. Simplesmente
        clicando no botão de extensão, você pode salvar todas as guias (ou
        selecionadas) em uma guia que armazenará uma lista de sites e estará
        pronta para ser reaberta a qualquer momento. Essa funcionalidade
        economiza significativamente os recursos do seu dispositivo, pois as
        guias abertas continuam funcionando e usando a memória do seu
        dispositivo.
      </p>
      <p>
        É importante notar também que não rastreamos exatamente o que você salva
        dessa maneira, o que garante sua privacidade, mas, ao mesmo tempo, não
        podemos salvar essas informações para você. Portanto, essas informações
        estão disponíveis apenas localmente neste navegador neste dispositivo em
        que você está trabalhando. Este é o custo da sua privacidade.
      </p>
      <p>
        <strong>Infinito Nova Aba</strong>
      </p>
      <p>
        Infinity New Tab é outro recurso de nossa extensão que fornece uma
        experiência de navegação sem fim. Com a nova guia Infinity, os usuários
        podem continuar navegando sem medo de fechar acidentalmente a última
        guia aberta. Esse recurso adiciona automaticamente uma nova guia à
        esquerda da última guia aberta, garantindo que os usuários sempre tenham
        uma guia para recorrer. Depois que as guias adicionais são abertas, a
        Infinity New Tab desaparece, permitindo que os usuários naveguem
        livremente sem qualquer interrupção. Esse recurso oferece tranquilidade
        aos usuários e evita a frustração resultante do fechamento acidental da
        última guia.
      </p>
      <p>
        <strong>Extensões TitleTab</strong>
      </p>
      <p>
        TitleTab é compatível com navegadores populares como Google Chrome,
        Mozilla Firefox e Microsoft Edge. Nossa extensão pode ser facilmente
        baixada e instalada nas lojas de extensões oficiais de cada navegador.
      </p>
      <p>Aqui está uma lista de onde você pode baixar o TitleTab:</p>
      <ul>
        <li>
          <a
            href="https://chrome.google.com/webstore/detail/titletab-web-dashboard/ldlndadamgafkigafpajckkjbblnepcb"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome Web Store
          </a>
        </li>
        <li>
          <a
            href="https://addons.mozilla.org/firefox/addon/titletab-web-dashboard/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox Add-ons
          </a>
        </li>
        <li>
          <a
            href="https://microsoftedge.microsoft.com/addons/detail/titletab-web-dashboard/mnckgifglkiecanfbdpnajiplpabkphh"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge Add-ons
          </a>
        </li>
      </ul>
      <p>
        Depois de instalado, você pode começar a usar o TitleTab para
        personalizar sua nova página de guia, salvar e organizar suas guias,
        evitar o fechamento acidental de guias e compartilhar links com outras
        pessoas. Não perca a oportunidade de otimizar sua experiência de
        navegação com o TitleTab. Baixe nossa extensão hoje!
      </p>
      <p>
        <strong>Privacidade e segurança</strong>
      </p>
      <p>
        TitleTab leva sua privacidade e segurança a sério. Todos os seus dados
        são armazenados de forma segura no seu dispositivo e na nuvem, e nunca
        compartilhamos suas informações com terceiros.
      </p>
      <hr />
      <p>
        TitleTab é a melhor solução de gerenciamento de guias para quem quer
        controlar sua experiência de navegação. Experimente hoje e veja a
        diferença por si mesmo!
      </p>
      <p>
        Se você tiver alguma dúvida sobre o TitleTab ou precisar de assistência
        com qualquer um de nossos recursos, não hesite em nos contatar em&nbsp;
        <a
          href="mailto:info@titletab.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@titletab.com
        </a>
        . Nossa equipe está sempre feliz em ajudar e responder a quaisquer
        perguntas que você possa ter. Valorizamos seu feedback e estamos
        comprometidos em fornecer a melhor experiência possível ao usuário,
        portanto, não hesite em nos contatar se tiver alguma sugestão ou
        feedback.
      </p>
    </>
  )
}
