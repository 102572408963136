import { database } from '../firebase'

export default function DataGet({ uid }) {
  return new Promise((resolve, reject) => {
    database.data
      .doc(uid)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          resolve(null)
        }
        resolve(doc.data())
      })
  })
}
