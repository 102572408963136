import React, { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useAuth } from '../../context/AuthContext'
import { useData } from '../../context/DataContext'
import FolderBody from './FolderBody'
import UpdateFolderModal from '../dnd/UpdateFolderModal'
import { Container } from './Folder.styled'
import FolderModal from './FolderModal'
import withLocalForage from '../../context/withLocalForage'

const Folder = ({ task, index, buildFolder }) => {
  const { currentUser } = useAuth()
  const path = `state${currentUser ? currentUser.uid : ''}`

  const { global, space, drag, addButton, activeState, setActiveState } =
    useData()
  const { settings, styleData } = global

  let isDragDisabled = !drag
  if (space === null) isDragDisabled = true

  useEffect(() => {
    if (!task.hasOwnProperty('columnOrder')) {
      buildFolder(path, task, settings)
    }
  }, [global])

  const [modalActive, setModalActive] = useState(false)
  const [folderActive, setFolderActive] = useState(false)

  const title =
    '(' +
    Object.keys(task.tasks).length +
    ')' +
    ' ' +
    (task.ownTitle || 'New Folder')

  const shotTitle =
    title.length > Math.floor(styleData.tabWidth / 8)
      ? title.slice(0, Math.floor(styleData.tabWidth / 8)) + '...'
      : title

  const backgroundFolder =
    task.ownBackground || (settings.styleTheme && '#1e1b30') || '#ffffff'

  function openFolder() {
    // let timeout = 500

    // if (device === 'mobile') {
    //   timeout = 1000

    //   // let search = document.querySelector('.search')
    //   // if (search) search.style.zIndex = '101'

    //   // setTimeout(() => {
    //   //   if (searchInput) searchInput.placeholder = title
    //   // }, 200)
    // }

    // let searchInput = document.getElementById('searchInput')
    // let clearButton = document.getElementById('clearButton')

    // setTimeout(() => {
    //   if (searchInput) searchInput.placeholder = title
    // }, 200)

    // if (clearButton) clearButton.style.display = 'none'
    // setTimeout(() => {
    //   // if (searchInput) searchInput.placeholder = title
    //   if (searchInput) document.getElementById('searchInput').value = ''
    //   if (clearButton) clearButton.style.display = 'none'
    // }, 200)
    setActiveState(task)
    setFolderActive(true)
  }

  const modalFolder = folderActive ? (
    <FolderModal
      task={task}
      active={folderActive}
      setActive={setFolderActive}
    />
  ) : null

  const modalUpdate =
    modalActive && addButton && space !== null ? (
      <UpdateFolderModal
        active={modalActive}
        setActive={setModalActive}
        task={task}
        button={3}
        fullWidth={false}
        mobileClose={false}
      />
    ) : null

  const settingsButton =
    addButton && space !== null ? (
      <a onClick={() => setModalActive(true)}>
        <div className="settings-inviz" title="Edit">
          <span className="settings-inviz-icon">&#9776;</span>
        </div>
      </a>
    ) : null

  return (
    <>
      <Draggable
        key={index}
        draggableId={task.id}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided) => (
          <Container
            styleData={styleData}
            dark={settings.styleTheme}
            headersBg={settings.headersBg}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className={'task-' + task.id}
            id={'task-' + task.id}
            key={'taskF-' + task.id}
          >
            <a onClick={openFolder} title={title} style={{ cursor: 'pointer' }}>
              <span
                className="folder"
                style={{
                  backgroundColor: backgroundFolder,
                }}
              >
                <span className="folderSpace">
                  <FolderBody task={task} />
                </span>
              </span>
              <p>{shotTitle}</p>
            </a>
            {settingsButton}
          </Container>
        )}
      </Draggable>
      {modalUpdate}
      {modalFolder}
    </>
  )
}

export default withLocalForage(Folder)
