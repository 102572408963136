import styled from 'styled-components'

export const Container = styled.div`
  .sidebar {
    position: fixed;
    box-sizing: border-box;
    left: 0;
    top: 0;
    height: 100%;
    width: 3vw;
    min-width: 60px;
    background: #fff;
    padding-top: 0.3vw;
    padding-top: max(0.3vw, 6px);
    padding-bottom: 0.3vw;
    padding-bottom: max(0.3vw, 6px);
    padding-left: 0.2vw;
    padding-left: max(0.2vw, 4px);
    padding-right: 0.2vw;
    padding-right: max(0.2vw, 4px);
    transition: all 0.5s ease;
  }
  .sidebar.dark {
    background: #11101d;
  }
  .sidebar.open {
    width: 17vw;
    min-width: 320px;
  }

  @media (max-width: 1200px) {
    .sidebar {
      z-index: 102;
    }
    .sidebar.open {
      width: 100%;
      min-width: 100%;
    }
  }
  /*  */
  /*  */
  /*  */
  /* NavBarDashboard */
  /*  */
  /*  */
  /*  */

  .sidebar .dashboard {
    box-sizing: border-box;
    position: fixed;
    height: 3vw;
    min-height: 60px;
    width: 3vw;
    min-width: 60px;
    top: 0;
    left: 0;
    background: #e7e7e7;
    transition: all 0.5s ease;
    overflow: hidden;
    text-align: center;
    z-index: 1;
  }

  .sidebar .dashboard:hover {
    background: #d5d5d5;
  }

  .sidebar.dark .dashboard {
    background: #1e1b30;
  }

  .sidebar.dark .dashboard:hover {
    background: #2a2545;
  }

  .sidebar.open .dashboard {
    width: 17vw;
    min-width: 320px;
  }

  @media (max-width: 1200px) {
    .sidebar.open .dashboard {
      width: 100%;
      min-width: 100%;
    }
  }

  .sidebar .dashboard #btn {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    width: 100%;
    height: 3vw;
    min-height: 60px;
    color: #fff;
    font-size: 0.9vw;
    font-size: max(0.9vw, 18px);
    line-height: 3vw;
    line-height: max(3vw, 60px);
    background: #0364c1;
    transform: translateY(-50%);
    transition: all 0.5s ease;
  }

  .sidebar .dashboard #btn:hover {
    background: #2b7de9;
  }

  .sidebar.open .dashboard #btn {
    width: 3vw;
    min-width: 60px;
  }

  /*  */
  /*  */
  /*  */
  /* Логоип */
  /*  */
  /*  */
  /*  */
  .sidebar .logo-details {
    display: flex;
    height: 3vw;
    min-height: 60px;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  .sidebar .logo-details .logo_name {
    color: #0364c1;
    font-size: 1.4vw;
    font-size: max(1.4vw, 28px);
    font-family: 'Manrope', sans-serif;
    opacity: 0;
    margin-left: 1vw;
    margin-left: max(1vw, 20px);
    transition: all 0.5s ease;
  }
  .sidebar.dark .logo-details .logo_name {
    color: #fff;
  }
  .sidebar.open .logo-details .icon,
  .sidebar.open .logo-details .logo_name {
    opacity: 1;
  }

  .sidebar .logo-details .logo_name i {
    position: relative;
    top: 2px;
  }

  /*  */
  /*  */
  /*  */
  /* NavBarDashboard */
  /*  */
  /*  */
  /*  */

  /*  */
  /*  */
  /*  */
  /* NavBarCorner */
  /*  */
  /*  */
  /*  */

  .navbarcorner {
    position: absolute;
    top: 0.5vw;
    right: 0.5vw;
  }

  @media (max-width: 1200px) {
    .navbarcorner {
      display: none;
    }
  }

  .button-singin {
    display: block;
    float: left;
    margin-left: 0.5vw;
    margin-left: max(0.5vw, 10px);
    padding-top: 0.35vw;
    padding-top: max(0.35vw, 7px);
    padding-right: 1vw;
    padding-right: max(1vw, 20px);
    padding-bottom: 0.35vw;
    padding-bottom: max(0.35vw, 7px);
    padding-left: 1vw;
    padding-left: max(1vw, 20px);
    border-radius: 0.2vw;
    border-radius: max(0.2vw, 4px);
    font-size: 0.8vw;
    font-size: max(0.8vw, 16px);
    color: #fff;
    background-color: #0364c1;
    cursor: pointer;
    transition: all 0.5s ease;
  }

  .button-singin:hover {
    background-color: #2b7de9;
  }

  .button-singin:active {
    outline: 0 !important;
  }

  /*  */
  /*  */
  /*  */
  /* NavBarCorner */
  /*  */
  /*  */
  /*  */

  /*  */
  /*  */
  /*  */
  /* NavBarProfile */
  /*  */
  /*  */
  /*  */

  .sidebar .profile {
    box-sizing: border-box;
    position: fixed;
    height: 3vw;
    min-height: 60px;
    width: 3vw;
    min-width: 60px;
    bottom: 0px;
    left: 0;
    background: #e7e7e7;
    transition: all 0.5s ease;
    overflow: hidden;
    text-align: center;
  }
  .sidebar .profile:hover {
    background: #d5d5d5;
  }
  .sidebar.dark .profile {
    background: #1e1b30;
  }
  .sidebar.dark .profile:hover {
    background: #2a2545;
  }
  .sidebar.open .profile {
    width: 17vw;
    min-width: 320px;
  }
  @media (max-width: 1200px) {
    .sidebar.open .profile {
      width: 100%;
      min-width: 100%;
    }
  }

  .sidebar .profile-details {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  .sidebar .profile .name_title {
    padding-top: 0.5vw;
    padding-top: max(0.5vw, 10px);
    padding-bottom: 0.5vw;
    padding-bottom: max(0.5vw, 10px);
    padding-left: 0.7vw;
    padding-left: max(0.7vw, 14px);
    padding-right: 0.7vw;
    padding-right: max(0.7vw, 14px);
  }
  .sidebar .profile .name,
  .sidebar .profile .greatest {
    width: 9vw;
    min-width: 180px;
    font-size: 0.75vw;
    font-size: max(0.75vw, 15px);
    color: #0364c1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sidebar.dark .profile .name,
  .sidebar.dark .profile .greatest {
    color: #fff;
  }
  .sidebar .profile .greatest {
    font-size: 0.6vw;
    font-size: max(0.6vw, 12px);
  }

  .sidebar .profile .signin {
    width: 13vw;
    min-width: 260px;
    height: 3vw;
    min-height: 60px;
    font-size: 1vw;
    font-size: max(1vw, 20px);
    line-height: 2.75vw;
    line-height: max(2.75vw, 55px);
    font-weight: 600;
    color: #0364c1;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 1200px) {
    .sidebar .profile .signin {
      width: calc(100% - 60px);
      max-width: calc(100% - 60px);
    }
  }

  .sidebar.dark .profile .signin {
    color: #fff;
  }

  .sidebar .profile #log_in {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    width: 100%;
    height: 3vw;
    min-height: 60px;
    color: #fff;
    font-size: 1.1vw;
    font-size: max(1.1vw, 22px);
    background: #0364c1;
    line-height: 3vw;
    line-height: max(3vw, 60px);
    transform: translateY(-50%);
    transition: all 0.5s ease;
  }
  .sidebar .profile #log_in:hover {
    background: #2b7de9;
  }
  .sidebar.open .profile #log_in {
    width: 3vw;
    min-width: 60px;
  }

  /*  */
  /*  */
  /*  */
  /* NavBarProfile */
  /*  */
  /*  */
  /*  */

  /*  */
  /*  */
  /*  */
  /* NavBarButtons */
  /*  */
  /*  */
  /*  */

  .sidebar .buttons {
    position: absolute;
    bottom: 3vw;
    bottom: max(3vw, 60px);
    left: 0;
    width: 3vw;
    min-width: 60px;
    text-align: center;
    z-index: 1;
    background-color: #fff;
    transition: all 0.5s ease;
  }

  .sidebar.dark .buttons {
    background-color: #11101d;
  }

  .sidebar.open .buttons {
    width: 17vw;
    min-width: 320px;
    float: right;
    opacity: 0;
    visibility: hidden;
  }

  .sidebar .buttons .container {
    position: relative;
    width: 3vw;
    min-width: 60px;
    height: 3vw;
    min-height: 60px;
  }

  .sidebar .buttons #extension,
  .sidebar .buttons #backgroung,
  .sidebar .buttons #about,
  .sidebar .buttons #share {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    width: 3vw;
    min-width: 60px;
    height: 3vw;
    min-height: 60px;
    background: none;
    font-size: 1.4vw;
    font-size: max(1.4vw, 28px);
    line-height: 3vw;
    line-height: max(3vw, 60px);
    transform: translateY(-50%);
    transition: all 0.5s ease;
  }

  .sidebar .buttons #extension {
    --button-color: #f39c12;
    --button-hover-bg: #f39c12;
    --button-hover-color: #fff;
  }

  .sidebar .buttons #backgroung {
    --button-color: #d35400;
    --button-hover-bg: #d35400;
    --button-hover-color: #fff;
  }

  .sidebar .buttons #about {
    --button-color: #27ae60;
    --button-hover-bg: #27ae60;
    --button-hover-color: #fff;
  }

  .sidebar .buttons #share {
    --button-color: #7b4b94;
    --button-hover-bg: #7b4b94;
    --button-hover-color: #fff;
  }

  .sidebar .buttons #extension,
  .sidebar .buttons #backgroung,
  .sidebar .buttons #about,
  .sidebar .buttons #share {
    color: var(--button-color);
  }

  .sidebar .buttons #extension:hover,
  .sidebar .buttons #backgroung:hover,
  .sidebar .buttons #about:hover,
  .sidebar .buttons #share:hover {
    background: var(--button-hover-bg);
    color: var(--button-hover-color);
  }

  /*  */
  /*  */
  /*  */
  /* NavBarButtons */
  /*  */
  /*  */
  /*  */

  .sidebar .spaces,
  .sidebar .addStatic {
    position: absolute;
    box-sizing: border-box;
    width: 3vw;
    min-width: 60px;
    left: 0;
    text-align: center;
    transition: all 0.5s ease;
  }

  .sidebar .background,
  .sidebar .extension,
  .sidebar .question,
  .sidebar .share {
    z-index: 1;
    background-color: #fff;
  }

  .sidebar.dark .background,
  .sidebar.dark .extension,
  .sidebar.dark .question,
  .sidebar.dark .share {
    background-color: #11101d;
  }

  .sidebar .spacesContent {
    transition: transform 0.1s ease-out;
  }

  .sidebar .spaces {
    top: 3vw;
    top: max(3vw, 60px);
    max-height: ${(props) => props.styled.height}px;
  }

  .sidebar .spaces .space {
    position: relative;
    width: 3vw;
    min-width: 60px;
    height: 3vw;
    min-height: 60px;
  }

  .sidebar .space.add {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    bottom: -3vw;
    bottom: min(-3vw, -60px);
  }
  .sidebar.dark .space.add {
    background-color: #11101d;
  }

  .sidebar.open .background,
  .sidebar.open .extension,
  .sidebar.open .question,
  .sidebar.open .share,
  .sidebar.open .spaces {
    width: 17vw;
    min-width: 320px;
    float: right;
  }

  .sidebar .background .tooltip,
  .sidebar .extension .tooltip,
  .sidebar .question .tooltip,
  .sidebar .share .tooltip,
  .sidebar .space .tooltip,
  .sidebar .container .tooltip,
  .sidebar .addStatic .tooltip {
    position: absolute;
    white-space: nowrap;
    top: -1vw;
    left: 3.5vw;
    left: max(3.5vw, 70px);
    z-index: 300;
    background: #fff;
    -webkit-box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.3);
    box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.3);
    padding-top: 0.3vw;
    padding-top: max(0.3vw, 6px);
    padding-bottom: 0.3vw;
    padding-bottom: max(0.3vw, 6px);
    padding-left: 0.6vw;
    padding-left: max(0.6vw, 12px);
    padding-right: 0.6vw;
    padding-right: max(0.6vw, 12px);
    border-radius: 0.2vw;
    font-size: 0.75vw;
    font-size: max(0.75vw, 15px);
    font-weight: 400;
    opacity: 0;
    pointer-events: none;
    transition: 0s;
  }

  .sidebar .background:hover .tooltip,
  .sidebar .extension:hover .tooltip,
  .sidebar .question:hover .tooltip,
  .sidebar .share:hover .tooltip,
  .sidebar .space:hover .tooltip,
  .sidebar .container:hover .tooltip,
  .sidebar .addStatic:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top: 50%;
    transform: translateY(-50%);
  }

  /* button */
  .sidebar .button {
    position: relative;
    width: 3vw;
    min-width: 60px;
    height: 3vw;
    min-height: 60px;
    transition: all 0.5s ease;
  }
  .sidebar.open .button {
    right: 0;
    width: 17vw;
  }

  .sidebar .button #space,
  .sidebar .button #activeSpace {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    width: 3vw;
    min-width: 60px;
    height: 3vw;
    min-height: 60px;
    font-size: 1.3vw;
    font-size: max(1.3vw, 26px);
    background: none;
    line-height: 3vw;
    line-height: max(3vw, 60px);
    transform: translateY(-50%);
    transition: all 0.5s ease;
  }

  .sidebar .button #space {
    color: #0364c1;
  }
  .sidebar.dark .button #space {
    color: #fff;
  }
  .sidebar .button #space:hover {
    color: #0364c1;
    background: #e7e7e7;
  }
  .sidebar.dark .button #space:hover {
    color: #fff;
    background: #1e1b30;
  }

  .sidebar .button #activeSpace {
    color: #0364c1;
    background: #e7e7e7;
  }

  .sidebar.dark .button #activeSpace {
    color: #fff;
    background: #1e1b30;
  }

  .sidebar.open .background,
  .sidebar.open .extension,
  .sidebar.open .question,
  .sidebar.open .share,
  .sidebar.open .spaces,
  .sidebar.open .addStatic {
    opacity: 0;
    visibility: hidden;
  }

  /*  */
  /*  */
  /*  */
  /* Ul, Li */
  /*  */
  /*  */
  /*  */

  .sidebar ul li i {
    color: #0364c1;
    height: 2vw;
    min-height: 40px;
    width: 2vw;
    min-width: 40px;
    text-align: center;
    font-size: 0.9vw;
    font-size: max(0.9vw, 18px);
    line-height: 2vw;
    line-height: max(2vw, 40px);
    border-radius: 0;
    transition: all 0.5s ease;
  }

  .sidebar ul li i.settings {
    position: absolute;
    right: 0;
    border-top-right-radius: 0.35vw;
    border-top-right-radius: max(0.35vw, 7px);
    border-bottom-right-radius: 0.35vw;
    border-bottom-right-radius: max(0.35vw, 7px);
    color: #0364c1;
  }

  .sidebar.dark ul li i.settings {
    color: #999;
  }

  .sidebar ul li i.down {
    position: absolute;
    right: 2vw;
    right: max(2vw, 40px);
    color: #0364c1;
  }

  .sidebar.dark ul li i.down {
    color: #999;
  }

  .sidebar ul li i.up {
    position: absolute;
    right: 4vw;
    right: max(4vw, 80px);
    color: #0364c1;
  }

  .sidebar.dark ul li i.up {
    color: #999;
  }

  .sidebar ul li i:hover.settings,
  .sidebar ul li i:hover.down,
  .sidebar ul li i:hover.up {
    color: #2b7de9;
  }

  .sidebar.dark ul i {
    color: #fff;
  }

  .sidebar.dark ul li i:hover.settings,
  .sidebar.dark ul li i:hover.down,
  .sidebar.dark ul li i:hover.up {
    color: #fff;
  }
  .sidebar .nav-list {
    visibility: hidden;
    margin-top: 3.5vw;
    margin-top: max(3.5vw, 70px);
    height: calc(100% - 7vw);
    height: calc(100% - max(7vw, 140px));
    opacity: 0;
    overflow-x: hidden;
  }
  .sidebar.open .nav-list {
    visibility: visible;
    margin-top: 3.5vw;
    margin-top: max(3.5vw, 70px);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #0364c1 #ffffff00;
    opacity: 1;

    transition: all 0.5s ease;
    transition-delay: 0.5s;
  }
  .sidebar.open .nav-list::-webkit-scrollbar {
    width: 0.4vw;
    min-width: 8px;
    background-color: none;
  }
  .sidebar.open .nav-list::-webkit-scrollbar-thumb {
    width: 0.4vw;
    min-width: 8px;
    background-color: #0364c1;
  }
  .sidebar.open.dark .nav-list {
    margin-top: 3.5vw;
    margin-top: max(3.5vw, 70px);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #0364c1 #ffffff00;
  }
  .sidebar.open.dark .nav-list::-webkit-scrollbar {
    width: 0.4vw;
    min-width: 8px;
    background-color: none;
  }
  .sidebar.open.dark .nav-list::-webkit-scrollbar-thumb {
    width: 0.4vw;
    min-width: 8px;
    background-color: #0364c1;
  }
  .sidebar ul {
    padding-left: 0;
  }
  .sidebar li {
    position: relative;
    margin-top: 0.4vw;
    margin-top: max(0.4vw, 8px);
    margin-bottom: 0.4vw;
    margin-bottom: max(0.4vw, 8px);
    margin-left: 0.3vw;
    margin-left: max(0.3vw, 6px);
    margin-right: 0.3vw;
    margin-right: max(0.3vw, 6px);
    list-style: none;
  }
  .sidebar li a {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 0.35vw;
    border-radius: max(0.35vw, 7px);
    align-items: center;
    text-decoration: none;
    transition: all 0.5s ease;
    background: #e7e7e7;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
  }

  .sidebar.open li a {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .sidebar.dark li a {
    background: #1e1b30;
  }

  .sidebar li a:focus {
    outline: 0 !important;
  }
  .sidebar li a .links_name {
    height: 2vw;
    min-height: 40px;
    line-height: 2vw;
    line-height: max(2vw, 40px);
    color: #0364c1;
    font-size: 0.75vw;
    font-size: max(0.75vw, 15px);
    white-space: nowrap;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .sidebar li a .links_name.short {
    width: calc(100% - 160px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sidebar li a .links_name.long {
    width: calc(100% - 80px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sidebar.dark li a .links_name {
    color: #fff;
  }
  .sidebar.open li a .links_name {
    opacity: 1;
  }
  .sidebar li a .links_name:hover {
    transition: all 0.5s ease;
    margin-left: 0.2vw;
    margin-left: max(0.2vw, 4px);
  }
  /*  */
  /*  */
  /*  */
  /* Input Search */
  /*  */
  /*  */
  /*  */

  /*  */
  /*  */
  /*  */
  /* Search Buttom */
  /*  */
  /*  */
  /*  */

  .sidebar .li-title {
    font-size: 0.9vw;
    font-size: max(0.9vw, 18px);
    color: #0364c1;
    background-color: none;
    margin-top: 1vw;
    margin-top: max(1vw, 20px);
    margin-bottom: 0;
    margin-left: 2.3vw;
    margin-left: max(2.3vw, 46px);
    margin-right: 0;
    opacity: 0;
    transition: all 0.3s ease;
  }
  .sidebar.dark .li-title {
    color: #fff;
  }
  .sidebar.open .li-title {
    opacity: 1;
    transition: all 0.5s ease;
  }

  .sidebar .li-switch {
    opacity: 0;
    transition: all 0.3s ease;
  }
  .sidebar.open .li-switch {
    opacity: 1;
    transition: all 0.5s ease;
  }

  /*  */
  /*  */
  /*  */
  /* Tooltip */
  /*  */
  /*  */
  /*  */
  .sidebar li .tooltip {
    position: absolute;
    white-space: nowrap;
    top: -1vw;
    left: calc(100% + 0.75vw);
    z-index: 3;
    background: #fff;
    -webkit-box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.3);
    box-shadow: 0 0.25vw 0.5vw rgba(0, 0, 0, 0.3);

    padding-top: 0.3vw;
    padding-top: max(0.3vw, 6px);
    padding-bottom: 0.3vw;
    padding-bottom: max(0.3vw, 6px);
    padding-left: 0.6vw;
    padding-left: max(0.6vw, 12px);
    padding-right: 0.6vw;
    padding-right: max(0.6vw, 12px);

    border-radius: 0.2vw;
    font-size: 0.75vw;
    font-size: max(0.75vw, 15px);
    font-weight: 400;
    opacity: 0;
    pointer-events: none;
    transition: 0s;
  }
  .sidebar li:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top: 50%;
    transform: translateY(-50%);
  }
  .sidebar.open li .tooltip {
    display: none;
  }

  .line {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 0.05vw;
    min-height: 1px;
    margin: 0 auto;
    margin-top: 0.75vw;
    margin-top: max(0.75vw, 15px);
    margin-bottom: 0.75vw;
    margin-bottom: max(0.75vw, 15px);
    position: relative;
    background-color: #ccc;
  }

  .line.x2 {
    margin-top: 1.5vw;
    margin-top: max(1.5vw, 30px);
  }

  .line span {
    position: absolute;
    top: -0.5vw;
    top: min(-0.5vw, -10px);
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.5vw;
    padding-left: max(0.5vw, 10px);
    padding-right: 0.5vw;
    padding-right: max(0.5vw, 10px);
    font-size: 0.6vw;
    font-size: max(0.6vw, 12px);
    font-weight: normal;
    line-height: 0.8vw;
    line-height: max(0.8vw, 16px);
    color: #999;
    transition: all 0.5s ease;
  }

  .line.dark span {
    background-color: #11101d;
  }
`
