import styled from 'styled-components'

export const Container = styled.div`
  .title {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 3vw;
    min-height: 60px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0.35vw;
    border-top-left-radius: max(0.35vw, 7px);
    border-top-right-radius: 0.35vw;
    border-top-right-radius: max(0.35vw, 7px);
    background-color: #f7f7f7;
    transition: all 0.5s ease;
  }
  .title.dark {
    background-color: #2e275c;
  }

  .title .header {
    width: calc(100% - max(6vw, 120px));
    height: 3vw;
    min-height: 60px;
    line-height: 3vw;
    line-height: max(3vw, 60px);
    font-size: 1vw;
    font-size: max(1vw, 20px);
    font-weight: 700;
    color: #0364c1;
    transition: all 0.5s ease;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 auto;
  }
  .title.dark .header {
    color: #fff;
  }

  .title .header i {
    font-size: 19px;
    font-weight: 600;
    position: relative;
    top: 1px;
  }

  //Кнопки закрыть и назад в title модального окна
  .close {
    width: 2vw;
    min-width: 41px;
    height: 2vw;
    min-height: 41px;
    border-radius: 4px;
    border-radius: max(0.2vw, 4px);

    position: absolute;
    top: 0.5vw;
    top: max(0.5vw, 10px);
    right: 0.5vw;
    right: max(0.5vw, 10px);
    display: block;
    text-indent: -9999px;
    cursor: pointer;
    background-color: #000;
    opacity: 0.2;
  }

  .close:hover,
  .close:hover {
    opacity: 0.3;
  }
  .close:before,
  .close:after {
    content: '';
    width: 60%;
    height: 4px;
    background: #fff;
    position: absolute;
    top: 47%;
    left: 20%;
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
  }
  .close:after {
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
  }
  .back {
    width: 2vw;
    min-width: 41px;
    height: 2vw;
    min-height: 41px;
    border-radius: 4px;
    border-radius: max(0.2vw, 4px);

    position: absolute;
    top: 0.5vw;
    top: max(0.5vw, 10px);
    left: 0.5vw;
    left: max(0.5vw, 10px);
    display: block;
    text-indent: -9999px;
    cursor: pointer;
    background-color: #000;
    opacity: 0.2;
  }

  .back:hover,
  .back:hover {
    opacity: 0.3;
  }
  .back:before,
  .back:after {
    content: '';
    width: 36%;
    height: 4px;
    background: #fff;
    position: absolute;
    top: 36%;
    left: 28%;
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
  }
  .back:after {
    top: 56%;
    left: 28%;
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
  }
`
