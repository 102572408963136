import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as localForage from 'localforage'
import { toast } from 'react-toastify'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import initialLanguage from '../language/initialLanguage'

export default function NavBarProfile() {
  const navigate = useNavigate()
  const { currentUser, logout } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`
  const displayName = currentUser
    ? currentUser.displayName || currentUser.email
    : ''
  const { global, setGlobal, setOutPublic, setRefresh, lang } = useData()

  async function handleUpdateProfile() {
    navigate('/update-profile')
    setOutPublic(false)
  }

  async function handleSignIn() {
    navigate('/sign-in')
    setOutPublic(false)
  }

  async function handleLogout() {
    try {
      await logout()
      await localForage.removeItem(path)
      await localForage.removeItem('space')
      navigate('/')
      setOutPublic(false)
      setGlobal()
      setRefresh('handleLogout')
      toast.success('Sign out successfully completed')
    } catch {
      toast.error('Failed to sign out')
    }
  }

  return (
    <>
      {currentUser ? (
        <div className="profile">
          <div className="profile-details" onClick={handleUpdateProfile}>
            <div className="name_title">
              <div className="name">{displayName}</div>
              <div className="greatest">
                {initialLanguage[lang].nb_greatest_user}
              </div>
            </div>
          </div>
          <i onClick={handleLogout} className="bx bx-log-out" id="log_in"></i>
        </div>
      ) : (
        <div className="profile" onClick={handleSignIn}>
          <div className="profile-details">
            <div className="signin">{initialLanguage[lang].nb_sign_in}</div>
          </div>
          <i className="bx bx-log-in" id="log_in"></i>
        </div>
      )}
    </>
  )
}
