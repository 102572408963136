import React from 'react'
import { Container } from './Link.styled'

export default function Link({ children, onClick, color }) {
  if (!color) color = 'grey'

  function getClassName() {
    let className = 'buttonLink' + ' ' + color
    return className
  }

  return (
    <Container>
      <div className="area">
        <div className={getClassName()} onClick={onClick}>
          {children}
        </div>
      </div>
    </Container>
  )
}
