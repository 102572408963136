import React from 'react'
import { useAuth } from './context/AuthContext'
import { usePage } from './context/PageContext'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ContactUs from './pages/ContactUs'
import BackgroundModal from './pages/BackgroundModal'
import MobileMenu from './pages/MobileMenu'
import Share from './pages/Share'

import ModalSmall from './pages/ModalSmall'
import { useData } from './context/DataContext'
import AddTabsModal from './components/dnd/AddTabsModal'
import UpdateTabsModal from './components/dnd/UpdateTabsModal'
import About from './pages/About'

export default function Modal() {
  const { currentUser } = useAuth()
  const { addModal, updateModal } = useData()
  const { shareModal, backgroundModal, privacyPolicy, contactUs } = usePage()
  let button = 2
  if (!currentUser) button = 1

  return (
    <>
      {privacyPolicy ? (
        <PrivacyPolicy button={0} fullWidth={true} mobileClose={true} />
      ) : null}

      {backgroundModal ? (
        <BackgroundModal button={0} fullWidth={true} mobileClose={true} />
      ) : null}

      {shareModal ? <Share button={2} mobileClose={false} /> : null}

      {addModal ? (
        <AddTabsModal button={2} fullWidth={false} mobileClose={false} />
      ) : null}

      {updateModal ? (
        <UpdateTabsModal button={3} fullWidth={false} mobileClose={false} />
      ) : null}

      {contactUs ? (
        <ContactUs button={1} fullWidth={false} mobileClose={false} />
      ) : null}

      <About button={0} fullWidth={true} mobileClose={true} />

      {/* <MobileMenu button={button} mobileClose={true} /> */}
      {/* <ModalSmall button={5} fullWidth={false} mobileClose={false} /> */}
    </>
  )
}
