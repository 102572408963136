import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { MonthGraphContainer } from '../styles/GetAnalytics.styled'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default function ExtantionsGraph({ timestamp, extantion }) {
  const options = {
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,

    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'right',
      },
    },
  }

  const labels = timestamp
  const useExtantion = extantion.map((item) => item.use)
  const notExtantion = extantion.map((item) => item.not)

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Use Extantion',
        data: useExtantion,
        borderColor: 'rgb(30, 142, 62)',
        backgroundColor: 'rgba(30, 142, 62, 1)',
      },
      {
        label: 'Not Extension',
        data: notExtantion,
        borderColor: 'rgb(249, 171, 0)',
        backgroundColor: 'rgba(249, 171, 0, 1)',
      },
    ],
  }
  return (
    <MonthGraphContainer>
      <div className="content">
        <Line options={options} data={data} />
      </div>
    </MonthGraphContainer>
  )
}
