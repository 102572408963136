import React from 'react'
import { useData } from '../context/DataContext'
import { Container } from './Button.styled'

export default function Button({
  children,
  onClick,
  button,
  color,
  isDesktop,
  plug,
  dark,
  type,
}) {
  let { device } = useData()

  if (window.innerWidth <= 1200) device = 'mobile'

  if (!color) color = 'grey'

  if (device === 'desktop' && !isDesktop) {
    if (plug) {
      return (
        <Container>
          <div className={device === 'desktop' ? 'desktop' : 'mobile'}></div>
        </Container>
      )
    } else {
      return null
    }
  }

  function getClassName() {
    let className = 'button' + ' ' + color
    if (device === 'desktop') {
      className += ' desktop'
    }
    if (dark) className += ' dark'
    return className
  }

  const getTag = () => {
    if (button) {
      return (
        <button className={getClassName()} onClick={onClick} type={type}>
          {children}
        </button>
      )
    } else {
      return (
        <div className={getClassName()} onClick={onClick} type={type}>
          {children}
        </div>
      )
    }
  }

  return (
    <Container>
      <div className={device === 'desktop' ? 'desktop' : 'mobile'}>
        {getTag()}
      </div>
    </Container>
  )
}
