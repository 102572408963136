const initialLanguage = {
  en: {
    //About
    a_title: 'About TitleTab', //About TitleTab
    a_cancel: 'Cancel', //Cancel
    //NavBarVertical/MobileMenu
    nb_contact_us: 'Contact Us', //Contact Us
    nb_account_settings: 'Account Settings', //AccountSettings
    nb_privacy_policy: 'Privacy Policy', //PrivacyPolicy
    nb_backgroung_image: 'Background Image', //Background Image
    nb_about: 'About TitleTab', //About Us
    nb_share_link: 'Share Link', //Share Link
    nb_greatest_user: 'Greatest User', //GreatestUser
    nb_sign_in: 'Sign In', //SignIn
    nb_extension: 'Extension for ', //Extension for
    nb_use_dark_theme: 'Use dark theme', //Use dark theme
    nb_tab_headers: 'Tab headers', //Tab headers
    nb_with_background: 'with background', //with background
    nb_use_images: 'Use the images ', //Use the images
    nb_as_a_fill_tabs: 'as a fill tabs', //as a fill tabs
    nb_open_tabs_target: 'Open Tabs ', //Open Tabs
    nb_in_a_new_window: 'in a new window', //in a new window
    nb_show_search: 'Show Search', //Show Search
    nb_open_search_results_target: 'Open search results ', //Open search results
    nb_default_search: 'Default Search Engine', //Default Search Engine
    nb_add_space: 'Add space', //Add space
    nb_public_space: 'Public space', //Public space
    nb_general_settings: 'general settings', //General Settings
    nb_private_space: 'private spaces', //private spaces
    nb_share: 'Share', //Share
    //Share
    s_share: 'Share', //Share
    s_link_to_share: 'Link to share', //Link to share
    s_share_on: 'Share on', //Share on
    s_copy_link: 'Copy link', //Copy link
    //Scale
    s_tab_size_desktop: 'Tab Size on Desktop', //Tab Size on Desktop
    s_tab_size_mobile: 'Tab Size on Mobile / Tablet', //Tab Size on Mobile / Tablet
    s_tabs_in_row: 'Tabs in a Row', //Tabs in a Row
    //AddSpaceModal
    asm_space_name: 'Enter Space Name', //Enter Space Name
    asm_add_new_space: 'Add New Space', //Add New Space
    asm_select_icon: 'Select Icon', //Select Icon
    //UpdateSpaceModal
    usm_space_name: 'Enter Space Name', //Enter Space Name
    usm_update_space: 'Update Space', //Update Space
    usm_select_icon: 'Select Icon', //Select Icon
    //AddTabsModal
    atm_log_in: 'Log in, data is not saved', //Log in, data is not saved
    atm_add_title: 'Add to TitleTab', //Add to TitleTab
    atm_type_url: 'Type URL to add to TitleTab', //Type URL to add to TitleTab
    atm_enter_title: 'Enter Title (is optional)', //Enter Title (is optional)
    atm_add_cancel: 'Cancel', //Cancel
    //UpdateTabsModal
    utm_url_address: 'URL Address', //URL Address
    utm_title: 'Title', //Title
    utm_base64_image_encoder: 'Base64 Image Encoder', //Base64 Image Encoder
    utm_title_color: 'Title Color', //Title Color
    utm_background_color: 'Background Color', //Background Color
    utm_add_custom_image: 'Add custom image (URL address or Base64)', //Add custom image (URL address or Base64)
    utm_apply_update: 'Apply update', //Apply update
    utm_delete: 'Delete', //Delete
    utm_cancel: 'Cancel', //Cancel
    //UpdateNavigation
    un_primary_settings: 'Primary settings', //Primary settings
    un_customize_color_apply: 'Customize Color and Apply', //Customize Color and Apply
    un_apply_screenshot: 'Apply a screenshot of the website page', //Apply a screenshot of the website page
    un_apply_logo: 'Apply a logo of the website', //Apply a logo of the website
    un_temporarily_unavailable: 'Temporarily unavailable', //Temporarily unavailable
    un_add_own_image: 'Add and apply your own image', //Add and apply your own image
    //SignIn/SignUp/ForgotPassword
    auth_sing_in_titletab: 'Sign in TitleTab', //Sign in TitleTab
    auth_sign_up_titletab: 'Sign up TitleTab', //Sign up TitleTab
    auth_password_recovery: 'Password Recovery', //Password Recovery
    auth_sign_google: 'Sign in with Google', //Sign in with Google
    auth_sign_facebook: 'Sign in with Facebook', //Sign in with Facebook
    auth_sign_twitter: 'Sign in with Twitter', //Sign in with Twitter
    auth_or: 'or', //or
    auth_email_address: 'Email Address', //Email Address
    auth_password: 'Password', //Password
    auth_password_confirmation: 'Confirm Password', //Confirm Password
    auth_forgot_password: 'I forgot the password', //I forgot the password
    auth_sign_in: 'Sign In', //Sign In
    auth_sign_up: 'Sign Up', //Sign Up
    auth_reset_password: 'Reset Password', //Reset Password
    auth_need_sign_up: 'I need to Sign up', //I need to Sign up
    auth_have_account: 'I have an Account', //I have an Account
    //UpdateProfile
    up_account_settings: 'Account Settings', //Account Settings
    up_greatest_user: 'Greatest User', //Greatest User
    up_make_selection: 'please make a selection', //please make a selection
    up_change_email: 'Change Email', //Change Email
    up_change_password: 'Change Password', //Change Password
    up_delete_account: 'Delete Account', //Delete Account
    up_log_out: 'Sign Out', //Sign Out
    up_account_settingsCancel: 'Cancel', //Cancel
    //ChangeEmail
    ce_change_email: 'Change Email', //Change Email
    ce_new_email: 'New Email', //New Email
    ce_password: 'Password', //Password
    ce_confirm_password: 'Confirm Password', //Confirm Password
    ce_save_new_email: 'Save New Email', //Save New Email
    ce_passwords_not_match: 'Passwords do not match!', //Passwords do not match!
    ce_wrong_password: 'Wrong Password!', //Wrong Password!
    ce_new_email_saved: 'Email Address Changed!', //Email Address Changed!
    ce_failed_update_email: 'Failed to update email!', //Failed to update email!
    ce_forbidden:
      'Your authorization method does not imply a email change. We do not store your email.', //Your authorization method does not imply a email change. We do not store your email.
    ce_cancel: 'Cancel', //Cancel
    //ChangePassword
    cp_change_password: 'Change Password', //Change Password
    cp_old_password: 'Old Password', //Old Password
    cp_new_password: 'New Password', //New Password
    cp_confirm_new_password: 'Confirm New Password', //Confirm New Password
    cp_save_new_password: 'Save New Password', //Save New Password
    cp_passwords_not_match: 'Passwords do not match!', //Passwords do not match!
    cp_new_password_saved: 'New Password Saved!', //New Password Saved!
    cp_failed_update_password: 'Failed to update password!', //Failed to update password!
    cp_wrong_password: 'Wrong Password!', //Wrong Password!
    cp_forbidden:
      'Your authorization method does not imply a password change. We do not store your password.', //Your authorization method does not imply a password change. We do not store your password.
    cp_cancel: 'Cancel', //Cancel
    //DeleteAccount
    da_delete_account: 'Delete Account', //Delete Account
    da_delete_account_text: 'Are you sure?', //Are you sure?
    da_email: 'Email', //Email
    da_password: 'Password', //Password
    da_confirm_password: 'Confirm Password', //Confirm Password
    da_delete_account_cancel: 'Cancel', //Cancel
    da_delete_account_delete: 'Delete Account', //Delete Account
    da_password_not_match: 'Passwords do not match!', //Passwords do not match!
    da_incorrect_email: 'Incorrect Email!', //Incorrect Email!
    da_account_deleted: 'Account successfully deleted!', //Account successfully deleted!
    da_account_faled: 'Failed to delete account!', //Failed to delete account!
    da_incorrect_data: 'Incorrect login data!', //Incorrect login data!
    da_google_delete: 'Enter your Google account email', //Enter your Google account email
    da_facebook_delete: 'Enter your Facebook account email', //Enter your Facebook account email
    da_twitter_delete: 'Enter your Twitter account Name', //Enter your Twitter account Name
    //SearchVertical/SearchHorizontal
    search_web: 'Search the Web and Tabs', //Search the Web and Tabs
    search_listening: 'Listening, please speak...', //Listening, please speak...
    search_error: 'Something went wrong, try again', //Something went wrong, try again
    search_unsupport: 'Speech Recognition is not supported by this browser.', //Speech Recognition is not supported by this browser.
    //Add
    add_site: 'Add a site', //Add a site
    //UploaderFile
    uf_main: '<u>Upload</u> or drop an image right here', //Upload or drop an image right here
    uf_drag: '<u>Drop</u> your file here', //Drop your file here
    uf_success: '<span style="color:green"><u>Upload</u> successful!</span>', //Upload successful!
    uf_errorMaxSize:
      '<span style="color:red">You can only upload files <u>up to 2MB</u></span>', //You can only upload files up to 2MB
    uf_errorMinSize:
      '<span style="color:red">You can only upload files <u>more than 100KB</u></span>', //You can only upload files more than 100KB
    uf_errorType:
      '<span style="color:red">You can only upload <u>image files</u></span>', //You can only upload image files
    uf_errorMultiple:
      '<span style="color:red">You can only upload <u>one file</u> at a time</span>', //You can only upload one file at a time

    //UpdateSpacePublicModal
    uspm_public_space_settings: 'Public Space Settings', //Public Space Settings
    uspm_activate_public_space: 'Activate Public Space', //Activate Public Space
    uspm_message:
      'Once you activate the public space, you can start sharing your links online.', //Once you activate the public space, you can start sharing your links online.
    uspm_path: 'Path for public space access', //Path for public space access
    uspm_link: 'Link to public space (read only)', //Link to public space (read only)
    uspm_public_space_name: 'Public space name (is optional)', //Public space name (is optional)
    uspm_public_space_title: 'Public space title (read only)', //Public space title (read only)
    uspm_description: 'Description (is optional)', //Description (is optional)
    uspm_save_space_settings: 'Save Space Settings', //Save Space Settings
    uspm_activate_public_space: 'Activate Public Space', //Activate Public Space
    uspm_sign_in_to_get_started: 'Sign in to get started', //Sign in to get started
    uspm_cancel: 'Cancel', //Cancel
    //BackgroundModal
    bm_choose_image: 'Background Image', //Background Image
    bm_or: 'or', //or
  },
  de: {
    //About
    a_title: 'Über TitleTab', //About TitleTab
    a_cancel: 'Abbrechen', //Cancel
    //NavBarVertical/MobileMenu
    nb_contact_us: 'Kontaktiere uns', //Contact Us
    nb_account_settings: 'Kontoeinstellungen', //AccountSettings
    nb_privacy_policy: 'Datenschutzbestimmungen', //PrivacyPolicy
    nb_backgroung_image: 'Hintergrundbild', //Background Image
    nb_about: 'Über TitleTab', //About Us
    nb_share_link: 'Link teilen', //Share Link
    nb_greatest_user: 'Großartigster Benutzer', //GreatestUser
    nb_sign_in: 'Anmelden', //SignIn
    nb_extension: 'Erweiterung für ', //Extension for
    nb_use_dark_theme: 'Dunkelfarbenes Design verwenden', //Use dark theme
    nb_tab_headers: 'Überschriften', //Tab headers
    nb_with_background: 'mit Hintergrund', //with background
    nb_use_images: 'Bilder verwenden ', //Use the images
    nb_as_a_fill_tabs: 'als Fülltabs', //as a fill tabs
    nb_open_tabs_target: 'Tabs öffnen ', //Open Tabs
    nb_in_a_new_window: 'in einem neuen Fenster', //in a new window
    nb_show_search: 'Suche anzeigen', //Show Search
    nb_open_search_results_target: 'Suchergebnisse öffnen ', //Open search results
    nb_default_search: 'Standard-Suchmaschine', //Default Search Engine
    nb_add_space: 'Platz hinzufügen', //Add Space
    nb_public_space: 'Öffentlicher Platz', //Public Space
    nb_general_settings: 'Allgemeine Einstellungen', //General Settings
    nb_private_space: 'Private Plätze', //private spaces
    nb_share: 'Teilen', //Share
    //Share
    s_share: 'Teilen', //Share
    s_link_to_share: 'Link zum Teilen', //Link to share
    s_share_on: 'Teilen auf', //Share on
    s_copy_link: 'Link kopieren', //Copy link
    //Scale
    s_tab_size_desktop: 'Tabgröße auf dem Desktop', //Tab Size on Desktop
    s_tab_size_mobile: 'Tabgröße auf Mobilgerät / Tablet', //Tab Size on Mobile / Tablet
    s_tabs_in_row: 'Tabs in einer Reihe', //Tabs in a Row
    //AddSpaceModal
    asm_space_name: 'Geben Sie den Platznamen ein',
    asm_add_new_space: 'Neuen Platz hinzufügen',
    asm_select_icon: 'Symbol auswählen',
    //UpdateSpaceModal
    usm_space_name: 'Geben Sie den Platznamen ein',
    usm_update_space: 'Platz aktualisieren',
    usm_select_icon: 'Symbol auswählen',
    //AddTabsModal
    atm_log_in: 'Anmelden, Daten werden nicht gespeichert', //Log in, data is not saved
    atm_add_title: 'Zu TitleTab hinzufügen', //Add to TitleTab
    atm_type_url: 'URL eingeben, um zu TitleTab hinzuzufügen', //Type URL to add to TitleTab
    atm_enter_title: 'Titel eingeben (optional)', //Enter Title (is optional)
    atm_add_cancel: 'Abbrechen', //Cancel
    //UpdateTabsModal
    utm_url_address: 'URL-Adresse', //URL Address
    utm_title: 'Titel', //Title
    utm_base64_image_encoder: 'Base64-Bildkodierer', //Base64 Image Encoder
    utm_title_color: 'Titelfarbe', //Title Color
    utm_background_color: 'Hintergrundfarbe', //Background Color
    utm_add_custom_image:
      'Benutzerdefiniertes Bild hinzufügen (URL-Adresse oder Base64)', //Add custom image (URL address or Base64)
    utm_apply_update: 'Update anwenden', //Apply update
    utm_delete: 'Löschen', //Delete
    utm_cancel: 'Abbrechen', //Cancel
    //UpdateNavigation
    un_primary_settings: 'Grundeinstellungen', //Primary settings
    un_customize_color_apply: 'Farben anpassen und anwenden', //Customize Color and Apply
    un_apply_screenshot: 'Screenshot der Webseitenseite anwenden', //Apply a screenshot of the website page
    un_apply_logo: 'Logo der Webseite anwenden', //Apply a logo of the website
    un_temporarily_unavailable: 'Vorübergehend nicht verfügbar', //Temporarily unavailable
    un_add_own_image: 'Eigenes Bild hinzufügen und anwenden', //Add and apply your own image
    //SignIn/SignUp/ForgotPassword
    auth_sing_in_titletab: 'Bei TitleTab anmelden', //Sign in TitleTab
    auth_sign_up_titletab: 'Bei TitleTab registrieren', //Sign up TitleTab
    auth_password_recovery: 'Passwortwiederherstellung', //Password Recovery
    auth_sign_google: 'Mit Google anmelden', //Sign in with Google
    auth_sign_facebook: 'Mit Facebook anmelden', //Sign in with Facebook
    auth_sign_twitter: 'Mit Twitter anmelden', //Sign in with Twitter
    auth_or: 'oder', //or
    auth_email_address: 'E-Mail-Adresse', //Email Address
    auth_password: 'Passwort', //Password
    auth_password_confirmation: 'Passwort bestätigen', //Confirm Password
    auth_forgot_password: 'Ich habe das Passwort vergessen', //I forgot the password
    auth_sign_in: 'Anmelden', //Sign In
    auth_sign_up: 'Registrieren', //Sign Up
    auth_reset_password: 'Passwort zurücksetzen', //Reset Password
    auth_need_sign_up: 'Ich muss mich registrieren', //I need to Sign up
    auth_have_account: 'Ich habe bereits einen Account', //I have an Account
    //UpdateProfile
    up_account_settings: 'Kontoeinstellungen', //Account Settings
    up_greatest_user: 'Großartigster Benutzer', //Greatest User
    up_make_selection: 'Bitte treffen Sie eine Auswahl', //please make a selection
    up_change_email: 'E-Mail-Adresse ändern', //Change Email
    up_change_password: 'Passwort ändern', //Change Password
    up_delete_account: 'Konto löschen', //Delete Account
    up_log_out: 'Abmelden', //Sign Out
    up_account_settingsCancel: 'Abbrechen', //Cancel
    //ChangeEmail
    ce_change_email: 'E-Mail-Adresse ändern', //Change Email
    ce_new_email: 'Neue E-Mail-Adresse', //New Email
    ce_password: 'Passwort', //Password
    ce_confirm_password: 'Passwort bestätigen', //Confirm Password
    ce_save_new_email: 'Neue E-Mail-Adresse speichern', //Save New Email
    ce_passwords_not_match: 'Passwörter stimmen nicht überein!', //Passwords do not match!
    ce_wrong_password: 'Falsches Passwort!', //Wrong Password!
    ce_new_email_saved: 'E-Mail-Adresse geändert!', //Email Address Changed!
    ce_failed_update_email: 'Änderung der E-Mail-Adresse fehlgeschlagen!', //Failed to update email!
    ce_forbidden:
      'Ihre Autorisierungsmethode beinhaltet keine Änderung der E-Mail-Adresse. Wir speichern keine E-Mail-Adressen.', //Your authorization method does not imply a email change. We do not store your email.
    ce_cancel: 'Abbrechen', //Cancel
    //ChangePassword
    cp_change_password: 'Passwort ändern', //Change Password
    cp_old_password: 'Altes Passwort', //Old Password
    cp_new_password: 'Neues Passwort', //New Password
    cp_confirm_new_password: 'Neues Passwort bestätigen', //Confirm New Password
    cp_save_new_password: 'Neues Passwort speichern', //Save New Password
    cp_passwords_not_match: 'Passwörter stimmen nicht überein!', //Passwords do not match!
    cp_new_password_saved: 'Neues Passwort gespeichert!', //New Password Saved!
    cp_failed_update_password: 'Änderung des Passworts fehlgeschlagen!', //Failed to update password!
    cp_wrong_password: 'Falsches Passwort!', //Wrong Password!
    cp_forbidden:
      'Ihre Autorisierungsmethode beinhaltet keine Änderung des Passworts. Wir speichern keine Passwörter.', //Your authorization method does not imply a password change. We do not store your password.
    cp_cancel: 'Abbrechen', //Cancel
    //DeleteAccount
    da_delete_account: 'Account löschen', //Delete Account
    da_delete_account_text: 'Sind Sie sicher?', //Are You sure?
    da_email: 'E-Mail', //Email
    da_password: 'Passwort', //Password
    da_confirm_password: 'Passwort bestätigen', //Confirm Password
    da_delete_account_cancel: 'Abbrechen', //Cancel
    da_delete_account_delete: 'Account löschen', //Delete Account
    da_password_not_match: 'Passwörter stimmen nicht überein!', //Passwords do not match!
    da_incorrect_email: 'Falsche E-Mail!', //Incorrect Email!
    da_account_deleted: 'Account erfolgreich gelöscht!', //Account successfully deleted!
    da_account_failed: 'Löschen des Accounts fehlgeschlagen!', //Failed to delete account!
    da_incorrect_data: 'Falsche Anmeldedaten!', //Incorrect login data!
    da_google_delete: 'Gib deine Google-Konto-E-Mail ein', //Enter your Google account email
    da_facebook_delete: 'Gib deine Facebook-Konto-E-Mail ein', //Enter your Facebook account email
    da_twitter_delete: 'Gib deinen Twitter-Account-Namen ein', //Enter your Twitter account Name
    //SearchVertical/SearchHorizontal
    search_web: 'Das Web und Tabs durchsuchen', //Search the Web and Tabs
    search_listening: 'Zuhören, bitte sprechen...', //Listening, please speak...
    search_error: 'Etwas ist schief gelaufen, versuchen Sie es erneut', //Something went wrong, try again
    search_unsupport:
      'Spracherkennung wird von diesem Browser nicht unterstützt.', //Speech Recognition is not supported by this browser.
    //Add
    add_site: 'Eine Seite hinzufügen', //Add a site
    //UploaderFile
    uf_main: '<u>Hochladen</u> oder ziehen Sie ein Bild hierher', //Upload or drop an image right here
    uf_drag: '<u>Ziehen</u> Sie Ihre Datei hierher', //Drop your file here
    uf_success:
      '<span style="color:green"><u>Hochladen</u> erfolgreich!</span>', //Upload successful!
    uf_errorMaxSize:
      '<span style="color:red">Sie können nur Dateien hochladen, die <u>bis zu 2 MB</u> groß sind</span>', //You can only upload files up to 2MB
    uf_errorMinSize:
      '<span style="color:red">Sie können nur Dateien hochladen, die <u>größer als 100 KB</u> sind</span>', //You can only upload files more than 100KB
    uf_errorType:
      '<span style="color:red">Sie können nur <u>Bilddateien</u> hochladen</span>', //You can only upload image files
    uf_errorMultiple:
      '<span style="color:red">Sie können nur <u>eine Datei</u> auf einmal hochladen</span>', //You can only upload one file at a time
    //UpdateSpacePublicModal
    uspm_public_space_settings: 'Öffentliche Platz-Einstellungen', //Public Space Settings
    uspm_activate_public_space: 'Aktivieren Sie öffentlichen Platz', //Activate Public Space
    uspm_message:
      'Sobald Sie den öffentlichen Platz aktivieren, können Sie Ihre Links online teilen.', //Once you activate the public space, you can start sharing your links online.
    uspm_path: 'Pfad für den öffentlichen Platzzugriff', //Path for public space access
    uspm_link: 'Link zum öffentlichen Platz (nur lesen)', //Link to public space (read only)
    uspm_public_space_name: 'Name des öffentlichen Platzes (optional)', //Public space name (is optional)
    uspm_public_space_title: 'Titel des öffentlichen Platzes (nur lesen)', //Public space title (read only)
    uspm_description: 'Beschreibung (optional)', //Description (is optional)
    uspm_save_space_settings: 'Platz Einstellungen speichern', //Save Space Settings
    uspm_activate_public_space: 'Aktivieren Sie öffentlichen Platz', //Activate Public Space
    uspm_sign_in_to_get_started: 'Melden Sie sich an, um loszulegen', //Sign in to get started
    uspm_cancel: 'Abbrechen', //Cancel
    //BackgroundModal
    bm_choose_image: 'Hintergrundbild', //Background Image
    bm_or: 'oder', //or
  },
  fr: {
    //About
    a_title: 'À propos de TitleTab', //About TitleTab
    a_cancel: 'Annuler', //Cancel
    //NavBarVertical/MobileMenu
    nb_contact_us: 'Contactez-nous', //Contact Us
    nb_account_settings: 'Paramètres du compte', //AccountSettings
    nb_privacy_policy: 'Politique de confidentialité', //PrivacyPolicy
    nb_backgroung_image: 'Image de fond', //Background Image
    nb_about: 'À propos de TitleTab', //About Us
    nb_share_link: 'Partager le lien', //Share Link
    nb_greatest_user: 'Le plus grand utilisateur', //GreatestUser
    nb_sign_in: 'Se connecter', //SignIn
    nb_extension: 'Extension pour ', //Extension for
    nb_use_dark_theme: 'Utiliser le thème sombre', //Use dark theme
    nb_tab_headers: "En-têtes d'onglets", //Tab headers
    nb_with_background: 'avec arrière-plan', //with background
    nb_use_images: 'Utiliser les images  ', //Use the images
    nb_as_a_fill_tabs: 'comme onglets', //as a fill tabs
    nb_open_tabs_target: 'Ouvrir les onglets  ', //Open Tabs
    nb_in_a_new_window: 'dans une nouvelle fenêtre', //in a new window
    nb_show_search: 'Afficher la recherche', //Show Search
    nb_open_search_results_target: 'Ouvrir la recherche ', //Open search results
    nb_default_search: 'Moteur de recherche par défaut', //Default Search Engine
    nb_add_space: 'Ajouter un espace', //Add Space
    nb_public_space: 'Espace public', //Public Space
    nb_general_settings: 'Paramètres généraux', //General Settings
    nb_private_space: 'Espaces privés', //private spaces
    nb_share: 'Partager', //Share
    //Share
    s_share: 'Partager', //Share
    s_link_to_share: 'Lien de partage', //Link to share
    s_share_on: 'Partager sur', //Share on
    s_copy_link: 'Copier le lien', //Copy link
    //Scale
    s_tab_size_desktop: "Taille d'onglet sur le bureau", //Tab Size on Desktop
    s_tab_size_mobile: "Taille d'onglet sur mobile / tablette", //Tab Size on Mobile / Tablet
    s_tabs_in_row: 'Onglets en ligne', //Tabs in a Row
    //AddSpaceModal
    asm_space_name: "Entrez le nom de l'espace", //Enter Space Name
    asm_add_new_space: 'Ajouter un nouvel espace', //Add New Space
    asm_select_icon: 'Sélectionner une icône', //Select Icon
    //UpdateSpaceModal
    usm_space_name: "Entrez le nom de l'espace", //Enter Space Name
    usm_update_space: "Mettre à jour l'espace", //Update Space
    usm_select_icon: 'Sélectionner une icône', //Select Icon
    //AddTabsModal
    atm_log_in: "S'identifier pour enregistrer des données", //Log in, data is not saved
    atm_add_title: 'Ajouter à TitleTab', //Add to TitleTab
    atm_type_url: "Saisissez l'URL à ajouter à TitleTab", //Type URL to add to TitleTab
    atm_enter_title: 'Entrez le titre (facultatif)', //Enter Title (is optional)
    atm_add_cancel: 'Annuler', //Cancel
    //UpdateTabsModal
    utm_url_address: 'Adresse URL', //URL Address
    utm_title: 'Titre', //Title
    utm_base64_image_encoder: "Encodeur d'image Base64", //Base64 Image Encoder
    utm_title_color: 'Couleur du titre', //Title Color
    utm_background_color: 'Couleur de fond', //Background Color
    utm_add_custom_image: 'Ajouter une image (URL ou Base64)', //Add custom image (URL address or Base64)
    utm_apply_update: 'Appliquer la mise à jour', //Apply update
    utm_delete: 'Supprimer', //Delete
    utm_cancel: 'Annuler', //Cancel
    //UpdateNavigation
    un_primary_settings: 'Paramètres principaux', //Primary settings
    un_customize_color_apply: 'Personnaliser la couleur et appliquer', //Customize Color and Apply
    un_apply_screenshot: "Appliquer une capture d'écran de la page du site", //Apply a screenshot of the website page
    un_apply_logo: 'Appliquer un logo du site', //Apply a logo of the website
    un_temporarily_unavailable: 'Temporairement indisponible', //Temporarily unavailable
    un_add_own_image: 'Ajouter et appliquer votre propre image', //Add and apply your own image
    //SignIn/SignUp/ForgotPassword
    auth_sing_in_titletab: 'Se connecter à TitleTab', //Sign in TitleTab
    auth_sign_up_titletab: "S'inscrire sur TitleTab", //Sign up TitleTab
    auth_password_recovery: 'Récupération de mot de passe', //Password Recovery
    auth_sign_google: 'Se connecter avec Google', //Sign in with Google
    auth_sign_facebook: 'Se connecter avec Facebook', //Sign in with Facebook
    auth_sign_twitter: 'Se connecter avec Twitter', //Sign in with Twitter
    auth_or: 'ou', //or
    auth_email_address: 'Adresse e-mail', //Email Address
    auth_password: 'Mot de passe', //Password
    auth_password_confirmation: 'Confirmez le mot de passe', //Confirm Password
    auth_forgot_password: "J'ai oublié mon mot de passe", //I forgot the password
    auth_sign_in: 'Se connecter', //Sign In
    auth_sign_up: "S'inscrire", //Sign Up
    auth_reset_password: 'Réinitialiser le mot de passe', //Reset Password
    auth_need_sign_up: "Je dois m'inscrire", //I need to Sign up
    auth_have_account: "J'ai un compte", //I have an Account
    //UpdateProfile
    up_account_settings: 'Paramètres du compte', //Account Settings
    up_greatest_user: 'Le plus grand utilisateur', //Greatest User
    up_make_selection: "s'il vous plaît faire un choix", //please make a selection
    up_change_email: "Changer d'e-mail", //Change Email
    up_change_password: 'Changer de mot de passe', //Change Password
    up_delete_account: 'Supprimer le compte', //Delete Account
    up_log_out: 'Se déconnecter', //Sign Out
    up_account_settingsCancel: 'Annuler', //Cancel
    //ChangeEmail
    ce_change_email: "Changer d'e-mail", //Change Email
    ce_new_email: 'Nouvel e-mail', //New Email
    ce_password: 'Mot de passe', //Password
    ce_confirm_password: 'Confirmez le mot de passe', //Confirm Password
    ce_save_new_email: 'Enregistrer le nouvel e-mail', //Save New Email
    ce_passwords_not_match: 'Les mots de passe ne correspondent pas!', //Passwords do not match!
    ce_wrong_password: 'Mot de passe incorrect!', //Wrong Password!
    ce_new_email_saved: 'Adresse e-mail changée!', //Email Address Changed!
    ce_failed_update_email: "Impossible de mettre à jour l'e-mail!", //Failed to update email!
    ce_forbidden:
      "Votre méthode d'autorisation ne prévoit pas de changement d'adresse e-mail. Nous ne stockons pas votre e-mail.", //Your authorization method does not imply a email change. We do not store your email.
    ce_cancel: 'Annuler', //Cancel
    //ChangePassword
    cp_change_password: 'Changer de mot de passe', //Change Password
    cp_old_password: 'Ancien mot de passe', //Old Password
    cp_new_password: 'Nouveau mot de passe', //New Password
    cp_confirm_new_password: 'Confirmer le nouveau mot de passe', //Confirm New Password
    cp_save_new_password: 'Enregistrer le nouveau mot de passe', //Save New Password
    cp_passwords_not_match: 'Les mots de passe ne correspondent pas!', //Passwords do not match!
    cp_new_password_saved: 'Nouveau mot de passe enregistré!', //New Password Saved!
    cp_failed_update_password: 'Impossible de mettre à jour le mot de passe!', //Failed to update password!
    cp_wrong_password: 'Mot de passe incorrect!', //Wrong Password!
    cp_forbidden:
      "Votre méthode d'autorisation ne prévoit pas de changement de mot de passe. Nous ne stockons pas votre mot de passe.", //Your authorization method does not imply a password change. We do not store your password.
    cp_cancel: 'Annuler', //Cancel
    //DeleteAccount
    da_delete_account: 'Supprimer le compte', //Delete Account
    da_delete_account_text: 'Êtes-vous sûr(e) ?', //Are you sure?
    da_email: 'Adresse électronique', //Email
    da_password: 'Mot de passe', //Password
    da_confirm_password: 'Confirmez le mot de passe', //Confirm Password
    da_delete_account_cancel: 'Annuler', //Cancel
    da_delete_account_delete: 'Supprimer le compte', //Delete Account
    da_password_not_match: 'Les mots de passe ne correspondent pas !', //Passwords do not match!
    da_incorrect_email: 'Adresse électronique incorrecte !', //Incorrect Email!
    da_account_deleted: 'Compte supprimé avec succès!', //Account successfully deleted!
    da_account_faled: 'Impossible de supprimer le compte!', //Failed to delete account!
    da_incorrect_data: 'Données de connexion incorrectes!', //Incorrect login data!
    da_google_delete: "Entrez l'adresse email de votre compte Google", //Enter your Google account email
    da_facebook_delete: "Entrez l'adresse email de votre compte Facebook", //Enter your Facebook account email
    da_twitter_delete: 'Entrez le nom de votre compte Twitter', //Enter your Twitter account Name
    //SearchVertical/SearchHorizontal
    search_web: 'Rechercher sur le Web et les onglets', //Search the Web and Tabs
    search_listening: "En écoute, parlez s'il vous plaît...", //Listening, please speak...
    search_error: 'Quelque chose a mal tourné, veuillez réessayer', //Something went wrong, try again
    search_unsupport:
      "La reconnaissance vocale n'est pas prise en charge par ce navigateur.", //Speech Recognition is not supported by this browser.
    //Add
    add_site: 'Ajouter un site', //Add a site
    //UploaderFile
    uf_main: '<u>Téléchargez</u> ou glissez-déposez une image ici', //<u>Upload</u> or drop an image right here
    uf_drag: '<u>Glissez</u> votre fichier ici', //<u>Drop</u> your file here
    uf_success:
      '<span style="color:green"><u>Téléchargement</u> réussi ! </span>', //<span style="color:green"><u>Upload</u> successful!</span>
    uf_errorMaxSize:
      '<span style="color:red">Vous pouvez seulement télécharger des fichiers de <u>2 Mo</u> maximum</span>', //<span style="color:red">You can only upload files <u>up to 2MB</u></span>
    uf_errorMinSize:
      '<span style="color:red">Vous pouvez seulement télécharger des fichiers de <u>100 Ko</u> minimum</span>', //<span style="color:red">You can only upload files <u>more than 100KB</u></span>
    uf_errorType:
      '<span style="color:red">Vous pouvez seulement télécharger <u>des fichiers d\'image</u></span>', //<span style="color:red">You can only upload <u>image files</u></span>
    uf_errorMultiple:
      '<span style="color:red">Vous pouvez seulement télécharger <u>un fichier à la fois</u></span>', //<span style="color:red">You can only upload <u>one file</u> at a time</span>
    //UpdateSpacePublicModal
    uspm_public_space_settings: "Paramètres de l'espace public", //Public Space Settings
    uspm_activate_public_space: "Activer l'espace public", //Activate Public Space
    uspm_message:
      "Une fois que vous avez activé l'espace public, vous pouvez commencer à partager vos liens en ligne.", //Once you activate the public space, you can start sharing your links online.
    uspm_path: "Chemin d'accès à l'espace public", //Path for public space access
    uspm_link: "Lien vers l'espace public (lecture seule)", //Link to public space (read only)
    uspm_public_space_name: "Nom de l'espace public (facultatif)", //Public space name (is optional)
    uspm_public_space_title: "Titre de l'espace public (lecture seule)", //Public space title (read only)
    uspm_description: 'Description (facultatif)', //Description (is optional)
    uspm_save_space_settings: "Enregistrer les paramètres de l'espace", //Save Space Settings
    uspm_activate_public_space: "Activer l'espace public", //Activate Public Space
    uspm_sign_in_to_get_started: 'Connectez-vous pour commencer', //Sign in to get started
    uspm_cancel: 'Annuler', //Cancel
    //BackgroundModal
    bm_choose_image: 'Image de fond', //Background Image
    bm_or: 'ou', //or
  },
  es: {
    //About
    a_title: 'Sobre TitleTab', //About TitleTab
    a_cancel: 'Cancelar', //Cancel
    //NavBarVertical/MobileMenu
    nb_contact_us: 'Contáctenos', //Contact Us
    nb_account_settings: 'Ajustes de cuenta', //AccountSettings
    nb_privacy_policy: 'Política de privacidad', //PrivacyPolicy
    nb_backgroung_image: 'Imagen de fondo', //Background Image
    nb_about: 'Sobre TitleTab', //About Us
    nb_share_link: 'Compartir enlace', //Share Link
    nb_greatest_user: 'El usuario más grande', //GreatestUser
    nb_sign_in: 'Iniciar sesión', //SignIn
    nb_extension: 'Extensión para ', //Extension for
    nb_use_dark_theme: 'Usar tema oscuro', //Use dark theme
    nb_tab_headers: 'Encabezados de pestañas', //Tab headers
    nb_with_background: 'con fondo', //with background
    nb_use_images: 'Usar imágenes ', //Use the images
    nb_as_a_fill_tabs: 'como relleno de pestañas', //as a fill tabs
    nb_open_tabs_target: 'Abrir pestañas ', //Open Tabs
    nb_in_a_new_window: 'en una nueva ventana', //in a new window
    nb_show_search: 'Mostrar búsqueda', //Show Search
    nb_open_search_results_target: 'Abrir resultados de búsqueda ', //Open search results
    nb_default_search: 'Motor de búsqueda predeterminado', //Default Search Engine
    nb_add_space: 'Agregar espacio', //Add Space
    nb_public_space: 'Espacio público', //Public Space
    nb_general_settings: 'Configuraciones generales', //General Settings
    nb_private_space: 'Espacios privados', //private spaces
    nb_share: 'Compartir', //Share
    //Share
    s_share: 'Compartir', //Share
    s_link_to_share: 'Enlace para compartir', //Link to share
    s_share_on: 'Compartir en', //Share on
    s_copy_link: 'Copiar enlace', //Copy link
    //Scale
    s_tab_size_desktop: 'Tamaño de la pestaña en el escritorio', //Tab Size on Desktop
    s_tab_size_mobile: 'Tamaño de la pestaña en móvil / tablet', //Tab Size on Mobile / Tablet
    s_tabs_in_row: 'Pestañas en una fila', //Tabs in a Row
    //AddSpaceModal
    asm_space_name: 'Ingrese el nombre del espacio', //Enter Space Name
    asm_add_new_space: 'Agregar nuevo espacio', //Add New Space
    asm_select_icon: 'Seleccionar icono', //Select Icon
    //UpdateSpaceModal
    usm_space_name: 'Ingrese el nombre del espacio', //Enter Space Name
    usm_update_space: 'Actualizar espacio', //Update Space
    usm_select_icon: 'Seleccionar icono', //Select Icon
    //AddTabsModal
    atm_log_in: 'Inicie sesión, los datos no se guardan', //Log in, data is not saved
    atm_add_title: 'Añadir a TitleTab', //Add to TitleTab
    atm_type_url: 'Escriba la URL para añadir a TitleTab', //Type URL to add to TitleTab
    atm_enter_title: 'Ingrese título (es opcional)', //Enter Title (is optional)
    atm_add_cancel: 'Cancelar', //Cancel
    //UpdateTabsModal
    utm_url_address: 'Dirección URL', //URL Address
    utm_title: 'Título', //Title
    utm_base64_image_encoder: 'Codificador de imagen Base64', //Base64 Image Encoder
    utm_title_color: 'Color del título', //Title Color
    utm_background_color: 'Color de fondo', //Background Color
    utm_add_custom_image:
      'Añadir imagen personalizada (dirección URL o Base64)', //Add custom image (URL address or Base64)
    utm_apply_update: 'Aplicar actualización', //Apply update
    utm_delete: 'Borrar', //Delete
    utm_cancel: 'Cancelar', //Cancel
    //UpdateNavigation
    un_primary_settings: 'Configuración principal', //Primary settings
    un_customize_color_apply: 'Personalizar color y aplicar', //Customize Color and Apply
    un_apply_screenshot:
      'Aplicar una captura de pantalla de la página del sitio web', //Apply a screenshot of the website page
    un_apply_logo: 'Aplicar un logo del sitio web', //Apply a logo of the website
    un_temporarily_unavailable: 'Temporalmente no disponible', //Temporarily unavailable
    un_add_own_image: 'Agregar e aplicar su propia imagen', //Add and apply your own image
    //SignIn/SignUp/ForgotPassword
    auth_sing_in_titletab: 'Iniciar sesión en TitleTab', //Sign in TitleTab
    auth_sign_up_titletab: 'Registrarse en TitleTab', //Sign up TitleTab
    auth_password_recovery: 'Recuperación de contraseña', //Password Recovery
    auth_sign_google: 'Iniciar sesión con Google', //Sign in with Google
    auth_sign_facebook: 'Iniciar sesión con Facebook', //Sign in with Facebook
    auth_sign_twitter: 'Iniciar sesión con Twitter', //Sign in with Twitter
    auth_or: 'o', //or
    auth_email_address: 'Dirección de correo electrónico', //Email Address
    auth_password: 'Contraseña', //Password
    auth_password_confirmation: 'Confirmar contraseña', //Confirm Password
    auth_forgot_password: 'Olvidé la contraseña', //I forgot the password
    auth_sign_in: 'Iniciar sesión', //Sign In
    auth_sign_up: 'Registrarse', //Sign Up
    auth_reset_password: 'Restablecer contraseña', //Reset Password
    auth_need_sign_up: 'Necesito registrarme', //I need to Sign up
    auth_have_account: 'Tengo una cuenta', //I have an Account
    //UpdateProfile
    up_account_settings: 'Ajustes de cuenta', //Account Settings
    up_greatest_user: 'El usuario más grande', //Greatest User
    up_make_selection: 'por favor, realice una selección', //please make a selection
    up_change_email: 'Cambiar correo electrónico', //Change Email
    up_change_password: 'Cambiar contraseña', //Change Password
    up_delete_account: 'Eliminar cuenta', //Delete Account
    up_log_out: 'Cerrar sesión', //Sign Out
    up_account_settingsCancel: 'Cancelar', //Cancel
    //ChangeEmail
    ce_change_email: 'Cambiar correo electrónico', //Change Email
    ce_new_email: 'Nuevo correo electrónico', //New Email
    ce_password: 'Contraseña', //Password
    ce_confirm_password: 'Confirmar contraseña', //Confirm Password
    ce_save_new_email: 'Guardar nuevo correo electrónico', //Save New Email
    ce_passwords_not_match: '¡Las contraseñas no coinciden!', //Passwords do not match!
    ce_wrong_password: '¡Contraseña incorrecta!', //Wrong Password!
    ce_new_email_saved: '¡Dirección de correo electrónico cambiada!', //Email Address Changed!
    ce_failed_update_email: '¡Error al actualizar el correo electrónico!', //Failed to update email!
    ce_forbidden:
      'Su método de autorización no implica un cambio de correo electrónico. No almacenamos su correo electrónico.', //Your authorization method does not imply a email change. We do not store your email.
    ce_cancel: 'Cancelar', //Cancel
    //ChangePassword
    cp_change_password: 'Cambiar contraseña', //Change Password
    cp_old_password: 'Contraseña anterior', //Old Password
    cp_new_password: 'Nueva contraseña', //New Password
    cp_confirm_new_password: 'Confirmar nueva contraseña', //Confirm New Password
    cp_save_new_password: 'Guardar nueva contraseña', //Save New Password
    cp_passwords_not_match: '¡Las contraseñas no coinciden!', //Passwords do not match!
    cp_new_password_saved: '¡Nueva contraseña guardada!', //New Password Saved!
    cp_failed_update_password: '¡Error al actualizar la contraseña!', //Failed to update password!
    cp_wrong_password: '¡Contraseña incorrecta!', //Wrong Password!
    cp_forbidden:
      'Su método de autorización no implica un cambio de contraseña. No almacenamos su contraseña.', //Your authorization method does not imply a password change. We do not store your password.
    cp_cancel: 'Cancelar', //Cancel
    //DeleteAccount
    da_delete_account: 'Eliminar cuenta', //Delete Account
    da_delete_account_text: '¿Estás seguro?', //Are you sure?
    da_email: 'Correo electrónico', //Email
    da_password: 'Contraseña', //Password
    da_confirm_password: 'Confirmar contraseña', //Confirm Password
    da_delete_account_cancel: 'Cancelar', //Cancel
    da_delete_account_delete: 'Eliminar cuenta', //Delete Account
    da_password_not_match: '¡Las contraseñas no coinciden!', //Passwords do not match!
    da_incorrect_email: '¡Correo electrónico incorrecto!', //Incorrect Email!
    da_account_deleted: '¡Cuenta eliminada correctamente!', //Account successfully deleted!
    da_account_faled: '¡Error al eliminar la cuenta!', //Failed to delete account!
    da_incorrect_data: '¡Datos de inicio de sesión incorrectos!', //Incorrect login data!
    da_google_delete: 'Ingresa tu correo electrónico de la cuenta de Google', //Enter your Google account email
    da_facebook_delete:
      'Ingresa tu correo electrónico de la cuenta de Facebook', //Enter your Facebook account email
    da_twitter_delete: 'Ingresa tu nombre de usuario de la cuenta de Twitter', //Enter your Twitter account Name
    //SearchVertical/SearchHorizontal
    search_web: 'Buscar en la web y en pestañas', //Search the Web and Tabs
    search_listening: 'Escuchando, por favor habla...', //Listening, please speak...
    search_error: 'Algo salió mal, inténtalo de nuevo', //Something went wrong, try again
    search_unsupport:
      'El reconocimiento de voz no es compatible con este navegador.', //Speech Recognition is not supported by this browser.
    //Añadir
    add_site: 'Añadir un sitio', //Add a site
    //UploaderFile
    uf_main: '<u>Cargar</u> o arrastrar una imagen aquí', //Upload or drop an image right here
    uf_drag: '<u>Arrastra</u> tu archivo aquí', //Drop your file here
    uf_success: '<span style="color:green"><u>Carga</u> exitosa!</span>', //Upload successful!
    uf_errorMaxSize:
      '<span style="color:red">Sólo puedes cargar archivos <u>hasta 2MB</u></span>', //You can only upload files up to 2MB
    uf_errorMinSize:
      '<span style="color:red">Sólo puedes cargar archivos <u>más grandes de 100KB</u></span>', //You can only upload files more than 100KB
    uf_errorType:
      '<span style="color:red">Sólo puedes cargar archivos <u>de imagen</u></span>', //You can only upload image files
    uf_errorMultiple:
      '<span style="color:red">Sólo puedes cargar <u>un archivo</u> a la vez</span>', //You can only upload one file at a timec
    //UpdateSpacePublicModal
    uspm_public_space_settings: 'Configuración del espacio público', //Public Space Settings
    uspm_activate_public_space: 'Activar espacio público', //Activate Public Space
    uspm_message:
      'Una vez que active el espacio público, podrá comenzar a compartir sus enlaces en línea.', //Once you activate the public space, you can start sharing your links online.
    uspm_path: 'Ruta de acceso al espacio público', //Path for public space access
    uspm_link: 'Enlace al espacio público (solo lectura)', //Link to public space (read only)
    uspm_public_space_name: 'Nombre del espacio público (opcional)', //Public space name (is optional)
    uspm_public_space_title: 'Título del espacio público (solo lectura)', //Public space title (read only)
    uspm_description: 'Descripción (opcional)', //Description (is optional)
    uspm_save_space_settings: 'Guardar configuración del espacio', //Save Space Settings
    uspm_activate_public_space: 'Activar espacio público', //Activate Public Space
    uspm_sign_in_to_get_started: 'Inicie sesión para comenzar', //Sign in to get started
    uspm_cancel: 'Cancelar', //Cancel
    //BackgroundModal
    bm_choose_image: 'Imagen de fondo', //Background Image
    bm_or: 'o', //or
  },
  pt: {
    //About
    a_title: 'Sobre TitleTab', //About TitleTab
    a_cancel: 'Cancelar', //Cancel
    //NavBarVertical/MobileMenu
    nb_contact_us: 'Contate-nos', //Contact Us
    nb_account_settings: 'Configurações da Conta', //AccountSettings
    nb_privacy_policy: 'Política de Privacidade', //PrivacyPolicy
    nb_backgroung_image: 'Imagem de Fundo', //Background Image
    nb_about: 'Sobre TitleTab', //About Us
    nb_share_link: 'Compartilhar Link', //Share Link
    nb_greatest_user: 'O Maior Usuário', //GreatestUser
    nb_sign_in: 'Entrar', //SignIn
    nb_extension: 'Extensão para ', //Extension for
    nb_use_dark_theme: 'Usar tema escuro', //Use dark theme
    nb_tab_headers: 'Cabeçalhos de guias', //Tab headers
    nb_with_background: 'com fundo', //with background
    nb_use_images: 'Use as imagens ', //Use the images
    nb_as_a_fill_tabs: 'como abas', //as a fill tabs
    nb_open_tabs_target: 'Abrir as abas ', //Open Tabs
    nb_in_a_new_window: 'em uma nova janela', //in a new window
    nb_show_search: 'Mostrar Pesquisa', //Show Search
    nb_open_search_results_target: 'Abrir os resultados de pesquisa ', //Open search results
    nb_default_search: 'Motor de Busca Padrão', //Default Search Engine
    nb_add_space: 'Adicionar espaço', //Add space
    nb_public_space: 'Espaço público', //Public space
    nb_general_settings: 'Configurações gerais', //General settings
    nb_private_space: 'Espaços privados', //private spaces
    nb_share: 'Compartilhar', //Share
    //Share
    s_share: 'Compartilhar', // Share
    s_link_to_share: 'Link para compartilhar', // Link to share
    s_share_on: 'Compartilhar no', // Share on
    s_copy_link: 'Copiar link', // Copy link
    //Scale
    s_tab_size_desktop: 'Tamanho da Aba no Desktop', //Tab Size on Desktop
    s_tab_size_mobile: 'Tamanho da Aba em Dispositivos Móveis / Tablet', //Tab Size on Mobile / Tablet
    s_tabs_in_row: 'Abas em Uma Linha', //Tabs in a Row
    //AddSpaceModal
    asm_space_name: 'Digite o nome do espaço', //Enter Space Name
    asm_add_new_space: 'Adicionar novo espaço', //Add New Space
    asm_select_icon: 'Selecionar ícone', //Select Icon
    //UpdateSpaceModal
    usm_space_name: 'Digite o nome do espaço', //Enter Space Name
    usm_update_space: 'Atualizar espaço', //Update Space
    usm_select_icon: 'Selecionar ícone', //Select Icon
    //AddTabsModal
    atm_log_in: 'Faça login, os dados não são salvos', //Log in, data is not saved
    atm_add_title: 'Adicionar ao TitleTab', //Add to TitleTab
    atm_type_url: 'Digite a URL para adicionar ao TitleTab', //Type URL to add to TitleTab
    atm_enter_title: 'Digite o Título (é opcional)', //Enter Title (is optional)
    atm_add_cancel: 'Cancelar', //Cancel
    //UpdateTabsModal
    utm_url_address: 'Endereço URL', //URL Address
    utm_title: 'Título', //Title
    utm_base64_image_encoder: 'Codificador de Imagem Base64', //Base64 Image Encoder
    utm_title_color: 'Cor do Título', //Title Color
    utm_background_color: 'Cor do Fundo', //Background Color
    utm_add_custom_image:
      'Adicionar imagem personalizada (endereço URL ou Base64)', //Add custom image (URL address or Base64)
    utm_apply_update: 'Aplicar atualização', //Apply update
    utm_delete: 'Excluir', //Delete
    utm_cancel: 'Cancelar', //Cancel
    //UpdateNavigation
    un_primary_settings: 'Configurações principais', //Primary settings
    un_customize_color_apply: 'Personalizar Cor e Aplicar', //Customize Color and Apply
    un_apply_screenshot: 'Aplicar uma captura de tela da página do site', //Apply a screenshot of the website page
    un_apply_logo: 'Aplicar um logo do site', //Apply a logo of the website
    un_temporarily_unavailable: 'Temporariamente indisponível', //Temporarily unavailable
    un_add_own_image: 'Adicionar e aplicar sua própria imagem', //Add and apply your own image
    //SignIn/SignUp/ForgotPassword
    auth_sing_in_titletab: 'Entrar no TitleTab', //Sign in TitleTab
    auth_sign_up_titletab: 'Cadastrar-se no TitleTab', //Sign up TitleTab
    auth_password_recovery: 'Recuperação de Senha', //Password Recovery
    auth_sign_google: 'Entrar com o Google', //Sign in with Google
    auth_sign_facebook: 'Entrar com o Facebook', //Sign in with Facebook
    auth_sign_twitter: 'Entrar com o Twitter', //Sign in with Twitter
    auth_or: 'ou', //or
    auth_email_address: 'Endereço de Email', //Email Address
    auth_password: 'Senha', //Password
    auth_password_confirmation: 'Confirmar Senha', //Confirm Password
    auth_forgot_password: 'Esqueci minha senha', //I forgot the password
    auth_sign_in: 'Entrar', //Sign In
    auth_sign_up: 'Cadastrar-se', //Sign Up
    auth_reset_password: 'Redefinir Senha', //Reset Password
    auth_need_sign_up: 'Preciso me Cadastrar', //I need to Sign up
    auth_have_account: 'Tenho uma Conta', //I have an Account
    //UpdateProfile
    up_account_settings: 'Configurações da Conta', //Account Settings
    up_greatest_user: 'O Maior Usuário', //Greatest User
    up_make_selection: 'por favor, faça uma seleção', //please make a selection
    up_change_email: 'Mudar Email', //Change Email
    up_change_password: 'Mudar Senha', //Change Password
    up_delete_account: 'Excluir Conta', //Delete Account
    up_log_out: 'Sair', //Sign Out
    up_account_settingsCancel: 'Cancelar', //Cancel
    //ChangeEmail
    ce_change_email: 'Mudar Email', //Change Email
    ce_new_email: 'Novo Email', //New Email
    ce_password: 'Senha', //Password
    ce_confirm_password: 'Confirmar Senha', //Confirm Password
    ce_save_new_email: 'Salvar Novo Email', //Save New Email
    ce_passwords_not_match: 'As senhas não correspondem!', //Passwords do not match!
    ce_wrong_password: 'Senha Incorreta!', //Wrong Password!
    ce_new_email_saved: 'Endereço de Email Alterado!', //Email Address Changed!
    ce_failed_update_email: 'Falha ao atualizar o email!', //Failed to update email!
    ce_forbidden:
      'Seu método de autorização não implica uma alteração de email. Não armazenamos o seu email.', //Your authorization method does not imply a email change. We do not store your email.
    ce_cancel: 'Cancelar', //Cancel
    //ChangePassword
    cp_change_password: 'Mudar Senha', //Change Password
    cp_old_password: 'Senha Antiga', //Old Password
    cp_new_password: 'Nova Senha', //New Password
    cp_confirm_new_password: 'Confirme a Nova Senha', //Confirm New Password
    cp_save_new_password: 'Salvar Nova Senha', //Save New Password
    cp_passwords_not_match: 'As senhas não correspondem!', //Passwords do not match!
    cp_new_password_saved: 'Nova Senha Salva!', //New Password Saved!
    cp_failed_update_password: 'Falha ao atualizar a senha!', //Failed to update password!
    cp_wrong_password: 'Senha Incorreta!', //Wrong Password!
    cp_forbidden:
      'Seu método de autorização não implica uma alteração de senha. Não armazenamos a sua senha.', //Your authorization method does not imply a password change. We do not store your password.
    cp_cancel: 'Cancelar', //Cancel
    //DeleteAccount
    da_delete_account: 'Excluir Conta', //Delete Account
    da_delete_account_text: 'Você tem certeza?', //Are you sure?
    da_email: 'Email', //Email
    da_password: 'Senha', //Password
    da_confirm_password: 'Confirmar Senha', //Confirm Password
    da_delete_account_cancel: 'Cancelar', //Cancel
    da_delete_account_delete: 'Excluir Conta', //Delete Account
    da_password_not_match: 'As senhas não correspondem!', //Passwords do not match!
    da_incorrect_email: 'Email Incorreto!', //Incorrect Email!
    da_account_deleted: 'Conta excluída com sucesso!', //Account successfully deleted!
    da_account_faled: 'Falha ao excluir a conta!', //Failed to delete account!
    da_incorrect_data: 'Dados de login incorretos!', //Incorrect login data!
    da_google_delete: 'Digite o email da sua conta do Google', //Enter your Google account email
    da_facebook_delete: 'Digite o email da sua conta do Facebook', //Enter your Facebook account email
    da_twitter_delete: 'Digite o nome da sua conta do Twitter', //Enter your Twitter account Name
    //SearchVertical/SearchHorizontal
    search_web: 'Pesquisar na Web e Guias', //Search the Web and Tabs
    search_listening: 'Ouvindo, por favor, fale...', //Listening, please speak...
    search_error: 'Algo deu errado, tente novamente', //Something went wrong, try again
    search_unsupport:
      'Reconhecimento de Fala não é suportado por este navegador.', //Speech Recognition is not supported by this browser.
    //Add
    add_site: 'Adicionar um site', //Add a site
    //UploaderFile
    uf_main: '<u>Fazer upload</u> ou arraste uma imagem aqui', //Upload or drag an image here
    uf_drag: '<u>Solte</u> seu arquivo aqui', //Drop your file here
    uf_success: '<span style="color:green"><u>Upload</u> bem-sucedido!</span>', //Upload successful!
    uf_errorMaxSize:
      '<span style="color:red">Você só pode fazer upload de arquivos <u>até 2MB</u></span>', //You can only upload files <u>up to 2MB</u>
    uf_errorMinSize:
      '<span style="color:red">Você só pode fazer upload de arquivos <u>mais do que 100KB</u></span>', //You can only upload files <u>more than 100KB</u>
    uf_errorType:
      '<span style="color:red">Você só pode fazer upload de <u>arquivos de imagem</u></span>', //You can only upload <u>image files</u>
    uf_errorMultiple:
      '<span style="color:red">Você só pode fazer upload de <u>um arquivo</u> de cada vez</span>', //You can only upload <u>one file</u> at a time
    //UpdateSpacePublicModal
    uspm_public_space_settings: 'Configurações do espaço público', //Public Space Settings
    uspm_activate_public_space: 'Ativar espaço público', //Activate Public Space
    uspm_message:
      'Depois de ativar o espaço público, você pode começar a compartilhar seus links online.', //Once you activate the public space, you can start sharing your links online.
    uspm_path: 'Caminho de acesso ao espaço público', //Path for public space access
    uspm_link: 'Link para espaço público (somente leitura)', //Link to public space (read only)
    uspm_public_space_name: 'Nome do espaço público (opcional)', //Public space name (is optional)
    uspm_public_space_title: 'Título do espaço público (somente leitura)', //Public space title (read only)
    uspm_description: 'Descrição (opcional)', //Description (is optional)
    uspm_save_space_settings: 'Salvar configurações do espaço', //Save Space Settings
    uspm_activate_public_space: 'Ativar espaço público', //Activate Public Space
    uspm_sign_in_to_get_started: 'Faça login para começar', //Sign in to get started
    uspm_cancel: 'Cancelar', //Cancel
    //BackgroundModal
    bm_choose_image: 'Imagem de Fundo', //Background Image
    bm_or: 'ou', //or
  },
  it: {
    //About
    a_title: 'Informazioni su TitleTab', //About TitleTab
    a_cancel: 'Annulla', //Cancel
    //NavBarVertical/MobileMenu
    nb_contact_us: 'Contattaci', //Contact Us
    nb_account_settings: "Impostazioni dell'account", //AccountSettings
    nb_privacy_policy: 'Politica sulla privacy', //PrivacyPolicy
    nb_backgroung_image: 'Immagine di sfondo', //Background Image
    nb_about: 'Informazioni su TitleTab', //About Us
    nb_share_link: 'Condividi il link', //Share Link
    nb_greatest_user: 'Il più grande utente', //GreatestUser
    nb_sign_in: 'Accedi', //SignIn
    nb_extension: 'Estensione per ', //Extension for
    nb_use_dark_theme: 'Usa tema scuro', //Use dark theme
    nb_tab_headers: 'Intestazioni schede', //Tab headers
    nb_with_background: 'con sfondo', //with background
    nb_use_images: 'Usa le immagini ', //Use the images
    nb_as_a_fill_tabs: 'come riempimento per le schede', //as a fill tabs
    nb_open_tabs_target: 'Apri schede ', //Open Tabs
    nb_in_a_new_window: 'in una nuova finestra', //in a new window
    nb_show_search: 'Mostra ricerca', //Show Search
    nb_open_search_results_target: 'Apri risultati di ricerca ', //Open search results
    nb_default_search: 'Motore di ricerca predefinito', //Default Search Engine
    nb_add_space: 'Aggiungi spazio', //Add space
    nb_public_space: 'Spazio pubblico', //Public space
    nb_general_settings: 'Impostazioni generali', //General settings
    nb_private_space: 'Spazi privati', //private spaces
    nb_share: 'Condividi', //Share
    //Share
    s_share: 'Condividi', //Share
    s_link_to_share: 'Link per la condivisione', //Link to share
    s_share_on: 'Condividi su', //Share on
    s_copy_link: 'Copia il link', //Copy link
    //Scale
    s_tab_size_desktop: 'Dimensione scheda su desktop', //Tab Size on Desktop
    s_tab_size_mobile: 'Dimensione scheda su mobile/tablet', //Tab Size on Mobile / Tablet
    s_tabs_in_row: 'Schede in una riga', //Tabs in a Row
    //AddSpaceModal
    asm_space_name: 'Inserisci il nome dello spazio', //Enter Space Name
    asm_add_new_space: 'Aggiungi nuovo spazio', //Add New Space
    asm_select_icon: "Seleziona l'icona", //Select Icon
    //UpdateSpaceModal
    usm_space_name: 'Inserisci il nome dello spazio', //Enter Space Name
    usm_update_space: 'Aggiorna spazio', //Update Space
    usm_select_icon: "Seleziona l'icona", //Select Icon
    //AddTabsModal
    atm_log_in: 'Accedi, i dati non sono salvati', //Log in, data is not saved
    atm_add_title: 'Aggiungi a TitleTab', //Add to TitleTab
    atm_type_url: "Digita l'URL da aggiungere a TitleTab", //Type URL to add to TitleTab
    atm_enter_title: 'Inserisci il titolo (è opzionale)', //Enter Title (is optional)
    atm_add_cancel: 'Annulla', //Cancel
    //UpdateTabsModal
    utm_url_address: 'Indirizzo URL', //URL Address
    utm_title: 'Titolo', //Title
    utm_base64_image_encoder: 'Codificatore immagine Base64', //Base64 Image Encoder
    utm_title_color: 'Colore del titolo', //Title Color
    utm_background_color: 'Colore di sfondo', //Background Color
    utm_add_custom_image:
      'Aggiungi immagine personalizzata (indirizzo URL o Base64)', //Add custom image (URL address or Base64)
    utm_apply_update: 'Applica aggiornamento', //Apply update
    utm_delete: 'Elimina', //Delete
    utm_cancel: 'Annulla', //Cancel
    //UpdateNavigation
    un_primary_settings: 'Impostazioni principali', //Primary settings
    un_customize_color_apply: 'Personalizza il colore e applica', //Customize Color and Apply
    un_apply_screenshot: 'Applica uno screenshot della pagina del sito web', //Apply a screenshot of the website page
    un_apply_logo: 'Applica il logo del sito web', //Apply a logo of the website
    un_temporarily_unavailable: 'Temporaneamente non disponibile', //Temporarily unavailable
    un_add_own_image: 'Aggiungi e applicala tua immagine', //Add and apply your own image
    //SignIn/SignUp/ForgotPassword
    auth_sing_in_titletab: 'Accedi a TitleTab', //Sign in TitleTab
    auth_sign_up_titletab: 'Iscriviti a TitleTab', //Sign up TitleTab
    auth_password_recovery: 'Recupero password', //Password Recovery
    auth_sign_google: 'Accedi con Google', //Sign in with Google
    auth_sign_facebook: 'Accedi con Facebook', //Sign in with Facebook
    auth_sign_twitter: 'Accedi con Twitter', //Sign in with Twitter
    auth_or: 'o', //or
    auth_email_address: 'Indirizzo email', //Email Address
    auth_password: 'Password', //Password
    auth_password_confirmation: 'Conferma password', //Confirm Password
    auth_forgot_password: 'Ho dimenticato la password', //I forgot the password
    auth_sign_in: 'Accedi', //Sign In
    auth_sign_up: 'Iscriviti', //Sign Up
    auth_reset_password: 'Reimposta password', //Reset Password
    auth_need_sign_up: 'Ho bisogno di iscrivermi', //I need to Sign up
    auth_have_account: 'Ho un account', //I have an Account
    //UpdateProfile
    up_account_settings: "Impostazioni dell'account", //Account Settings
    up_greatest_user: 'Il più grande utente', //Greatest User
    up_make_selection: 'si prega di fare una selezione', //please make a selection
    up_change_email: 'Cambia email', //Change Email
    up_change_password: 'Cambia password', //Change Password
    up_delete_account: 'Elimina account', //Delete Account
    up_log_out: 'Disconnetti', //Sign Out
    up_account_settingsCancel: 'Annulla', //Cancel
    //ChangeEmail
    ce_change_email: 'Cambia email', //Change Email
    ce_new_email: 'Nuova email', //New Email
    ce_password: 'Password', //Password
    ce_confirm_password: 'Conferma password', //Confirm Password
    ce_save_new_email: 'Salva nuova email', //Save New Email
    ce_passwords_not_match: 'Le password non corrispondono!', //Passwords do not match!
    ce_wrong_password: 'Password errata!', //Wrong Password!
    ce_new_email_saved: 'Indirizzo email cambiato!', //Email Address Changed!
    ce_failed_update_email: 'Impossibile aggiornare lemail!', //Failed to update email!
    ce_forbidden:
      'Il metodo di autorizzazione non prevede il cambio di email. Non conserviamo la tua email.', //Your authorization method does not imply a email change. We do not store your email.
    ce_cancel: 'Annulla', //Cancel
    //ChangePassword
    cp_change_password: 'Cambia password', //Change Password
    cp_old_password: 'Vecchia password', //Old Password
    cp_new_password: 'Nuova password', //New Password
    cp_confirm_new_password: 'Conferma nuova password', //Confirm New Password
    cp_save_new_password: 'Salva nuova password', //Save New Password
    cp_passwords_not_match: 'Le password non corrispondono!', //Passwords do not match!
    cp_new_password_saved: 'Nuova password salvata!', //New Password Saved!
    cp_failed_update_password: 'Impossibile aggiornare la password!', //Failed to update password!
    cp_wrong_password: 'Password errata!', //Wrong Password!
    cp_forbidden:
      'Il metodo di autorizzazione non prevede il cambio di password. Non conserviamo la tua password.', //Your authorization method does not imply a password change. We do not store your password.
    cp_cancel: 'Annulla', //Cancel
    //DeleteAccount
    da_delete_account: 'Elimina account', //Delete Account
    da_delete_account_text: 'Sei sicuro?', //Are you sure?
    da_email: 'Email', //Email
    da_password: 'Password', //Password
    da_confirm_password: 'Conferma password', //Confirm Password
    da_delete_account_cancel: 'Annulla', //Cancel
    da_delete_account_delete: 'Elimina account', //Delete Account
    da_password_not_match: 'Le password non corrispondono!', //Passwords do not match!
    da_incorrect_email: 'Email non corretta!', //Incorrect Email!
    da_account_deleted: 'Account eliminato con successo!', //Account successfully deleted!
    da_account_faled: "Impossibile eliminare l'account!", //Failed to delete account!
    da_incorrect_data: 'Dati di login non corretti!', //Incorrect login data!
    da_google_delete: "Inserisci l'email del tuo account Google", //Enter your Google account email
    da_facebook_delete: "Inserisci l'email del tuo account Facebook", //Enter your Facebook account email
    da_twitter_delete: 'Inserisci il nome del tuo account Twitter', //Enter your Twitter account Name
    //SearchVertical/SearchHorizontal
    search_web: 'Cerca sul Web e schede', //Search the Web and Tabs
    search_listening: 'In ascolto, parla pure...', //Listening, speak now...
    search_error: 'Qualcosa è andato storto, riprova', //Something went wrong, try again
    search_unsupport:
      'Il riconoscimento vocale non è supportato da questo browser.', //Speech recognition is not supported by this browser.
    //Add
    add_site: 'Aggiungi un sito', //Add a Site
    //UploaderFile
    uf_main: "<u>Carica</u> o trascina un'immagine qui", //Upload or drag an image here
    uf_drag: '<u>Trascina</u> il file qui', //Drag the file here
    uf_success: '<span style="color:green"><u>Caricamento</u> riuscito!</span>', //Upload successful!
    uf_errorMaxSize:
      '<span style="color:red">Puoi caricare solo file <u>fino a 2MB</u></span>', //You can only upload files up to 2MB
    uf_errorMinSize:
      '<span style="color:red">Puoi caricare solo file <u>più grandi di 100KB</u></span>', //You can only upload files larger than 100KB
    uf_errorType:
      '<span style="color:red">Puoi caricare solo <u>file immagine</u></span>', //You can only upload image files
    uf_errorMultiple:
      '<span style="color:red">Puoi caricare <u>un solo file</u> alla volta</span>', //You can only upload one file at a time
    //UpdateSpacePublicModal
    uspm_public_space_settings: 'Impostazioni dello spazio pubblico', //Public Space Settings
    uspm_activate_public_space: 'Attiva lo spazio pubblico', //Activate Public Space
    uspm_message:
      'Una volta attivato lo spazio pubblico, puoi iniziare a condividere i tuoi link online.', //Once you activate the public space, you can start sharing your links online.
    uspm_path: "Percorso per l'accesso allo spazio pubblico", //Path for public space access
    uspm_link: 'Link allo spazio pubblico (solo lettura)', //Link to public space (read only)
    uspm_public_space_name: 'Nome dello spazio pubblico (opzionale)', //Public space name (is optional)
    uspm_public_space_title: 'Titolo dello spazio pubblico (solo lettura)', //Public space title (read only)
    uspm_description: 'Descrizione (opzionale)', //Description (is optional)
    uspm_save_space_settings: 'Salva impostazioni dello spazio', //Save Space Settings
    uspm_activate_public_space: 'Attiva lo spazio pubblico', //Activate Public Space
    uspm_sign_in_to_get_started: 'Accedi per iniziare', //Sign in to get started
    uspm_cancel: 'Annulla', //Cancel
    //BackgroundModal
    bm_choose_image: 'Immagine di sfondo', //Background Image
    bm_or: 'o', //or
  },
  pl: {
    //About
    a_title: 'Informacje o TitleTab', //About TitleTab
    a_cancel: 'Anuluj', //Cancel
    //NavBarVertical/MobileMenu
    nb_contact_us: 'Skontaktuj się z nami', //Contact Us
    nb_account_settings: 'Ustawienia konta', //AccountSettings
    nb_privacy_policy: 'Polityka prywatności', //PrivacyPolicy
    nb_backgroung_image: 'Obraz tła', //Background Image
    nb_about: 'Informacje o TitleTab', //About Us
    nb_share_link: 'Udostępnij link', //Share Link
    nb_greatest_user: 'Największy Użytkownik', //GreatestUser
    nb_sign_in: 'Zaloguj się', //SignIn
    nb_extension: 'Rozszerzenie dla ', //Extension for
    nb_use_dark_theme: 'Użyj ciemnego motywu', //Use dark theme
    nb_tab_headers: 'Nagłówki kart', //Tab headers
    nb_with_background: 'z tłem', //with background
    nb_use_images: 'Użyj obrazów ', //Use the images
    nb_as_a_fill_tabs: 'jako wypełnienie kart', //as a fill tabs
    nb_open_tabs_target: 'Otwórz karty ', //Open Tabs
    nb_in_a_new_window: 'w nowym oknie', //in a new window
    nb_show_search: 'Pokaż wyszukiwarkę', //Show Search
    nb_open_search_results_target: 'Otwórz wyniki wyszukiwania ', //Open search results
    nb_default_search: 'Domyślna wyszukiwarka', //Default Search Engine
    nb_add_space: 'Dodaj przestrzeń', //Add Space
    nb_public_space: 'Przestrzeń publiczna', //Public Space
    nb_general_settings: 'Ustawienia ogólne', //General Settings
    nb_private_space: 'Przestrzenie prywatne', //Private Spaces
    nb_share: 'Udostępnij', //Share
    //Share
    s_share: 'Udostępnij', //Share
    s_link_to_share: 'Link do udostępnienia', //Link to share
    s_share_on: 'Udostępnij na', //Share on
    s_copy_link: 'Kopiuj link', //Copy link
    //Scale
    s_tab_size_desktop: 'Rozmiar karty na komputerze', //Tab Size on Desktop
    s_tab_size_mobile: 'Rozmiar karty na urządzeniach mobilnych / tabletach', //Tab Size on Mobile / Tablet
    s_tabs_in_row: 'Karty w rzędzie', //Tabs in a Row
    //AddSpaceModal
    asm_space_name: 'Wprowadź nazwę przestrzeni', //Enter Space Name
    asm_add_new_space: 'Dodaj nową przestrzeń', //Add New Space
    asm_select_icon: 'Wybierz ikonę', //Select Icon
    //UpdateSpaceModal
    usm_space_name: 'Wprowadź nazwę przestrzeni', //Enter Space Name
    usm_update_space: 'Aktualizuj przestrzeń', //Update Space
    usm_select_icon: 'Wybierz ikonę', //Select Icon
    //AddTabsModal
    atm_log_in: 'Zaloguj się, dane nie są zapisywane', //Log in, data is not saved
    atm_add_title: 'Dodaj do TitleTab', //Add to TitleTab
    atm_type_url: 'Wpisz adres URL, aby dodać do TitleTab', //Type URL to add to TitleTab
    atm_enter_title: 'Wprowadź tytuł (opcjonalnie)', //Enter Title (is optional)
    atm_add_cancel: 'Anuluj', //Cancel
    //UpdateTabsModal
    utm_url_address: 'Adres URL', //URL Address
    utm_title: 'Tytuł', //Title
    utm_base64_image_encoder: 'Koder obrazu Base64', //Base64 Image Encoder
    utm_title_color: 'Kolor tytułu', //Title Color
    utm_background_color: 'Kolor tła', //Background Color
    utm_add_custom_image: 'Dodaj niestandardowy obraz (adres URL lub Base64)', //Add custom image (URL address or Base64)
    utm_apply_update: 'Zastosuj aktualizację', //Apply update
    utm_delete: 'Usuń', //Delete
    utm_cancel: 'Anuluj', //Cancel
    //UpdateNavigation
    un_primary_settings: 'Ustawienia podstawowe', //Primary settings
    un_customize_color_apply: 'Dostosuj kolor i zastosuj', //Customize Color and Apply
    un_apply_screenshot: 'Zastosuj zrzut ekranu strony internetowej', //Apply a screenshot of the website page
    un_apply_logo: 'Zastosuj logo strony internetowej', //Apply a logo of the website
    un_temporarily_unavailable: 'Tymczasowo niedostępne', //Temporarily unavailable
    un_add_own_image: 'Dodaj i zastosuj własny obraz', //Add and apply your own image
    //SignIn/SignUp/ForgotPassword
    auth_sing_in_titletab: 'Zaloguj się do TitleTab', //Sign in TitleTab
    auth_sign_up_titletab: 'Zarejestruj się w TitleTab', //Sign up TitleTab
    auth_password_recovery: 'Odzyskiwanie hasła', //Password Recovery
    auth_sign_google: 'Zaloguj się za pomocą Google', //Sign in with Google
    auth_sign_facebook: 'Zaloguj się za pomocą Facebooka', //Sign in with Facebook
    auth_sign_twitter: 'Zaloguj się za pomocą Twittera', //Sign in with Twitter
    auth_or: 'lub', //or
    auth_email_address: 'Adres e-mail', //Email Address
    auth_password: 'Hasło', //Password
    auth_password_confirmation: 'Potwierdź hasło', //Confirm Password
    auth_forgot_password: 'Zapomniałem hasła', //I forgot the password
    auth_sign_in: 'Zaloguj się', //Sign In
    auth_sign_up: 'Zarejestruj się', //Sign Up
    auth_reset_password: 'Zresetuj hasło', //Reset Password
    auth_need_sign_up: 'Potrzebuję się zarejestrować', //I need to Sign up
    auth_have_account: 'Mam konto', //I have an Account
    //UpdateProfile
    up_account_settings: 'Ustawienia konta', //Account Settings
    up_greatest_user: 'Największy Użytkownik', //Greatest User
    up_make_selection: 'proszę dokonać wyboru', //please make a selection
    up_change_email: 'Zmień adres e-mail', //Change Email
    up_change_password: 'Zmień hasło', //Change Password
    up_delete_account: 'Usuń konto', //Delete Account
    up_log_out: 'Wyloguj się', //Sign Out
    up_account_settingsCancel: 'Anuluj', //Cancel
    //ChangeEmail
    ce_change_email: 'Zmień adres e-mail', //Change Email
    ce_new_email: 'Nowy adres e-mail', //New Email
    ce_password: 'Hasło', //Password
    ce_confirm_password: 'Potwierdź hasło', //Confirm Password
    ce_save_new_email: 'Zapisz nowy adres e-mail', //Save New Email
    ce_passwords_not_match: 'Hasła nie są zgodne!', //Passwords do not match!
    ce_wrong_password: 'Błędne hasło!', //Wrong Password!
    ce_new_email_saved: 'Adres e-mail został zmieniony!', //Email Address Changed!
    ce_failed_update_email: 'Nie udało się zaktualizować adresu e-mail!', //Failed to update email!
    ce_forbidden:
      'Twój sposób autoryzacji nie obejmuje zmiany adresu e-mail. Nie przechowujemy Twojego adresu e-mail.', //Your authorization method does not imply a email change. We do not store your email.
    ce_cancel: 'Anuluj', //Cancel
    //ChangePassword
    cp_change_password: 'Zmień hasło', //Change Password
    cp_old_password: 'Stare hasło', //Old Password
    cp_new_password: 'Nowe hasło', //New Password
    cp_confirm_new_password: 'Potwierdź nowe hasło', //Confirm New Password
    cp_save_new_password: 'Zapisz nowe hasło', //Save New Password
    cp_passwords_not_match: 'Hasła nie są zgodne!', //Passwords do not match!
    cp_new_password_saved: 'Nowe hasło zostało zapisane!', //New Password Saved!
    cp_failed_update_password: 'Nie udało się zaktualizować hasła!', //Failed to update password!
    cp_wrong_password: 'Błędne hasło!', //Wrong Password!
    cp_forbidden:
      'Twój sposób autoryzacji nie obejmuje zmiany hasła. Nie przechowujemy Twojego hasła.', //Your authorization method does not imply a password change. We do not store your password.
    cp_cancel: 'Anuluj', //Cancel
    //DeleteAccount
    da_delete_account: 'Usuń konto', //Delete Account
    da_delete_account_text: 'Jesteś pewny?', //Are you sure?
    da_email: 'Email', //Email
    da_password: 'Hasło', //Password
    da_confirm_password: 'Potwierdź hasło', //Confirm Password
    da_delete_account_cancel: 'Anuluj', //Cancel
    da_delete_account_delete: 'Usuń konto', //Delete Account
    da_password_not_match: 'Hasła nie pasują do siebie!', //Passwords do not match!
    da_incorrect_email: 'Niepoprawny Email!', //Incorrect Email!
    da_account_deleted: 'Konto zostało pomyślnie usunięte!', //Account successfully deleted!
    da_account_faled: 'Nie udało się usunąć konta!', //Failed to delete account!
    da_incorrect_data: 'Niepoprawne dane logowania!', //Incorrect login data!
    da_google_delete: 'Wpisz swój adres e-mail konta Google', //Enter your Google account email
    da_facebook_delete: 'Wpisz swój adres e-mail konta Facebook', //Enter your Facebook account email
    da_twitter_delete: 'Wpisz swoją nazwę konta Twitter', //Enter your Twitter account Name
    //SearchVertical/SearchHorizontal
    search_web: 'Przeszukaj sieć i karty', //Search the Web and Tabs
    search_listening: 'Słucham, proszę mówić...', //Listening, please speak...
    search_error: 'Coś poszło nie tak, spróbuj ponownie', //Something went wrong, try again
    search_unsupport:
      'Rozpoznawanie mowy nie jest obsługiwane przez tą przeglądarkę.', //Speech Recognition is not supported by this browser.
    //Add
    add_site: 'Dodaj witrynę', //Add a site
    //UploaderFile
    uf_main: '<u>Prześlij</u> lub upuść obraz tutaj', //Upload or drop an image right here
    uf_drag: '<u>Upuść</u> swój plik tutaj', //Drop your file here
    uf_success: '<span style="color:green"><u>Prześlij</u> pomyślnie!</span>', //Upload successful!
    uf_errorMaxSize:
      '<span style="color:red">Możesz przesłać tylko pliki <u>o wielkości do 2MB</u></span>', //You can only upload files up to 2MB
    uf_errorMinSize:
      '<span style="color:red">Możesz przesłać tylko pliki <u>większe niż 100KB</u></span>', //You can only upload files more than 100KB
    uf_errorType:
      '<span style="color:red">Możesz przesłać tylko pliki <u>obrazów</u></span>', //You can only upload image files
    uf_errorMultiple:
      '<span style="color:red">Możesz przesłać tylko <u>jeden plik</u> na raz</span>', //You can only upload one file at a time
    //UpdateSpacePublicModal
    uspm_public_space_settings: 'Ustawienia publicznej przestrzeni', //Public Space Settings
    uspm_activate_public_space: 'Aktywuj przestrzeń publiczną', //Activate Public Space
    uspm_message:
      'Po aktywacji przestrzeni publicznej możesz zacząć udostępniać swoje linki online.', //Once you activate the public space, you can start sharing your links online.
    uspm_path: 'Ścieżka dostępu do przestrzeni publicznej', //Path for public space access
    uspm_link: 'Link do przestrzeni publicznej (tylko do odczytu)', //Link to public space (read only)
    uspm_public_space_name: 'Nazwa przestrzeni publicznej (opcjonalna)', //Public space name (is optional)
    uspm_public_space_title: 'Tytuł przestrzeni publicznej (tylko do odczytu)', //Public space title (read only)
    uspm_description: 'Opis (opcjonalny)', //Description (is optional)
    uspm_save_space_settings: 'Zapisz ustawienia przestrzeni', //Save Space Settings
    uspm_activate_public_space: 'Aktywuj przestrzeń publiczną', //Activate Public Space
    uspm_sign_in_to_get_started: 'Zaloguj się, aby rozpocząć', //Sign in to get started
    uspm_cancel: 'Anuluj', //Cancel
    //BackgroundModal
    bm_choose_image: 'Obraz tła', //Background Image
    bm_or: 'lub', //or
  },
  cs: {
    //About
    a_title: 'O TitleTab', //About TitleTab
    a_cancel: 'Zrušit', //Cancel
    //NavBarVertical/MobileMenu
    nb_contact_us: 'Kontaktujte nás', //Contact Us
    nb_account_settings: 'Nastavení účtu', // AccountSettings
    nb_privacy_policy: 'Zásady ochrany osobních údajů', // PrivacyPolicy
    nb_backgroung_image: 'Obrázek na pozadí', // BackgroundImage
    nb_about: 'O TitleTab', //About Us
    nb_share_link: 'Sdílet odkaz', //Share Link
    nb_greatest_user: 'Největší Uživatel', // GreatestUser
    nb_sign_in: 'Přihlásit se', // SignIn
    nb_extension: 'Rozšíření pro ', // Extension for
    nb_use_dark_theme: 'Používat tmavé téma', // Use dark theme
    nb_tab_headers: 'Záložkové záhlaví', //Tab headers
    nb_with_background: 's pozadím', //with background
    nb_use_images: 'Používat obrázky ', // Use the images
    nb_as_a_fill_tabs: 'jako výplň tabů', // as a fill tabs
    nb_open_tabs_target: 'Otevřít panely ', // Open Tabs
    nb_in_a_new_window: 'v novém okně', // in a new window
    nb_show_search: 'Zobrazit vyhledávání', // Show Search
    nb_open_search_results_target: 'Otevřít výsledky hledání ', // Open search results
    nb_default_search: 'Výchozí vyhledávač', // Default Search Engine
    nb_add_space: 'Přidat prostor', // Add Space
    nb_public_space: 'Veřejný prostor', // Public Space
    nb_general_settings: 'Obecná nastavení', // General Settings
    nb_private_space: 'Soukromé prostory', //Private Spaces
    nb_share: 'Sdílet', //Share
    //Share
    s_share: 'Sdílet', //Share
    s_link_to_share: 'Odkaz ke sdílení', //Link to share
    s_share_on: 'Sdílet na', //Share on Facebook
    s_copy_link: 'Kopírovat odkaz', //Copy link
    //Scale
    s_tab_size_desktop: 'Velikost panelu na počítači', // Tab Size on Desktop
    s_tab_size_mobile: 'Velikost panelu na mobilním / tabletu', // Tab Size on Mobile / Tablet
    s_tabs_in_row: 'Panely v řadě', // Tabs in a Row
    //AddSpaceModal
    asm_space_name: 'Zadejte název prostoru', //Enter Space Name
    asm_add_new_space: 'Přidat nový prostor', //Add New Space
    asm_select_icon: 'Vyberte ikonu', //Select Icon
    //UpdateSpaceModal
    usm_space_name: 'Zadejte název prostoru', //Enter Space Name
    usm_update_space: 'Aktualizovat prostor', //Update Space
    usm_select_icon: 'Vyberte ikonu', //Select Icon
    // AddTabsModal
    atm_log_in: 'Přihlaste se, data nejsou uložena', // Log in, data is not saved
    atm_add_title: 'Přidat do TitleTab', // Add to TitleTab
    atm_type_url: 'Zadejte adresu URL, kterou chcete přidat do TitleTab', // Type URL to add to TitleTab
    atm_enter_title: 'Zadejte název (není povinné)', // Enter Title (is optional)
    atm_add_cancel: 'Zrušit', // Cancel
    //UpdateTabsModal
    utm_url_address: 'Adresa URL', // URL Address
    utm_title: 'Název', // Title
    utm_base64_image_encoder: 'Base64 Image Encoder', // Base64 Image Encoder
    utm_title_color: 'Barva názvu', // Title Color
    utm_background_color: 'Barva pozadí', // Background Color
    utm_add_custom_image: 'Přidat vlastní obrázek (adresa URL nebo Base64)', // Add custom image (URL address or Base64)
    utm_apply_update: 'Použít aktualizaci', // Apply update
    utm_delete: 'Odstranit', // Delete
    utm_cancel: 'Zrušit', // Cancel
    // UpdateNavigation
    un_primary_settings: 'Základní nastavení', // Primary settings
    un_customize_color_apply: 'Upravit barvu a použít', // Customize Color and Apply
    un_apply_screenshot: 'Použít snímek obrazovky stránky webu', // Apply a screenshot of the website page
    un_apply_logo: 'Použít logo webu', // Apply a logo of the website
    un_temporarily_unavailable: 'Dočasně nedostupné', // Temporarily unavailable
    un_add_own_image: 'Přidat a použít vlastní obrázek', // Add and apply your own image
    //SignIn/SignUp/ForgotPassword
    auth_sing_in_titletab: 'Přihlášení do TitleTab', // Sign in TitleTab
    auth_sign_up_titletab: 'Registrace do TitleTab', // Sign up TitleTab
    auth_password_recovery: 'Obnova hesla', // Password Recovery
    auth_sign_google: 'Přihlášení přes Google', // Sign in with Google
    auth_sign_facebook: 'Přihlášení přes Facebook', // Sign in with Facebook
    auth_sign_twitter: 'Přihlášení přes Twitter', // Sign in with Twitter
    auth_or: 'nebo', // or
    auth_email_address: 'E-mailová adresa', // Email Address
    auth_password: 'Heslo', // Password
    auth_password_confirmation: 'Potvrzení hesla', // Confirm Password
    auth_forgot_password: 'Zapomněl jsem heslo', // I forgot the password
    auth_sign_in: 'Přihlásit se', // Sign In
    auth_sign_up: 'Zaregistrovat se', // Sign Up
    auth_reset_password: 'Resetovat heslo', // Reset Password
    auth_need_sign_up: 'Potřebuji se zaregistrovat', // I need to Sign up
    auth_have_account: 'Mám účet', // I have an Account
    //UpdateProfile
    up_account_settings: 'Nastavení účtu', // Account Settings
    up_greatest_user: 'Největší Uživatel', // Greatest User
    up_make_selection: 'prosím vyberte možnost', // please make a selection
    up_change_email: 'Změnit e-mail', // Change Email
    up_change_password: 'Změnit heslo', // Change Password
    up_delete_account: 'Odstranit účet', // Delete Account
    up_log_out: 'Odhlásit se', // Sign Out
    up_account_settingsCancel: 'Zrušit', // Cancel
    //ChangeEmail
    ce_change_email: 'Změnit e-mail', // Change Email
    ce_new_email: 'Nový e-mail', // New Email
    ce_password: 'Heslo', // Password
    ce_confirm_password: 'Potvrzení hesla', // Confirm Password
    ce_save_new_email: 'Uložit nový e-mail', // Save New Email
    ce_passwords_not_match: 'Hesla se neshodují!', // Passwords do not match!
    ce_wrong_password: 'Špatné heslo!', // Wrong Password!
    ce_new_email_saved: 'E-mailová adresa byla změněna!', // Email Address Changed!
    ce_failed_update_email: 'Nepodařilo se aktualizovat e-mail!', // Failed to update email!
    ce_forbidden:
      'Váš způsob autorizace nezahrnuje změnu e-mailu. Neukládáme váš e-mail.', // Your authorization method does not imply a email change. We do not store your email.
    ce_cancel: 'Zrušit', // Cancel
    //ChangePassword
    cp_change_password: 'Změnit heslo', // Change Password
    cp_old_password: 'Staré heslo', // Old Password
    cp_new_password: 'Nové heslo', // New Password
    cp_confirm_new_password: 'Potvrzení nového hesla', // Confirm New Password
    cp_save_new_password: 'Uložit nové heslo', // Save New Password
    cp_passwords_not_match: 'Hesla se neshodují!', // Passwords do not match!
    cp_new_password_saved: 'Nové heslo bylo uloženo!', // New Password Saved!
    cp_failed_update_password: 'Nepodařilo se aktualizovat heslo!', // Failed to update password!
    cp_wrong_password: 'Špatné heslo!', // Wrong Password!
    cp_forbidden:
      'Váš způsob autorizace nezahrnuje změnu hesla. Neukládáme vaše heslo.', // Your authorization method does not imply a password change. We do not store your password.
    cp_cancel: 'Zrušit', // Cancel
    //DeleteAccount
    da_delete_account: 'Smazat účet', //Delete Account
    da_delete_account_text: 'Jsi si jistý?', //Are you sure?
    da_email: 'Email', //Email
    da_password: 'Heslo', //Password
    da_confirm_password: 'Potvrďte heslo', //Confirm Password
    da_delete_account_cancel: 'Zrušit', //Cancel
    da_delete_account_delete: 'Smazat účet', //Delete Account
    da_password_not_match: 'Hesla se neshodují!', //Passwords do not match!
    da_incorrect_email: 'Nesprávný email!', //Incorrect Email!
    da_account_deleted: 'Účet byl úspěšně smazán!', //Account successfully deleted!
    da_account_failed: 'Nepodařilo se smazat účet!', //Failed to delete account!
    da_incorrect_data: 'Nesprávné přihlašovací údaje!', //Incorrect login data!
    da_google_delete: 'Zadejte email svého účtu Google', //Enter your Google account email
    da_facebook_delete: 'Zadejte email svého účtu Facebook', //Enter your Facebook account email
    da_twitter_delete: 'Zadejte název svého účtu Twitter', //Enter your Twitter account Name
    //SearchVertical/SearchHorizontal
    search_web: 'Vyhledat na webu a na kartách', //Search the Web and Tabs
    search_listening: 'Poslouchám, prosím mluvte...', //Listening, please speak...
    search_error: 'Něco se pokazilo, zkuste to znovu', //Something went wrong, try again
    search_unsupport: 'Rozpoznávání řeči není tímto prohlížečem podporováno.', //Speech Recognition is not supported by this browser.
    //Add
    add_site: 'Přidat stránku', //Add a site
    //UploaderFile
    uf_main: '<u>Nahrát</u> nebo přetáhněte obrázek sem', //Upload or drop an image right here
    uf_drag: '<u>Přetáhněte</u> svůj soubor sem', //Drop your file here
    uf_success: '<span style="color:green"><u>Nahrání</u> úspěšné!</span>', //Upload successful!
    uf_errorMaxSize:
      '<span style="color:red">Můžete nahrát pouze soubory <u>do 2MB</u></span>', //You can only upload files up to 2MB
    uf_errorMinSize:
      '<span style="color:red">Můžete nahrát pouze soubory <u>větší než 100KB</u></span>', //You can only upload files more than 100KB
    uf_errorType:
      '<span style="color:red">Můžete nahrát pouze <u>obrázkové soubory</u></span>', //You can only upload image files
    uf_errorMultiple:
      '<span style="color:red">Můžete nahrát pouze <u>jeden soubor</u> najednou</span>', //You can only upload one file at a time
    //UpdateSpacePublicModal
    uspm_public_space_settings: 'Nastavení veřejného prostoru', //Public Space Settings
    uspm_activate_public_space: 'Aktivovat veřejný prostor', //Activate Public Space
    uspm_message:
      'Jakmile aktivujete veřejný prostor, můžete začít sdílet své odkazy online.', //Once you activate the public space, you can start sharing your links online.
    uspm_path: 'Cesta pro přístup k veřejnému prostoru', //Path for public space access
    uspm_link: 'Odkaz na veřejný prostor (jen pro čtení)', //Link to public space (read only)
    uspm_public_space_name: 'Název veřejného prostoru (je volitelný)', //Public space name (is optional)
    uspm_public_space_title: 'Název veřejného prostoru (jen pro čtení)', //Public space title (read only)
    uspm_description: 'Popis (je volitelný)', //Description (is optional)
    uspm_save_space_settings: 'Uložit nastavení prostoru', //Save Space Settings
    uspm_activate_public_space: 'Aktivovat veřejný prostor', //Activate Public Space
    uspm_sign_in_to_get_started: 'Přihlaste se pro začátek', //Sign in to get started
    uspm_cancel: 'Zrušit', //Cancel
    //BackgroundModal
    bm_choose_image: 'Obrázek na pozadí', //Background Image
    bm_or: 'lub', //or
  },
  hr: {
    //About
    a_title: 'O TitleTab', //About TitleTab
    a_cancel: 'Otkazati', //Cancel
    //NavBarVertical/MobileMenu
    nb_contact_us: 'Kontaktirajte nas', //Contact Us
    nb_account_settings: 'Postavke računa', //AccountSettings
    nb_privacy_policy: 'Politika privatnosti', //PrivacyPolicy
    nb_backgroung_image: 'Pozadinska slika', //BackgroundImage
    nb_about: 'O TitleTab', //About Us
    nb_share_link: 'Podijelite vezu', //Share Link
    nb_greatest_user: 'Najveći korisnik', //GreatestUser
    nb_sign_in: 'Prijava', //SignIn
    nb_extension: 'Dodatak za ', //Extension for
    nb_use_dark_theme: 'Koristi tamnu temu', //Use dark theme
    nb_tab_headers: 'Zaglavlja kartica', //Tab headers
    nb_with_background: 's pozadinom', //with background
    nb_use_images: 'Koristi slike ', //Use the images
    nb_as_a_fill_tabs: 'kao punjenje kartica', //as a fill tabs
    nb_open_tabs_target: 'Otvori kartice ', //Open Tabs
    nb_in_a_new_window: 'u novom prozoru', //in a new window
    nb_show_search: 'Prikaži pretraživanje', //Show Search
    nb_open_search_results_target: 'Otvori pretraživanja ', //Open search results
    nb_default_search: 'Zadana tražilica', //Default Search Engine
    nb_add_space: 'Dodaj prostor', //Add space
    nb_public_space: 'Javni prostor', //Public space
    nb_general_settings: 'opće postavke', //General Settings
    nb_private_space: 'privatni prostori', //private spaces
    nb_share: 'Podijeli', //Share
    //Share
    s_share: 'Podijeli', //Share
    s_link_to_share: 'Poveznica za dijeljenje', //Link to share
    s_share_on: 'Podijeli na', //Share on Facebook
    s_copy_link: 'Kopiraj poveznicu', //Copy link
    //Scale
    s_tab_size_desktop: 'Veličina kartice na radnoj površini', //Tab Size on Desktop
    s_tab_size_mobile: 'Veličina kartice na mobilnom / tabletu', //Tab Size on Mobile / Tablet
    s_tabs_in_row: 'Kartice u redu', //Tabs in a Row
    //AddSpaceModal
    asm_space_name: 'Unesite naziv prostora', //Enter Space Name
    asm_add_new_space: 'Dodaj novi prostor', //Add New Space
    asm_select_icon: 'Odaberite ikonu', //Select Icon
    //UpdateSpaceModal
    usm_space_name: 'Unesite naziv prostora', //Enter Space Name
    usm_update_space: 'Ažurirajte prostor', //Update Space
    usm_select_icon: 'Odaberite ikonu', //Select Icon
    //AddTabsModal
    atm_log_in: 'Prijavite se, podaci se ne spremaju', //Log in, data is not saved
    atm_add_title: 'Dodajte u TitleTab', //Add to TitleTab
    atm_type_url: 'Upišite URL za dodavanje u TitleTab', //Type URL to add to TitleTab
    atm_enter_title: 'Unesite naslov (nije obavezno)', //Enter Title (is optional)
    atm_add_cancel: 'Otkazati', //Cancel
    //UpdateTabsModal
    utm_url_address: 'Adresa URL-a', //URL Address
    utm_title: 'Naslov', //Title
    utm_base64_image_encoder: 'Kodirnik slike Base64', //Base64 Image Encoder
    utm_title_color: 'Boja naslova', //Title Color
    utm_background_color: 'Boja pozadine', //Background Color
    utm_add_custom_image: 'Dodajte prilagođenu sliku (adresa URL-a ili Base64)', //Add custom image (URL address or Base64)
    utm_apply_update: 'Primijeni ažuriranje', //Apply update
    utm_delete: 'Izbriši', //Delete
    utm_cancel: 'Otkazati', //Cancel
    //UpdateNavigation
    un_primary_settings: 'Osnovne postavke', //Primary settings
    un_customize_color_apply: 'Prilagodi boju i primijeni', //Customize Color and Apply
    un_apply_screenshot: 'Primijeni snimku zaslona web stranice', //Apply a screenshot of the website page
    un_apply_logo: 'Primijeni logotip web stranice', //Apply a logo of the website
    un_temporarily_unavailable: 'Privremeno nedostupno', //Temporarily unavailable
    un_add_own_image: 'Dodajte i primijenite vlastitu sliku', //Add and apply your own image
    //SignIn/SignUp/ForgotPassword
    auth_sing_in_titletab: 'Prijava TitleTab', //Sign in TitleTab
    auth_sign_up_titletab: 'Registracija TitleTab', //Sign up TitleTab
    auth_password_recovery: 'Obnova lozinke', //Password Recovery
    auth_sign_google: 'Prijavite se putem Google računa', //Sign in with Google
    auth_sign_facebook: 'Prijavite se putem Facebook računa', //Sign in with Facebook
    auth_sign_twitter: 'Prijavite se putem Twitter računa', //Sign in with Twitter
    auth_or: 'ili', //or
    auth_email_address: 'Adresa e-pošte', //Email Address
    auth_password: 'Lozinka', //Password
    auth_password_confirmation: 'Potvrda lozinke', //Confirm Password
    auth_forgot_password: 'Zaboravio sam lozinku', //I forgot the password
    auth_sign_in: 'Prijava', //Sign In
    auth_sign_up: 'Registracija', //Sign Up
    auth_reset_password: 'Resetiraj lozinku', //Reset Password
    auth_need_sign_up: 'Treba mi registracija', //I need to Sign up
    auth_have_account: 'Već imam račun', //I have an Account
    //UpdateProfile
    up_account_settings: 'Postavke računa', //Account Settings
    up_greatest_user: 'Najveći korisnik', //Greatest User
    up_make_selection: 'molimo odaberite opciju', //please make a selection
    up_change_email: 'Promijenite e-mail', //Change Email
    up_change_password: 'Promijenite lozinku', //Change Password
    up_delete_account: 'Obrišite račun', //Delete Account
    up_log_out: 'Odjava', //Sign Out
    up_account_settingsCancel: 'Otkazati', //Cancel
    //ChangeEmail
    ce_change_email: 'Promijenite e-mail', //Change Email
    ce_new_email: 'Novi e-mail', //New Email
    ce_password: 'Lozinka', //Password
    ce_confirm_password: 'Potvrdite lozinku', //Confirm Password
    ce_save_new_email: 'Spremi novi e-mail', //Save New Email
    ce_passwords_not_match: 'Lozinke se ne podudaraju!', //Passwords do not match!
    ce_wrong_password: 'Pogrešna lozinka!', //Wrong Password!
    ce_new_email_saved: 'E-mail adresa je promijenjena!', //Email Address Changed!
    ce_failed_update_email: 'Neuspjelo ažuriranje e-maila!', //Failed to update email!
    ce_forbidden:
      'Vaša metoda autorizacije ne podrazumijeva promjenu e-maila. Ne pohranjujemo vaš e-mail.', //Your authorization method does not imply a email change. We do not store your email.
    ce_cancel: 'Otkazati', //Cancel
    //ChangePassword
    cp_change_password: 'Promijeni lozinku', //Change Password
    cp_old_password: 'Stara lozinka', //Old Password
    cp_new_password: 'Nova lozinka', //New Password
    cp_confirm_new_password: 'Potvrdi novu lozinku', //Confirm New Password
    cp_save_new_password: 'Spremi novu lozinku', //Save New Password
    cp_passwords_not_match: 'Lozinke se ne podudaraju!', //Passwords do not match!
    cp_new_password_saved: 'Nova lozinka spremljena!', //New Password Saved!
    cp_failed_update_password: 'Neuspjelo ažuriranje lozinke!', //Failed to update password!
    cp_wrong_password: 'Pogrešna lozinka!', //Wrong Password!
    cp_forbidden:
      'Vaša metoda autorizacije ne podrazumijeva promjenu lozinke. Ne pohranjujemo vašu lozinku.', //Your authorization method does not imply a password change. We do not store your password.
    cp_cancel: 'Otkazati', //Cancel
    //DeleteAccount
    da_delete_account: 'Obriši račun', //Delete Account
    da_delete_account_text: 'Jeste li sigurni?', //Are you sure?
    da_email: 'Email', //Email
    da_password: 'Lozinka', //Password
    da_confirm_password: 'Potvrdi lozinku', //Confirm Password
    da_delete_account_cancel: 'Otkazati', //Cancel
    da_delete_account_delete: 'Obriši račun', //Delete Account
    da_password_not_match: 'Lozinke se ne podudaraju!', //Passwords do not match!
    da_incorrect_email: 'Neispravan email!', //Incorrect Email!
    da_account_deleted: 'Račun uspješno obrisan!', //Account successfully deleted!
    da_account_faled: 'Neuspjelo brisanje računa!', //Failed to delete account!
    da_incorrect_data: 'Neispravni podaci za prijavu!', //Incorrect login data!
    da_google_delete: 'Unesite svoj Google račun email', //Enter your Google account email
    da_facebook_delete: 'Unesite svoj Facebook račun email', //Enter your Facebook account email
    da_twitter_delete: 'Unesite svoje korisničko ime Twitter računa', //Enter your Twitter account Name
    //SearchVertical/SearchHorizontal
    search_web: 'Pretraži Web i kartice', //Search the Web and Tabs
    search_listening: 'Slušam, molim vas govorite...', //Listening, please speak...
    search_error: 'Došlo je do greške, pokušajte ponovno', //Something went wrong, try again
    search_unsupport: 'Prepoznavanje govora nije podržano u ovom pregledniku.', //Speech Recognition is not supported by this browser.
    //Add
    add_site: 'Dodaj stranicu', //Add a site
    //UploaderFile
    uf_main: '<u>Postavi</u> ili povuci sliku ovdje', //Upload or drop an image right here
    uf_drag: '<u>Povuci</u> svoj dokument ovdje', //Drop your file here
    uf_success:
      '<span style="color:green"><u>Postavljanje</u> uspješno!</span>', //Upload successful!
    uf_errorMaxSize:
      '<span style="color:red">Možete postaviti samo datoteke <u>do 2 MB</u></span>', //You can only upload files up to 2MB
    uf_errorMinSize:
      '<span style="color:red">Možete postaviti samo datoteke <u>veće od 100 KB</u></span>', //You can only upload files more than 100KB
    uf_errorType:
      '<span style="color:red">Možete postaviti samo <u>slikovne datoteke</u></span>', //You can only upload image files
    uf_errorMultiple:
      '<span style="color:red">Možete postaviti samo <u>jednu datoteku</u> u isto vrijeme</span>', //You can only upload one file at a time
    //UpdateSpacePublicModal
    uspm_public_space_settings: 'Postavke javnog prostora', //Public Space Settings
    uspm_activate_public_space: 'Aktiviraj javni prostor', //Activate Public Space
    uspm_message:
      'Jednom kad aktivirate javni prostor, moći ćete početi dijeliti svoje veze na mreži.', //Once you activate the public space, you can start sharing your links online.
    uspm_path: 'Putanja za pristup javnom prostoru', //Path for public space access
    uspm_link: 'Poveznica na javni prostor (samo za čitanje)', //Link to public space (read only)
    uspm_public_space_name: 'Naziv javnog prostora (neobavezno)', //Public space name (is optional)
    uspm_public_space_title: 'Naslov javnog prostora (samo za čitanje)', //Public space title (read only)
    uspm_description: 'Opis (neobavezno)', //Description (is optional)
    uspm_save_space_settings: 'Spremi postavke prostora', //Save Space Settings
    uspm_activate_public_space: 'Aktiviraj javni prostor', //Activate Public Space
    uspm_sign_in_to_get_started: 'Prijavite se da biste počeli', //Sign in to get started
    uspm_cancel: 'Otkazati', //Cancel
    //BackgroundModal
    bm_choose_image: 'Pozadinska slika', //Background Image
    bm_or: 'ili', //or
  },
  ru: {
    //About
    a_title: 'О TitleTab', //About TitleTab
    a_cancel: 'Отмена', //Cancel
    //NavBarVertical/MobileMenu
    nb_contact_us: 'Связаться с нами', //Contact Us
    nb_account_settings: 'Настройки аккаунта',
    nb_privacy_policy: 'Политика конфиденциальности',
    nb_backgroung_image: 'Фоновое изображение',
    nb_about: 'О TitleTab', //About Us
    nb_share_link: 'Поделиться ссылкой',
    nb_greatest_user: 'Величайший пользователь',
    nb_sign_in: 'Войти',
    nb_extension: 'Расширение для ',
    nb_use_dark_theme: 'Тёмная тема',
    nb_tab_headers: 'Заголовки вкладок', //Tab headers
    nb_with_background: 'с фоном', //with background
    nb_use_images: 'Изображения ',
    nb_as_a_fill_tabs: 'для заливки вкладки',
    nb_open_tabs_target: 'Открывать вкладки ',
    nb_in_a_new_window: 'в новом окне',
    nb_show_search: 'Показывать поиск',
    nb_open_search_results_target: 'Результаты поиска ',
    nb_default_search: 'Поисковая система по умолчанию',
    nb_add_space: 'Добавить пространство',
    nb_public_space: 'Публичное пространство',
    nb_general_settings: 'oбщие настройки',
    nb_private_space: 'приватные пространства', //Private Spaces
    nb_share: 'Поделиться', //Share
    //Share
    s_share: 'Поделиться', //Share
    s_link_to_share: 'Ссылка', //Link to share
    s_share_on: 'Поделиться с помощью', //Share on
    s_copy_link: 'Копировать ссылку', //Copy Link
    //Scale
    s_tab_size_desktop: 'Размер вкладок на компьютере',
    s_tab_size_mobile: 'Размер вкладок на телефоне / планшете',
    s_tabs_in_row: 'Вкладок в ряд',
    //AddSpaceModal
    asm_space_name: 'Введите имя пространства', //Enter Space Name
    asm_add_new_space: 'Добавить новое пространство', //Add New Space
    asm_select_icon: 'Выберите значок', //Select Icon
    //UpdateSpaceModal
    usm_space_name: 'Введите имя пространства', //Enter Space Name
    usm_update_space: 'Обновить пространство', //Update Space
    usm_select_icon: 'Выберите значок', //Select Icon
    //AddTabsModal
    atm_log_in: 'Авторизуйтесь, данные не сохраняются',
    atm_add_title: 'Добавить в TitleTab',
    atm_type_url: 'Введите URL-адрес',
    atm_enter_title: 'Введите заголовок (не обязательно)',
    atm_add_cancel: 'Отмена',
    //UpdateTabsModal
    utm_url_address: 'URL-адрес',
    utm_title: 'Заголовок',
    utm_base64_image_encoder: 'Изображение в Base64',
    utm_title_color: 'Цвет заголовка',
    utm_background_color: 'Цвет фона',
    utm_add_custom_image: 'Добавить изображение (URL-адрес или Base64)',
    utm_apply_update: 'Применить обновления',
    utm_delete: 'Удалить',
    utm_cancel: 'Отмена',
    //UpdateNavigation
    un_primary_settings: 'Основные настройки',
    un_customize_color_apply: 'Настроить цвет и применить',
    un_apply_screenshot: 'Применить скриншот страницы сайта',
    un_apply_logo: 'Применить логотип веб-сайта',
    un_temporarily_unavailable: 'Временно недоступен',
    un_add_own_image: 'Добавьте и примените собственное изображение',
    //Login/Signup/ForgotPassword
    auth_sing_in_titletab: 'Войти в TitleTab',
    auth_sign_up_titletab: 'Зарегистрироваться на TitleTab',
    auth_password_recovery: 'Восстановление пароля',
    auth_sign_google: 'Войти через Google ',
    auth_sign_facebook: 'Войти через Facebook',
    auth_sign_twitter: 'Войти через Twitter',
    auth_or: 'или',
    auth_email_address: 'Адрес электронной почты',
    auth_password: 'Пароль',
    auth_password_confirmation: 'Подтверждение пароля',
    auth_forgot_password: 'Я забыл пароль',
    auth_sign_in: 'Войти',
    auth_sign_up: 'Зарегистрироваться',
    auth_reset_password: 'Сменить пароль',
    auth_need_sign_up: 'Зарегистрироваться',
    auth_have_account: 'Войти',
    //UpdateProfile
    up_account_settings: 'Настройки аккаунта',
    up_greatest_user: 'Величайший пользователь',
    up_make_selection: 'выберите один из вариантов',
    up_change_email: 'Изменить Email',
    up_change_password: 'Изменить пароль',
    up_delete_account: 'Удалить аккаунт',
    up_log_out: 'Выйти',
    up_account_settingsCancel: 'Отмена',
    //ChangeEmail
    ce_change_email: 'Изменить Email',
    ce_new_email: 'Новый Email',
    ce_password: 'Пароль',
    ce_confirm_password: 'Подтвердите пароль',
    ce_save_new_email: 'Сохранить новый Email',
    ce_passwords_not_match: 'Пароли не совпадают',
    ce_wrong_password: 'Неверный пароль',
    ce_new_email_saved: 'Новый Email сохранен',
    ce_failed_update_email: 'Не удалось обновить Email',
    ce_forbidden:
      'Ваш способ авторизации не подразумевает смену Email. Мы не храним ваш Email.',
    ce_cancel: 'Отмена',
    //ChangePassword
    cp_change_password: 'Изменить пароль',
    cp_old_password: 'Старый пароль',
    cp_new_password: 'Новый пароль',
    cp_confirm_new_password: 'Подтвердите новый пароль',
    cp_save_new_password: 'Сохранить новый пароль',
    cp_passwords_not_match: 'Пароли не совпадают',
    cp_new_password_saved: 'Новый пароль сохранен',
    cp_failed_update_password: 'Не удалось обновить пароль',
    cp_wrong_password: 'Неверный пароль',
    cp_forbidden:
      'Ваш метод авторизации не подразумевает смену пароля. Мы не храним ваш пароль.',
    cp_cancel: 'Отмена',
    //DeleteAccount
    da_delete_account: 'Удалить аккаунт',
    da_delete_account_text: 'Вы уверены?',
    da_email: 'Email',
    da_password: 'Пароль',
    da_confirm_password: 'Подтвердите пароль',
    da_delete_account_cancel: 'Отмена',
    da_delete_account_delete: 'Удалить аккаунт',
    da_password_not_match: 'Пароли не совпадают',
    da_incorrect_email: 'Неверный Email',
    da_account_deleted: 'Аккаунт удален',
    da_account_faled: 'Не удалось удалить аккаунт',
    da_incorrect_data: 'Неверные данные',
    da_google_delete: 'Введите Email своей учетной записи Google',
    da_facebook_delete: 'Введите Email своей учетной записи Facebook',
    da_twitter_delete: 'Введите имя своей учетной записи Twitter',
    //SearchVertical/SearchHorizontal
    search_web: 'Поиск в Интернете и во вкладках',
    search_listening: 'Слушаю, пожалуйста, говорите...',
    search_error: 'Что-то пошло не так, попробуйте снова.',
    search_unsupport: 'Распознавание речи не поддерживается этим браузером.',
    //Add
    add_site: 'Добавить сайт',
    //UploaderFile
    uf_main: '<u>Загрузите</u> или перетащите изображение прямо сюда',
    uf_drag: '<u>Отпустите</u> файл здесь',
    uf_success:
      '<span style="color:green"><u>Загрузка</u> выполнена успешно!</span>',
    uf_errorMaxSize:
      '<span style="color:red">Вы можете загружать только файлы <u>до 2 МБ</u></span>',
    uf_errorMinSize:
      '<span style="color:red">Вы можете загружать только файлы <u>больше 100 КБ</u></span>',
    uf_errorType:
      '<span style="color:red">Вы можете загружать только <u>изображения</u></span>',
    uf_errorMultiple:
      '<span style="color:red">Вы можете загружать только <u>один файл</u> за раз</span>',
    //UpdateSpacePublicModal
    uspm_public_space_settings: 'Настройки публичного пространства', //Public Space Settings
    uspm_activate_public_space: 'Активировать публичное пространство', //Activate Public Space
    uspm_message:
      'После активации публичного пространства вы сможете начать делиться своими ссылками онлайн.', //Once you activate the public space, you can start sharing your links online.
    uspm_path: 'Путь для доступа к публичному пространству', //Path for public space access
    uspm_link: 'Ссылка на публичное пространство (только для чтения)', //Link to public space (read only)
    uspm_public_space_name: 'Название публичного пространства (необязательно)', //Public space name (is optional)
    uspm_public_space_title:
      'Заголовок публичного пространства (только для чтения)', //Public space title (read only)
    uspm_description: 'Описание (необязательно)', //Description (is optional)
    uspm_save_space_settings: 'Сохранить настройки пространства', //Save Space Settings
    uspm_activate_public_space: 'Активировать публичное пространство', //Activate Public Space
    uspm_sign_in_to_get_started: 'Войдите, чтобы начать', //Sign in to get started
    uspm_cancel: 'Отмена', //Cancel
    //BackgroundModal
    bm_choose_image: 'Фоновое изображение', //Background Image
    bm_or: 'или', //or
  },
  uk: {
    //About
    a_title: 'Про TitleTab', //About TitleTab
    a_cancel: 'Відміна', //Cancel
    //NavBarVertical/MobileMenu
    nb_contact_us: 'Зв’язатися з нами', //Contact Us
    nb_account_settings: 'Налаштування акаунта',
    nb_privacy_policy: 'Політика конфіденційності',
    nb_backgroung_image: 'Фонове зображення',
    nb_about: 'Про TitleTab', //About Us
    nb_share_link: 'Поділитися посиланням',
    nb_greatest_user: 'Найвеличніший користувач',
    nb_sign_in: 'Увійти',
    nb_extension: 'Розширення для ',
    nb_use_dark_theme: 'Темна тема',
    nb_tab_headers: 'Заголовки вкладок', //Tab headers
    nb_with_background: 'з фоном', //with background
    nb_use_images: 'Зображення ',
    nb_as_a_fill_tabs: 'для заливки вкладок',
    nb_open_tabs_target: 'Відкривати вкладки ',
    nb_in_a_new_window: 'у новому вікні',
    nb_show_search: 'Відображати пошук',
    nb_open_search_results_target: 'Результати пошуку ',
    nb_default_search: 'Пошукова система за замовчуванням',
    nb_add_space: 'Додати простір',
    nb_public_space: 'Публічний простір',
    nb_general_settings: 'загальні налаштування',
    nb_private_space: 'приватні простори', //Private Spaces
    nb_share: 'Поділитися', //Share
    //Share
    s_share: 'Поділитися', //Share
    s_link_to_share: 'Посилання', //Link to share
    s_share_on: 'Поділитися за допомогою', //Share on
    s_copy_link: 'Копіювати посилання', //Copy link
    //Scale
    s_tab_size_desktop: "Розмір вкладок на комп'ютері",
    s_tab_size_mobile: 'Розмір вкладок на телефоні / планшеті',
    s_tabs_in_row: 'Вкладок у ряд',
    //AddSpaceModal
    asm_space_name: 'Введіть ім’я простору', //Enter Space Name
    asm_add_new_space: 'Додати новий простір', //Add New Space
    asm_select_icon: 'Виберіть іконку', //Select Icon
    //UpdateSpaceModal
    usm_space_name: 'Введіть ім’я простору', //Enter Space Name
    usm_update_space: 'Оновити простір', //Update Space
    usm_select_icon: 'Виберіть іконку', //Select Icon
    //AddTabsModal
    atm_log_in: 'Авторизуйтесь, дані не зберігаються',
    atm_add_title: 'Додати до TitleTab',
    atm_type_url: 'Введіть URL-адресу',
    atm_enter_title: "Введіть заголовок (не обов'язково)",
    atm_add_cancel: 'Відміна',
    //UpdateTabsModal
    utm_url_address: 'URL-адреса',
    utm_title: 'Назва',
    utm_base64_image_encoder: 'Зображення у Base64',
    utm_title_color: 'Колір назви',
    utm_background_color: 'Колір фону',
    utm_add_custom_image: 'Додати зображення (URL-адреса або Base64)',
    utm_apply_update: 'Застосувати оновлення',
    utm_delete: 'Видалити',
    utm_cancel: 'Відміна',
    //UpdateNavigation
    un_primary_settings: 'Основні налаштування',
    un_customize_color_apply: 'Налаштуйте колір і застосуйте',
    un_apply_screenshot: 'Застосуйте скріншот сторінки веб-сайту',
    un_apply_logo: 'Застосуйте логотип веб-сайту',
    un_temporarily_unavailable: 'Тимчасово недоступний',
    un_add_own_image: 'Додайте та застосуйте власне зображення',
    //Login/Signup/ForgotPassword
    auth_sing_in_titletab: 'Увійти до TitleTab',
    auth_sign_up_titletab: 'Зареєструватися у TitleTab',
    auth_password_recovery: 'Відновлення паролю',
    auth_sign_google: 'Увійти через Google',
    auth_sign_facebook: 'Увійти через Facebook',
    auth_sign_twitter: 'Увійти через Twitter',
    auth_or: 'або',
    auth_email_address: 'Адреса електронної пошти',
    auth_password: 'Пароль',
    auth_password_confirmation: 'Підтвердження пароля',
    auth_forgot_password: 'Я забув пароль',
    auth_sign_in: 'Увійти',
    auth_sign_up: 'Зареєструватись',
    auth_reset_password: 'Змінити пароль',
    auth_need_sign_up: 'Зареєструватись',
    auth_have_account: 'Увійти',
    //UpdateProfile
    up_account_settings: 'Налаштування акаунта',
    up_greatest_user: 'Найвеличніший користувач',
    up_make_selection: 'оберіть один з варіантів',
    up_change_email: 'Змінити Email',
    up_change_password: 'Змінити пароль',
    up_delete_account: 'Видалити акаунт',
    up_log_out: 'Вийти',
    up_account_settingsCancel: 'Відміна',
    //ChangeEmail
    ce_change_email: 'Змінити Email',
    ce_new_email: 'Новий Email',
    ce_password: 'Пароль',
    ce_confirm_password: 'Підтвердження паролю',
    ce_save_new_email: 'Зберегти новий Email',
    ce_passwords_not_match: 'Паролі не співпадають',
    ce_wrong_password: 'Несправний пароль',
    ce_new_email_saved: 'Новий Email збережено',
    ce_failed_update_email: 'Не вдалося оновити Email',
    ce_forbidden:
      'Ваш спосіб авторизації не передбачає зміну Email. Ми не зберігаємо ваш Email.',
    ce_cancel: 'Відміна',
    //ChangePassword
    cp_change_password: 'Змінити пароль',
    cp_old_password: 'Старий пароль',
    cp_new_password: 'Новий пароль',
    cp_confirm_new_password: 'Підтвердити новий пароль',
    cp_save_new_password: 'Зберегти новий пароль',
    cp_passwords_not_match: 'Паролі не співпадають',
    cp_new_password_saved: 'Новий пароль збережено',
    cp_failed_update_password: 'Не вдалося змінити пароль',
    cp_wrong_password: 'Несправний пароль',
    cp_forbidden:
      'Ваш спосіб авторизації не передбачає зміну пароля. Ми не зберігаємо ваш пароля.',
    cp_cancel: 'Відміна',
    //DeleteAccount
    da_delete_account: 'Видалити акаунт',
    da_delete_account_text: 'Ви впевнені?',
    da_email: 'Email',
    da_password: 'Пароль',
    da_confirm_password: 'Підтвердження паролю',
    da_delete_account_cancel: 'Відміна',
    da_delete_account_delete: 'Видалити акаунт',
    da_password_not_match: 'Паролі не співпадають',
    da_incorrect_email: 'Несправний Email!',
    da_account_deleted: 'Акаунт видалено',
    da_account_faled: 'Не вдалося видалити акаунт',
    da_incorrect_data: 'Невірні дані для входу!',
    da_google_delete: 'Введіть Email свого облікового запису Google',
    da_facebook_delete: 'Введіть Email свого облікового запису Facebook',
    da_twitter_delete: "Введіть ім'я свого облікового запису Twitter",
    //SearchVertical/SearchHorizontal
    search_web: 'Пошук в Інтернеті та у вкладках',
    search_listening: 'Слухаю, будь ласка, кажіть...',
    search_error: 'Щось пішло не так, спробуйте знову.',
    search_unsupport: 'Розпізнавання мови не підтримується браузером.',
    //Add
    add_site: 'Додати сайт',
    //UploaderFile
    uf_main: '<u>Завантажте</u> або перетягніть зображення прямо сюди',
    uf_drag: '<u>Відпустіть</u> файл тут',
    uf_success:
      '<span style="color:green"><u>Завантаження</u> виконано успішно!</span>',
    uf_errorMaxSize:
      '<span style="color:red">Ви можете завантажувати лише файли до 2 МБ</u></span>',
    uf_errorMinSize:
      '<span style="color:red">Ви можете завантажувати лише файли більше 100 КБ</u></span>',
    uf_errorType:
      '<span style="color:red">Ви можете завантажувати лише <u>зображення</u></span>',
    uf_errorMultiple:
      '<span style="color:red">Ви можете завантажувати лише <u>один файл</u> за раз</span>',
    //UpdateSpacePublicModal
    uspm_public_space_settings: 'Налаштування публічного простору', //Public Space Settings
    uspm_activate_public_space: 'Активувати публічний простір', //Activate Public Space
    uspm_message:
      'Як тільки ви активуєте публічний простір, ви зможете почати ділитися своїми посиланнями в Інтернеті.', //Once you activate the public space, you can start sharing your links online.
    uspm_path: 'Шлях доступу до публічного простору', //Path for public space access
    uspm_link: 'Посилання на публічний простір (тільки читання)', //Link to public space (read only)
    uspm_public_space_name: 'Назва публічного простору (опціонально)', //Public space name (is optional)
    uspm_public_space_title: 'Заголовок публічного простору (тільки читання)', //Public space title (read only)
    uspm_description: 'Опис (опціонально)', //Description (is optional)
    uspm_save_space_settings: 'Зберегти налаштування простору', //Save Space Settings
    uspm_activate_public_space: 'Активувати публічний простір', //Activate Public Space
    uspm_sign_in_to_get_started: 'Увійдіть, щоб почати', //Sign in to get started
    uspm_cancel: 'Відміна', //Cancel
    //BackgroundModal
    bm_choose_image: 'Фонове зображення', //Background Image
    bm_or: 'або', //or
  },
  tr: {
    //About
    a_title: 'Hakkında TitleTab', //About TitleTab
    a_cancel: 'İptal', //Cancel
    //NavBarVertical/MobileMenu
    nb_contact_us: 'Bize Ulaşın', //Contact Us
    nb_account_settings: 'Hesap Ayarları', //AccountSettings
    nb_privacy_policy: 'Gizlilik Politikası', //PrivacyPolicy
    nb_backgroung_image: 'Arka Plan Resmi', //Background Image
    nb_about: 'Hakkımızda', //About Us
    nb_share_link: 'Bağlantıyı Paylaş', //Share Link
    nb_greatest_user: 'En Büyük Kullanıcı', //GreatestUser
    nb_sign_in: 'Oturum Aç', //SignIn
    nb_extension: 'Uzantı için ', //Extension for
    nb_use_dark_theme: 'Koyu tema kullan', //Use dark theme
    nb_tab_headers: 'Sekme başlıkları', //Tab headers
    nb_with_background: 'arka plan ile', //with background
    nb_use_images: 'Resimli sekmeleri ', //Use the images
    nb_as_a_fill_tabs: 'kullanın', //as a fill tabs
    nb_open_tabs_target: 'Açık Sekmeler ', //Open Tabs
    nb_in_a_new_window: 'yeni pencerede', //in a new window
    nb_show_search: 'Aramayı Göster', //Show Search
    nb_open_search_results_target: 'Arama sonuçlarını aç ', //Open search results
    nb_default_search: 'Varsayılan Arama Motoru', //Default Search Engine
    nb_add_space: 'Boşluk Ekle', //Add space
    nb_public_space: 'Genel alan', //Public space
    nb_general_settings: 'genel ayarlar', //General Settings
    nb_private_space: 'özel alanlar', //private spaces
    nb_share: 'Paylaş', //Share
    //Share
    s_share: 'Paylaş', //Share
    s_link_to_share: 'Paylaşılacak Bağlantı', //Link to share
    s_share_on: 'Paylaş', //Share on
    s_copy_link: 'Bağlantıyı Kopyala', //Copy link
    //Scale
    s_tab_size_desktop: 'Bilgisayardaki Sekme Boyutu', //Tab Size on Desktop
    s_tab_size_mobile: 'Mobil / Tablet Sekme Boyutu', //Tab Size on Mobile / Tablet
    s_tabs_in_row: 'Satır Başına Sekmeler', //Tabs in a Row
    //AddSpaceModal
    asm_space_name: 'Alan Adı Girin', //Enter Space Name
    asm_add_new_space: 'Yeni Alan Ekle', //Add New Space
    asm_select_icon: 'Simgesi Seçin', //Select Icon
    //UpdateSpaceModal
    usm_space_name: 'Alan Adı Girin', //Enter Space Name
    usm_update_space: 'Alanı Güncelle', //Update Space
    usm_select_icon: 'Simgesi Seçin', //Select Icon
    //AddTabsModal
    atm_log_in: 'Oturum açın, veriler kaydedilmez', //Log in, data is not saved
    atm_add_title: "TitleTab'a ekle", //Add to TitleTab
    atm_type_url: "TitleTab'a eklemek için URL girin", //Type URL to add to TitleTab
    atm_enter_title: 'Başlık girin (isteğe bağlıdır)', //Enter Title (is optional)
    atm_add_cancel: 'İptal', //Cancel
    //UpdateTabsModal
    utm_url_address: 'URL Adresi', //URL Address
    utm_title: 'Başlık', //Title
    utm_base64_image_encoder: 'Base64 Resim Kodlayıcı', //Base64 Image Encoder
    utm_title_color: 'Başlık Rengi', //Title Color
    utm_background_color: 'Arka Plan Rengi', //Background Color
    utm_add_custom_image: 'Özel resim ekle (URL adresi veya Base64)', //Add custom image (URL address or Base64)
    utm_apply_update: 'Güncellemeyi Uygula', //Apply update
    utm_delete: 'Sil', //Delete
    utm_cancel: 'İptal', //Cancel
    //UpdateNavigation
    un_primary_settings: 'Birincil ayarlar', //Primary settings
    un_customize_color_apply: 'Renkleri Özelleştir ve Uygula', //Customize Color and Apply
    un_apply_screenshot: 'Web sitesi sayfasının ekran görüntüsünü uygula', //Apply a screenshot of the website page
    un_apply_logo: 'Web sitesinin logosunu uygula', //Apply a logo of the website
    un_temporarily_unavailable: 'Geçici olarak kullanılamıyor', //Temporarily unavailable
    un_add_own_image: 'Kendi resminizi ekleyin ve uygulayın', //Add and apply your own image
    //SignIn/SignUp/ForgotPassword
    auth_sing_in_titletab: "TitleTab'a giriş yapın", //Sign in TitleTab
    auth_sign_up_titletab: "TitleTab'a kaydolun", //Sign up TitleTab
    auth_password_recovery: 'Şifre Kurtarma', //Password Recovery
    auth_sign_google: 'Google ile giriş yapın', //Sign in with Google
    auth_sign_facebook: 'Facebook ile giriş yapın', //Sign in with Facebook
    auth_sign_twitter: 'Twitter ile giriş yapın', //Sign in with Twitter
    auth_or: 'veya', //or
    auth_email_address: 'E-posta Adresi', //Email Address
    auth_password: 'Şifre', //Password
    auth_password_confirmation: 'Şifreyi Onayla', //Confirm Password
    auth_forgot_password: 'Şifremi unuttum', //I forgot the password
    auth_sign_in: 'Giriş Yap', //Sign In
    auth_sign_up: 'Kaydol', //Sign Up
    auth_reset_password: 'Şifreyi Sıfırla', //Reset Password
    auth_need_sign_up: 'Kayıt olmam gerekli', //I need to Sign up
    auth_have_account: 'Hesabım var', //I have an Account
    //UpdateProfile
    up_account_settings: 'Hesap Ayarları', //Account Settings
    up_greatest_user: 'En Büyük Kullanıcı', //Greatest User
    up_make_selection: 'lütfen bir seçim yapın', //please make a selection
    up_change_email: 'E-postayı Değiştir', //Change Email
    up_change_password: 'Şifreyi Değiştir', //Change Password
    up_delete_account: 'Hesabı Sil', //Delete Account
    up_log_out: 'Oturumu Kapat', //Sign Out
    up_account_settingsCancel: 'İptal', //Cancel
    //ChangeEmail
    ce_change_email: 'E-postayı Değiştir', //Change Email
    ce_new_email: 'Yeni E-posta', //New Email
    ce_password: 'Şifre', //Password
    ce_confirm_password: 'Şifreyi Onayla', //Confirm Password
    ce_save_new_email: 'Yeni E-postayı Kaydet', //Save New Email
    ce_passwords_not_match: 'Şifreler eşleşmiyor!', //Passwords do not match!
    ce_wrong_password: 'Yanlış Şifre!', //Wrong Password!
    ce_new_email_saved: 'E-posta Adresi Değiştirildi!', //Email Address Changed!
    ce_failed_update_email: 'E-posta güncelleme başarısız oldu!', //Failed to update email!
    ce_forbidden:
      'Yetkilendirme yönteminiz e-posta değişikliği içermemektedir. E-postanızı saklamıyoruz.', //Your authorization method does not imply a email change. We do not store your email.
    ce_cancel: 'İptal', //Cancel
    //ChangePassword
    cp_change_password: 'Şifreyi Değiştir', //Change Password
    cp_old_password: 'Eski Şifre', //Old Password
    cp_new_password: 'Yeni Şifre', //New Password
    cp_confirm_new_password: 'Yeni Şifreyi Onayla', //Confirm New Password
    cp_save_new_password: 'Yeni Şifreyi Kaydet', //Save New Password
    cp_passwords_not_match: 'Şifreler eşleşmiyor!', //Passwords do not match!
    cp_new_password_saved: 'Yeni Şifre Kaydedildi!', //New Password Saved!
    cp_failed_update_password: 'Şifre güncelleme başarısız oldu!', //Failed to update password!
    cp_wrong_password: 'Yanlış Şifre!', //Wrong Password!
    cp_forbidden:
      'Yetkilendirme yönteminiz şifre değişikliği içermemektedir. Şifrenizi saklamıyoruz.', //Your authorization method does not imply a password change. We do not store your password.
    cp_cancel: 'İptal', //Cancel
    //DeleteAccount
    da_delete_account: 'Hesabı Sil', //Delete Account
    da_delete_account_text: 'Emin misiniz?', //Are you sure?
    da_email: 'E-posta', //Email
    da_password: 'Şifre', //Password
    da_confirm_password: 'Şifreyi Onayla', //Confirm Password
    da_delete_account_cancel: 'İptal', //Cancel
    da_delete_account_delete: 'Hesabı Sil', //Delete Account
    da_password_not_match: 'Şifreler eşleşmiyor!', //Passwords do not match!
    da_incorrect_email: 'Hatalı E-posta!', //Incorrect Email!
    da_account_deleted: 'Hesap başarıyla silindi!', //Account successfully deleted!
    da_account_faled: 'Hesap silme işlemi başarısız oldu!', //Failed to delete account!
    da_incorrect_data: 'Hatalı giriş verileri!', //Incorrect login data!
    da_google_delete: 'Google hesabı e-postanızı girin', //Enter your Google account email
    da_facebook_delete: 'Facebook hesabı e-postanızı girin', //Enter your Facebook account email
    da_twitter_delete: 'Twitter hesabı adınızı girin', //Enter your Twitter account Name
    //SearchVertical/SearchHorizontal
    search_web: "Web'de ve Sekmelerde Ara", //Search the Web and Tabs
    search_listening: 'Dinliyor, lütfen konuşun...', //Listening, please speak...
    search_error: 'Bir şeyler yanlış gitti, tekrar deneyin', //Something went wrong, try again
    search_unsupport: 'Bu tarayıcı tarafından Konuşma Tanıma desteklenmiyor.', //Speech Recognition is not supported by this browser.
    //Add
    add_site: 'Site ekle', //Add a site
    //UploaderFile
    uf_main: '<u>Resim yükleyin</u> veya buraya bırakın', //Upload or drop an image right here
    uf_drag: '<u>Dosyanızı buraya bırakın</u>', //Drop your file here
    uf_success: '<span style="color:green"><u>Yükleme</u> başarılı!</span>', //Upload successful!
    uf_errorMaxSize:
      '<span style="color:red">Sadece <u>2MB\'ye kadar dosyalar</u> yükleyebilirsiniz</span>', //You can only upload files up to 2MB
    uf_errorMinSize:
      '<span style="color:red">Sadece <u>100KB\'den büyük dosyalar</u> yükleyebilirsiniz</span>', //You can only upload files more than 100KB
    uf_errorType:
      '<span style="color:red">Sadece <u>resim dosyaları</u> yükleyebilirsiniz</span>', //You can only upload image files
    uf_errorMultiple:
      '<span style="color:red">Bir seferde <u>yalnızca bir dosya</u> yükleyebilirsiniz</span>', //You can only upload one file at a time

    //UpdateSpacePublicModal
    uspm_public_space_settings: 'Genel Alan Ayarları', //Public Space Settings
    uspm_activate_public_space: 'Genel Alanı Etkinleştir', //Activate Public Space
    uspm_message:
      'Genel alanı etkinleştirdikten sonra, bağlantılarınızı çevrimiçi paylaşmaya başlayabilirsiniz.', //Once you activate the public space, you can start sharing your links online.
    uspm_path: 'Genel alan erişimi için yol', //Path for public space access
    uspm_link: 'Genel alana bağlantı (yalnızca okunur)', //Link to public space (read only)
    uspm_public_space_name: 'Genel alan adı (isteğe bağlı)', //Public space name (is optional)
    uspm_public_space_title: 'Genel alan başlığı (yalnızca okunur)', //Public space title (read only)
    uspm_description: 'Açıklama (isteğe bağlı)', //Description (is optional)
    uspm_save_space_settings: 'Alan Ayarlarını Kaydet', //Save Space Settings
    uspm_activate_public_space: 'Genel Alanı Etkinleştir', //Activate Public Space
    uspm_sign_in_to_get_started: 'Başlamak için oturum açın', //Sign in to get started
    uspm_cancel: 'İptal', //Cancel
    //BackgroundModal
    bm_choose_image: 'Arka Plan Resmi', //Background Image
    bm_or: 'veya', //or
  },
}

export default initialLanguage
