import React from 'react'
import { Container } from '../style/LineHr.styled'

export default function LineHr({ text, dark }) {
  function getClassName() {
    let className = 'line'
    if (dark) className += ' dark'
    return className
  }

  return (
    <Container>
      <div className={getClassName()}>
        <span>{text}</span>
      </div>
    </Container>
  )
}
