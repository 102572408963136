export function getLinkHost({ task, domains }) {
  let linkHostLength = domains[task.idDomain].domainHostname.length
  let domainZones = ['com', 'net', 'org']
  let urlParts = domains[task.idDomain].domainHostname.split('.')
  let urlPartsLength = domains[task.idDomain].domainHostname.split('.')

  let urlShortParts = domains[task.idDomain].domainHostname.split('.')
  if (linkHostLength > 18) {
    do {
      let newUrlShortParts = urlShortParts.slice()
      newUrlShortParts.sort(function (a, b) {
        return b.length - a.length
      })
      if (newUrlShortParts[0].length < 5) {
        break
      }
      for (let i = 0; i < urlShortParts.length; i++) {
        if (urlShortParts[i] === newUrlShortParts[0]) {
          urlShortParts[i] =
            urlShortParts[i].slice(0, urlShortParts[i].length - 3) + '..'
        }
      }
    } while (urlShortParts.join('.').length > 18)
  }

  urlPartsLength.sort(function (a, b) {
    return b.length - a.length
  })
  let strong = true
  for (let i = 0; i < urlParts.length; i++) {
    if (
      urlParts[i] === urlPartsLength[0] &&
      i != urlParts.length - 1 &&
      urlParts[i].length > 2 &&
      domainZones.includes(urlParts[i]) === false
    ) {
      urlParts[i] = <b key={'linkHost' + task.id + i}>{urlShortParts[i]}.</b>
      strong = false
    } else {
      if (i != urlParts.length - 1) {
        urlParts[i] = urlShortParts[i] + '.'
      }
    }
  }

  if (strong) {
    urlParts[0] = <b key={'linkHost' + task.id}>{urlParts[0]}</b>
  }

  return urlParts
}

export function getTabFillPicture({
  settings,
  task,
  domains,
  pages,
  images,
  swUseImg,
}) {
  if (
    (task.pictureImage && swUseImg) ||
    (settings.tabsAsImage && swUseImg) ||
    (!settings.tabsAsImage && swUseImg)
  ) {
    ////////////////////////////////////////////////////
    if (task.ownPicture && task.ownPicture.startsWith('http')) {
      return task.ownPicture
    }
    if (task.ownPicture && images[task.ownPicture]) {
      return images[task.ownPicture].image
    }
    ////////////////////////////////////////////////////
    if (domains[task.idDomain].domainPicture)
      return domains[task.idDomain].domainPicture
    if (pages[task.idPages].pagesPicture)
      return pages[task.idPages].pagesPicture
    return false
  }
  return false
}

export function getShowLoader({ settings, task, domains, pages, swUseImg }) {
  if ((task.pictureImage && swUseImg) || (settings.tabsAsImage && swUseImg)) {
    if (task.picture) return false
    if (domains[task.idDomain].domainPicture) return false
    if (pages[task.idPages].pagesPicture) return false
    return true
  }
  return false
}

export function getTabFillBackground({ task, domains, pages, background }) {
  if (background) return background
  if (task.ownBackground) return task.ownBackground
  if (domains[task.idDomain].domainBackground)
    return domains[task.idDomain].domainBackground
  if (pages[task.idPages].pagesBackground)
    return pages[task.idPages].pagesBackground
  return '#fff'
}

export function getTabFillColor({ task, domains, pages, color }) {
  if (color) return color
  if (task.ownColor) return task.ownColor
  if (domains[task.idDomain].domainColor)
    return domains[task.idDomain].domainColor
  if (pages[task.idPages].pagesColor) return pages[task.idPages].pagesColor
  return '#266df0'
}
