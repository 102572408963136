import React from 'react'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import initialLanguage from '../language/initialLanguage'
import './scale.css'
import withLocalForage from '../context/withLocalForage'

const Scale = ({ updateSettingsReBuild }) => {
  const { currentUser } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`

  const { global, lang, device } = useData()
  const { settings } = global

  const smallDesktop = () => {
    global.settings.tabsSizeDesktop = 7
    if (device === 'desktop') global.settings.tabsInRow = 7
    updateSettingsReBuild(path, global.settings)
  }

  const mediumDesktop = () => {
    global.settings.tabsSizeDesktop = 6
    if (device === 'desktop') global.settings.tabsInRow = 6
    updateSettingsReBuild(path, global.settings)
  }

  const largeDesktop = () => {
    global.settings.tabsSizeDesktop = 5
    if (device === 'desktop') global.settings.tabsInRow = 5
    updateSettingsReBuild(path, global.settings)
  }

  const xlargeDesktop = () => {
    global.settings.tabsSizeDesktop = 4
    if (device === 'desktop') global.settings.tabsInRow = 4
    updateSettingsReBuild(path, global.settings)
  }

  const xxlargeDesktop = () => {
    global.settings.tabsSizeDesktop = 3
    if (device === 'desktop') global.settings.tabsInRow = 3
    updateSettingsReBuild(path, global.settings)
  }

  const smallMobile = () => {
    global.settings.tabsSizeMobile = 5
    if (device === 'mobile') global.settings.tabsInRow = 5
    updateSettingsReBuild(path, global.settings)
  }

  const mediumMobile = () => {
    global.settings.tabsSizeMobile = 4
    if (device === 'mobile') global.settings.tabsInRow = 4
    updateSettingsReBuild(path, global.settings)
  }

  const largeMobile = () => {
    global.settings.tabsSizeMobile = 3
    if (device === 'mobile') global.settings.tabsInRow = 3
    updateSettingsReBuild(path, global.settings)
  }

  const xlargeMobile = () => {
    global.settings.tabsSizeMobile = 2
    if (device === 'mobile') global.settings.tabsInRow = 2
    updateSettingsReBuild(path, global.settings)
  }

  const xxlargeMobile = () => {
    global.settings.tabsSizeMobile = 1
    if (device === 'mobile') global.settings.tabsInRow = 1
    updateSettingsReBuild(path, global.settings)
  }

  return (
    <>
      <div className="scale">
        <section className="scale_text">
          {initialLanguage[lang].s_tab_size_desktop}
        </section>
        <div className="rectangle">
          <div
            className={settings.tabsSizeDesktop <= 7 ? 'small activ' : 'small'}
            title={'7 ' + initialLanguage[lang].s_tabs_in_row}
            onClick={smallDesktop}
          >
            S
          </div>
          <div
            className={
              settings.tabsSizeDesktop <= 6 ? 'medium activ' : 'medium'
            }
            title={'6 ' + initialLanguage[lang].s_tabs_in_row}
            onClick={mediumDesktop}
          >
            M
          </div>
          <div
            className={settings.tabsSizeDesktop <= 5 ? 'large activ' : 'large'}
            title={'5 ' + initialLanguage[lang].s_tabs_in_row}
            onClick={largeDesktop}
          >
            L
          </div>
          <div
            className={
              settings.tabsSizeDesktop <= 4 ? 'xlarge activ' : 'xlarge'
            }
            title={'4 ' + initialLanguage[lang].s_tabs_in_row}
            onClick={xlargeDesktop}
          >
            XL
          </div>
          <div
            className={
              settings.tabsSizeDesktop <= 3 ? 'xxlarge activ' : 'xxlarge'
            }
            title={'3 ' + initialLanguage[lang].s_tabs_in_row}
            onClick={xxlargeDesktop}
          >
            XXL
          </div>
        </div>
      </div>
      <div className="scale">
        <section className="scale_text">
          {initialLanguage[lang].s_tab_size_mobile}
        </section>
        <div className="rectangle">
          <div
            className={settings.tabsSizeMobile <= 5 ? 'small activ' : 'small'}
            title={'5 ' + initialLanguage[lang].s_tabs_in_row}
            onClick={smallMobile}
          >
            S
          </div>
          <div
            className={settings.tabsSizeMobile <= 4 ? 'medium activ' : 'medium'}
            title={'4 ' + initialLanguage[lang].s_tabs_in_row}
            onClick={mediumMobile}
          >
            M
          </div>
          <div
            className={settings.tabsSizeMobile <= 3 ? 'large activ' : 'large'}
            title={'3 ' + initialLanguage[lang].s_tabs_in_row}
            onClick={largeMobile}
          >
            L
          </div>
          <div
            className={settings.tabsSizeMobile <= 2 ? 'xlarge activ' : 'xlarge'}
            title={'2 ' + initialLanguage[lang].s_tabs_in_row}
            onClick={xlargeMobile}
          >
            XL
          </div>
          <div
            className={
              settings.tabsSizeMobile <= 1 ? 'xxlarge activ' : 'xxlarge'
            }
            title={'1 ' + initialLanguage[lang].s_tabs_in_row}
            onClick={xxlargeMobile}
          >
            XXL
          </div>
        </div>
      </div>
    </>
  )
}

export default withLocalForage(Scale)
