import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'

import { Container } from '../style/ModalSmall.styled'

import Button from '../button/Button'
import Input from '../input/Input'
import Title from '../modal/Title'
import Content from '../modal/Content'
import initialLanguage from '../language/initialLanguage'
import LineHr from '../minor/LineHr'

import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getButton,
  getFocus,
} from '../functionSet/ModalFunction'
import withLocalForage from '../context/withLocalForage'
import { usePage } from '../context/PageContext'
import ListScrollOne from '../listscroll/ListScrollOne'

const Share = ({ button, fullWidth, mobileClose }) => {
  const { currentUser } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`
  const { global, setDrag, lang, shareState, setShareState } = useData()
  const { settings, styleData } = global

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  const { shareModal, setShareModal } = usePage()
  const [activeDelay, setActiveDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(shareModal)
      setDrag(false)
    }, 1)
  }, [shareModal])

  let modalActiv = activeDelay

  async function handleClose(e) {
    e.preventDefault()
    // navigate("/");

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      setShareModal(false)
      setShareState('')
    }, 400)
  }

  let shareLink = document.location.href
  if (shareState && shareState.url) shareLink = shareState.url

  let shareTitle = document.title
  if (shareState && shareState.title) shareTitle = shareState.title

  let shareDescription = document.querySelector(
    'meta[name="description"]'
  ).content
  if (shareState && shareState.description)
    shareDescription = shareState.description

  const [state, setState] = useState('')

  const [modal, setModal] = useState(true)

  async function handleSubmit(e) {}

  const navigate = useNavigate()

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({ device, styleData, button }),
    width: getWidth({ styleData, fullWidth }),
  }

  async function handleBack(e) {
    e.preventDefault()
    // navigate(-1);
    handleClose(e)
  }

  async function handleCopy(e) {
    const textToCopy = shareLink
    const textarea = document.createElement('textarea')
    textarea.value = textToCopy
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  useEffect(() => {
    if (state === 0) {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${shareLink}&t=${shareTitle}&description=${shareDescription}`,
        'facebook-share-dialog',
        'width=626,height=436'
      )
    }
    if (state === 1) {
      window.open(
        `https://twitter.com/intent/tweet?url=${shareLink}&text=${shareTitle}&hashtags=yourHashtags&via=yourTwitterHandle&description=${shareDescription}`,
        'twitter-share-dialog',
        'width=626,height=436'
      )
    }
    if (state === 2) {
      window.open(
        `https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}&title=${shareTitle}&summary=${shareDescription}`,
        'linkedin-share-dialog',
        'width=626,height=436'
      )
    }
    if (state === 3) {
      window.open(
        `https://pinterest.com/pin/create/button/?url=${shareLink}&description=${shareDescription}`,
        'pinterest-share-dialog',
        'width=750,height=500'
      )
    }
    if (state === 4) {
      window.open(
        `https://www.reddit.com/submit?url=${shareLink}&title=${shareTitle}`,
        'reddit-share-dialog',
        'width=800,height=600'
      )
    }
    if (state === 5) {
      window.open(
        `https://www.tumblr.com/widgets/share/tool?canonicalUrl=${shareLink}&title=${shareTitle}&caption=${shareDescription}`,
        'tumblr-share-dialog',
        'width=540,height=600'
      )
    }
    if (state === 6) {
      window.open(
        `https://web.skype.com/share?url=${shareLink}&source=button&text=${shareTitle}%20${shareLink}%20${shareDescription}`,
        'skype-share-dialog',
        'width=626,height=436'
      )
    }
    if (state === 7) {
      window.open(
        `https://api.whatsapp.com/send?text=${shareTitle}%20${shareLink}`,
        'whatsapp-share-dialog',
        'width=800,height=600'
      )
    }
    if (state === 8) {
      window.open(
        `https://telegram.me/share/url?url=${shareLink}&text=${shareTitle}`,
        'telegram-share-dialog',
        'width=800,height=600'
      )
    }
    if (state === 9) {
      window.open(
        `https://www.blogger.com/blog-this.g?u=${shareLink}&n=${shareTitle}&t=${shareDescription}`,
        'blogger-share-dialog',
        'width=800,height=600'
      )
    }
    if (state === 10) {
      window.open(
        `mailto:?subject=${shareTitle}&body=${shareDescription}%0D%0A%0D%0A${shareLink}`,
        'email-share-dialog',
        'width=800,height=600'
      )
    }
    if (state === 11) {
      window.open(
        `https://vk.com/share.php?url=${shareLink}&title=${shareTitle}&description=${shareDescription}`,
        'vk-share-dialog',
        'width=800,height=600'
      )
    }
    if (state === 12) {
      window.open(
        `https://connect.ok.ru/offer?url=${shareLink}&title=${shareTitle}&description=${shareDescription}`,
        'ok-share-dialog',
        'width=800,height=600'
      )
    }
    setState(null)
  }, [state])

  useEffect(() => {
    //Для поля Email
    getFocus('url_add', setModal)
  }, [])

  return (
    <div
      className={getBlackout({ device, modalActiv })}
      onClick={modal ? handleClose : null}
    >
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={getClassName({
            modalActiv,
            device,
            dark: settings.styleTheme,
          })}
        >
          <Title
            children={initialLanguage[lang].s_share}
            dark={settings.styleTheme}
            handleBack={handleBack}
            handleClose={handleClose}
          />

          <Content>
            <Input
              id="url_add"
              labelId="urk_add_label"
              type="text"
              state={shareLink}
              readOnly={true}
              copyIcon={true}
              placeholder={''}
              label={initialLanguage[lang].s_link_to_share}
              labelUp={true}
              autoComplete="off"
              required={true}
              dark={settings.styleTheme}
            />
            <LineHr
              text={initialLanguage[lang].s_share_on}
              dark={settings.styleTheme}
            />
            <ListScrollOne setState={setState} dark={settings.styleTheme} />
          </Content>

          <Button
            onClick={handleCopy}
            button={true}
            color={'blue'}
            isDesktop={true}
            dark={settings.styleTheme}
          >
            {initialLanguage[lang].s_copy_link}
          </Button>

          <Button
            onClick={handleClose}
            color={'grey'}
            isDesktop={true}
            dark={settings.styleTheme}
          >
            {initialLanguage[lang].atm_add_cancel}
          </Button>

          <div className="indent40"></div>
        </div>
      </Container>
    </div>
  )
}

export default withLocalForage(Share)
