import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import { usePage } from '../context/PageContext'
import { Container } from '../style/ModalSmall.styled'
import { toast } from 'react-toastify'
import initialLanguage from '../language/initialLanguage'
import Button from '../button/Button'
import Input from '../input/Input'
import Title from '../modal/Title'
import Content from '../modal/Content'
import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getColor,
  getButton,
  getClassLink,
  checkRequired,
  checkEmail,
  checkPassword,
  checkPasswordMatch,
  setError,
  clearError,
  getFocus,
} from '../functionSet/ModalFunction'

export default function ForgotPassword({ button, fullWidth, mobileClose }) {
  const { resetPassword } = useAuth()
  const { global, setDrag, lang } = useData()
  const { settings, styleData } = global

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  const navigate = useNavigate()
  const [modal, setModal] = useState(true)

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({
      device,
      styleData,
      button,
    }),
    width: getWidth({ styleData, fullWidth }),
  }

  const { forgotPassword, setForgotPassword } = usePage()
  const [activeDelay, setActiveDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(forgotPassword)
      setDrag(false)
    }, 1)
  }, [forgotPassword])

  let modalActiv = activeDelay

  async function handleClose(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/')
      setForgotPassword(false)
    }, 400)
  }

  async function handleBack(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate(-1)
      setForgotPassword(false)
    }, 400)
  }

  async function handleSignUp(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/sign-up')
      setForgotPassword(false)
    }, 400)
  }

  async function handleSignIn(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/sign-in')
      setForgotPassword(false)
    }, 400)
  }

  const [email, setEmail] = useState('')

  async function handleSubmit(e) {
    e.preventDefault()

    const emailValid = checkEmail({
      email: email,
      id: 'email_fp',
      device: device,
      afterFocus: true,
    })

    if (!emailValid) {
      return
    }

    try {
      await resetPassword(email)
      setEmail('')
      toast.success('Check your inbox for further instructions!')
    } catch {
      toast.error('Failed to reset password!')
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  useEffect(() => {
    //Для поля Email
    getFocus('email_fp', setModal)
  }, [])

  return (
    <div
      className={getBlackout({ device, modalActiv })}
      onClick={modal ? handleClose : null}
    >
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={getClassName({
              modalActiv,
              device,
              dark: settings.styleTheme,
            })}
          >
            <Title
              children={initialLanguage[lang].auth_password_recovery}
              dark={settings.styleTheme}
              handleBack={handleBack}
              handleClose={handleClose}
            />

            <Content>
              <Input
                id="email_fp"
                labelId="email_fp_label"
                type="email"
                state={email}
                setState={setEmail}
                placeholder={''}
                label={initialLanguage[lang].auth_email_address}
                autoComplete="off"
                required={true}
                dark={settings.styleTheme}
              />
            </Content>

            <Button
              onClick={handleSubmit}
              button={true}
              color={'blue'}
              isDesktop={true}
              type="submit"
            >
              {initialLanguage[lang].auth_reset_password}
            </Button>

            <Button
              onClick={handleSignIn}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].auth_have_account}
            </Button>

            <Button
              onClick={handleSignUp}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].auth_need_sign_up}
            </Button>

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
    </div>
  )
}
