import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { useData } from '../../context/DataContext'

import { Container } from '../../style/ModalSmall.styled'

import Button from '../../button/Button'
import Input from '../../input/Input'
import Title from '../../modal/Title'
import Content from '../../modal/Content'
import initialLanguage from '../../language/initialLanguage'
import withLocalForage from '../../context/withLocalForage'

import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getButton,
  getFocus,
} from '../../functionSet/ModalFunction'
import Palette from '../../palette/Palette'
import PreviewFolder from '../../minor/PreviewFolder'

const UpdateFolderModal = ({
  active,
  setActive,
  task,
  button,
  fullWidth,
  mobileClose,
  updateFolderModal,
  deleteFolderModal,
}) => {
  const { currentUser } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`

  const { global, setDrag, lang } = useData()
  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  const { settings, styleData } = global

  const currentName = task.ownTitle || ''

  const currentBackground = task.ownBackground
    ? task.ownBackground
    : settings.styleTheme
    ? '#2e275c'
    : '#ffffff'

  const currentNumAndName =
    '(' +
    Object.keys(task.tasks).length +
    ')' +
    ' ' +
    (currentName || 'New Folder')

  const modalTitle =
    currentNumAndName.length > Math.floor(styleData.tabWidth / 8)
      ? currentNumAndName.slice(0, Math.floor(styleData.tabWidth / 8)) + '...'
      : currentNumAndName

  const [name, setName] = useState(currentName)
  const [background, setBackground] = useState(currentBackground)

  const [activeDelay, setActiveDelay] = useState(false)
  const [modal, setModal] = useState(true)

  async function handleSubmit(e) {
    e.preventDefault()

    setName(name || currentName)
    setBackground(background || currentBackground)

    if (name !== currentName) {
      task.ownTitle = name
    }

    if (background !== currentBackground) {
      task.ownBackground = background
    }

    updateFolderModal(path, task)

    setActiveDelay(false)
    // setDrag(true)
    setTimeout(() => {
      setActive(false)
    }, 400)
  }
  async function handleDelete(e) {
    e.preventDefault()

    deleteFolderModal(path, task, settings)

    setActiveDelay(false)
    // setDrag(true)
    setTimeout(() => {
      setActive(false)
    }, 400)
  }

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(active)
      setDrag(false)
    }, 1)
  }, [active])

  let modalActiv = activeDelay
  const navigate = useNavigate()

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({ device, styleData, button }),
    width: getWidth({ styleData, fullWidth }),
  }

  async function handleClose(e) {
    e.preventDefault()
    // navigate("/");

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      setActive(false)
    }, 400)
  }

  async function handleBack(e) {
    e.preventDefault()
    handleClose(e)
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  useEffect(() => {
    //Для поля Email
    getFocus('name_up', setModal)
    getFocus('background_up', setModal)
  }, [])

  return (
    <div
      className={getBlackout({ device, modalActiv })}
      onClick={modal ? handleClose : null}
    >
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={getClassName({
              modalActiv,
              device,
              dark: settings.styleTheme,
            })}
          >
            <Title
              children={modalTitle}
              dark={settings.styleTheme}
              handleBack={handleBack}
              handleClose={handleClose}
            />

            <Content>
              <PreviewFolder task={task} background={background} />

              <>
                <Input
                  id="name_up"
                  labelId="name_up_label"
                  type="text"
                  state={name}
                  setState={setName}
                  placeholder={'New Folder'}
                  label={initialLanguage[lang].utm_title}
                  labelUp={true}
                  autoComplete="off"
                  required={true}
                  dark={settings.styleTheme}
                />

                <Palette
                  id="background_up"
                  text={background}
                  setText={setBackground}
                  label={initialLanguage[lang].utm_background_color}
                  dark={settings.styleTheme}
                />
              </>
            </Content>

            <Button
              onClick={handleSubmit}
              color={'blue'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].utm_apply_update}
            </Button>

            <Button
              onClick={handleDelete}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].utm_delete}
            </Button>

            <Button
              onClick={handleClose}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].utm_cancel}
            </Button>

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
    </div>
  )
}

export default withLocalForage(UpdateFolderModal)
