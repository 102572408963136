import React, { useMemo, useCallback } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { useData } from '../../context/DataContext'
import Tab from './Tab'
import Add from './Add'
import Folder from '../folders/Folder'
import { Container, TaskList } from '../../style/Column.styled'

//*
export default function Column({ column, tasks, isCombine, main, dragData }) {
  const { global } = useData()
  const { settings, styleData } = global

  const renderTask = useCallback(
    (task, index) =>
      task.tasks ? (
        <Folder key={task.id} task={task} index={index} />
      ) : (
        <Tab
          key={task.id}
          task={task}
          index={index}
          main={main}
          dragData={dragData}
        />
      ),
    [dragData]
  )

  const renderedTasks = useMemo(
    () => tasks.map((task, index) => (task ? renderTask(task, index) : null)),
    [tasks, renderTask]
  )

  const renderAdd = useCallback(() => <Add tasks={tasks} />, [tasks, settings])

  return (
    <Container styleData={styleData} id={column.id}>
      <Droppable
        droppableId={column.id}
        direction="horizontal"
        isCombineEnabled={isCombine}
      >
        {(provided, snapshot) => (
          <TaskList
            styleData={styleData}
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            <span style={{ float: 'left' }}>
              {renderedTasks}
              {provided.placeholder}
            </span>
            {renderAdd()}
          </TaskList>
        )}
      </Droppable>
    </Container>
  )
}
