import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  width: ${(props) => props.styleData.width}px;
  top: ${(props) => props.styleData.top}px;
  left: 0px;
  right: 0px;
  margin: auto;

  @media (max-width: 1200px) {
    top: 85px;
    left: auto;
    right: 0px;
  }
`

export const TodayContainer = styled.div`
  width: calc(66.6% - 20px);
  height: 100%;
  float: left;
  margin: 10px;
  min-height: 250px;
  background-color: #00000020;
  border-radius: 8px;

  @media (max-width: 800px) {
    width: 100%;
  }

  hr {
    width: 30%;
    height: 1px;
    background-color: #fff;
    border: none;
    margin: 10px 0px;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 275px;
    margin: 20px 40px;
  }

  @media (max-width: 800px) {
    .content {
      flex-direction: column;
      height: auto;
    }
  }

  .visitors,
  .views,
  .clicks {
    width: 100%;
    height: 100%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }

  @media (max-width: 800px) {
    .visitors,
    .views,
    .clicks {
      margin: 20px 0px;
    }
  }

  .number {
    position: relative;
    font-size: 60px;
    font-weight: 600;
    color: #fff;
  }

  .number span {
    font-size: 20px;
    position: absolute;
    top: -5px;
  }

  .number span.plus {
    color: rgba(30, 142, 62);
  }

  .number span.minus {
    color: rgba(217, 48, 37);
  }

  .number span.null {
    color: rgba(119, 119, 119);
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }
`

export const ExtantionsContainer = styled.div`
  width: calc(33.3% - 20px);
  height: 100%;
  float: left;
  margin: 10px;
  min-height: 250px;
  background-color: #00000020;
  border-radius: 8px;

  @media (max-width: 800px) {
    width: 100%;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 275px;
    margin: 20px;
  }
`

export const MonthGraphContainer = styled.div`
  width: 100%;
  height: 100%;
  float: left;
  margin: 10px;
  min-height: 250px;
  background-color: #00000020;
  border-radius: 8px;

  @media (max-width: 800px) {
    width: 100%;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 275px;
    margin: 20px;
`

export const MinuteGraphContainer = styled.div`
  width: 100%;
  height: 100%;
  float: left;
  margin: 10px;
  min-height: 250px;
  background-color: #00000020;
  border-radius: 8px;

  @media (max-width: 800px) {
    width: 100%;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    margin: 20px;
`

export const UsersListContainer = styled.div`
  width: 100%;
  height: 100%;
  float: left;
  margin: 10px;
  min-height: 100px;
  background-color: #00000020;
  border-radius: 8px;
  color: #fff;

  .title {
    width: calc(100% - 60px);
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin: 20px;
    padding: 10px;
    border-bottom: 1px solid #fff;
  }

  .visitor {
    width: calc(100% - 60px);
    margin: 20px;
    padding: 10px;
    border-bottom: 1px solid #fff;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }

  table {
    width: calc(100% - 40px);
    margin: 20px;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }

  th {
    background-color: #00000040;
    text-align: center;
  }
`
