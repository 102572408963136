import React, { useState, useEffect } from 'react'
import { storageRef } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { useData } from '../../context/DataContext'

import { Container } from '../../style/ModalSmall.styled'

import Button from '../../button/Button'
import Input from '../../input/Input'
import Title from '../../modal/Title'
import Content from '../../modal/Content'
import Preview from '../../minor/Preview'
import initialLanguage from '../../language/initialLanguage'
import UpdateNavigation from '../../modal/UpdateNavigation'
import GetImage from '../../function/GetImage'
import UrlValid from '../../url/UrlValid'
import Domain from '../../url/Domain'
import Page from '../../url/Page'

import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getButton,
  getFocus,
} from '../../functionSet/ModalFunction'
import Palette from '../../palette/Palette'
import UploaderFile from '../../uploader/UploaderFile'

import withLocalForage from '../../context/withLocalForage'

const UpdateTabsModal = ({
  // active,
  // task,
  button,
  fullWidth,
  mobileClose,
  // main,
  // dragData,
  updateTabsModal,
  deleteTabsModal,
}) => {
  const { currentUser } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`

  const { global, setDrag, lang, updateModal, setUpdateModal } = useData()

  const { settings, styleData, domains, pages, images } = global

  const { active, main, dragData } = updateModal

  let { task } = updateModal

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  // let search = document.querySelector('.search')
  // if (search) search.style.zIndex = '99'

  //*
  const currentUrl = `${domains[task.idDomain].domainProtocol}${
    domains[task.idDomain].domainWWW ? 'www.' : ''
  }${domains[task.idDomain].domainHostname}${pages[task.idPages].pagesPathname}`
  //*
  const currentName =
    task.ownTitle ||
    domains[task.idDomain].domainTitle ||
    pages[task.idPages].pagesTitle ||
    domains[task.idDomain].domainHostname + ' (temp.)'
  //*
  const currentImage =
    task.ownPicture && task.ownPicture.startsWith('http')
      ? task.ownPicture
      : task.ownPicture && images[task.ownPicture]
      ? images[task.ownPicture].image
      : null
  //*
  const currentBackground =
    task.ownBackground ||
    domains[task.idDomain].domainBackground ||
    pages[task.idPages].pagesBackground ||
    '#ffffff'
  //*
  const currentColor =
    task.ownColor ||
    domains[task.idDomain].domainColor ||
    pages[task.idPages].pagesColor ||
    '#4285F4'
  //*
  const screen = pages[task.idPages].pagesPicture || null
  //*
  const logo = domains[task.idDomain].domainPicture || null
  //*
  const modalTitle =
    currentName.length > Math.floor(styleData.tabWidth / 8)
      ? currentName.slice(0, Math.floor(styleData.tabWidth / 8)) + '...'
      : currentName

  const [url, setUrl] = useState(currentUrl)
  const [name, setName] = useState(currentName)
  const [image, setImage] = useState(currentImage)
  const [background, setBackground] = useState(currentBackground)
  const [color, setColor] = useState(currentColor)

  const [homeNav, setHomeNav] = useState(true)
  const [colorNav, setColorNav] = useState(false)
  const [screenNav, setScreenNav] = useState(false)
  const [logoNav, setLogoNav] = useState(false)
  const [imageNav, setImageNav] = useState(false)

  const [activeDelay, setActiveDelay] = useState(false)
  const [modal, setModal] = useState(true)

  async function handleSubmit(e) {
    e.preventDefault()

    setUrl(url || currentUrl)
    setName(name || currentName)
    setImage(image || currentImage)
    setBackground(background || currentBackground)
    setColor(color || currentColor)

    if (url !== currentUrl) {
      //Означает что поле было изменено
      //Исходим из того, что данные изменились полновстью
      //Запрашиваем домен, если его нет, создаем новый домен
      //Запрашиваем старницу домена, если ее нет, создаем новую страницу
      //Обновляем данные

      //проверка на валидность url
      const urlValid = UrlValid({ url })

      const elemLabel = document.getElementById('url_up_label')
      if (urlValid) {
        if (elemLabel) elemLabel.style.color = ''
      } else {
        if (elemLabel) elemLabel.style.color = 'red'
        return
      }

      //проверка на наличие домена в списке доменов
      const domain = await Domain({ urlValid })
      if (!domain) return
      domains[domain.idDomain] = {
        ...domain,
      }

      //проверка на наличие страницы в списке страниц
      const page = await Page({ urlValid })
      if (!page) return
      pages[page.idPages] = {
        ...page,
      }

      //формируем новый объект для обновления данных
      task = {
        ...task,
        idDomain: domain.idDomain,
        idPages: page.idPages,
        ownTitle: name !== currentName ? name : '',
        ownPicture: image !== currentImage ? image : '',
        ownBackground:
          background !== currentBackground || color !== currentColor
            ? background
            : '',
        ownColor:
          background !== currentBackground || color !== currentColor
            ? color
            : '',
      }
    } else {
      if (name !== currentName) {
        task.ownTitle = name
      }

      if (image !== currentImage) {
        task.ownPicture = image
      }

      if (background !== currentBackground) {
        task.ownBackground = background
      }

      if (color !== currentColor) {
        task.ownColor = color
      }
    }

    setActiveDelay(false)
    if (colorNav) task.tabFill = 'color'
    if (screenNav) task.tabFill = 'screen'
    if (logoNav) task.tabFill = 'logo'
    if (imageNav) task.tabFill = 'image'

    if (!settings.tabsAsImage && task.tabFill === 'color') task.tabFill = ''
    if (settings.tabsAsImage && task.tabFill === 'screen' && !logo && !image)
      task.tabFill = ''
    if (settings.tabsAsImage && task.tabFill === 'logo' && !image)
      task.tabFill = ''
    if (settings.tabsAsImage && task.tabFill === 'image') task.tabFill = ''

    updateTabsModal(path, task, domains, pages, main, dragData, settings)

    setUrl('')
    setName('')
    setImage('')
    setBackground('')
    setColor('')

    setTimeout(() => {
      // if (search) search.style.zIndex = '100'
      setUpdateModal(false)
    }, 400)
  }

  async function handleDelete(e) {
    e.preventDefault()

    deleteTabsModal(path, task, main, dragData, settings)

    setActiveDelay(false)
    setUrl('')
    setName('')
    setTimeout(() => {
      // if (search) search.style.zIndex = '100'
      setUpdateModal(false)
    }, 400)
  }

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(active)
      setDrag(false)
    }, 1)
  }, [active])

  let modalActiv = activeDelay
  const navigate = useNavigate()

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({ device, styleData, button }),
    width: getWidth({ styleData, fullWidth }),
  }

  async function handleClose(e) {
    e.preventDefault()
    // navigate("/");

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      // if (search) search.style.zIndex = '100'
      setUpdateModal(false)
    }, 400)
  }

  async function handleBack(e) {
    e.preventDefault()
    // navigate(-1);
    handleClose(e)
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  useEffect(() => {
    //Для поля Email
    getFocus('url_up', setModal)
    getFocus('name_up', setModal)
    getFocus('color_up', setModal)
    getFocus('background_up', setModal)
    getFocus('image_up', setModal)
  }, [])

  const handlerChange = async (files) => {
    let reader = new FileReader()

    reader.readAsDataURL(files[0])
    reader.onload = function () {
      let image = new Image()
      image.src = reader.result

      image.onload = function () {
        let width = image.width
        let height = image.height

        if (width / height < 16 / 9) {
          let newHeight = (width / 16) * 9
          let top = (height - newHeight) / 2
          let canvas = document.createElement('canvas')
          canvas.width = 960
          canvas.height = 540
          let ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, top, width, newHeight, 0, 0, 960, 540)
          canvas.toBlob(async function (blob) {
            const uniq = generateRandomLetters()
            const storageImage = storageRef.child(
              `images/${Date.now()}_${uniq}.webp`
            )
            const snapshot = await storageImage.put(blob)
            setImage(`https://bucket.titletab.com/images/${snapshot.ref.name}`)
          }, 'image/webp')
          return
        }

        if (width / height > 16 / 9) {
          let newWidth = (height / 9) * 16
          let left = (width - newWidth) / 2
          let canvas = document.createElement('canvas')
          canvas.width = 960
          canvas.height = 540
          let ctx = canvas.getContext('2d')
          ctx.drawImage(image, left, 0, newWidth, height, 0, 0, 960, 540)
          canvas.toBlob(async function (blob) {
            const uniq = generateRandomLetters()
            const storageImage = storageRef.child(
              `images/${Date.now()}_${uniq}.webp`
            )
            const snapshot = await storageImage.put(blob)
            setImage(`https://bucket.titletab.com/images/${snapshot.ref.name}`)
          }, 'image/webp')
          return
        }

        if (width > 960 || height > 540) {
          let canvas = document.createElement('canvas')
          let ctx = canvas.getContext('2d')
          canvas.width = 960
          canvas.height = 540
          ctx.drawImage(image, 0, 0, 960, 540)
          canvas.toBlob(async function (blob) {
            const uniq = generateRandomLetters()
            const storageImage = storageRef.child(
              `images/${Date.now()}_${uniq}.webp`
            )
            const snapshot = await storageImage.put(blob)
            setImage(`https://bucket.titletab.com/images/${snapshot.ref.name}`)
          }, 'image/webp')
          return
        }

        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext('2d')
        canvas.width = width
        canvas.height = height
        ctx.drawImage(image, 0, 0, width, height)
        canvas.toBlob(async function (blob) {
          const uniq = generateRandomLetters()
          const storageImage = storageRef.child(
            `images/${Date.now()}_${uniq}.webp`
          )
          const snapshot = await storageImage.put(blob)
          setImage(`https://bucket.titletab.com/images/${snapshot.ref.name}`)
        }, 'image/webp')
        return
      }
    }
  }

  function generateRandomLetters() {
    var alphabet = 'abcdefghijklmnopqrstuvwxyz'
    var result = ''
    for (var i = 0; i < 4; i++) {
      result += alphabet.charAt(Math.floor(Math.random() * alphabet.length))
    }
    return result
  }

  const updateNav = () => {
    if (colorNav) {
      return (
        <>
          <Palette
            id="color_up"
            text={color}
            setText={setColor}
            label={initialLanguage[lang].utm_title_color}
            dark={settings.styleTheme}
          />
          <Palette
            id="background_up"
            text={background}
            setText={setBackground}
            label={initialLanguage[lang].utm_background_color}
            dark={settings.styleTheme}
          />
        </>
      )
    }

    if (screenNav || logoNav) {
      return (
        <>
          <div className="null"></div>
          <div className="desc"></div>
        </>
      )
    }

    if (imageNav) {
      return (
        <>
          {/* <Input
            id="image_up"
            labelId="image_up_label"
            type="text"
            state={image}
            setState={setImage}
            placeholder={''}
            label={initialLanguage[lang].utm_add_custom_image}
            labelUp={true}
            autoComplete="off"
            required={true}
            dark={settings.styleTheme}
            refLink={true}
          /> */}

          <UploaderFile
            id="first" //unique id for each component
            handlerChange={handlerChange}
            styled={{
              theme: settings.styleTheme ? true : false, //true - dark, false - light
              icon: 'bx bx-image-add', //i`m using boxicons
              iconColor: '#0364c1',
              iconColorDark: '#fff',
              iconColorHover: '#2b7de9',
              iconColorHoverDark: '#fff',

              textColor: '#666',
              textColorDark: '#fff',
              textColorHover: '#666',
              textColorHoverDark: '#fff',

              borderWidth: 2, //px
              borderStyle: 'dashed', //solid, dashed, dotted
              borderColor: '#0364c1',
              borderColorDark: '#fff',
              borderColorHover: '#2b7de9',
              borderColorHoverDark: '#fff',

              backgroundColor: '#fff',
              backgroundColorDark: '#2e275c',
              backgroundColorHover: '#f7f7f7',
              backgroundColorHoverDark: '#251f4a',

              fontSize: 12, //px
              heightContainer: 65, //px
              widthTypesSizeLabel: 110, //px
            }}
            label={{
              main: initialLanguage[lang].uf_main,
              drag: initialLanguage[lang].uf_drag,
              success: initialLanguage[lang].uf_success,
              errorMaxSize: initialLanguage[lang].uf_errorMaxSize,
              errorMinSize: initialLanguage[lang].uf_errorMinSize,
              errorType: initialLanguage[lang].uf_errorType,
              errorMultiple: initialLanguage[lang].uf_errorMultiple,
            }}
            settings={{
              children: false, //you can add your own component
              multiple: false, // Allow multiple files to be uploaded
              types: ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'],
              typesLabel: 'JPEG, PNG',
              maxSize: 2, //MB
              minSize: 0, //MB (0 = no min size)
            }}
          />
          <div className="null"></div>
        </>
      )
    }

    return (
      <>
        <Input
          id="url_up"
          labelId="url_up_label"
          type="text"
          state={url}
          setState={setUrl}
          placeholder={''}
          label={initialLanguage[lang].utm_url_address}
          labelUp={true}
          autoComplete="off"
          required={true}
          dark={settings.styleTheme}
        />

        <Input
          id="name_up"
          labelId="name_up_label"
          type="text"
          state={name}
          setState={setName}
          placeholder={''}
          label={initialLanguage[lang].utm_title}
          labelUp={true}
          autoComplete="off"
          required={true}
          dark={settings.styleTheme}
        />
      </>
    )
  }

  return (
    <div
      className={getBlackout({ device, modalActiv })}
      onClick={modal ? handleClose : null}
    >
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={getClassName({
              modalActiv,
              device,
              dark: settings.styleTheme,
            })}
          >
            <Title
              children={modalTitle}
              dark={settings.styleTheme}
              handleBack={handleBack}
              handleClose={handleClose}
            />

            <Content>
              <Preview
                url={url}
                task={task}
                color={color}
                background={background}
                screen={screen}
                logo={logo}
                image={image}
                homeNav={homeNav}
                colorNav={colorNav}
                screenNav={screenNav}
                logoNav={logoNav}
                imageNav={imageNav}
                handlerChange={handlerChange}
              />

              <UpdateNavigation
                screen={screen}
                logo={logo}
                homeNav={homeNav}
                colorNav={colorNav}
                screenNav={screenNav}
                logoNav={logoNav}
                imageNav={imageNav}
                setHomeNav={setHomeNav}
                setColorNav={setColorNav}
                setScreenNav={setScreenNav}
                setLogoNav={setLogoNav}
                setImageNav={setImageNav}
                dark={settings.styleTheme}
              />

              {updateNav()}
            </Content>

            <Button
              onClick={handleSubmit}
              color={'blue'}
              isDesktop={true}
              dark={settings.styleTheme}
              type="submit"
            >
              {initialLanguage[lang].utm_apply_update}
            </Button>

            <Button
              onClick={handleDelete}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].utm_delete}
            </Button>

            <Button
              onClick={handleClose}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].utm_cancel}
            </Button>

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
    </div>
  )
}

export default withLocalForage(UpdateTabsModal)
