import React, { useEffect } from 'react'
import { useData } from '../context/DataContext'
import { getLinkHost } from '../functionSet/PreviewFunction'
import { Container } from '../style/Preview.styled'
import UploaderFile from '../uploader/UploaderFile'
import initialLanguage from '../language/initialLanguage'

export default function Preview({
  url,
  image,
  background,
  color,
  screen,
  logo,
  task,
  homeNav,
  colorNav,
  screenNav,
  logoNav,
  imageNav,
  handlerChange,
}) {
  const { global, lang, device } = useData()
  const { settings, styleData, domains } = global

  let ratio = 1.55
  if (device === 'mobile') ratio = 2

  let tabFill

  if (!background) background = '#fff'
  if (!color) color = '#266df0'

  if (homeNav) tabFill = getTabFill()
  if (colorNav) tabFill = 'color'
  if (screenNav) tabFill = 'screen'
  if (logoNav) tabFill = 'logo'
  if (imageNav) tabFill = 'image'
  if (!tabFill) tabFill = 'color'

  useEffect(() => {
    if (!tabFill) return
    const uploaderFile = document.getElementById('previewSection')
    if (uploaderFile) {
      uploaderFile.style.transition = 'opacity 0s'
      uploaderFile.style.opacity = 0
    }

    setTimeout(() => {
      const images = document.querySelectorAll('.previewImg')
      if (!images) return
      const handleLoad = () => {
        if (uploaderFile) {
          uploaderFile.style.transition = 'opacity 1s'
          uploaderFile.style.opacity = 1
        }
      }
      const promises = Array.from(images).map((img) => {
        return new Promise((resolve) => {
          if (img.complete) {
            resolve()
          } else {
            img.addEventListener('load', resolve)
          }
        })
      })
      Promise.all(promises).then(handleLoad)
    }, 10)
  }, [tabFill, task.pictureImage])

  let linkHost = getLinkHost({ task, domains })

  function getTabFill() {
    if (task.tabFill) return task.tabFill
    if (settings.tabsAsImage) {
      if (image) return 'image'
      if (logo) return 'logo'
      if (screen) return 'screen'
      return 'color'
    }
    return 'color'
  }

  const getContent = () => {
    if (tabFill === 'color') {
      return (
        <UploaderFile
          handlerChange={handlerChange}
          label={{
            main: initialLanguage[lang].uf_main,
            drag: initialLanguage[lang].uf_drag,
            success: initialLanguage[lang].uf_success,
            errorMaxSize: initialLanguage[lang].uf_errorMaxSize,
            errorMinSize: initialLanguage[lang].uf_errorMinSize,
            errorType: initialLanguage[lang].uf_errorType,
            errorMultiple: initialLanguage[lang].uf_errorMultiple,
          }}
          settings={{
            children: true,
          }}
        >
          <section
            className="preview"
            style={{
              backgroundColor: background,
            }}
            id="previewSection"
          >
            <span
              className="preview_text"
              style={{
                color: color,
                fontSize: styleData.tabFontSize * ratio + 'px',
              }}
            >
              {linkHost}
            </span>
          </section>
        </UploaderFile>
      )
    }

    if (tabFill === 'screen') {
      if (screen === 'downloadFile') {
        return (
          <UploaderFile
            handlerChange={handlerChange}
            label={{
              main: initialLanguage[lang].uf_main,
              drag: initialLanguage[lang].uf_drag,
              success: initialLanguage[lang].uf_success,
              errorMaxSize: initialLanguage[lang].uf_errorMaxSize,
              errorMinSize: initialLanguage[lang].uf_errorMinSize,
              errorType: initialLanguage[lang].uf_errorType,
              errorMultiple: initialLanguage[lang].uf_errorMultiple,
            }}
            settings={{
              children: true,
            }}
          >
            <section
              className="preview"
              style={{
                backgroundColor: '#ffffff',
              }}
              id="previewSection"
            >
              <span
                className="preview_text"
                style={{
                  color: '#0364c1',
                  fontSize: styleData.tabFontSize * ratio * 3 + 'px',
                }}
              >
                <i className="bx bxs-file-import"></i>
              </span>
            </section>
          </UploaderFile>
        )
      }
      return (
        <UploaderFile
          handlerChange={handlerChange}
          label={{
            main: initialLanguage[lang].uf_main,
            drag: initialLanguage[lang].uf_drag,
            success: initialLanguage[lang].uf_success,
            errorMaxSize: initialLanguage[lang].uf_errorMaxSize,
            errorMinSize: initialLanguage[lang].uf_errorMinSize,
            errorType: initialLanguage[lang].uf_errorType,
            errorMultiple: initialLanguage[lang].uf_errorMultiple,
          }}
          settings={{
            children: true,
          }}
        >
          <section className="preview" id="previewSection">
            <img src={screen} className="previewImg" />
          </section>
        </UploaderFile>
      )
    }

    if (tabFill === 'logo') {
      return (
        <UploaderFile
          handlerChange={handlerChange}
          label={{
            main: initialLanguage[lang].uf_main,
            drag: initialLanguage[lang].uf_drag,
            success: initialLanguage[lang].uf_success,
            errorMaxSize: initialLanguage[lang].uf_errorMaxSize,
            errorMinSize: initialLanguage[lang].uf_errorMinSize,
            errorType: initialLanguage[lang].uf_errorType,
            errorMultiple: initialLanguage[lang].uf_errorMultiple,
          }}
          settings={{
            children: true,
          }}
        >
          <section
            className="preview"
            style={{
              backgroundColor: background,
            }}
            id="previewSection"
          >
            <img src={logo} className="previewImg" />
          </section>
        </UploaderFile>
      )
    }

    if (tabFill === 'image') {
      return (
        <UploaderFile
          handlerChange={handlerChange}
          label={{
            main: initialLanguage[lang].uf_main,
            drag: initialLanguage[lang].uf_drag,
            success: initialLanguage[lang].uf_success,
            errorMaxSize: initialLanguage[lang].uf_errorMaxSize,
            errorMinSize: initialLanguage[lang].uf_errorMinSize,
            errorType: initialLanguage[lang].uf_errorType,
            errorMultiple: initialLanguage[lang].uf_errorMultiple,
          }}
          settings={{
            children: true,
          }}
        >
          {image ? (
            <section className="preview" id="previewSection">
              <img src={image} className="previewImg" />
            </section>
          ) : (
            <section
              className="preview"
              style={{
                backgroundColor: background,
              }}
              id="previewSection"
            >
              <span
                className="preview_text"
                style={{
                  color: color,
                  fontSize: styleData.tabFontSize * ratio + 'px',
                }}
              >
                add an image
              </span>
            </section>
          )}
        </UploaderFile>
      )
    }

    return (
      <UploaderFile
        handlerChange={handlerChange}
        label={{
          main: initialLanguage[lang].uf_main,
          drag: initialLanguage[lang].uf_drag,
          success: initialLanguage[lang].uf_success,
          errorMaxSize: initialLanguage[lang].uf_errorMaxSize,
          errorMinSize: initialLanguage[lang].uf_errorMinSize,
          errorType: initialLanguage[lang].uf_errorType,
          errorMultiple: initialLanguage[lang].uf_errorMultiple,
        }}
        settings={{
          children: true,
        }}
      >
        <section
          className="preview previewImg"
          style={{
            backgroundColor: background,
          }}
          id="previewSection"
        >
          <span
            className="preview_text"
            style={{
              color: color,
              fontSize: styleData.tabFontSize * ratio + 'px',
            }}
          >
            {linkHost}
          </span>
        </section>
      </UploaderFile>
    )
  }

  return (
    <Container>
      <a href={url} target="_blank">
        {getContent()}
      </a>
    </Container>
  )
}
