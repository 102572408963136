import React from 'react'

export default function AboutPl() {
  return (
    <>
      <p>
        Witamy w TitleTab - Panelu szybkiego wybierania, najlepszym rozwiązaniu
        do zarządzania kartami, zaprojektowanym tak, aby przeglądanie było
        szybsze i wygodniejsze niż kiedykolwiek wcześniej. Nasze rozwiązanie
        oferuje szereg funkcji, które pomogą Ci spersonalizować karty
        przeglądarki, zapobiegać przypadkowemu zamknięciu przeglądarki i
        udostępniać ulubione linki innym użytkownikom.
      </p>
      <p>Oto niektóre z niesamowitych funkcji, które oferuje TitleTab:</p>
      <p>
        <strong>Spersonalizowana strona początkowa</strong>
      </p>
      <p>
        Nasza funkcja StartPage umożliwia łatwe przeciąganie i upuszczanie kart
        w celu ułożenia ich w sposób odpowiadający Twoim potrzebom. Możesz także
        dostosować każdą kartę za pomocą różnych opcji wyświetlania, w tym
        zrzutu ekranu strony internetowej, tekstu, logo popularnych witryn lub
        własnych obrazów. Oferujemy zarówno jasny, jak i ciemny motyw, więc
        możesz wybrać ten, który odpowiada Twoim preferencjom.
      </p>
      <p>
        <strong>
          Organizacja zakładek: foldery i nieograniczone przestrzenie
        </strong>
      </p>
      <p>
        TitleTab oferuje zaawansowane funkcje organizacji kart, które pozwalają
        uporządkować karty w sposób, który Ci odpowiada. Możesz grupować karty w
        foldery, po prostu przeciągając i upuszczając jedną kartę na drugą.
        Ponadto możesz tworzyć nowe przestrzenie, które pomogą Ci oddzielić
        różne typy kart, takie jak karty związane z pracą i karty związane z
        hobby. Dzięki funkcjom organizacji kart w TitleTab możesz łatwo
        zarządzać swoimi kartami i organizować i wydajnie przeglądać.
      </p>
      <p>
        <strong>Przestrzeń publiczna z unikalnym łączem</strong>
      </p>
      <p>
        Użytkownicy TitleTab mogą również tworzyć przestrzeń publiczną, do
        której dostęp mają inni użytkownicy posiadający łącze. Gdy aktywujesz
        przycisk „Przestrzeń publiczna” po raz pierwszy, poprosimy Cię o
        wypełnienie niektórych pól, które są niezbędne do uruchomienia Twojej
        przestrzeni publicznej. Najważniejszym polem, które należy wypełnić,
        jest unikalny adres linku („Ścieżka dostępu do przestrzeni publicznej”),
        przez który będzie dostępna Twoja przestrzeń z zakładkami, które chcesz
        udostępnić innym użytkownikom. Po aktywowaniu przestrzeni publicznej
        możesz zacząć dodawać do niej zakładki. Ta funkcja jest szczególnie
        przydatna, jeśli chcesz udostępniać karty znajomym lub współpracownikom
        w celu wspólnej pracy. Dzięki funkcji przestrzeni publicznej w TitleTab
        możesz łatwo udostępniać swoje karty i współpracować z innymi w wygodny
        i bezpieczny sposób.
      </p>
      <p>
        <strong>OneTab: Zapisz wszystkie otwarte karty w jednym</strong>
      </p>
      <p>
        Dla popularnych przeglądarek dostępne jest rozszerzenie, które pozwala
        użytkownikom zarządzać otwartymi kartami w przeglądarce. Po prostu
        klikając przycisk rozszerzenia, możesz zapisać wszystkie (lub wybrane)
        karty w jednej karcie, która będzie przechowywać listę witryn i będzie
        gotowa do ponownego otwarcia w dowolnym momencie. Ta funkcja znacznie
        oszczędza zasoby Twojego urządzenia, ponieważ otwarte karty nadal
        działają i wykorzystują pamięć Twojego urządzenia.
      </p>
      <p>
        Warto również zauważyć, że nie śledzimy, co dokładnie zapisujesz w ten
        sposób, co gwarantuje Ci prywatność, ale jednocześnie nie możemy zapisać
        tych informacji za Ciebie. Dlatego te informacje są dostępne tylko
        lokalnie w tej przeglądarce na tym urządzeniu, na którym pracujesz. To
        jest cena Twojej prywatności.
      </p>
      <p>
        <strong>Nieskończoność Nowa karta</strong>
      </p>
      <p>
        Nowa karta Infinity to kolejna funkcja naszego rozszerzenia, która
        zapewnia niekończące się przeglądanie. Dzięki nieskończonej liczbie
        nowych kart użytkownicy mogą kontynuować przeglądanie bez obawy o
        przypadkowe zamknięcie ostatniej otwartej karty. Ta funkcja
        automatycznie dodaje nową kartę po lewej stronie ostatnio otwartej
        karty, dzięki czemu użytkownicy zawsze mają kartę, na której mogą się
        cofnąć. Po otwarciu dodatkowych kart nowa karta Infinity znika,
        umożliwiając użytkownikom swobodne przeglądanie bez żadnych przerw. Ta
        funkcja zapewnia użytkownikom spokój ducha i zapobiega frustracji
        związanej z przypadkowym zamknięciem ostatniej karty.
      </p>
      <p>
        <strong>Rozszerzenia karty tytułowej</strong>
      </p>
      <p>
        TitleTab jest kompatybilny z popularnymi przeglądarkami internetowymi,
        takimi jak Google Chrome, Mozilla Firefox i Microsoft Edge. Nasze
        rozszerzenie można łatwo pobrać i zainstalować z oficjalnych sklepów z
        rozszerzeniami każdej przeglądarki.
      </p>
      <p>Oto lista miejsc, z których można pobrać TitleTab:</p>
      <ul>
        <li>
          <a
            href="https://chrome.google.com/webstore/detail/titletab-web-dashboard/ldlndadamgafkigafpajckkjbblnepcb"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome Web Store
          </a>
        </li>
        <li>
          <a
            href="https://addons.mozilla.org/firefox/addon/titletab-web-dashboard/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Firefox Add-ons
          </a>
        </li>
        <li>
          <a
            href="https://microsoftedge.microsoft.com/addons/detail/titletab-web-dashboard/mnckgifglkiecanfbdpnajiplpabkphh"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge Add-ons
          </a>
        </li>
      </ul>
      <p>
        Po zainstalowaniu możesz zacząć używać TitleTab do dostosowywania strony
        nowej karty, zapisywania i organizowania kart, zapobiegania
        przypadkowemu zamknięciu kart i udostępniania linków innym osobom. Nie
        przegap okazji, aby usprawnić przeglądanie dzięki TitleTab. Pobierz
        nasze rozszerzenie już dziś!
      </p>
      <p>
        <strong>Prywatność i ochrona</strong>
      </p>
      <p>
        TitleTab poważnie traktuje Twoją prywatność i bezpieczeństwo. Wszystkie
        Twoje dane są bezpiecznie przechowywane na Twoim urządzeniu oraz w
        chmurze i nigdy nie udostępniamy Twoich danych stronom trzecim.
      </p>
      <hr />
      <p>
        TitleTab to najlepsze rozwiązanie do zarządzania kartami dla każdego,
        kto chce przejąć kontrolę nad przeglądaniem. Wypróbuj już dziś i sam
        zobacz różnicę!
      </p>
      <p>
        Jeśli masz jakiekolwiek pytania dotyczące TitleTab lub potrzebujesz
        pomocy w zakresie którejkolwiek z naszych funkcji, skontaktuj się z nami
        pod adresem&nbsp;
        <a
          href="mailto:info@titletab.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@titletab.com
        </a>
        . Nasz zespół zawsze chętnie pomoże i odpowie na wszelkie pytania.
        Cenimy Twoją opinię i dokładamy wszelkich starań, aby zapewnić jak
        najlepsze wrażenia użytkownika, więc nie wahaj się z nami skontaktować,
        jeśli masz jakieś sugestie lub uwagi.
      </p>
    </>
  )
}
