const initialState = {
  styled: {
    theme: false,
    icon: "bx bx-image-add",
    iconColor: "#0364c1",
    iconColorDark: "#fff",
    iconColorHover: "#2b7de9",
    iconColorHoverDark: "#fff",
    textColor: "#666",
    textColorDark: "#fff",
    textColorHover: "#666",
    textColorHoverDark: "#fff",
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: "#0364c1",
    borderColorDark: "#fff",
    borderColorHover: "#2b7de9",
    borderColorHoverDark: "#fff",
    backgroundColor: "#fff",
    backgroundColorDark: "#2e275c",
    backgroundColorHover: "#f7f7f7",
    backgroundColorHoverDark: "#251f4a",
    fontSize: 12,
    heightContainer: 65,
    widthTypesSizeLabel: 110,
  },
  label: {
    main: "<u>Upload</u> or drop an image right here",
    drag: "<u>Drop</u> your file here",
    success: '<span style="color:green"><u>Upload</u> successful!</span>',
    errorMaxSize:
      '<span style="color:red">You can only upload files <u>up to 2MB</u></span>',
    errorMinSize:
      '<span style="color:red">You can only upload files <u>more than 100KB</u></span>',
    errorType:
      '<span style="color:red">You can only upload <u>image files</u></span>',
    errorMultiple:
      '<span style="color:red">You can only upload <u>one file</u> at a time</span>',
  },
  settings: {
    children: false,
    multiple: false,
    types: ["image/png", "image/jpeg", "image/jpg"],
    typesLabel: "JPEG, PNG",
    maxSize: 2,
    minSize: 0,
  },
};

export default initialState;
