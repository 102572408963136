import React, { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import { usePage } from '../context/PageContext'
import Switch from '../switch/Switch'
import Select from '../select/Select'
import Scale from '../scale/Scale'
import initialLanguage from '../language/initialLanguage'

export default function NavBarOpen({
  changeSpaceBar,
  updateSettings,
  spaceUpBar,
  spaceDownBar,
  searchRestore,
  setModalActive,
  setSpaceUpdate,
  setModalUpdateActive,
  setModalUpdatePublicActive,
}) {
  const navigate = useNavigate()
  const { currentUser, logout } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`
  const displayName = currentUser
    ? currentUser.displayName || currentUser.email
    : ''
  const {
    global,
    space,
    setSpace,
    setActiveState,
    setShareState,
    setOutPublic,
    lang,
  } = useData()
  const { settings } = global
  const { setShareModal } = usePage()

  const [swUseDark, setSwUseDark] = useState(settings.styleTheme)
  const [swHeadersBg, setHeadersBg] = useState(settings?.headersBg)
  const [swUseImg, setSwUseImg] = useState(settings.tabsAsImage)
  const [swOpenTabTarget, setSwOpenTabTarget] = useState(settings.targetTabs)
  const [swOpenSearchTarget, setSwOpenSearchTarget] = useState(
    settings.targetSearch
  )

  const searchList = [
    'Google',
    'Bing',
    'Yahoo',
    'Baidu',
    'DuckDuckGo',
    'Yandex',
  ]

  let sliceIndex = currentUser ? 1 : 0

  const spaceFullNav = Object.entries(global.spaces)
    .slice(sliceIndex)
    .map((item, index) => {
      index = parseInt(index, 10) + 1
      return (
        <li key={item} id={'li' + index}>
          <a className={space == index ? 'active' : null} title={item[1].space}>
            <i
              className={item[1].icon ? item[1].icon : 'bx bx-bookmarks'}
              onClick={() => changeSpace(index)}
            ></i>
            <span
              className="links_name short"
              onClick={() => changeSpace(index)}
            >
              {item[1].space}
            </span>
            <i
              className="up bx bx-chevron-up"
              title="Space Up"
              onClick={() => spaceUp(index)}
            ></i>
            <i
              className="down bx bx-chevron-down"
              title="Space Down"
              onClick={() => spaceDown(index)}
            ></i>
            <i
              className="settings bx bx-cog"
              title="Space Settings"
              onClick={() => updateSpace(index)}
            ></i>
          </a>
        </li>
      )
    })

  let isSpaceRunning = false

  function changeSpace(value) {
    if (isSpaceRunning) return
    isSpaceRunning = true

    changeSpaceBar(path, value, space)

    isSpaceRunning = false
    return
  }

  function spaceUp(key) {
    if (isSpaceRunning) return
    isSpaceRunning = true

    spaceUpBar(path, key, space)
    setTimeout(() => {
      isSpaceRunning = false
    }, 300)
    return
  }

  function spaceDown(key) {
    if (isSpaceRunning) return
    isSpaceRunning = true

    spaceDownBar(path, key, space)

    setTimeout(() => {
      isSpaceRunning = false
    }, 300)
    return
  }

  function addSpace() {
    setModalActive(true)
    navigate('/')
    setOutPublic(false)
  }

  function updateSpace(value) {
    searchRestore(path, true)
    if (currentUser) {
      if (Number(value) !== space || space === null) {
        const dragContainer = document.getElementById('dragContainer')
        if (dragContainer) dragContainer.style.opacity = 0
        navigate('/')
        setTimeout(() => {
          setOutPublic(false)
          setSpace(Number(value))
        }, 200)
      }
    }

    setActiveState(global.spaces[value])
    setSpaceUpdate(value)
    if (value === 0) {
      setModalUpdatePublicActive(true)
    } else {
      setModalUpdateActive(true)
    }
  }

  function getClassName(value) {
    let className = 'line'
    if (value) className += ' x2'
    if (settings.styleTheme) className += ' dark'
    return className
  }

  function copyData() {
    const textToCopy = 'https://titletab.com' + global.spaces[0].nickname
    const textarea = document.createElement('textarea')
    textarea.value = textToCopy
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }

  async function sharePablic() {
    let slash = global.spaces[0].name ? ' ' : ''
    setShareModal(true)
    setShareState({
      url: 'https://titletab.com' + global.spaces[0].nickname,
      title:
        global.spaces[0].name +
        slash +
        global.spaces[0].nickname +
        ' » TitleTab - Speed Dial Dashboard',
      description:
        global.spaces[0].description ||
        'Explore the favorite websites and online resources of our community members with TitleTab Personal Spaces! Get a unique glimpse into the interests and hobbies of our users and discover new websites that you may have never found on your own.',
    })
    return
  }

  function openOwnPublic() {
    if (global.spaces[0] && global.spaces[0].nickname) {
      changeSpaceBar(path, 0, space)
    } else {
      updateSpace(0)
    }

    return
  }

  async function handleExtension() {
    window.open(extensionLink)
  }

  async function handleBackground() {
    navigate('/background')
    setOutPublic(false)
  }

  async function handleAbout(e) {
    navigate('/about')
    setOutPublic(false)
  }

  async function handleShare() {
    setShareModal(true)
    setShareState()
    return
  }

  async function handlePolicy(e) {
    navigate('/privacy-policy')
    setOutPublic(false)
  }

  async function handleUpdateProfile() {
    navigate('/update-profile')
    setOutPublic(false)
  }

  async function handleContactUs(e) {
    navigate('/contact-us')
    setOutPublic(false)
  }

  const browser = useMemo(() => {
    let browser = 'unknown'
    if (
      (navigator.userAgent.indexOf('Opera') != -1 ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      browser = 'Opera'
    } else if (navigator.userAgent.indexOf('Edg') != -1) {
      browser = 'Edge'
    } else if (
      navigator.userAgent.indexOf('Chrome') != -1 ||
      navigator.userAgent.indexOf('Chromium') != -1
    ) {
      browser = 'Chrome'
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      browser = 'Safari'
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      browser = 'Firefox'
    } else if (
      navigator.userAgent.indexOf('MSIE') != -1 ||
      !!document.documentMode == true
    ) {
      browser = 'IE'
    }
    return browser
  }, [])

  const extensionIcon = useMemo(() => {
    if (browser === 'Chrome') {
      return 'bx bxl-chrome'
    } else if (browser === 'Firefox') {
      return 'bx bxl-firefox'
    } else if (browser === 'Edge') {
      return 'bx bxl-edge'
    } else {
      return null
    }
  }, [browser])

  const extensionText = useMemo(() => {
    if (browser === 'Chrome') {
      return `${initialLanguage[lang].nb_extension} Chrome`
    } else if (browser === 'Firefox') {
      return `${initialLanguage[lang].nb_extension} Firefox`
    } else if (browser === 'Edge') {
      return `${initialLanguage[lang].nb_extension} Edge`
    } else {
      return null
    }
  }, [browser])

  const extensionLink = useMemo(() => {
    if (browser === 'Chrome') {
      return 'https://chrome.google.com/webstore/detail/titletab-web-dashboard/ldlndadamgafkigafpajckkjbblnepcb'
    } else if (browser === 'Firefox') {
      return 'https://addons.mozilla.org/firefox/addon/titletab-web-dashboard/'
    } else if (browser === 'Edge') {
      return 'https://microsoftedge.microsoft.com/addons/detail/titletab-web-dashboard/mnckgifglkiecanfbdpnajiplpabkphh'
    } else {
      return null
    }
  }, [browser])

  const styleThemeChange = () => {
    setSwUseDark(!swUseDark)
    global.settings.styleTheme = !swUseDark
    updateSettings(path, global.settings)
  }

  const styleHeadersBg = () => {
    setHeadersBg(!swHeadersBg)
    global.settings.headersBg = !swHeadersBg
    updateSettings(path, global.settings)
  }

  const styleUseImg = () => {
    setSwUseImg(!swUseImg)
    global.settings.tabsAsImage = !swUseImg
    updateSettings(path, global.settings)
  }

  const openTabTarget = () => {
    setSwOpenTabTarget(!swOpenTabTarget)
    global.settings.targetTabs = !swOpenTabTarget
    updateSettings(path, global.settings)
  }

  const openSearchTarget = () => {
    setSwOpenSearchTarget(!swOpenSearchTarget)
    global.settings.targetSearch = !swOpenSearchTarget
    updateSettings(path, global.settings)
  }

  const selectSearch = (value) => {
    global.settings.defaultSearch = value
    updateSettings(path, global.settings)
  }

  return (
    <ul className="nav-list">
      <li>
        <a onClick={openOwnPublic}>
          <i className="bx bx-world"></i>
          <span
            className={
              global.spaces[0] && global.spaces[0].nickname
                ? 'links_name short'
                : 'links_name long'
            }
          >
            {initialLanguage[lang].nb_public_space}
          </span>
          {global.spaces[0] && global.spaces[0].nickname ? (
            <>
              <i
                className="up bx bxs-share-alt"
                title="Share  Space"
                onClick={() => sharePablic()}
              ></i>
              <i
                className="down bx bx-copy"
                title="Copy Link"
                onClick={copyData}
              ></i>
            </>
          ) : null}
          <i
            className="settings bx bx-cog"
            title="Public Space Settings"
            onClick={() => updateSpace(0)}
          ></i>
        </a>
      </li>
      {/* <li>
    <a onClick={openOwnPublic}>
      <i className="bx bx-user-voice"></i>
      <span className="links_name long">My Following</span>
      <i className="settings bx bx-cog"></i>
    </a>
  </li>
  <li>
    <a onClick={openOwnPublic}>
      <i className="bx bx-user-voice"></i>
      <span className="links_name long">My Followers</span>
      <i className="settings bx bx-cog"></i>
    </a>
  </li> */}
      <div className={getClassName()}>
        <span>{initialLanguage[lang].nb_private_space}</span>
      </div>
      {spaceFullNav}
      <li>
        <a onClick={addSpace}>
          <i className="bx bx-plus"></i>
          <span className="links_name long">
            {initialLanguage[lang].nb_add_space}
          </span>
        </a>
      </li>

      <div className={getClassName(true)}>
        <span>{initialLanguage[lang].nb_general_settings}</span>
      </div>
      <li className="li-switch">
        <Switch
          name="swUseDark"
          title={initialLanguage[lang].nb_use_dark_theme}
          checked={swUseDark}
          onChange={styleThemeChange}
          disabled={false}
        />
      </li>
      <li className="li-switch">
        <Switch
          name="swUseDark"
          title={initialLanguage[lang].nb_tab_headers}
          smallTitle={initialLanguage[lang].nb_with_background}
          checked={swHeadersBg}
          onChange={styleHeadersBg}
          disabled={false}
        />
      </li>
      {/* <li>
    <ButtonBar
      title="Фон сайта для этого устройства"
      background="Endless Constellatio (default)"
      onClick={handlePolicy}
    />
  </li> */}
      <li className="li-switch">
        <Switch
          name="swUseImg"
          title={initialLanguage[lang].nb_use_images}
          smallTitle={initialLanguage[lang].nb_as_a_fill_tabs}
          checked={swUseImg}
          onChange={styleUseImg}
          disabled={false}
        />
      </li>
      <li className="li-switch">
        <Switch
          name="swOpenTabTarget"
          title={initialLanguage[lang].nb_open_tabs_target}
          smallTitle={initialLanguage[lang].nb_in_a_new_window}
          checked={swOpenTabTarget}
          onChange={openTabTarget}
          disabled={false}
        />
      </li>
      {/* <li className="li-switch">
    <Switch
      name="swShowSearch"
      title={initialLanguage[lang].nb_show_search}
      checked={swShowSearch}
      onChange={showSearch}
      disabled={false}
    />
  </li> */}
      <li className="li-switch">
        <Switch
          name="swOpenSearchTarget"
          title={initialLanguage[lang].nb_open_search_results_target}
          smallTitle={initialLanguage[lang].nb_in_a_new_window}
          checked={swOpenSearchTarget}
          onChange={openSearchTarget}
          disabled={false}
        />
      </li>
      <li className="li-switch">
        <Select
          title={initialLanguage[lang].nb_default_search}
          value={settings.defaultSearch}
          options={searchList}
          onChange={selectSearch}
        />
      </li>
      <li className="li-switch">
        <Scale />
      </li>
      {extensionIcon && extensionText && extensionLink ? (
        <li>
          <a onClick={handleExtension}>
            <i className={extensionIcon}></i>
            <span className="links_name">{extensionText}</span>
          </a>
          <span className="tooltip">{extensionText}</span>
        </li>
      ) : null}
      <li>
        <a onClick={handleBackground}>
          <i className="bx bx-image"></i>
          <span className="links_name">
            {initialLanguage[lang].nb_backgroung_image}
          </span>
        </a>
        <span className="tooltip">
          {initialLanguage[lang].nb_backgroung_image}
        </span>
      </li>
      <li>
        <a onClick={handleShare}>
          <i className="bx bx-share-alt"></i>
          <span className="links_name">
            {initialLanguage[lang].nb_share_link}
          </span>
        </a>
        <span className="tooltip">{initialLanguage[lang].nb_share_link}</span>
      </li>
      {/* <li>
        <a onClick={handlePolicy}>
          <i className="bx bx-user-check"></i>
          <span className="links_name">
            {initialLanguage[lang].nb_privacy_policy}
          </span>
        </a>
        <span className="tooltip">
          {initialLanguage[lang].nb_privacy_policy}
        </span>
      </li> */}

      <li>
        <a onClick={handleAbout}>
          <i className="bx bx-info-circle"></i>
          <span className="links_name">{initialLanguage[lang].nb_about}</span>
        </a>
        <span className="tooltip">{initialLanguage[lang].nb_about}</span>
      </li>

      <li>
        <a
          href="https://www.buymeacoffee.com/titletab"
          target="_blank"
          rel="noreferrer"
        >
          <i className="bx bx-coffee-togo"></i>
          <span className="links_name">Buy Me a Coffee</span>
        </a>
        <span className="tooltip">Buy Me a Coffee</span>
      </li>
      <li>
        <a onClick={handleContactUs}>
          <i className="bx bx-support"></i>
          <span className="links_name">
            {initialLanguage[lang].nb_contact_us}
          </span>
        </a>
        <span className="tooltip">{initialLanguage[lang].nb_contact_us}</span>
      </li>
      {currentUser && (
        <li>
          <a onClick={handleUpdateProfile}>
            <i className="bx bx-cog"></i>
            <span className="links_name">
              {initialLanguage[lang].nb_account_settings}
            </span>
          </a>
          <span className="tooltip">
            {initialLanguage[lang].nb_account_settings}
          </span>
        </li>
      )}

      {/* <li>
    <a onClick={shareFacebook}>
      <i className="bx bxl-facebook"></i>
      <span className="links_name">Share on Facebook</span>
    </a>
  </li>
  <li>
    <a onClick={shareTwitter}>
      <i className="bx bxl-twitter"></i>
      <span className="links_name">Share on Twitter</span>
    </a>
  </li> */}
    </ul>
  )
}
