import * as localForage from 'localforage'
import SettingsGet from '../firebase/SettingsGet'
import SettingsUpdate from '../firebase/SettingsUpdate'

export default async function SyncSettings({
  path,
  uid,
  device,
  space,
  fromFirebase,
  assignGlobal,
}) {
  //Получаем данные из localForage по ключю.
  let data = await localForage.getItem(path)

  let settings = data.settings

  //Получить актуальные данные из базы данных
  let base = await SettingsGet({ uid })

  if (!settings) return null

  if (settings.time === base.time) {
    //Настройки не изменились
  }

  //Сравнить данные из базы данных с данными из localForage
  if (settings.time > base.time) {
    //Локальная версия settings более новая, чем база. Обновляем базу.
    await SettingsUpdate({ uid, settings })
    //Настройки обновляются на сервере
  }

  if (settings.time < base.time) {
    //Локальная версия settings более старая, чем база. Обновляем локальную версию.
    const firebase = await fromFirebase(path, uid, device)
    return assignGlobal(firebase, space)
  }
}
