const initialRoutes = {
  '/': {
    title: 'TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: false,
    setForgotPassword: false,
    setPrivacyPolicy: false,
    setBackgroundModal: false,
    setAnalytics: false,
    setAbout: false,
    setUpdateProfile: false,
    setContactUs: false,
    setChangeEmail: false,
    setChangePassword: false,
    setDeleteAccount: false,
    setMobileMenu: false,
    setModalLarge: false,
    setModalSmall: false,
  },
  '/sign-in': {
    title: 'Sign In » TitleTab - Speed Dial Dashboard',
    setSignIn: true,
    setSignUp: false,
    setForgotPassword: false,
    setPrivacyPolicy: false,
    setBackgroundModal: false,
    setAnalytics: false,
    setAbout: false,
    setUpdateProfile: false,
    setContactUs: false,
    setChangeEmail: false,
    setChangePassword: false,
    setDeleteAccount: false,
    setMobileMenu: false,
    setModalLarge: false,
    setModalSmall: false,
  },
  '/sign-up': {
    title: 'Sing Up » TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: true,
    setForgotPassword: false,
    setPrivacyPolicy: false,
    setBackgroundModal: false,
    setAnalytics: false,
    setAbout: false,
    setUpdateProfile: false,
    setContactUs: false,
    setChangeEmail: false,
    setChangePassword: false,
    setDeleteAccount: false,
    setMobileMenu: false,
    setModalLarge: false,
    setModalSmall: false,
  },
  '/forgot-password': {
    title: 'Password Recovery » TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: false,
    setForgotPassword: true,
    setPrivacyPolicy: false,
    setBackgroundModal: false,
    setAnalytics: false,
    setAbout: false,
    setUpdateProfile: false,
    setContactUs: false,
    setChangeEmail: false,
    setChangePassword: false,
    setDeleteAccount: false,
    setMobileMenu: false,
    setModalLarge: false,
    setModalSmall: false,
  },
  '/privacy-policy': {
    title: 'Privacy Policy » TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: false,
    setForgotPassword: false,
    setPrivacyPolicy: true,
    setBackgroundModal: false,
    setAnalytics: false,
    setAbout: false,
    setUpdateProfile: false,
    setContactUs: false,
    setChangeEmail: false,
    setChangePassword: false,
    setDeleteAccount: false,
    setMobileMenu: false,
    setModalLarge: false,
    setModalSmall: false,
  },
  '/background': {
    title: 'Choose a Background Image » TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: false,
    setForgotPassword: false,
    setPrivacyPolicy: false,
    setBackgroundModal: true,
    setAnalytics: false,
    setAbout: false,
    setUpdateProfile: false,
    setContactUs: false,
    setChangeEmail: false,
    setChangePassword: false,
    setDeleteAccount: false,
    setMobileMenu: false,
    setModalLarge: false,
    setModalSmall: false,
  },
  '/analytics': {
    title: 'Analytics TitleTab » TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: false,
    setForgotPassword: false,
    setPrivacyPolicy: false,
    setBackgroundModal: false,
    setAnalytics: true,
    setAbout: false,
    setUpdateProfile: false,
    setContactUs: false,
    setChangeEmail: false,
    setChangePassword: false,
    setDeleteAccount: false,
    setMobileMenu: false,
    setModalLarge: false,
    setModalSmall: false,
  },
  '/about': {
    title: 'About Us » TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: false,
    setForgotPassword: false,
    setPrivacyPolicy: false,
    setBackgroundModal: false,
    setAnalytics: false,
    setAbout: true,
    setUpdateProfile: false,
    setContactUs: false,
    setChangeEmail: false,
    setChangePassword: false,
    setDeleteAccount: false,
    setMobileMenu: false,
    setModalLarge: false,
    setModalSmall: false,
  },
  '/update-profile': {
    title: 'Update Profile » TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: false,
    setForgotPassword: false,
    setPrivacyPolicy: false,
    setBackgroundModal: false,
    setAnalytics: false,
    setAbout: false,
    setUpdateProfile: true,
    setContactUs: false,
    setChangeEmail: false,
    setChangePassword: false,
    setDeleteAccount: false,
    setMobileMenu: false,
    setModalLarge: false,
    setModalSmall: false,
  },
  '/contact-us': {
    title: 'Contact Us » TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: false,
    setForgotPassword: false,
    setPrivacyPolicy: false,
    setBackgroundModal: false,
    setAnalytics: false,
    setAbout: false,
    setUpdateProfile: false,
    setContactUs: true,
    setChangeEmail: false,
    setChangePassword: false,
    setDeleteAccount: false,
    setMobileMenu: false,
    setModalLarge: false,
    setModalSmall: false,
  },
  '/change-email': {
    title: 'Change Email » TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: false,
    setForgotPassword: false,
    setPrivacyPolicy: false,
    setBackgroundModal: false,
    setAnalytics: false,
    setAbout: false,
    setUpdateProfile: false,
    setContactUs: false,
    setChangeEmail: true,
    setChangePassword: false,
    setDeleteAccount: false,
    setMobileMenu: false,
    setModalLarge: false,
    setModalSmall: false,
  },
  '/change-password': {
    title: 'Change Password » TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: false,
    setForgotPassword: false,
    setPrivacyPolicy: false,
    setBackgroundModal: false,
    setAnalytics: false,
    setAbout: false,
    setUpdateProfile: false,
    setContactUs: false,
    setChangeEmail: false,
    setChangePassword: true,
    setDeleteAccount: false,
    setMobileMenu: false,
    setModalLarge: false,
    setModalSmall: false,
  },
  '/delete-account': {
    title: 'Delete Account » TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: false,
    setForgotPassword: false,
    setPrivacyPolicy: false,
    setBackgroundModal: false,
    setAnalytics: false,
    setAbout: false,
    setUpdateProfile: false,
    setContactUs: false,
    setChangeEmail: false,
    setChangePassword: false,
    setDeleteAccount: true,
    setMobileMenu: false,
    setModalLarge: false,
    setModalSmall: false,
  },
  '/mobile-menu': {
    title: 'Settings Panel » TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: false,
    setForgotPassword: false,
    setPrivacyPolicy: false,
    setBackgroundModal: false,
    setAnalytics: false,
    setAbout: false,
    setUpdateProfile: false,
    setContactUs: false,
    setChangeEmail: false,
    setChangePassword: false,
    setDeleteAccount: false,
    setMobileMenu: true,
    setModalLarge: false,
    setModalSmall: false,
  },
  '/modal-large': {
    title: 'Modal Large » TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: false,
    setForgotPassword: false,
    setPrivacyPolicy: false,
    setBackgroundModal: false,
    setAnalytics: false,
    setAbout: false,
    setUpdateProfile: false,
    setContactUs: false,
    setChangeEmail: false,
    setChangePassword: false,
    setDeleteAccount: false,
    setMobileMenu: false,
    setModalLarge: true,
    setModalSmall: false,
  },
  '/modal-small': {
    title: 'Modal Small » TitleTab - Speed Dial Dashboard',
    setSignIn: false,
    setSignUp: false,
    setForgotPassword: false,
    setPrivacyPolicy: false,
    setBackgroundModal: false,
    setAnalytics: false,
    setAbout: false,
    setUpdateProfile: false,
    setContactUs: false,
    setChangeEmail: false,
    setChangePassword: false,
    setDeleteAccount: false,
    setMobileMenu: false,
    setModalLarge: false,
    setModalSmall: true,
  },
}

export default initialRoutes
