//*
export default function Device() {
  const userDeviceArray = [
    { device: 'mobile', platform: /Android/ },
    { device: 'mobile', platform: /Symbian/ },
    { device: 'mobile', platform: /Windows Phone/ },
    { device: 'mobile', platform: /iPhone/ },
    { device: 'mobile', platform: /iPad/ },
    { device: 'mobile', platform: /Tablet OS/ },
    { device: 'desktop', platform: /Linux/ },
    { device: 'desktop', platform: /Windows NT/ },
    { device: 'desktop', platform: /Macintosh/ },
  ]
  const platform = navigator.userAgent
  const matchedDevice = userDeviceArray.find((x) => x.platform.test(platform))
  return matchedDevice ? matchedDevice.device : 'desktop'
}
