import React from 'react'
import { Container } from '../style/Title.styled'

export default function Title({
  children,
  dark,
  icon,
  handleBack,
  handleClose,
}) {
  function getClassName() {
    let className = 'title'
    if (dark) className += ' dark'
    return className
  }

  return (
    <Container>
      <div className={getClassName()}>
        <div className="back" onClick={handleBack} />
        <div className="header">
          {icon ? <i className="bx bx-bookmarks"></i> : null}
          {children}
        </div>
        <div className="close" onClick={handleClose}></div>
      </div>
    </Container>
  )
}
