import React, { useContext, useState } from 'react'

const PageContext = React.createContext()

export function usePage() {
  return useContext(PageContext)
}

export function PageProvider({ children }) {
  const [publicPage, setPublicPage] = useState(false)
  const [about, setAbout] = useState(false)
  const [signIn, setSignIn] = useState(false)
  const [signUp, setSignUp] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState(false)
  const [updateProfile, setUpdateProfile] = useState(false)
  const [contactUs, setContactUs] = useState(false)
  const [changeEmail, setChangeEmail] = useState(false)
  const [changePassword, setChangePassword] = useState(false)
  const [deleteAccount, setDeleteAccount] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(false)
  const [backgroundModal, setBackgroundModal] = useState(false)
  const [analytics, setAnalytics] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const [modalLarge, setModalLarge] = useState(false)
  const [modalSmall, setModalSmall] = useState(false)

  const value = {
    publicPage,
    setPublicPage,
    about,
    setAbout,
    signIn,
    setSignIn,
    signUp,
    setSignUp,
    forgotPassword,
    setForgotPassword,
    privacyPolicy,
    setPrivacyPolicy,
    updateProfile,
    setUpdateProfile,
    contactUs,
    setContactUs,
    changeEmail,
    setChangeEmail,
    changePassword,
    setChangePassword,
    deleteAccount,
    setDeleteAccount,
    mobileMenu,
    setMobileMenu,
    backgroundModal,
    setBackgroundModal,
    analytics,
    setAnalytics,
    shareModal,
    setShareModal,
    modalLarge,
    setModalLarge,
    modalSmall,
    setModalSmall,
  }

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}
