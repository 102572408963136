import React, { useState, useEffect } from 'react'
import * as localForage from 'localforage'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import { usePage } from '../context/PageContext'

import { Container } from '../style/ModalSmall.styled'

import Button from '../button/Button'
import Input from '../input/Input'
import Title from '../modal/Title'
import LineHr from '../minor/LineHr'
import Content from '../modal/Content'
import initialLanguage from '../language/initialLanguage'
import UserName from '../minor/UserName'
import { toast } from 'react-toastify'

import {
  getContextHeight,
  getWidth,
  getClassName,
  getBlackout,
  getColor,
  getButton,
  getClassLink,
  checkRequired,
  checkEmail,
  checkPassword,
  checkPasswordMatch,
  setError,
  clearError,
  getFocus,
} from '../functionSet/ModalFunction'

export default function DeleteAccount({ button, fullWidth, mobileClose }) {
  const { currentUser, login, signupGoogle, signupFacebook, handleTwitter } =
    useAuth()
  const { global, setGlobal, lang, setRefresh, setOutPublic, setDrag } =
    useData()
  const { settings, styleData } = global

  let { device } = useData()
  if (window.innerWidth <= 1200) device = 'mobile'

  const navigate = useNavigate()
  const [modal, setModal] = useState(true)

  button = getButton({ button, device, mobileClose })

  let styledIndent = {
    contextHeight: getContextHeight({
      device,
      styleData,
      button,
    }),
    width: getWidth({ styleData, fullWidth }),
  }

  const { deleteAccount, setDeleteAccount } = usePage()
  const [activeDelay, setActiveDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setActiveDelay(deleteAccount)
      setDrag(false)
    }, 1)
  }, [deleteAccount])

  let modalActiv = activeDelay

  async function handleClose(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate('/')
      setDeleteAccount(false)
    }, 400)
  }

  async function handleBack(e) {
    e.preventDefault()

    setActiveDelay(false)
    setDrag(true)
    setTimeout(() => {
      navigate(-1)
      setDeleteAccount(false)
    }, 400)
  }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  async function handleSubmit(e) {
    e.preventDefault()

    if (currentUser.providerData[0].providerId === 'password') {
      const emailValid = checkEmail({
        email: email,
        id: 'email_da',
        device: device,
        afterFocus: false,
      })

      const passwordValid = checkPassword({
        password: password,
        id: 'password_da',
        device: device,
        afterFocus: false,
      })

      const confirmPasswordValid = checkPassword({
        password: confirmPassword,
        id: 'password2_da',
        device: device,
        afterFocus: false,
      })

      const passwordMatch = checkPasswordMatch({
        password: password,
        passwordValid: passwordValid,
        id: 'password_da',
        ////////
        confirmPassword: confirmPassword,
        confirmPasswordValid: confirmPasswordValid,
        id2: 'password2_da',
      })

      if (
        !emailValid ||
        !passwordValid ||
        !confirmPasswordValid ||
        !passwordMatch
      ) {
        return
      }

      if (email !== currentUser.email) {
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        return toast.error(initialLanguage[lang].da_incorrect_email)
      }

      try {
        await login(email, password)
        try {
          await currentUser.delete()
          localForage.clear()
          navigate('/')
          setOutPublic(false)
          setGlobal()
          setTimeout(() => {
            setRefresh('handleDelete')
          }, 100)

          toast.success(initialLanguage[lang].da_account_deleted)
        } catch {
          setEmail('')
          setPassword('')
          setConfirmPassword('')
          toast.error(initialLanguage[lang].da_account_faled)
        }
      } catch {
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        toast.error(initialLanguage[lang].da_incorrect_data)
      }
    }

    if (currentUser.providerData[0].providerId === 'google.com') {
      const emailValid = checkEmail({
        email: email,
        id: 'email_da',
        device: device,
        afterFocus: false,
      })

      if (!emailValid) {
        return
      }

      if (email !== currentUser.email) {
        setEmail('')
        return toast.error(initialLanguage[lang].da_incorrect_email)
      }

      try {
        await signupGoogle()
        try {
          await currentUser.delete()
          localForage.clear()
          navigate('/')
          setOutPublic(false)
          setGlobal()
          setTimeout(() => {
            setRefresh('handleDelete')
          }, 100)

          toast.success(initialLanguage[lang].da_account_deleted)
        } catch {
          setEmail('')
          setPassword('')
          setConfirmPassword('')
          toast.error(initialLanguage[lang].da_account_faled)
        }
      } catch {
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        toast.error(initialLanguage[lang].da_incorrect_data)
      }
    }

    if (currentUser.providerData[0].providerId === 'facebook.com') {
      const emailValid = checkEmail({
        email: email,
        id: 'email_da',
        device: device,
        afterFocus: false,
      })

      if (!emailValid) {
        return
      }

      if (email !== currentUser.email) {
        setEmail('')
        return toast.error(initialLanguage[lang].da_incorrect_email)
      }

      try {
        await signupFacebook()
        try {
          await currentUser.delete()
          localForage.clear()
          navigate('/')
          setOutPublic(false)
          setGlobal()
          setTimeout(() => {
            setRefresh('handleDelete')
          }, 100)

          toast.success(initialLanguage[lang].da_account_deleted)
        } catch {
          setEmail('')
          setPassword('')
          setConfirmPassword('')
          toast.error(initialLanguage[lang].da_account_faled)
        }
      } catch {
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        toast.error(initialLanguage[lang].da_incorrect_data)
      }
    }

    if (currentUser.providerData[0].providerId === 'twitter.com') {
      const nameRequired = checkRequired({
        value: email,
        device: device,
        id: 'email_da',
        afterFocus: false,
      })

      if (!nameRequired) {
        return
      }

      try {
        try {
          await currentUser.delete()
          localForage.clear()
          navigate('/')
          setOutPublic(false)
          setGlobal()
          setTimeout(() => {
            setRefresh('handleDelete')
          }, 100)

          toast.success(initialLanguage[lang].da_account_deleted)
        } catch {
          setEmail('')
          setPassword('')
          setConfirmPassword('')
          toast.error(initialLanguage[lang].da_account_faled)
        }
      } catch {
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        toast.error(initialLanguage[lang].da_incorrect_data)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleClose(e)
      }
    })
  }, [])

  useEffect(() => {
    //Для поля Email
    getFocus('email_da', setModal)
    //Для поля Password
    getFocus('password_da', setModal)
    //Для поля Confirm Password
    getFocus('password2_da', setModal)
  }, [])

  const changePasswordTag = () => {
    if (currentUser.providerData[0].providerId === 'password') {
      return (
        <>
          <br />
          <LineHr
            text={initialLanguage[lang].da_delete_account_text}
            dark={settings.styleTheme}
          />

          <Input
            id="email_da"
            labelId="email_da_label"
            type="email"
            state={email}
            setState={setEmail}
            placeholder={''}
            label={initialLanguage[lang].da_email}
            autoComplete="new-password"
            dark={settings.styleTheme}
          />

          <Input
            id="password_da"
            labelId="password_da_label"
            type="password"
            state={password}
            setState={setPassword}
            placeholder={''}
            label={initialLanguage[lang].da_password}
            autoComplete="new-password"
            required={true}
            dark={settings.styleTheme}
          />

          <Input
            id="password2_da"
            labelId="password2_da_label"
            type="password"
            state={confirmPassword}
            setState={setConfirmPassword}
            placeholder={''}
            label={initialLanguage[lang].da_confirm_password}
            autoComplete="new-password"
            required={true}
            dark={settings.styleTheme}
          />
        </>
      )
    }
    if (currentUser.providerData[0].providerId === 'google.com') {
      return (
        <>
          <br />
          <LineHr
            text={initialLanguage[lang].da_delete_account_text}
            dark={settings.styleTheme}
          />

          <Input
            id="email_da"
            labelId="email_da_label"
            type="email"
            state={email}
            setState={setEmail}
            placeholder={''}
            label={initialLanguage[lang].da_google_delete}
            autoComplete="new-password"
            dark={settings.styleTheme}
          />
        </>
      )
    }
    if (currentUser.providerData[0].providerId === 'facebook.com') {
      return (
        <>
          <br />
          <LineHr
            text={initialLanguage[lang].da_delete_account_text}
            dark={settings.styleTheme}
          />

          <Input
            id="email_da"
            labelId="email_da_label"
            type="email"
            state={email}
            setState={setEmail}
            placeholder={''}
            label={initialLanguage[lang].da_facebook_delete}
            autoComplete="new-password"
            dark={settings.styleTheme}
          />
        </>
      )
    }
    if (currentUser.providerData[0].providerId === 'twitter.com') {
      return (
        <>
          <br />
          <LineHr
            text={initialLanguage[lang].da_delete_account_text}
            dark={settings.styleTheme}
          />

          <Input
            id="email_da"
            labelId="email_da_label"
            type="text"
            state={email}
            setState={setEmail}
            placeholder={''}
            label={initialLanguage[lang].da_twitter_delete}
            autoComplete="new-password"
            dark={settings.styleTheme}
          />
        </>
      )
    }
  }

  return (
    <div
      className={getBlackout({ device, modalActiv })}
      onClick={modal ? handleClose : null}
    >
      <Container
        styleData={styleData}
        styledIndent={styledIndent}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={getClassName({
              modalActiv,
              device,
              dark: settings.styleTheme,
            })}
          >
            <Title
              children={initialLanguage[lang].da_delete_account}
              dark={settings.styleTheme}
              handleBack={handleBack}
              handleClose={handleClose}
            />

            {device === 'desktop' ? <div className="indent20"></div> : null}

            <Content>
              <UserName />

              {changePasswordTag()}
            </Content>

            <Button
              onClick={handleSubmit}
              color={'red'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].da_delete_account_delete}
            </Button>

            <Button
              onClick={handleClose}
              color={'grey'}
              isDesktop={true}
              dark={settings.styleTheme}
            >
              {initialLanguage[lang].da_delete_account_cancel}
            </Button>

            <div className="indent40"></div>
          </div>
        </form>
      </Container>
    </div>
  )
}
