import firebase from 'firebase/compat/app'
// import { initializeAnalytics, logEvent } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

import { Timestamp } from 'firebase/firestore'

// import FieldValue from firebase firestore

const app = firebase.initializeApp({
  apiKey: 'AIzaSyDXDmV5GlY2rpuGEfs9W5HXL0BXLT5yxD0',
  authDomain: 'auth.titletab.com',
  projectId: 'titletab-bd69b',
  storageBucket: 'titletab-bd69b.appspot.com',
  messagingSenderId: '524558868998',
  appId: '1:524558868998:web:03759f6c223c5e6fe7d987',
  measurementId: 'G-SCP28SB8GV',
})

const firestore = app.firestore()
const storage = app.storage()

const utcDate = Timestamp.now().toDate().toUTCString()
export const currentDate = new Date(utcDate).toISOString().slice(0, 10)
export const currentTime = new Date(utcDate).toISOString().slice(11, 16)
export const currentTimeMilliseconds = new Date(utcDate).getTime()

export const { FieldValue } = firebase.firestore

export const storageRef = storage.ref()

export const runTransaction = (transactionFunction) => {
  return firestore.runTransaction(transactionFunction)
}

export const database = {
  analytics: firestore.collection('analytics'),
  data: firestore.collection('data'),
  domain: firestore.collection('domain'),
  images: firestore.collection('images'),
  pages: firestore.collection('pages'),
  search: firestore.collection('search'),
  settings: firestore.collection('settings'),
  space: firestore.collection('space'),
}

export const transactions = {
  analytics: firestore.collection('analytics'),
}

export const auth = getAuth(app)

export default app

//Perfomence and CrashLytics
// const perf = firebase.performance()

// ошибка при signOut из-за того, что не размонтирован слушатель onSnapshot
// подробнее https://firebase.google.com/docs/firestore/query-data/listen#detach_a_listener
// раздел Отключить прослушиватель
//статься про firebase https://blog.logrocket.com/user-authentication-firebase-react-apps/

// export const firebaseAnalytics = initializeAnalytics(app, {
//   config: {
//     cookie_flags: 'max-age=7200;secure;samesite=none',
//   },
// })
// export const firebaseLogEvent = logEvent
