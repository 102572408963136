import React, { useState, useCallback } from 'react'
import * as localForage from 'localforage'
import { DragDropContext } from 'react-beautiful-dnd'
import OnBeforeCapture from '../dnd/OnBeforeCapture'
import OnDragStart from '../dnd/OnDragStart'
import OnDragUpdate from '../dnd/OnDragUpdate'
import OnDragEnd from '../dnd/OnDragEnd'
import Column from './Column'
import { Container } from '../../style/Drag.styled'
import { useAuth } from '../../context/AuthContext'
import { useData } from '../../context/DataContext'
import withLocalForage from '../../context/withLocalForage'

const Drag = ({ dragData, main, id }) => {
  const { currentUser } = useAuth()
  let path = `state${currentUser ? currentUser.uid : ''}`
  const {
    global,
    space,
    setDrag,
    launch,
    setLaunch,
    setActiveState,
    device,
    setFolderModal,
  } = useData()
  const { styleData } = global
  const styleDataTop = main ? styleData.top + 'px' : 'none'
  const styleDataposition = main ? 'absolute' : 'none'
  const columns = getColumnsAndTasks(dragData)
  const [isCombine, setIsCombine] = useState(main ? true : false)

  const onBeforeCapture = useCallback(() => {
    OnBeforeCapture({ value: '.addTab', styleData: styleData })
  }, [styleData])

  const onBeforeDragStart = useCallback(() => {
    /*...*/
  }, [])

  const onDragStart = useCallback(
    (start) => {
      OnDragStart({
        value: dragData.tasks[start.draggableId],
        setIsCombine,
        device,
        main,
      })
    },
    [dragData]
  )

  const onDragUpdate = useCallback(
    (update) => {
      OnDragUpdate({
        update,
        state: dragData,
        settings: global.settings,
      })
    },
    [dragData]
  )

  const onDragEnd = useCallback(
    (result) => {
      setDrag(false)

      const newState = OnDragEnd({
        space: global.spaces[space],
        result,
        state: dragData,
        settings: global.settings,
      })

      if (newState) {
        const newSearch = createSearch(newState, global)

        if (newState.chandedMain) {
          main = true
          const newBuild = buildState({
            newTasks: newState.tasks,
            settings: global.settings,
          })
          newState.columnOrder = newBuild.columnOrder
          newState.columns = newBuild.columns
        }

        delete newState.chandedMain

        if (main) {
          global.spaces[space].columnOrder = newState.columnOrder
          global.spaces[space].columns = newState.columns
          global.spaces[space].tasks = newState.tasks
          global.spaces[space].search = newSearch
          setActiveState(global.spaces[space])
        } else {
          global.spaces[space].tasks[newState.id].columnOrder =
            newState.columnOrder
          global.spaces[space].tasks[newState.id].columns = newState.columns
          global.spaces[space].tasks[newState.id].tasks = newState.tasks
          global.spaces[space].tasks[newState.id].search = newSearch
          setActiveState(global.spaces[space].tasks[newState.id])
        }

        global.time = new Date().getTime()

        localForage.setItem(path, global)

        let unique = Math.floor(Math.random() * (7000 - 6000 + 1)) + 6000
        while (unique === launch) {
          unique += 1
        }
        setLaunch(unique)
      }

      if (main) setIsCombine(true)
      setDrag(true)
    },
    [dragData]
  )

  return (
    <DragDropContext
      onBeforeCapture={onBeforeCapture}
      onBeforeDragStart={onBeforeDragStart}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
      onDragEnd={onDragEnd}
    >
      <Container
        styleData={styleData}
        styleDataTop={styleDataTop}
        styleDataposition={styleDataposition}
        id={id}
      >
        {columns.map(({ column, columnTasks }) => (
          <Column
            isCombine={isCombine}
            key={column.id}
            column={column}
            tasks={columnTasks}
            main={main}
            dragData={dragData}
          />
        ))}
      </Container>
    </DragDropContext>
  )
}

export default withLocalForage(Drag)

const getColumnsAndTasks = (dragData) => {
  const { columnOrder, columns, tasks } = dragData
  return columnOrder.map((columnId) => {
    const column = columns[columnId]
    const columnTasks = column.taskIds.map((taskId) => tasks[taskId])
    return { column, columnTasks }
  })
}

function createSearch(newState, global) {
  const { domains, pages } = global
  const { tasks } = newState
  let index = 1

  return Object.entries(tasks).reduce((search, [key, task]) => {
    const title =
      task.ownTitle ||
      (task.tasks && 'New Folder') ||
      (domains[task.idDomain] && domains[task.idDomain].domainTitle) ||
      (pages[task.idPages] && pages[task.idPages].pagesTitle) ||
      ''

    const url =
      (domains[task.idDomain] &&
        domains[task.idDomain].domainProtocol +
          (domains[task.idDomain].domainWWW ? 'www.' : '') +
          domains[task.idDomain].domainHostname +
          pages[task.idPages].pagesPathname) ||
      ''

    search[index++] = {
      id: key,
      title,
      url,
    }

    return search
  }, {})
}

function buildState({ newTasks, settings }) {
  const { tabsInRow } = settings

  let columnData = {}
  let columnOrderData = []

  //normalize data
  let normalizedTasks = {}
  let i = 1
  Object.entries(newTasks).forEach(([key, task]) => {
    task.id = i.toString()
    normalizedTasks[i] = task
    i++
  })
  newTasks = normalizedTasks

  // Формируем данные "columns" и "columnOrder"
  const numColumns = Math.ceil(Object.keys(newTasks).length / tabsInRow)
  const taskIds = Object.keys(newTasks)
  for (let i = 0; i < numColumns; i++) {
    const columnId = `column-${i + 1}`
    columnOrderData.push(columnId)
    columnData[columnId] = {
      id: columnId,
      taskIds: taskIds.slice(i * tabsInRow, i * tabsInRow + tabsInRow),
    }
  }

  // На случай если количество вкладок кратно количеству вкладок в ряду, добавляем пустой ряд для кнопки добавления новой вкладки
  const columnNumber = taskIds.length % tabsInRow
  if (columnNumber === 0) {
    columnOrderData.push('column-' + (columnOrderData.length + 1))
    columnData['column-' + columnOrderData.length] = {
      id: 'column-' + columnOrderData.length,
      taskIds: [],
    }
  }

  return {
    tasks: newTasks,
    columns: columnData,
    columnOrder: columnOrderData,
  }
}
