//*
export default function OnDragStart({ value, setIsCombine, device, main }) {
  if (value?.tasks) setIsCombine(false)
  if (!main) {
    let search = document.querySelector('.search')
    if (search) {
      search.style.opacity = '0'
    }
    return
  }
  return
  // if (device === 'mobile') return
  // let search = document.querySelector('.search')
  // if (search) {
  //   search.style.zIndex = '99'
  // }
}

//https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/sensors/touch.md#tap-support
//вибрация при тапе на тачскрине не работает
// if (window.navigator.vibrate) {
//   window.navigator.vibrate(1000);
// }
//
//add style to drag tab
// let tab = document.querySelector(
//   `[data-rbd-draggable-id="${start.draggableId}"]`
// );
/*...*/
