import React from 'react'
import { TodayContainer } from '../styles/GetAnalytics.styled'

export default function Today({
  clicks,
  views,
  visitors,
  newVisitors,
  clicksDifference,
  viewsDifference,
  visitorsDifference,
}) {
  let difClicks = () => {
    if (clicks > clicksDifference)
      return <span className="plus">+{clicks - clicksDifference}</span>
    if (clicks < clicksDifference)
      return <span className="minus">-{clicksDifference - clicks}</span>
    return <span className="plus">0</span>
  }

  let difViews = () => {
    if (views > viewsDifference)
      return <span className="plus">+{views - viewsDifference}</span>
    if (views < viewsDifference)
      return <span className="minus">-{viewsDifference - views}</span>
    return <span className="null">0</span>
  }

  let difVisitors = () => {
    if (visitors > visitorsDifference)
      return <span className="plus">+{visitors - visitorsDifference}</span>
    if (visitors < visitorsDifference)
      return <span className="minus">-{visitorsDifference - visitors}</span>
    return <span className="null">0</span>
  }

  return (
    <TodayContainer>
      <div className="content">
        <div className="visitors">
          <div className="number">
            {visitors} {difVisitors()}
          </div>
          <div className="title">Visitors</div>
          <hr />
          <div className="number-su">{Object.keys(newVisitors).length}</div>
          <div className="title-su">New visitors</div>
        </div>
        <div className="views">
          <div className="number">
            {views} {difViews()}
          </div>
          <div className="title">Views</div>
          <hr />
          <div className="number-sub">{(views / visitors).toFixed(1)}</div>
          <div className="title-sub">Views per visitor</div>
        </div>
        <div className="clicks">
          <div className="number">
            {clicks} {difClicks()}
          </div>
          <div className="title">Clicks</div>
          <hr />
          <div className="number-su">{(clicks / visitors).toFixed(1)}</div>
          <div className="title-su">Clicks per visitor</div>
        </div>
      </div>
    </TodayContainer>
  )
}
