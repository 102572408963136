import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import localForage from 'localforage'
import { useData } from './DataContext'
import DataSet from '../firebase/DataSet'
import DataGet from '../firebase/DataGet'
import SettingsSet from '../firebase/SettingsSet'
import SettingsGet from '../firebase/SettingsGet'
import DomainGet from '../firebase/DomainGet'
import PagesGet from '../firebase/PagesGet'
import PublicOutGet from '../firebase/PublicOutGet'
import ImagesGet from '../firebase/ImagesGet'
import initialState from '../components/initialState/initialState'
import PublicOwnGet from '../firebase/PublicOwnGet'
import PublicOwnSet from '../firebase/PublicOwnSet'
import PublicOwnUpdate from '../firebase/PublicOwnUpdate'

const withLocalForage = (WrappedComponent) => {
  return (props) => {
    const {
      global,
      outPublic,
      setOutPublic,
      setLoadSpace,
      setGlobal,
      setLoad,
      activeState,
      setActiveState,
      space,
      setSpace,
      spaceUpdate,
      setSpaceUpdate,
      device,
      launch,
      setLaunch,
      background,
      setBackground,
      setDrag,
      setAddButton,
    } = useData()

    const navigate = useNavigate()

    const [taskQueue, setTaskQueue] = useState([])
    const [isProcessing, setIsProcessing] = useState(false)

    const addTask = (task) => {
      setTaskQueue((prevQueue) => [...prevQueue, task])
    }

    const processQueue = async () => {
      setIsProcessing(true)
      while (taskQueue.length > 0) {
        const task = taskQueue.shift()
        await task()
      }
      setIsProcessing(false)
    }

    useEffect(() => {
      if (!isProcessing && taskQueue.length > 0) {
        processQueue()
      }
    }, [taskQueue, isProcessing])

    const assignSpace = async () => {
      return new Promise((resolve) => {
        addTask(async () => {
          let space = await localForage.getItem('space')
          if (!space) {
            space = 1
            await localForage.setItem('space', 1)
          }
          setSpace(Number(space))
          resolve(space)
        })
      })
    }

    const assignForage = async (path) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const forage = await localForage.getItem(path)
          resolve(forage)
        })
      })
    }

    const assignGlobal = async (forage, space, uid, pathname) => {
      return new Promise((resolve) => {
        addTask(async () => {
          if (pathname) {
            document.title = 'TitleTab - Speed Dial Dashboard'
            const outSpace = await assignOutPublic(forage, pathname)
            if (outSpace) {
              forage.domains = { ...forage.domains, ...outSpace.domains }
              forage.pages = { ...forage.pages, ...outSpace.pages }
              setOutPublic(outSpace.spaces[1])
              setActiveState(outSpace.spaces[1])
              sessionStorage.setItem(
                'outPublic',
                JSON.stringify(outSpace.spaces[1])
              )
              setSpace(null)
              setLoadSpace(false)
            } else {
              setOutPublic(null)
              setSpace(1)
              setActiveState(forage.spaces[1])
              window.location.replace('/')
            }
          } else {
            // if (!activeState) {
            //   setActiveState(forage.spaces[space])
            // }
            setOutPublic(null)
            setActiveState(forage.spaces[space])
            setSpace(space)
          }

          const checkForage = checkForageData(forage, uid)
          if (!checkForage) {
            forage = null
            localForage.clear()
            window.location.reload()
          }
          const normalizeForage = normalizeForageData(forage, uid, device)
          if (normalizeForage !== null) {
            forage = normalizeForage
          }

          if (!forage.spaces[space]) {
            setSpace(1)
          }

          setGlobal(forage)
          setLoad(forage)
          setDrag(true)
        })
      })
    }

    const syncDomains = async (domainsReCheck, path) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const domainsCheck = await reCheckDomains(domainsReCheck)

          if (Object.keys(domainsCheck).length > 0) {
            const forage = await localForage.getItem(path)
            forage.domains = {
              ...forage.domains,
              ...domainsCheck,
            }

            await localForage.setItem(path, forage)
            resolve(forage)
          }
        })
      })
    }

    const syncPages = async (pagesReCheck, path) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const pagesCheck = await reCheckPages(pagesReCheck)

          if (Object.keys(pagesCheck).length > 0) {
            const forage = await localForage.getItem(path)
            forage.pages = {
              ...forage.pages,
              ...pagesCheck,
            }

            await localForage.setItem(path, forage)
            resolve(forage)
          }
        })
      })
    }

    const fromFirebase = async (path, uid, device) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const global = await createSpaces(uid)
          global.spaces[0] = await createSpace0(uid)
          global.settings = await createSettings(uid, device)
          global.domains = await createDomains(global.spaces)
          global.pages = await createPages(global.spaces)
          global.images = await createImages(global.spaces)
          global.styleData = createStyled({ settings: global.settings, device })
          global.spaces = buildSpaces({
            global,
            spaces: global.spaces,
            settings: global.settings,
          })

          await localForage.setItem(path, global)

          resolve(global)
        })
      })
    }

    const forNewUser = async (path, device) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const global = initialState
          global.spaces = buildSpaces({
            global,
            spaces: global.spaces,
            settings: global.settings,
          })
          global.styleData = createStyled({ settings: global.settings, device })

          await localForage.setItem(path, global)

          resolve(global)
        })
      })
    }

    const updateSettings = async (key, settings) => {
      return new Promise((resolve) => {
        addTask(async () => {
          // const global = await localForage.getItem(key)
          global.settings = settings
          global.settings.time = new Date().getTime()

          setGlobal(global)
          uniqueLaunch(launch, setLaunch)

          await localForage.setItem(key, global)

          clearState(setDrag, setAddButton)

          resolve()
        })
      })
    }

    const updateSettingsReBuild = async (key, settings) => {
      return new Promise((resolve) => {
        addTask(async () => {
          if (space === null) {
            changeSpaceBar(key, 1, space)
            setOutPublic(false)
            return resolve()
          }

          // const global = await localForage.getItem(key)

          Object.entries(global.spaces).forEach(([spaceKey, space]) => {
            Object.entries(space.tasks).forEach(([taskKey, task]) => {
              if (task.tasks) {
                delete task.columnOrder
                delete task.columns
                delete task.search
              }
            })
          })

          global.spaces = buildSpaces({
            global,
            spaces: global.spaces,
            settings,
          })
          global.styleData = createStyled({ settings, device })
          global.settings = settings
          global.settings.time = new Date().getTime()

          setGlobal(global)
          // setActiveState(global.spaces[space])
          uniqueLaunch(launch, setLaunch)

          await localForage.setItem(key, global)

          clearState(setDrag, setAddButton)

          resolve()
        })
      })
    }

    const updateStyled = async (path, settings, styleData) => {
      return new Promise((resolve) => {
        addTask(async () => {
          if (styleData.innerWidth === window.innerWidth) return resolve()
          const global = await localForage.getItem(path)
          global.styleData = createStyled({ settings, device })

          setGlobal(global)
          uniqueLaunch(launch, setLaunch)

          await localForage.setItem(path, global)

          resolve()
        })
      })
    }

    const buildFolder = async (path, task, settings) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const global = await localForage.getItem(path)

          const folder = buildState({ newTasks: task.tasks, settings })
          folder.search = createSearch({ global, tasks: folder.tasks })

          if (space !== null) {
            global.spaces[space].tasks[task.id] = {
              ...global.spaces[space].tasks[task.id],
              ...folder,
            }
          } else {
            outPublic.tasks[task.id] = {
              ...outPublic.tasks[task.id],
              ...folder,
            }

            setOutPublic(outPublic)
          }
          // global.time = new Date().getTime()

          setGlobal(global)
          // setActiveState(global.spaces[space])
          uniqueLaunch(launch, setLaunch)

          await localForage.setItem(path, global)

          resolve()
        })
      })
    }

    //////////////////////////////////////////////////////////////

    const addTabsModal = async (path, task, domains, pages, settings) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const global = await localForage.getItem(path)

          let tasks = {}
          if (!activeState.id) {
            tasks = global.spaces[space].tasks
          } else {
            tasks = global.spaces[space].tasks[activeState.id].tasks
          }
          const newId = (Object.keys(tasks).length + 1).toString()

          tasks = {
            ...tasks,
            [newId]: {
              ...task,
              id: newId,
            },
          }

          const newSpace = buildState({
            newTasks: tasks,
            settings,
          })

          global.domains = domains
          global.pages = pages

          const newSearch = {
            search: createSearch({ global, tasks }),
          }

          if (!activeState.id) {
            global.spaces[space] = {
              ...global.spaces[space],
              ...newSearch,
              columnOrder: newSpace.columnOrder,
              columns: newSpace.columns,
              tasks: newSpace.tasks,
            }
          } else {
            global.spaces[space].tasks[activeState.id] = {
              ...global.spaces[space].tasks[activeState.id],
              ...newSearch,
              columnOrder: newSpace.columnOrder,
              columns: newSpace.columns,
              tasks: newSpace.tasks,
            }
          }

          global.time = new Date().getTime()

          setGlobal(global)
          if (!activeState.id) {
            setActiveState(global.spaces[space])
          } else {
            setActiveState(global.spaces[space].tasks[activeState.id])
          }
          uniqueLaunch(launch, setLaunch)

          await localForage.setItem(path, global)

          resolve()
        })
      })
    }

    const updateTabsModal = async (
      path,
      task,
      domains,
      pages,
      main,
      dragData,
      settings
    ) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const global = await localForage.getItem(path)

          const newTasks = {
            ...dragData.tasks,
            [task.id]: task,
          }

          const newSpace = buildState({
            newTasks,
            settings,
          })

          global.domains = domains
          global.pages = pages

          const newSearch = {
            search: createSearch({ global, tasks: newSpace.tasks }),
          }

          if (main) {
            global.spaces[space] = {
              ...global.spaces[space],
              ...newSearch,
              columnOrder: newSpace.columnOrder,
              columns: newSpace.columns,
              tasks: newSpace.tasks,
            }
          } else {
            global.spaces[space].tasks[dragData.id] = {
              ...global.spaces[space].tasks[dragData.id],
              ...newSearch,
              columnOrder: newSpace.columnOrder,
              columns: newSpace.columns,
              tasks: newSpace.tasks,
            }
          }

          global.time = new Date().getTime()

          setGlobal(global)
          if (!activeState.id) {
            setActiveState(global.spaces[space])
          } else {
            setActiveState(global.spaces[space].tasks[activeState.id])
          }
          uniqueLaunch(launch, setLaunch)

          setDrag(true)

          await localForage.setItem(path, global)

          resolve()
        })
      })
    }

    const deleteTabsModal = async (path, task, main, dragData, settings) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const global = await localForage.getItem(path)

          delete dragData.tasks[task.id]

          const newSpace = buildState({
            newTasks: dragData.tasks,
            settings,
          })

          const newSearch = {
            search: createSearch({ global, tasks: newSpace.tasks }),
          }

          if (main) {
            global.spaces[space] = {
              ...global.spaces[space],
              ...newSearch,
              columnOrder: newSpace.columnOrder,
              columns: newSpace.columns,
              tasks: newSpace.tasks,
            }
          } else {
            global.spaces[space].tasks[dragData.id] = {
              ...global.spaces[space].tasks[dragData.id],
              ...newSearch,
              columnOrder: newSpace.columnOrder,
              columns: newSpace.columns,
              tasks: newSpace.tasks,
            }
          }

          global.time = new Date().getTime()

          setGlobal(global)
          if (!activeState.id) {
            setActiveState(global.spaces[space])
          } else {
            setActiveState(global.spaces[space].tasks[activeState.id])
          }
          uniqueLaunch(launch, setLaunch)

          setDrag(true)

          await localForage.setItem(path, global)

          resolve()
        })
      })
    }

    const addSpaceModal = async (key, space) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const global = await localForage.getItem(key)
          let i = 1
          if (global.spaces[0]) i = 0
          const spaceLength = Object.keys(global.spaces).length + i

          global.spaces[spaceLength] = space

          global.time = new Date().getTime()

          const dragContainer = document.getElementById('dragContainer')
          if (dragContainer) dragContainer.style.opacity = 0

          setGlobal(global)
          setActiveState(global.spaces[spaceLength])

          setTimeout(() => {
            setSpace(Number(spaceLength))
          }, 200)

          uniqueLaunch(launch, setLaunch)

          await localForage.setItem(key, global)
          await localForage.setItem('space', spaceLength)

          clearState(setDrag, setAddButton)

          resolve()
        })
      })
    }

    const updateSpaceModal = async (key, space, spaceUpdate) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const global = await localForage.getItem(key)

          global.spaces[spaceUpdate] = {
            ...global.spaces[spaceUpdate],
            space: space.space,
            icon: space.icon,
          }

          global.time = new Date().getTime()

          setGlobal(global)
          setActiveState(global.spaces[space])
          uniqueLaunch(launch, setLaunch)

          await localForage.setItem(key, global)

          clearState(setDrag, setAddButton)

          resolve()
        })
      })
    }

    const deleteSpaceModal = async (key, spaceUpdate) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const global = await localForage.getItem(key)

          delete global.spaces[spaceUpdate]

          let normalizedSpaces = {}

          let i = global.spaces[0] ? 0 : 1

          Object.entries(global.spaces).forEach(([key, space]) => {
            normalizedSpaces[i] = space
            i++
          })
          global.spaces = normalizedSpaces

          global.time = new Date().getTime()

          setTimeout(() => {
            setGlobal(global)
            setActiveState(global.spaces[space])
            uniqueLaunch(launch, setLaunch)

            clearState(setDrag, setAddButton)
          }, 400)

          await localForage.setItem(key, global)
          await localForage.setItem('space', 1)

          resolve()
        })
      })
    }

    const updatePublicSpaceModal = async (path, uid, space) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const isNicknameFree = await assignPathnamePublic(
            uid,
            space.nickname,
            space.name,
            space.description
          )

          if (!isNicknameFree) {
            resolve(false)
            return
          }

          const global = await localForage.getItem(path)

          global.spaces[0] = {
            ...global.spaces[0],
            name: space.name,
            nickname: space.nickname,
            description: space.description,
          }

          global.time = new Date().getTime()

          setGlobal(global)
          setActiveState(global.spaces[0])
          uniqueLaunch(launch, setLaunch)

          await localForage.setItem(path, global)

          clearState(setDrag, setAddButton)

          resolve(true)
        })
      })
    }

    const updateFolderModal = async (key, task) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const global = await localForage.getItem(key)

          global.spaces[space].tasks[task.id] = task

          global.time = new Date().getTime()

          setGlobal(global)
          setActiveState(global.spaces[space])
          uniqueLaunch(launch, setLaunch)

          setDrag(true)

          await localForage.setItem(key, global)

          resolve()
        })
      })
    }

    const deleteFolderModal = async (path, task, settings) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const global = await localForage.getItem(path)

          delete global.spaces[space].tasks[task.id]

          const newSpace = buildState({
            newTasks: global.spaces[space].tasks,
            settings,
          })
          const newSearch = {
            search: createSearch({ global, tasks: newSpace.tasks }),
          }

          global.spaces[space] = {
            ...global.spaces[space],
            ...newSearch,
            columnOrder: newSpace.columnOrder,
            columns: newSpace.columns,
            tasks: newSpace.tasks,
          }

          global.time = new Date().getTime()

          setGlobal(global)
          setActiveState(global.spaces[space])
          uniqueLaunch(launch, setLaunch)

          setDrag(true)

          await localForage.setItem(path, global)

          resolve()
        })
      })
    }

    const changeSpaceBar = async (key, value, space) => {
      return new Promise((resolve) => {
        addTask(async () => {
          navigate('/')
          setOutPublic(false)

          if (Number(value) === Number(space) && space !== null)
            return resolve()

          const global = await localForage.getItem(key)

          const dragContainer = document.getElementById('dragContainer')
          if (spaceUpdate) {
            if (dragContainer) dragContainer.style.opacity = 0
          }
          setActiveState(global.spaces[value])
          setTimeout(() => {
            setSpace(Number(value))
          }, 200)

          if (value > 0) {
            await localForage.setItem('space', value)
          }
          // setSpaceUpdate(true)
          clearState(setDrag, setAddButton)

          resolve()
        })
      })
    }

    const spaceUpBar = async (path, key, space) => {
      return new Promise((resolve) => {
        addTask(async () => {
          setSpaceUpdate(false)
          if (space === null) {
            changeSpaceBar(path, 1, space)
            return resolve()
          }
          const global = await localForage.getItem(path)

          key = parseInt(key, 10)
          space = parseInt(space, 10)

          if (key === 1) return resolve()

          let currentSpace = global.spaces[key]
          let previousSpace = global.spaces[key - 1]
          global.spaces[key] = previousSpace
          global.spaces[key - 1] = currentSpace

          global.time = new Date().getTime()

          let currentLi = document.getElementById(`li${key}`)
          let previousLi = document.getElementById(`li${key - 1}`)

          if (!currentLi && !previousLi) return resolve()

          currentLi.style.transition = 'all 0.5s cubic-bezier(0.2, 0, 0, 1)'
          currentLi.style.transform = 'translateY(-48px)'
          previousLi.style.transition = 'all 0.5s cubic-bezier(0.2, 0, 0, 1)'
          previousLi.style.transform = 'translateY(48px)'

          let newSpace = space
          if (key === space) newSpace = key - 1
          if (key - 1 === space) newSpace = space + 1

          await localForage.setItem(path, global)
          await localForage.setItem('space', newSpace)

          setTimeout(() => {
            setSpace(Number(newSpace))
            setGlobal(global)
            setActiveState(global.spaces[space])
            uniqueLaunch(launch, setLaunch)
            clearState(setDrag, setAddButton)

            requestAnimationFrame(() => {
              currentLi.style.transition = ''
              currentLi.style.transform = ''
              previousLi.style.transition = ''
              previousLi.style.transform = ''

              setTimeout(() => {
                setSpaceUpdate(true)
                resolve()
              }, 100)
            })
          }, 700)
        })
      })
    }

    const spaceDownBar = async (path, key, space) => {
      return new Promise((resolve) => {
        addTask(async () => {
          setSpaceUpdate(false)
          if (space === null) {
            changeSpaceBar(path, 1, space)
            return resolve()
          }
          const global = await localForage.getItem(path)

          key = parseInt(key, 10)
          space = parseInt(space, 10)
          if (!global.spaces[key + 1]) return resolve()

          if (key >= Object.keys(global.spaces).length) return resolve()

          let currentSpace = global.spaces[key]
          let nextSpace = global.spaces[key + 1]
          global.spaces[key] = nextSpace
          global.spaces[key + 1] = currentSpace

          global.time = new Date().getTime()

          let currentLi = document.getElementById(`li${key}`)
          let nextLi = document.getElementById(`li${key + 1}`)

          if (!currentLi && !nextLi) return resolve()

          currentLi.style.transition = 'all 0.5s cubic-bezier(0.2, 0, 0, 1)'
          currentLi.style.transform = 'translateY(48px)'
          nextLi.style.transition = 'all 0.5s cubic-bezier(0.2, 0, 0, 1)'
          nextLi.style.transform = 'translateY(-48px)'

          let newSpace = space
          if (key === space) newSpace = key + 1
          if (key + 1 === space) newSpace = space - 1

          await localForage.setItem(path, global)
          await localForage.setItem('space', newSpace)

          setTimeout(() => {
            setSpace(Number(newSpace))
            setGlobal(global)
            setActiveState(global.spaces[space])
            uniqueLaunch(launch, setLaunch)
            clearState(setDrag, setAddButton)

            requestAnimationFrame(() => {
              currentLi.style.transition = ''
              currentLi.style.transform = ''
              nextLi.style.transition = ''
              nextLi.style.transform = ''

              setTimeout(() => {
                setSpaceUpdate(true)
                resolve()
              }, 100)
            })
          }, 700)
        })
      })
    }

    const searchCreate = async (path, activeState, searchTasks, settings) => {
      return new Promise((resolve) => {
        addTask(async () => {
          const global = await localForage.getItem(path)

          const newSpace = buildState({ newTasks: searchTasks, settings })
          if (outPublic) {
            if (!activeState.id) {
              setOutPublic({
                ...outPublic,
                tasks: newSpace.tasks,
                columnOrder: newSpace.columnOrder,
                columns: newSpace.columns,
              })
            } else {
              setOutPublic({
                ...outPublic,
                tasks: {
                  ...outPublic.tasks,
                  [activeState.id]: {
                    ...outPublic.tasks[activeState.id],
                    tasks: newSpace.tasks,
                    columnOrder: newSpace.columnOrder,
                    columns: newSpace.columns,
                  },
                },
              })
            }
          } else {
            if (!activeState.id) {
              global.spaces[space].tasks = newSpace.tasks
              global.spaces[space].columnOrder = newSpace.columnOrder
              global.spaces[space].columns = newSpace.columns
            } else {
              global.spaces[space].tasks[activeState.id].tasks = newSpace.tasks
              global.spaces[space].tasks[activeState.id].columnOrder =
                newSpace.columnOrder
              global.spaces[space].tasks[activeState.id].columns =
                newSpace.columns
            }

            setGlobal(global)
            uniqueLaunch(launch, setLaunch)
            setDrag(false)
            setAddButton(false)
          }

          resolve()
        })
      })
    }

    const searchRestore = async (path, clear, closeFolder) => {
      return new Promise((resolve) => {
        addTask(async () => {
          if (outPublic) {
            const outPublicString = sessionStorage.getItem('outPublic')
            const outPublic = JSON.parse(outPublicString)
            if (activeState.id && !closeFolder) {
              setActiveState(outPublic.tasks[activeState.id])
            } else {
              setActiveState(outPublic)
            }
            setOutPublic(outPublic)
          } else {
            const global = await localForage.getItem(path)
            if (activeState.id && !closeFolder) {
              setActiveState(global.spaces[space].tasks[activeState.id])
            } else {
              setActiveState(global.spaces[space])
            }
            setGlobal(global)
            uniqueLaunch(launch, setLaunch)
          }

          if (clear) clearState(setDrag, setAddButton)

          resolve()
        })
      })
    }

    const updateBackground = async (path, image, type) => {
      return new Promise((resolve) => {
        addTask(async () => {
          let background = ''

          if (type === 'image') {
            if (image === '1682433271021_dflt.webp') {
              background = ''
            } else {
              const screenWidth = window.screen.width
              const screenHeight = window.screen.height

              let imageSize
              if (screenWidth <= 960 && screenHeight <= 540) {
                imageSize = 'preview'
              } else if (screenWidth <= 1600 && screenHeight <= 900) {
                imageSize = 'small'
              } else if (screenWidth <= 2560 && screenHeight <= 1440) {
                imageSize = 'middle'
              } else {
                imageSize = 'large'
              }

              background = `https://bucket.titletab.com/background/${imageSize}/${image}`
            }
          } else if (type === 'base64') {
            background = image
          } else {
            return
          }

          localStorage.setItem(path, background)
          uniqueLaunch(background, setBackground)
          resolve()
        })
      })
    }

    return (
      <WrappedComponent
        {...props}
        assignSpace={assignSpace}
        assignForage={assignForage}
        assignGlobal={assignGlobal}
        syncDomains={syncDomains}
        syncPages={syncPages}
        // formatForage={formatForage}
        fromFirebase={fromFirebase}
        forNewUser={forNewUser}
        updateSettings={updateSettings}
        updateSettingsReBuild={updateSettingsReBuild}
        updateStyled={updateStyled}
        buildFolder={buildFolder}
        addTabsModal={addTabsModal}
        updateTabsModal={updateTabsModal}
        deleteTabsModal={deleteTabsModal}
        addSpaceModal={addSpaceModal}
        updateSpaceModal={updateSpaceModal}
        deleteSpaceModal={deleteSpaceModal}
        updatePublicSpaceModal={updatePublicSpaceModal}
        updateFolderModal={updateFolderModal}
        deleteFolderModal={deleteFolderModal}
        changeSpaceBar={changeSpaceBar}
        spaceUpBar={spaceUpBar}
        spaceDownBar={spaceDownBar}
        searchCreate={searchCreate}
        searchRestore={searchRestore}
        updateBackground={updateBackground}
      />
    )
  }
}

export default withLocalForage

function createStyled({ settings, device }) {
  let innerWidth = window.innerWidth
  let innerHeight = window.innerHeight
  let vw = getVW()
  let top = getTop({ device })
  let left = getLeft()
  let width = getWidth()
  let modalWidthLarge = width * 0.98
  let modalWidthSmall = width * 0.45
  let modalHeight = getModalHeight({ top })
  let tabFullWidth = getTabFullWidth({ width, tabsInRow: settings.tabsInRow })
  let tabFullHeight = getTabFullHeight({ tabFullWidth, device, settings })
  let tabMargin = getTabMargin({ tabFullWidth })
  let tabWidth = getTabWidth({ tabFullWidth, tabMargin })
  let tabHeight = getTabHeight({ tabWidth })
  let transformX = getTransformX({
    tabFullWidth,
    tabsInRow: settings.tabsInRow,
  })
  let transformY = getTransformY({ tabFullWidth, device, settings })
  let tabFontSize = getTabFontSize({ tabFullWidth })
  let boxShadowTabs = getBoxShadowTabs({ vw })
  let boxShadowFolder = getBoxShadowFolder({ vw })
  let boxShadowFolderActiveDark = getShadowFolderActiveDark({ vw })
  let boxShadowFolderActiveLight = getShadowFolderActiveLight({ vw })

  let styleData = {
    innerWidth: innerWidth,
    innerHeight: innerHeight,
    vw: vw,
    top: top,
    left: left,
    width: width,
    modalWidthLarge: modalWidthLarge,
    modalWidthSmall: modalWidthSmall,
    modalHeight: modalHeight,
    tabFullWidth: tabFullWidth,
    tabFullHeight: tabFullHeight,
    tabMargin: tabMargin,
    tabWidth: tabWidth,
    tabHeight: tabHeight,
    transformX: transformX,
    transformY: transformY,
    tabFontSize: tabFontSize,
    boxShadowTabs: boxShadowTabs,
    boxShadowFolder: boxShadowFolder,
    boxShadowFolderActiveDark: boxShadowFolderActiveDark,
    boxShadowFolderActiveLight: boxShadowFolderActiveLight,
  }

  return styleData

  function getVW() {
    let vw = Math.ceil(window.innerWidth / 100)
    if (vw < 20) vw = 20
    return vw
  }

  function getBoxShadowTabs({ vw }) {
    let boxShadow = `0 ${0.5 * vw}px ${1 * vw}px -${
      0.25 * vw
    }px rgb(0 0 0 / 24%)`
    return boxShadow
  }

  function getBoxShadowFolder({ vw }) {
    let boxShadow = `0 ${0.25 * vw}px ${0.5 * vw}px -${
      0.1 * vw
    }px rgb(0 0 0 / 24%)`
    return boxShadow
  }

  function getShadowFolderActiveLight({ vw }) {
    let boxShadow = `0px 0px 0px ${0.4 * vw}px rgba(0, 0, 0, 0.1)`
    return boxShadow
  }

  function getShadowFolderActiveDark({ vw }) {
    let boxShadow = `0px 0px 0px ${0.4 * vw}px rgba(255, 255, 255, 0.25)`
    return boxShadow
  }

  function getTop({ device }) {
    let top
    if (device === 'desktop') {
      top = Math.round(window.innerWidth * 0.0612)
      if (top < 115) top = 115
    } else {
      top = 80
    }
    return top
  }

  function getWidth() {
    let elem = document.querySelector('.responsive')
    let responsive = elem.getBoundingClientRect()
    let width = Math.ceil(responsive.width)
    return width
  }

  function getModalHeight({ top }) {
    let height = Math.round(window.innerHeight - 2 * top)
    if (window.innerHeight < 720) height = window.innerHeight - top - 20
    if (height < 500) height = 500
    return height
  }

  function getLeft() {
    let elem = document.querySelector('.responsive')
    let responsive = elem.getBoundingClientRect()
    let left = Math.round(responsive.left)
    return left
  }

  function getTabFullWidth({ width, tabsInRow }) {
    return Math.floor(width / tabsInRow)
  }

  function getTabFullHeight({ tabFullWidth, device, settings }) {
    let index = 1
    if (settings.tabsSizeMobile === 1) index = 0.65
    if (settings.tabsSizeMobile === 2) index = 0.75
    if (settings.tabsSizeMobile === 3) index = 0.85
    if (settings.tabsSizeMobile === 4) index = 0.95
    if (settings.tabsSizeMobile === 5) index = 1

    return device === 'desktop' ? tabFullWidth * 0.75 : tabFullWidth * index
  }

  function getTabMargin({ tabFullWidth }) {
    return Math.round(tabFullWidth * 0.07)
  }

  function getTabWidth({ tabFullWidth, tabMargin }) {
    return tabFullWidth - tabMargin * 2
  }

  function getTabHeight({ tabWidth }) {
    return Math.round(tabWidth * 0.56338)
  }

  function getTransformX({ tabFullWidth, tabsInRow }) {
    return tabFullWidth * (tabsInRow - 1)
  }

  function getTransformY({ tabFullWidth, device, settings }) {
    let index = 1
    if (settings.tabsSizeMobile === 1) index = 0.65
    if (settings.tabsSizeMobile === 2) index = 0.75
    if (settings.tabsSizeMobile === 3) index = 0.85
    if (settings.tabsSizeMobile === 4) index = 0.95
    if (settings.tabsSizeMobile === 5) index = 1

    return device === 'desktop'
      ? Math.round(tabFullWidth * 0.75)
      : Math.round(tabFullWidth * index)
  }
  function getTabFontSize({ tabFullWidth }) {
    return Math.round(tabFullWidth * 0.08)
  }
}

async function assignPathnamePublic(uid, nickname, name, description) {
  try {
    const dataForPathname = await PublicOutGet({ pathname: nickname })
    if (dataForPathname && dataForPathname.uid !== uid) {
      return false
    } else {
      const space = {
        name,
        nickname,
        description,
      }
      await PublicOwnUpdate({ uid, space })
      return true
    }
  } catch (error) {
    return false
  }
}

async function assignOutPublic(forage, pathname) {
  if (!pathname) {
    return
  }

  try {
    const global = {}
    const { settings } = forage

    const { name, nickname, description, tasks } = await PublicOutGet({
      pathname,
    })
    if (!tasks) {
      return null
    }

    const title = `${name} ${nickname} » TitleTab - Speed Dial Dashboard`
    document.title = title
    document.querySelector('meta[name="description"]').content =
      description ||
      'Explore the favorite websites and online resources of our community members with TitleTab Personal Spaces! Get a unique glimpse into the interests and hobbies of our users and discover new websites that you may have never found on your own.'

    global.spaces = {
      1: buildState({
        newTasks: tasks,
        settings,
      }),
    }

    global.domains = await createDomains(global.spaces)
    global.pages = await createPages(global.spaces)
    global.spaces[1].search = createSearch({
      global,
      tasks: tasks,
    })

    global.spaces[1].name = name
    global.spaces[1].nickname = nickname
    global.spaces[1].description = description

    return global
  } catch (error) {
    console.error(error)
    return null
  }
}

function buildSpaces({ global, spaces, settings }) {
  for (let space in spaces) {
    let { tasks } = spaces[space]
    const newState = buildState({ newTasks: tasks, settings })
    spaces[space].search = createSearch({ global, tasks })
    spaces[space].columns = newState.columns
    spaces[space].columnOrder = newState.columnOrder
  }
  return spaces
}

function buildState({ newTasks, settings }) {
  const { tabsInRow } = settings

  let columnData = {}
  let columnOrderData = []

  //normalize data
  let normalizedTasks = {}
  let i = 1
  Object.entries(newTasks).forEach(([key, task]) => {
    task.id = i.toString()
    normalizedTasks[i] = task
    i++
  })
  newTasks = normalizedTasks

  // Формируем данные "columns" и "columnOrder"
  const numColumns = Math.ceil(Object.keys(newTasks).length / tabsInRow)
  const taskIds = Object.keys(newTasks)
  for (let i = 0; i < numColumns; i++) {
    const columnId = `column-${i + 1}`
    columnOrderData.push(columnId)
    columnData[columnId] = {
      id: columnId,
      taskIds: taskIds.slice(i * tabsInRow, i * tabsInRow + tabsInRow),
    }
  }

  // На случай если количество вкладок кратно количеству вкладок в ряду, добавляем пустой ряд для кнопки добавления новой вкладки
  const columnNumber = taskIds.length % tabsInRow
  if (columnNumber === 0) {
    columnOrderData.push('column-' + (columnOrderData.length + 1))
    columnData['column-' + columnOrderData.length] = {
      id: 'column-' + columnOrderData.length,
      taskIds: [],
    }
  }

  return {
    tasks: newTasks,
    columns: columnData,
    columnOrder: columnOrderData,
  }
}

function createSearch({ global, tasks }) {
  const { domains, pages } = global
  let index = 1

  return Object.entries(tasks).reduce((search, [key, task]) => {
    const title =
      task.ownTitle ||
      (task.tasks && 'New Folder') ||
      (domains[task.idDomain] && domains[task.idDomain].domainTitle) ||
      (pages[task.idPages] && pages[task.idPages].pagesTitle) ||
      ''

    const url =
      (domains[task.idDomain] &&
        domains[task.idDomain].domainProtocol +
          (domains[task.idDomain].domainWWW ? 'www.' : '') +
          domains[task.idDomain].domainHostname +
          pages[task.idPages].pagesPathname) ||
      ''

    search[index++] = {
      id: key,
      title,
      url,
    }

    return search
  }, {})
}

function uniqueLaunch(launch, setLaunch) {
  let unique = Math.floor(Math.random() * (7000 - 6000 + 1)) + 6000
  while (unique === launch) {
    unique += 1
  }
  setLaunch(unique)
}

function clearState(setDrag, setAddButton) {
  let searchInput = document.getElementById('searchInput')
  let clearButton = document.getElementById('clearButton')
  setTimeout(() => {
    if (searchInput) document.getElementById('searchInput').value = ''
    if (clearButton) clearButton.style.display = 'none'
    setDrag(true)
    setAddButton(true)
  }, 100)
}

async function createSpaces(uid) {
  let firebase = await DataGet({ uid })
  if (firebase) {
    let time = firebase.time
    delete firebase.time
    return {
      spaces: firebase,
      time: time,
    }
  }
  //Если данные полученные из Firebase == null, значит пользователь совершает свой первый вход в учетную запись после регистрации
  //Т.к. зарегистрированный пользователь совершает свой первых вход после регистрации, необходимо сохранить в Firebase первые данные
  //Получаем данные из localForage для незарегистрированного пользователя, эти данные необходимо записать в Firebase для зарегистрированного пользователя и очистить из localForage до значения по умолчанию
  let forage = await localForage.getItem('state')
  if (!forage) forage = initialState
  if (!forage.spaces) forage = initialState

  let spaces = forage.spaces
  Object.entries(spaces).forEach(([key, space]) => {
    delete space.columnOrder
    delete space.columns
    delete space.search
    Object.entries(space.tasks).forEach(([key, task]) => {
      if (task.tasks) {
        delete task.columnOrder
        delete task.columns
        delete task.search
      }
    })
    if (!space.icon) space.icon = 'bx bx-bookmarks'
  })
  spaces.time = forage.time

  await DataSet({ uid, data: spaces })

  delete spaces.time

  return {
    spaces: spaces,
    time: forage.time,
  }
}

async function createSpace0(uid) {
  let space0 = await PublicOwnGet({ uid })
  if (!space0) {
    await PublicOwnSet({ uid })
    space0 = {
      name: '',
      nickname: '',
      description: '',
      tasks: {},
    }
  }

  return space0
}

async function createSettings(uid, device) {
  let settings = await SettingsGet({ uid })

  if (settings) {
    settings.tabsInRow =
      device === 'desktop' ? settings.tabsSizeDesktop : settings.tabsSizeMobile
    return settings
  }
  //Если данные полученные из Firebase == null, значит пользователь совершает свой первый вход в учетную запись после регистрации
  //Т.к. зарегистрированный пользователь совершает свой первых вход после регистрации, необходимо сохранить в Firebase первые данные
  //Получаем данные из localForage для незарегистрированного пользователя, эти данные необходимо записать в Firebase для зарегистрированного пользователя и очистить из localForage до значения по умолчанию

  let forage = await localForage.getItem('state')
  if (!forage) forage = initialState
  if (!forage.spaces) forage = initialState

  await SettingsSet({ uid, settings: forage.settings })

  initialState.styleData = forage.styleData

  // await localForage.setItem('state', initialState)
  await localForage.removeItem('state')

  return forage.settings
}

async function createDomains(spaces) {
  let domains = {}
  let idDomain = []

  for (let space in spaces) {
    for (let task in spaces[space].tasks) {
      //Если запись содержит папки, запускаем цикл по папке
      if (spaces[space].tasks[task].tasks) {
        for (let folder in spaces[space].tasks[task].tasks) {
          if (
            !idDomain.includes(spaces[space].tasks[task].tasks[folder].idDomain)
          ) {
            idDomain.push(spaces[space].tasks[task].tasks[folder].idDomain)
          }
        }
      }
      //Если запись содержит домены, добавляем запись в объект вкладок
      if (spaces[space].tasks[task].idDomain) {
        if (!idDomain.includes(spaces[space].tasks[task].idDomain)) {
          idDomain.push(spaces[space].tasks[task].idDomain)
        }
      }
    }
  }

  await Promise.all(
    idDomain.map(async (idDomain) => {
      domains[idDomain] = await DomainGet({ idDomain })
    })
  )

  return domains
}

async function reCheckDomains(domainsReCheck) {
  let domains = {}

  await Promise.all(
    domainsReCheck.map(async (idDomain) => {
      let domain = await DomainGet({ idDomain })
      if (domain.verifiedD === true) domains[idDomain] = domain
    })
  )

  return domains
}

async function reCheckPages(pagesReCheck) {
  let pages = {}

  await Promise.all(
    pagesReCheck.map(async (idPages) => {
      let page = await PagesGet({ idPages })
      if (page.verifiedP === true) pages[idPages] = page
    })
  )

  return pages
}

async function createPages(spaces) {
  let pages = {}
  let idPage = []

  for (let space in spaces) {
    for (let task in spaces[space].tasks) {
      //Если запись содержит папки, запускаем цикл по папке
      if (spaces[space].tasks[task].tasks) {
        for (let folder in spaces[space].tasks[task].tasks) {
          if (
            !idPage.includes(spaces[space].tasks[task].tasks[folder].idPages)
          ) {
            idPage.push(spaces[space].tasks[task].tasks[folder].idPages)
          }
        }
      }
      //Если запись содержит домены, добавляем запись в объект вкладок
      if (spaces[space].tasks[task].idPages) {
        if (!idPage.includes(spaces[space].tasks[task].idPages)) {
          idPage.push(spaces[space].tasks[task].idPages)
        }
      }
    }
  }

  await Promise.all(
    idPage.map(
      async (idPages) => (pages[idPages] = await PagesGet({ idPages }))
    )
  )

  return pages
}

async function createImages(spaces) {
  let images = {}
  let idImages = []

  for (let space in spaces) {
    for (let task in spaces[space].tasks) {
      let aTask = spaces[space].tasks[task]
      //Если запись содержит папки, запускаем цикл по папке
      if (aTask.tasks) {
        for (let folder in aTask.tasks) {
          if (aTask.tasks[folder].ownPicture) {
            if (!idImages.includes(aTask.tasks[folder].ownPicture)) {
              if (!aTask.tasks[folder].ownPicture.startsWith('http')) {
                idImages.push(aTask.tasks[folder].ownPicture)
              }
            }
          }
        }
      }
      //Если запись содержит домены, добавляем запись в объект вкладок
      if (aTask.ownPicture) {
        if (!idImages.includes(aTask.ownPicture)) {
          if (!aTask.ownPicture.startsWith('http')) {
            idImages.push(aTask.ownPicture)
          }
        }
      }
    }
  }

  await Promise.all(
    idImages.map(async (idImage) => {
      images[idImage] = {
        image: 'https://bucket.titletab.com/404.webp',
      }
    })
  )

  return images
}

function checkForageData(forage, uid) {
  if (!forage) return false
  try {
    const { domains, pages, settings, spaces, styleData } = forage
    if (!domains || !pages || !settings || !spaces || !styleData) return false

    if (Object.keys(spaces).length === 0) return false

    if (uid && !spaces[0]) return false
    if (!spaces[1]) return false

    let entries = true
    Object.entries(spaces).forEach(([key, space]) => {
      const { columnOrder, columns, search, tasks } = space

      if (!columnOrder || !columns || !search || !tasks) entries = false

      Object.entries(tasks).forEach(([key, task]) => {
        if (task.idDomain && !domains[task.idDomain]) entries = false
        if (task.idPages && !pages[task.idPages]) entries = false
      })
    })

    if (entries === false) return false
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

function normalizeForageData(forage, uid, device) {
  // if (!forage) {
  //   const path = `state${uid ? uid : ''}`
  //   const global = initialState
  //   global.spaces = buildSpaces({
  //     global,
  //     spaces: global.spaces,
  //     settings: global.settings,
  //   })
  //   global.styleData = createStyled({ settings: global.settings, device })

  //   localForage.setItem(path, global)

  //   return global
  // }

  const { spaces } = forage

  let isSpacesNormal = true
  let isTasksNormal = true

  let iSpace = spaces[0] ? 0 : 1
  Object.entries(spaces).forEach(([key, space]) => {
    if (Number(key) !== iSpace) isSpacesNormal = false
    iSpace++
    let iTask = 1
    Object.entries(space.tasks).forEach(([key, task]) => {
      if (Number(key) !== iTask || Number(key) !== Number(task.id))
        isTasksNormal = false
      iTask++
    })
  })

  if (isSpacesNormal && isTasksNormal) return null

  let normalizedSpaces = {}
  let i = spaces[0] ? 0 : 1
  Object.entries(spaces).forEach(([key, space]) => {
    normalizedSpaces[i] = space
    i++
  })

  Object.entries(normalizedSpaces).forEach(([key, space]) => {
    let normalizedTasks = {}
    let i = 1
    Object.entries(space.tasks).forEach(([key, task]) => {
      normalizedTasks[i] = {
        ...task,
        id: i.toString(),
      }
      i++
    })
    normalizedSpaces[key].tasks = normalizedTasks
  })
  forage.spaces = normalizedSpaces

  const path = `state${uid ? uid : ''}`
  localForage.setItem(path, forage)

  return forage
}
