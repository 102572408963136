import styled from 'styled-components'

export const Container = styled.div`
  .folderOpen {
    width: ${(props) => props.styleData.width + 80}px;
    position: fixed;
    top: ${(props) => props.styledFolder.folderTop}px;
    left: 0px;
    right: 0px;
    margin: auto;
    background-color: #e6eef6;
    border-top-left-radius: 0.35vw;
    border-top-left-radius: max(0.35vw, 7px);
    border-top-right-radius: 0.35vw;
    border-top-right-radius: max(0.35vw, 7px);
    border-bottom-left-radius: 0.35vw;
    border-bottom-left-radius: max(0.35vw, 7px);
    border-bottom-right-radius: 0.35vw;
    border-bottom-right-radius: max(0.35vw, 7px);
    opacity: 0;
    transition: all 0.5s ease;
    scale: 0.5;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }
  .folderOpen.dark {
    background-color: #1e1b30;
  }
  .folderOpen.active {
    opacity: 1;
    scale: 1;
  }

  @media (max-width: 1200px) {
    .folderOpen {
      display: block;
      top: 0;
      left: auto;
      right: 0;
      position: fixed;
      width: calc(100% - 60px);
      height: 100%;
      border-radius: 0;
    }
  }

  // .folderOpen.mobile {
  //   display: block;
  //   top: 0;
  //   position: fixed;
  //   width: 100%;
  //   height: 100vh;
  //   border-radius: 0;
  // }

  .background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    left: 0px;
    top: 0px;
  }

  .folderOpen .title {
    width: 100%;
    position: relative;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0.35vw;
    border-top-left-radius: max(0.35vw, 7px);
    border-top-right-radius: 0.35vw;
    border-top-right-radius: max(0.35vw, 7px);
    background-color: #f7f7f7;
    transition: all 0.5s ease;
    -webkit-box-shadow: 0px 6px 6px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 6px 6px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 6px 6px 0px rgba(34, 60, 80, 0.2);
    z-index: 100;
  }

  .folderOpen.dark .title {
    background-color: #2e275c;
  }

  @media (max-width: 1200px) {
    .folderOpen .title {
      border-radius: 0px;
    }
  }

  // .folderOpen.mobile .title {
  //   border-radius: 0px;
  // }

  .folderOpen .header {
    width: 100%;
    height: 3vw;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2vw;
    line-height: max(2vw, 40px);
    font-size: 1vw;
    font-size: max(1vw, 20px);
    font-weight: 700;
    color: #0364c1;
    transition: all 0.5s ease;
  }

  .folderOpen.dark .header {
    color: #fff;
  }

  .back {
    width: 2vw;
    min-width: 41px;
    height: 2vw;
    min-height: 41px;
    border-radius: 4px;
    border-radius: max(0.2vw, 4px);

    position: absolute;
    top: 0.5vw;
    top: max(0.5vw, 10px);
    left: 0.5vw;
    left: max(0.5vw, 10px);
    display: block;
    z-index: 200;
    text-indent: -9999px;
    cursor: pointer;
    background-color: #000;
    opacity: 0.2;
  }
  @media (max-width: 1200px) {
    .back {
      display: none;
    }
  }

  .back:hover,
  .back:hover {
    opacity: 0.3;
  }
  .back:before,
  .back:after {
    content: '';
    width: 36%;
    height: 4px;
    background: #fff;
    position: absolute;
    top: 36%;
    left: 28%;
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
  }
  .back:after {
    top: 56%;
    left: 28%;
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
  }

  .close {
    width: 2vw;
    min-width: 41px;
    height: 2vw;
    min-height: 41px;
    border-radius: 4px;
    border-radius: max(0.2vw, 4px);

    position: absolute;
    top: 0.5vw;
    top: max(0.5vw, 10px);
    right: 0.5vw;
    right: max(0.5vw, 10px);
    display: block;
    text-indent: -9999px;
    cursor: pointer;
    background-color: #000;
    opacity: 0.2;
  }

  .close:hover,
  .close:hover {
    opacity: 0.3;
  }
  .close:before,
  .close:after {
    content: '';
    width: 60%;
    height: 4px;
    background: #fff;
    position: absolute;
    top: 47%;
    left: 20%;
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
  }
  .close:after {
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
  }

  .folderOpen .contentFolder {
    height: ${(props) => props.styledFolder.contentHeight}px;
    padding-top: 1vw;
    padding-top: max(1vw, 25px);
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #0364c1 #ffffff00;
  }

  @media (max-width: 1200px) {
    .folderOpen .contentFolder {
      position: absolute;
      height: calc(100% - 85px);
      right: 0;
    }
  }

  // .folderOpen.mobile .contentFolder {
  //   max-height: ${(props) => props.styleData.innerHeight - 80}px;
  //   min-height: max(
  //     200px,
  //     calc(${(props) => props.styleData.innerHeight - 80}px)
  //   );
  //   padding-top: 1vw;
  //   padding-top: max(1vw, 20px);
  //   margin-right: 0;
  //   padding-left: 0;
  // }

  .folderOpen.dark .contentFolder {
    scrollbar-color: #0364c1 #ffffff00;
  }

  .folderOpen .contentFolder::-webkit-scrollbar {
    width: 0.4vw;
    min-width: 8px;
    background-color: none;
  }

  .folderOpen .contentFolder::-webkit-scrollbar-track {
    margin-top: 1vw;
    margin-top: max(1vw, 20px);
    margin-bottom: 1vw;
    margin-bottom: max(1vw, 20px);
  }

  .folderOpen .contentFolder::-webkit-scrollbar-thumb {
    width: 0.4vw;
    min-width: 8px;
    background-color: #0364c1;
  }

  // .dragMiddle {
  //   transform: translate(
  //     ${(props) => props.styleData.tabFullWidth}px,
  //     0px
  //   ) !important;
  //   transition: transform 0.4s !important;
  //   transition-delay: 0.07s !important;
  // }
  // .dragLast {
  //   z-index: 1 !important;
  //   transform: translate(
  //     -${(props) => props.styleData.transformX}px,
  //     ${(props) => props.styleData.transformY}px
  //   ) !important;
  //   transition: transform 0.4s !important;
  //   transition-delay: 0.07s !important;
  // }

  // .dragRightTop {
  //   z-index: 1 !important;
  //   transform: translate(
  //     ${(props) => props.styleData.transformX}px,
  //     -${(props) => props.styleData.transformY}px
  //   ) !important;
  //   transition: transform 0.4s !important;
  //   transition-delay: 0.07s !important;
  // }
  // .dragLeft {
  //   transform: translate(
  //     -${(props) => props.styleData.tabFullWidth}px,
  //     0px
  //   ) !important;
  //   transition: transform 0.4s !important;
  //   transition-delay: 0.07s !important;
  // }

  // .noDrag {
  //   z-index: 1 !important;
  //   transform: translate(0px, 0px) !important;
  //   transition-delay: 0.07s !important;
  // }
`
