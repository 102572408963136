export default function OnDragEnd({ space, result, state, settings }) {
  let newState

  const { destination, source, draggableId, combine } = result
  // const taskIds = state.columns[source.droppableId].taskIds

  let search = document.querySelector('.search')
  if (search) {
    search.style.opacity = '1'
    // search.style.zIndex = '100'
  }

  if (result.combine) {
    //обводка вкладки/папки при наведении
    const theme = settings.styleTheme ? 'shadowFolderDark' : 'shadowFolderLight'
    const element = document.querySelector(`.${theme}`)
    if (element) {
      element.classList.remove(theme)
    }

    const sourceIndex = state.columnOrder.indexOf(source.droppableId)
    const destinationIndex = state.columnOrder.indexOf(combine.droppableId)
    const lastTaskId = Object.keys(state.tasks).length
    const futureFolderId =
      destinationIndex > sourceIndex && combine.draggableId < lastTaskId
        ? parseInt(combine.draggableId) + 1
        : combine.draggableId

    const task = state.tasks[result.draggableId]
    const folder = state.tasks[futureFolderId]

    if (!folder.tasks) {
      folder.tasks = {
        1: { ...folder, id: '1' },
      }
      folder.ownBackground = ''
      folder.ownTitle = ''
      delete folder.idDomain
      delete folder.idPages
      delete folder.idParent
      delete folder.ownColor
      delete folder.ownPicture
      delete folder.pictureImage
      delete folder.tabFill
    }
    delete folder.columnOrder
    delete folder.columns
    delete folder.search

    const newTaskId = Object.keys(folder.tasks).length + 1
    folder.tasks[newTaskId] = {
      ...task,
      id: newTaskId.toString(),
    }

    delete state.tasks[result.draggableId]

    newState = {
      ...state,
      tasks: setIds({ ...state.tasks }),
    }
  }
  if (!result.combine) {
    if (!destination && state.id) {
      const newId = (Object.keys(space.tasks).length + 1).toString()
      space.tasks[newId] = {
        ...state.tasks[draggableId],
        id: newId,
      }
      delete space.tasks[state.id].tasks[draggableId]
      delete space.tasks[state.id].columnOrder
      delete space.tasks[state.id].columns
      delete space.tasks[state.id].search

      //normalize data
      let normalizedTasks = {}
      let i = 1
      Object.entries(space.tasks[state.id].tasks).forEach(([key, task]) => {
        task.id = i.toString()
        normalizedTasks[i] = task
        i++
      })
      space.tasks[state.id].tasks = normalizedTasks

      space.chandedMain = true

      return space
    }
    if (!destination) {
      //если объект destination не содержит поле draggableId
      setTimeout(() => {
        let elem = document.querySelector('.addTab')
        if (elem) {
          elem.style.position = 'relative'
          elem.style.left = ''
        }
      }, 1)
      return
    }
    if (destination && destination.hasOwnProperty('draggableId')) {
      //если объект destination содержит поле draggableId
      setTimeout(() => {
        let elem = document.querySelector('.addTab')
        if (elem) {
          elem.style.position = 'relative'
          elem.style.left = ''
        }
      }, 1)
      return
    }
    const tabIndex = destination.index
    const rowIndex = state.columnOrder.indexOf(destination.droppableId)
    const droppableId = tabIndex + 1 + settings.tabsInRow * rowIndex
    const tasks = Object.entries(state.tasks)

    const newTasks = tasks
      .map(([key, task]) => {
        if (Number(key) === Number(draggableId)) {
          return { ...task, id: String(droppableId) }
        } else if (Number(key) === droppableId) {
          const updateId = droppableId > Number(draggableId) ? -1 : 1
          return {
            ...task,
            id: String(Number(task.id) + updateId),
          }
        } else if (
          (droppableId > Number(draggableId) &&
            Number(key) > Number(draggableId) &&
            Number(key) < droppableId) ||
          (droppableId < Number(draggableId) &&
            Number(key) < Number(draggableId) &&
            Number(key) > droppableId)
        ) {
          const updateId = droppableId > Number(draggableId) ? -1 : 1
          return {
            ...task,
            id: String(Number(task.id) + updateId),
          }
        }
        return task
      })
      .reduce((acc, task) => {
        acc[task.id] = task
        return acc
      }, {})

    newState = {
      ...state,
      tasks: newTasks,
    }
  }

  setTimeout(() => {
    let dragLast = document.querySelectorAll('.dragLast')
    let dragMiddle = document.querySelectorAll('.dragMiddle')
    let dragRightTop = document.querySelectorAll('.dragRightTop')
    let dragLeft = document.querySelectorAll('.dragLeft')
    let noDrag = document.querySelectorAll('.noDrag')
    let elem = document.querySelector('.addTab')
    if (elem) {
      elem.style.position = 'relative'
      elem.style.left = ''
    }
    classListForRemove(dragLast, 'dragLast')
    classListForRemove(dragMiddle, 'dragMiddle')
    classListForRemove(dragRightTop, 'dragRightTop')
    classListForRemove(dragLeft, 'dragLeft')
    classListForRemove(noDrag, 'noDrag')
  }, 1)

  newState = buildState(newState, settings)

  return newState
}

function setIds(tasks) {
  const newTasks = {}
  Object.entries(tasks).forEach(([key, value], index) => {
    const newId = String(index + 1)
    newTasks[newId] = value
    value.id = newId
  })
  return newTasks
}

function buildState(newState, settings) {
  const { tasks } = newState
  const { tabsInRow } = settings

  let columnData = {}
  let columnOrderData = []

  let index = 1
  for (const task of Object.values(tasks)) {
    task.id = index.toString()
    index++
  }

  // Формируем данные "columns" и "columnOrder"
  const numColumns = Math.ceil(Object.keys(tasks).length / tabsInRow)
  const taskIds = Object.keys(tasks)
  for (let i = 0; i < numColumns; i++) {
    const columnId = `column-${i + 1}`
    columnOrderData.push(columnId)
    columnData[columnId] = {
      id: columnId,
      taskIds: taskIds.slice(i * tabsInRow, i * tabsInRow + tabsInRow),
    }
  }

  // На случай если количество вкладок кратно количеству вкладок в ряду, добавляем пустой ряд для кнопки добавления новой вкладки
  const columnNumber = taskIds.length % tabsInRow
  if (columnNumber === 0) {
    columnOrderData.push('column-' + (columnOrderData.length + 1))
    columnData['column-' + columnOrderData.length] = {
      id: 'column-' + columnOrderData.length,
      taskIds: [],
    }
  }

  newState.columns = columnData
  newState.columnOrder = columnOrderData

  return newState
}

function classListForRemove(array, css) {
  for (var i = 0; i < array.length; i++) {
    array[i].classList.remove(css)
  }
}
