import localForage from 'localforage'
import DataGet from '../firebase/DataGet'
import DataUpdate from '../firebase/DataUpdate'
import SpaceUpdate from '../firebase/SpaceUpdate'

const SyncData = async ({
  path,
  uid,
  device,
  space,
  fromFirebase,
  assignGlobal,
}) => {
  try {
    // Get data from localForage
    const data = await localForage.getItem(path)

    // Get latest data from database
    const base = await DataGet({ uid })

    // Return early if no local data is found
    if (!data) return null

    // Compare timestamps
    if (data.time === base.time) {
      // Data has not changed
    } else if (data.time > base.time) {
      // Local data is newer than database, update database
      // Remove spaces[0] from data object and assign to space0 variable and assign to spaces variable without spaces[0]
      const { [0]: space0, ...spaces } = data.spaces

      delete space0.columnOrder
      delete space0.columns
      delete space0.search

      Object.keys(spaces).forEach((key) => {
        const space = spaces[key]
        delete space.columnOrder
        delete space.columns
        delete space.search
        Object.keys(space.tasks).forEach((taskKey) => {
          const task = space.tasks[taskKey]
          if (task.tasks) {
            delete task.columnOrder
            delete task.columns
            delete task.search
          }
        })
        if (!space.icon) space.icon = 'bx bx-bookmarks'
      })
      spaces.time = data.time
      await DataUpdate({ uid, data: spaces })
      await SpaceUpdate({ uid, space: space0 })
    } else if (data.time < base.time) {
      // Local data is older than database, update local data
      const firebase = await fromFirebase(path, uid, device)
      return assignGlobal(firebase, space)
    }
  } catch (error) {
    console.error(error)
  }
}

export default SyncData
