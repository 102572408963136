import React from 'react'
import { Container } from './ListScroll.styled'
import initialList from './initialList'

export default function ListScroll({ icon, setIcon, dark }) {
  const iconList = initialList

  const chooseIcon = (e) => {
    setIcon(iconList[e])
  }

  let iconScroll = document.getElementById('iconScroll')
  if (iconScroll) {
    iconScroll.addEventListener('wheel', function (event) {
      event.preventDefault()
      let delta = event.deltaY || event.detail || event.wheelDelta
      if (delta > 0) {
        iconScroll.scrollLeft += 20
      } else {
        iconScroll.scrollLeft -= 20
      }
    })
  }

  const iconFirst = iconList.map((item, index) => {
    if (index % 2 == 0) {
      return (
        <div
          className={icon == item ? 'chooseIcon active' : 'chooseIcon'}
          key={index}
          onClick={() => chooseIcon(index)}
        >
          <i className={item}></i>
        </div>
      )
    }
  })
  const iconSecond = iconList.map((item, index) => {
    if (index % 2 != 0) {
      return (
        <div
          className={icon == item ? 'chooseIcon active' : 'chooseIcon'}
          key={index}
          onClick={() => chooseIcon(index)}
        >
          <i className={item}></i>
        </div>
      )
    }
  })

  function getClassName() {
    let className = 'iconContainer'
    if (dark) className += ' dark'
    return className
  }

  return (
    <Container>
      <div className={getClassName()} id="iconScroll">
        <div className="iconLine" style={{ marginTop: '1vw' }}>
          {iconFirst}
        </div>
        <div className="iconLine" style={{ marginBottom: '1vw' }}>
          {iconSecond}
        </div>
      </div>
    </Container>
  )
}
