import React from 'react'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import UploaderFile from '../uploader/UploaderFile'
import initialLanguage from '../language/initialLanguage'
import withLocalForage from '../context/withLocalForage'

const BackgroundUploader = ({ updateBackground }) => {
  const { currentUser } = useAuth()
  let path = `background${currentUser ? currentUser.uid : ''}`

  const { global, lang } = useData()
  const { settings } = global

  const screenWidth = window.screen.width
  const screenHeight = window.screen.height

  const handlerChange = async (files) => {
    let reader = new FileReader()
    reader.readAsDataURL(files[0])
    reader.onload = function () {
      let image = new Image()
      image.src = reader.result
      image.onload = function () {
        let width = image.width
        let height = image.height
        if (width / height < 16 / 9) {
          let newHeight = (width / 16) * 9
          let top = (height - newHeight) / 2
          let canvas = document.createElement('canvas')
          canvas.width = screenWidth
          canvas.height = screenHeight
          let ctx = canvas.getContext('2d')
          ctx.drawImage(
            image,
            0,
            top,
            width,
            newHeight,
            0,
            0,
            screenWidth,
            screenHeight
          )
          let base64Image = canvas.toDataURL('image/webp')
          updateBackground(path, base64Image, 'base64')
          return
        }
        if (width / height > 16 / 9) {
          let newWidth = (height / 9) * 16
          let left = (width - newWidth) / 2
          let canvas = document.createElement('canvas')
          canvas.width = screenWidth
          canvas.height = screenHeight
          let ctx = canvas.getContext('2d')
          ctx.drawImage(
            image,
            left,
            0,
            newWidth,
            height,
            0,
            0,
            screenWidth,
            screenHeight
          )
          let base64Image = canvas.toDataURL('image/webp')
          updateBackground(path, base64Image, 'base64')
          return
        }
        if (width > screenWidth || height > screenHeight) {
          let canvas = document.createElement('canvas')
          let ctx = canvas.getContext('2d')
          canvas.width = screenWidth
          canvas.height = screenHeight
          ctx.drawImage(image, 0, 0, screenWidth, screenHeight)
          let base64Image = canvas.toDataURL('image/webp')
          updateBackground(path, base64Image, 'base64')
          return
        }
        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext('2d')
        canvas.width = width
        canvas.height = height
        ctx.drawImage(image, 0, 0, width, height)
        let base64Image = canvas.toDataURL('image/webp')
        updateBackground(path, base64Image, 'base64')
        return
      }
    }
  }
  return (
    <UploaderFile
      id="uploaderBackground" //unique id for each component
      handlerChange={handlerChange}
      styled={{
        theme: settings.styleTheme ? true : false, //true - dark, false - light
        icon: 'bx bx-image-add', //i`m using boxicons
        iconColor: '#0364c1',
        iconColorDark: '#fff',
        iconColorHover: '#2b7de9',
        iconColorHoverDark: '#fff',

        textColor: '#666',
        textColorDark: '#fff',
        textColorHover: '#666',
        textColorHoverDark: '#fff',

        borderWidth: 2, //px
        borderStyle: 'dashed', //solid, dashed, dotted
        borderColor: '#0364c1',
        borderColorDark: '#fff',
        borderColorHover: '#2b7de9',
        borderColorHoverDark: '#fff',

        backgroundColor: '#fff',
        backgroundColorDark: '#2e275c',
        backgroundColorHover: '#f7f7f7',
        backgroundColorHoverDark: '#251f4a',

        fontSize: 12, //px
        heightContainer: 65, //px
        widthTypesSizeLabel: 150, //px
      }}
      label={{
        main: initialLanguage[lang].uf_main,
        drag: initialLanguage[lang].uf_drag,
        success: initialLanguage[lang].uf_success,
        errorMaxSize:
          '<span style="color:red">You can only upload files <u>up to 5MB</u></span>',
        errorMinSize: initialLanguage[lang].uf_errorMinSize,
        errorType: initialLanguage[lang].uf_errorType,
        errorMultiple: initialLanguage[lang].uf_errorMultiple,
      }}
      settings={{
        children: false, //you can add your own component
        multiple: false, // Allow multiple files to be uploaded
        types: ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'],
        typesLabel: 'JPEG, PNG, WEBP',
        maxSize: 5, //MB
        minSize: 0, //MB (0 = no min size)
      }}
    />
  )
}

export default withLocalForage(BackgroundUploader)
