import styled from 'styled-components'
export const Container = styled.div`
  .iconContainer {
    overflow-x: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0364c1 #ffffff00;
  }

  .iconLine {
    display: flex;
    flex-wrap: nowrap;
  }

  .iconContainer .chooseIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    padding: 0.8vw;
    color: #0364c1;
    float: left;
    transition: all 0.5s ease;
  }

  .iconContainer.dark .chooseIcon {
    color: #fff;
  }
  .iconContainer .chooseIcon.active {
    background-color: #e7e7e7;
  }
  .iconContainer.dark .chooseIcon.active {
    background-color: #2e275c;
  }

  .iconContainer .chooseIcon:hover {
    cursor: pointer;
    background-color: #e7e7e7;
  }

  .iconContainer.dark .chooseIcon:hover {
    background-color: #2e275c;
  }

  .iconContainer::-webkit-scrollbar {
    height: 0.4vw;
    min-height: 8px;
    background-color: #e7e7e7;
  }

  .iconContainer.dark::-webkit-scrollbar {
    height: 0.4vw;
    min-height: 8px;
    background-color: #999;
  }
  .iconContainer::-webkit-scrollbar-thumb {
    height: 0.4vw;
    min-height: 8px;
    background-color: #0364c1;
  }
`
