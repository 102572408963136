import React from 'react'
import { useData } from '../../context/DataContext'
import { Container } from '../../style/Add.styled'
import initialLanguage from '../../language/initialLanguage'

export default function Add({ tasks }) {
  const { global, space, lang, addButton, device, setAddModal } = useData()
  const { settings, styleData } = global

  const showAdd = tasks.length !== settings.tabsInRow ? true : false

  const classNameAdd = `button ${!addButton ? ' inactive' : ''} ${
    device === 'mobile' ? ' mobile' : ''
  }`

  function addButtonClick() {
    if (addButton) {
      setAddModal(true)
    }
  }
  return showAdd && space !== null ? (
    <>
      <Container
        styleData={styleData}
        dark={settings.styleTheme}
        headersBg={settings.headersBg}
        className={'addTab'}
      >
        <a onClick={addButtonClick}>
          <div className={classNameAdd}>
            <i className="bx bx-plus"></i>
          </div>
          <p className={addButton ? null : 'inactiveP'}>
            {initialLanguage[lang].add_site}
          </p>
        </a>
      </Container>
    </>
  ) : null
}
