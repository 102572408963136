import styled from 'styled-components'

export const Container = styled.div`
  .desktop {
    height: 2vw;
    min-height: 45px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .mobile {
    height: 3.25vw;
    min-height: 65px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .button {
    width: 100%;
    height: 3vw;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2vw;
    line-height: max(2vw, 40px);
    border: none;
    font-size: 1vw;
    font-size: max(1vw, 20px);
    font-weight: 700;
    color: #bababa;
    transition: all 0.5s ease;
    cursor: pointer;
  }

  .button.desktop {
    width: 100%;
    height: 1.75vw;
    min-height: 35px;

    font-weight: normal;
    margin: 0 auto;

    margin-right: 3vw;
    margin-right: max(3vw, 60px);
    margin-left: 3vw;
    margin-left: max(3vw, 60px);
    border-radius: 0.2vw;
    border-radius: max(0.2vw, 4px);
    font-size: 0.7vw;
    font-size: max(0.7vw, 14px);
  }

  @media (max-width: 1200px), screen and (max-height: 580px) {
    .button.desktop {
  
      margin-right: 1vw;
      margin-right: max(1vw, 20px);
      margin-left: 1vw;
      margin-left: max(1vw, 20px);
    }
  }

  //////////////////////////////////////////////////////////////////////////////


  .grey {
    background-color: #e7e7e7;
    color: #0364c1;
  }
  .grey:hover {
    background-color: #d5d5d5;s
    color: #0364c1;
  }

  .grey.dark {
    background-color: #2e275c;
    color: #fff;
  }
  .grey.dark:hover {
    background-color: #251f4a;
    color: #fff;
  }

  .blue {
    background-color: #0364c1;
    color: #fff;
  }

  .blue:hover {
    background-color: #2b7de9;
  }
  
  .red {
    background-color: #d00000;
    color: #fff;
  }

  .red:hover {
    background-color: #fc3220;
  }

  .google {
    background-color: #dc4e41;
    color: #fff;
  }
  .google:hover {
    background-color: #dd5e52;
    color: #fff;
  }

  .facebook {
    background-color: #3b5998;
    color: #fff;
  }
  .facebook:hover {
    background-color: #4c6599;
    color: #fff;
  }
  .twitter {
    background-color: #5baaf4;
    color: #fff;
  }
  .twitter:hover {
    background-color: #72b5f4;
    color: #fff;
  }
`
